import React from 'react'

const EditComponent = React.lazy(() => import('./user-edit.component'))

export default {
  to: '/home/users/:id',
  component: EditComponent,
  exact: true,
  isAdmin: true,
}
