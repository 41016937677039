import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Flex, Spinner } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { TEAM_QUERIES } from '../../components/coaches/queries/teams.queries'
import Filters from '../../components/coaches/filters/filters'
import { TeamInterface } from '../../components/coaches/store/coaches.types'
import { TeamContext } from '../../context/TeamContext/Context'
import ManagedTeams from '../../components/coaches/ui/ManagedTeams/ManagedTeams'
import {
  selectIsTeamManager,
  selectUser,
} from '../../components/auth/store/auth.selectors'

import './DirectorPortal.scss'
import {
  PaymentContext,
  IPaymentProvider,
} from '../../context/PaymentContext/Context'
import { UserInterface } from '../../components/users/store/users.types'

const DirectorPortal: React.FC = () => {
  const history = useHistory()
  const user: UserInterface | undefined = useSelector(selectUser)
  const hasManagerAccess = useSelector(selectIsTeamManager)
  const { setSelectedTeam, handleSelectTeam } = useContext<any>(TeamContext)
  const { openAddNewDashboard } = useContext(PaymentContext) as IPaymentProvider
  const [sortDirection, setSortDirection] = useState<boolean | null>(null)
  const [managedTeams, setManagedTeams] = useState<TeamInterface[] | null>([])

  const [refetchTeams, { data, loading: managerTeamsLoading }] = useLazyQuery(
    TEAM_QUERIES.GET_TEAMS_BY_OWNER,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        ownerId: user?.id,
      },
      onCompleted: (data) => {
        if (!data?.teamsByOwner) history.push('/home/my-team/new')
        else setManagedTeams(data.teamsByOwner)
      },
    }
  )

  const resetManagedTeams = () => setManagedTeams(data?.teamsByOwner || [])

  useEffect(() => {
    refetchTeams()
  }, [])

  const goToMyTeam = (team: TeamInterface) => {
    handleSelectTeam(team)
  }

  return (
    <>
      {managerTeamsLoading && (
        <Flex alignItems="center" justifyContent="center" w="100%" h="100vh">
          <Spinner />
        </Flex>
      )}
      <Box color="GrayText">
        <Filters
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          resetManagedTeams={resetManagedTeams}
          managedTeams={managedTeams}
          setManagedTeams={setManagedTeams}
          allTeams={data?.teamsByOwner || []}
        />
        <Box className="coach-portal__teams-container">
          <Box className="coach-portal__team-wrapper mobile-container">
            {managedTeams?.map((team: TeamInterface) => (
              <ManagedTeams
                team={team}
                setSelectedTeam={goToMyTeam}
                key={team.id}
              />
            ))}
            {hasManagerAccess && (
              <Button
                borderRadius="6px"
                variant="solid"
                isFullWidth
                bg="brand.400"
                _hover={{
                  bg: 'brand.500',
                }}
                color="white"
                py={7}
                onClick={openAddNewDashboard(() => setSelectedTeam(null))}
              >
                Add Team Dashboard
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default DirectorPortal
