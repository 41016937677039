import { gql } from '@apollo/client'

const createVideoRecommendation = gql`
  mutation createVideoRecommendation($data: VideoRecommendationCreateInput!) {
    createVideoRecommendation(data: $data) {
      id
      title
    }
  }
`

const deleteVideoRecommendation = gql`
  mutation deleteVideoRecommendation($id: String!) {
    deleteVideoRecommendation(id: $id) {
      id
    }
  }
`

const updateVideoRecommendation = gql`
  mutation updateVideoRecommendation(
    $id: String!
    $data: VideoRecommendationCreateInput!
  ) {
    updateVideoRecommendation(id: $id, data: $data) {
      id
      updatedAt
      title
      pillarSlug
    }
  }
`

export const RECOMMENDATION_MUTATIONS = {
  CREATE_RECOMMENDATION: createVideoRecommendation,
  UPDATE_RECOMMENDATION: updateVideoRecommendation,
  DELETE_RECOMMENDATION: deleteVideoRecommendation,
}
