import amplitude, { AmplitudeClient } from 'amplitude-js'
import isEmpty from 'lodash/isEmpty'

import { config } from '../config'

export enum AnalyticEvents {
  USER_NAVIGATE_TO = 'Navigate to ',
  USER_OPENED_APP = 'User opened Fitgmr app',
  USER_OPENED_SIGNUP = 'User started sign up process',
}

export class Analytics {
  private static amplitudeInstance?: AmplitudeClient

  private static timetOutRef: any

  private static debounceTime = 200

  public static getAmplitudeInstance(): AmplitudeClient | null {
    if (Analytics.amplitudeInstance) {
      return Analytics.amplitudeInstance
    }

    if (!isEmpty(config.amplitudeApiKey) && config.amplitudeApiKey) {
      Analytics.amplitudeInstance = amplitude.getInstance()
      Analytics.amplitudeInstance.init(config.amplitudeApiKey)
      return Analytics.amplitudeInstance
    }

    return null
  }

  public static track(
    eventName: AnalyticEvents | string,
    eventData: object | null = null
  ) {
    clearTimeout(Analytics.timetOutRef)
    Analytics.timetOutRef = setTimeout(() => {
      if (!Analytics.getAmplitudeInstance()) {
        console.log('[Amplitude] => Could not track: ', {
          eventName,
          eventData,
        })
        return
      }

      if (eventData) {
        Analytics.amplitudeInstance?.logEvent(eventName, eventData)
      } else {
        Analytics.amplitudeInstance?.logEvent(eventName)
      }
    }, Analytics.debounceTime)
  }
}
