import client from '../../../gql/client'
import {
  TeamCreatePayload,
  TeamFetchRequest,
  TeamInvitePayload,
  TeamRemoveUserPayload,
  TeamUpdatePayload,
  GetMessagesData,
  GetUserAnswer,
} from '../store/coaches.types'

import { TEAM_MUTATIONS } from '../mutations/teams.mutations'
import { TEAM_QUERIES } from '../queries/teams.queries'
import { JOURNAL_QUERIES } from '../queries/journal.queries'
import { IComment } from '../ui/journal-tabs/journalContext'
import { JOURNAL_MUTATIONS } from '../mutations/journal.mutations'

const teamFetchService = (data: TeamFetchRequest): Promise<any> =>
  client.query({
    query: TEAM_QUERIES.GET_TEAM,
    variables: data,
    fetchPolicy: 'no-cache',
  })

const journalComments = (data: GetMessagesData): Promise<any> =>
  client.query({
    query: JOURNAL_QUERIES.JOURNAL_COMMENTS,
    variables: data,
  })

const repliedComment = (data: { answerTo: string }): Promise<any> =>
  client.query({
    query: JOURNAL_QUERIES.REPLIED_COMMENT,
    variables: data,
  })

const userAnswer = (data: GetUserAnswer): Promise<any> =>
  client.query({
    query: JOURNAL_QUERIES.USER_ANSWER,
    variables: data,
  })

const teamUpdateService = (data: TeamUpdatePayload): Promise<any> =>
  client.mutate({
    mutation: TEAM_MUTATIONS.UPDATE_TEAM,
    variables: data,
  })

const teamCreateService = (data: TeamCreatePayload): Promise<any> =>
  client.mutate({
    mutation: TEAM_MUTATIONS.CREATE_TEAM,
    variables: data,
    update(cache, { data: { createTeam } }) {
      cache.modify({
        id: cache.identify({ __ref: `User:${createTeam.users[0].id}` }),
        fields: {
          Team() {
            return createTeam
          },
        },
      })
    },
  })

const sendInviteService = (data: TeamInvitePayload): Promise<any> =>
  client.mutate({
    mutation: TEAM_MUTATIONS.SEND_INVITATION,
    variables: data,
  })

const deleteInviteService = (data: string): Promise<any> =>
  client.mutate({
    mutation: TEAM_MUTATIONS.DELETE_INVITATION,
    variables: {
      id: data,
    },
  })

const removeUserFromTeamService = (data: TeamRemoveUserPayload): Promise<any> =>
  client.mutate({
    mutation: TEAM_MUTATIONS.REMOVE_FROM_TEAM,
    variables: data,
  })

const getSelectedTeam = (): Promise<any> =>
  client.mutate({
    mutation: TEAM_QUERIES.GET_SELECTED_TEAM,
    variables: {},
  })

const setSelectedTeam = (data: { teamId: string }): Promise<any> =>
  client.mutate({
    mutation: TEAM_MUTATIONS.SET_SELECTED_TEAM,
    variables: data,
  })

const createAnswer = (data: { data: Partial<IComment> }): Promise<any> =>
  client.mutate({
    mutation: JOURNAL_MUTATIONS.CREATE_COMMENT,
    variables: data,
  })

const updateAnswer = (data: { data: Partial<IComment> }): Promise<any> =>
  client.mutate({
    mutation: JOURNAL_MUTATIONS.UPDATE_COMMENT,
    variables: data,
  })

const deleteAnswer = (data: { id: string; userId: string }): Promise<any> =>
  client.mutate({
    mutation: JOURNAL_MUTATIONS.DELETE_COMMENT,
    variables: data,
  })

const TEAM_SERVICE = {
  TEAM_FETCH_SERVICE: teamFetchService,
  TEAM_UPDATE_SERVICE: teamUpdateService,
  TEAM_CREATE_SERVICE: teamCreateService,
  TEAM_INVITE_SERVICE: sendInviteService,
  TEAM_DELETE_INVITE_SERVICE: deleteInviteService,
  TEAM_REMOVE_USER: removeUserFromTeamService,
  GET_SELECTED_TEAM: getSelectedTeam,
  SET_SELECTED_TEAM: setSelectedTeam,
  GET_JOURNAL_COMMENTS: journalComments,
  GET_REPLIED_COMMENT: repliedComment,
  GET_USER_ANSWER: userAnswer,
  CREATE_ANSWER: createAnswer,
  UPDATE_ANSWER: updateAnswer,
  DELETE_ANSWER: deleteAnswer,
}

export default TEAM_SERVICE
