import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { PayloadedAction, SortOrder } from '../../../types'
import ContentService from './content.services'
import {
  CONTENT,
  InstructorDeleteInterfaceMany,
  InstructorSeriesCreateOrUpdateInterface,
  InstructorUpdateInterface,
  VideoAggregatedRequestInterface,
  VideoCrudInterface,
  VideoInterface,
  VideoListRequest,
  VideoQuestionCrudInterface,
  VideoSeriesDeleteInterfaceMany,
  VideoS3LinkInterface,
} from '../store/content.types'

import {
  setActionFailure,
  setActionInProgress,
  setActionListRequest,
  setActionListSuccess,
  setActionRequest,
  setActionSuccess,
  setUploadInProgress,
} from '../store/content.actions'
import {
  selectInstructors,
  selectSeries,
  selectStagingSeries,
  selectUpdateSeries,
  selectUploadedVideo,
  selectVideoList,
} from '../store/content.selectors'

function* videoCreate(action: PayloadedAction<VideoCrudInterface>): any {
  yield put(setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.VIDEO_CREATE_SERVICE,
      action.payload
    )
    const data: VideoInterface = response.data.createVideo
    yield put(setActionSuccess(CONTENT.CONTENT_CREATE_SUCCESS, data))
  } catch (err) {
    let error =
      "We're having some trouble creating the video content. Please try again later."
    if (
      err.message.includes('There is already a video with that title.') ||
      err.message.includes('Unique constraint failed on the fields: (`title`)')
    ) {
      error =
        "We're having some trouble creating the video content. There is already a video with that title."
    }
    yield put(
      setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videoUpload(action: PayloadedAction<VideoCrudInterface>): any {
  yield put(setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: '' }))
  yield put(setUploadInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.VIDEO_UPLOAD_SERVICE,
      action.payload
    )

    const data = response.data.uploadVideo

    yield put(setActionSuccess(CONTENT.CONTENT_UPLOAD_SUCCESS, data))
  } catch (err) {
    let error =
      "We're having some trouble uploading the video. Please try again later."
    if (
      err.message.includes('There is already a video with that title.') ||
      err.message.includes('Unique constraint failed on the fields: (`title`)')
    ) {
      error =
        "We're having some trouble creating the video content. There is already a video with that title."
    }
    yield put(
      setActionFailure(CONTENT.CONTENT_UPLOAD_FAILED, { message: error })
    )
  } finally {
    yield put(setUploadInProgress(false))
  }
}

function* videosList(action: PayloadedAction<VideoListRequest>): any {
  yield put(setActionFailure(CONTENT.CONTENT_LIST_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.VIDEO_LIST_SERVICE,
      action.payload
    )

    const data: VideoInterface[] = [...response.data.videos]
    yield put(setActionListSuccess(CONTENT.CONTENT_LIST_SUCCESS, data))
  } catch (err) {
    console.log(err)
    let error =
      "We're having some trouble fetching the video content. Please try again later."
    if (err.message.includes('There is already a video with that title.')) {
      error = err.message
    }
    yield put(setActionFailure(CONTENT.CONTENT_LIST_FAILED, { message: error }))
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videosAggregate(
  action: PayloadedAction<VideoAggregatedRequestInterface>
): any {
  yield put(
    setActionFailure(CONTENT.VIDEO_LIST_METADATA_FAILED, { message: '' })
  )
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.VIDEO_AGGREGATE_SERVICE,
      action.payload
    )
    if (response?.data?.aggregateVideo) {
      const { _count }: any = response.data.aggregateVideo
      const tags: any = response.data.allVideoTags
      yield put(
        setActionListSuccess(CONTENT.VIDEO_LIST_METADATA_SUCCESS, _count.id)
      )
      yield put(setActionListSuccess(CONTENT.VIDEO_TAGS_LIST_SUCCESS, tags))
    }
  } catch (err) {
    console.log(err)
    let error =
      "We're having some trouble fetching the video content. Please try again later."
    if (err.message.includes('There is already a video with that title.')) {
      error = err.message
    }
    yield put(
      setActionFailure(CONTENT.VIDEO_LIST_METADATA_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videoUpdate(action: PayloadedAction<VideoCrudInterface>): any {
  yield put(setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.VIDEO_UPDATE_SERVICE,
      action.payload
    )
    let selectedVideo: VideoInterface = yield select(selectUploadedVideo)
    const data = response.data.updateVideo
    selectedVideo = { ...selectedVideo, ...data }
    yield put(setActionSuccess(CONTENT.CONTENT_UPDATE_SUCCESS, selectedVideo))

    yield put(
      setActionSuccess(CONTENT.VIDEO_QUESTION_CREATE_SUCCESS, selectedVideo)
    )
  } catch (err) {
    let error =
      "We're having some trouble updating the video content. Please try again later."
    if (err.message.includes('There is already a video with that title.')) {
      error = err.message
    } else if (
      err.message.includes(
        'Unique constraint failed on the fields: (`seriesId`,`episode`)'
      )
    ) {
      error =
        'You may be trying to add this video to a series that already has that episode number. Please increment the episode number.'
    } else if (
      err.message.includes('Unique constraint failed on the fields: (`slug`)')
    ) {
      error =
        'Are you trying to add a the same question to this video? Please add a different question.'
    }
    yield put(
      setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videoSubscriptionUpdate(
  action: PayloadedAction<VideoInterface>
): any {
  const updatePayload = action.payload
  const videoList: VideoInterface[] = yield select(selectVideoList)
  const newVideoList = [...videoList]

  const index = videoList.findIndex((item) => item.id === updatePayload?.id)

  const updatedVideo = { ...newVideoList[index], ...updatePayload }

  newVideoList.splice(index, 1, updatedVideo)

  yield put(setActionListSuccess(CONTENT.CONTENT_LIST_SUCCESS, newVideoList))
}

function* videoEnable(action: PayloadedAction<VideoCrudInterface>): any {
  yield put(setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    yield call<any>(ContentService.VIDEO_ENABLE_SERVICE, action.payload)
    yield put(
      setActionListRequest(CONTENT.CONTENT_LIST_REQUEST, {
        take: 10,
        skip: 0,
        orderBy: [
          {
            updatedAt: SortOrder.desc,
          },
        ],
        where: {
          AND: [
            {
              enabled: {
                equals: false,
              },
            },
          ],
        },
      })
    )
  } catch (err) {
    console.log(err)
    let error =
      "We're having some trouble updating the video content. Please try again later."
    if (err.message.includes('There is already a video with that title.')) {
      error = err.message
    }
    yield put(
      setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videoDisable(action: PayloadedAction<VideoCrudInterface>): any {
  yield put(setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    yield call<any>(ContentService.VIDEO_DISABLE_SERVICE, action.payload)
    yield put(
      setActionListRequest(CONTENT.CONTENT_LIST_REQUEST, {
        take: 10,
        skip: 0,
        orderBy: [
          {
            updatedAt: SortOrder.desc,
          },
        ],
        where: {
          AND: [
            {
              enabled: {
                equals: true,
              },
            },
          ],
        },
      })
    )
  } catch (err) {
    console.log(err)
    let error =
      "We're having some trouble updating the video content. Please try again later."
    if (err.message.includes('There is already a video with that title.')) {
      error = err.message
    }
    yield put(
      setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}
function* videoInstructorUpdate(
  action: PayloadedAction<InstructorUpdateInterface>
): any {
  yield put(setActionFailure(CONTENT.INSTRUCTOR_UPDATE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.INSTRUCTOR_UPDATE_SERVICE,
      action.payload
    )
    const { updateVideoInstructor } = response.data

    yield put(
      setActionListSuccess(
        CONTENT.INSTRUCTOR_UPDATE_SUCCESS,
        updateVideoInstructor
      )
    )
  } catch (err) {
    let error =
      "We're having some trouble creating the instructor. Please try again later."
    if (
      err.message.includes('There is already a instructor with that name.') ||
      err.message.includes('Unique constraint failed on the fields: (`name`)')
    ) {
      error =
        "We're having some trouble creating the instructor content. There is already a instructor with that name."
    }
    yield put(
      setActionFailure(CONTENT.INSTRUCTOR_UPDATE_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videoInstructorDelete(
  action: PayloadedAction<InstructorDeleteInterfaceMany>
): any {
  yield put(setActionFailure(CONTENT.INSTRUCTOR_DELETE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const instructorPayloads = action?.payload?.where?.instructors
    if (instructorPayloads) {
      for (let i: number = 0; i < instructorPayloads.length; i++) {
        const response = yield call<any>(
          ContentService.INSTRUCTOR_DELETE_SERVICE,
          instructorPayloads[i]
        )
        const { deleteVideoInstructor } = response.data
        yield put(
          setActionSuccess(
            CONTENT.INSTRUCTOR_DELETE_SUCCESS,
            deleteVideoInstructor
          )
        )
      }
    }
  } catch (err) {
    console.log({ err })
    const error =
      "We're having some trouble deleting the instructor. Please try again later."

    yield put(
      setActionFailure(CONTENT.INSTRUCTOR_DELETE_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videoSerieDeleteMany(
  action: PayloadedAction<VideoSeriesDeleteInterfaceMany>
): any {
  yield put(setActionFailure(CONTENT.SERIES_DELETE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const seriePayload = action?.payload?.where?.series
    if (seriePayload) {
      for (let i: number = 0; i < seriePayload.length; i++) {
        const response = yield call<any>(
          ContentService.SERIE_DELETE_SERVICE,
          seriePayload[i]
        )

        const { deleteVideoSeries } = response.data
        yield put(
          setActionSuccess(CONTENT.SERIES_DELETE_SUCCESS, deleteVideoSeries)
        )
      }
    }
  } catch (err) {
    console.log({ err })
    const error =
      "We're having some trouble deleting the series. Please try again later."

    yield put(
      setActionFailure(CONTENT.SERIES_DELETE_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videoDelete(action: PayloadedAction<VideoCrudInterface>): any {
  yield put(setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.VIDEO_DELETE_SERVICE,
      action.payload
    )
    const videos: VideoInterface[] = yield select(selectVideoList)
    const { id } = response.data.deleteVideo

    const newVideoList = videos.filter((i) => i.id !== id)
    yield put(setActionListSuccess(CONTENT.CONTENT_LIST_SUCCESS, newVideoList))
  } catch (err) {
    console.log(err)
    const error =
      "We're having some trouble deleting the video content. Please try again later."
    yield put(
      setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videoInstructorAndSeriesList(): any {
  yield put(
    setActionFailure(CONTENT.INSTRUCTOR_SERIES_LIST_FAILED, { message: '' })
  )
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.INSTRUCTOR_AND_SERIES_RETRIEVE_SERVICE
    )
    const { findManyVideoSeries, videoInstructors } = response.data
    const data = {
      series: findManyVideoSeries,
      instructors: videoInstructors,
    }
    yield put(
      setActionListSuccess(CONTENT.INSTRUCTOR_SERIES_LIST_SUCCESS, data)
    )
  } catch (err) {
    const error =
      "We're having some trouble fetching the video options. Please try again later."
    yield put(
      setActionFailure(CONTENT.INSTRUCTOR_SERIES_LIST_FAILED, {
        message: error,
      })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videoSeriesCreate(
  action: PayloadedAction<InstructorSeriesCreateOrUpdateInterface>
): any {
  yield put(setActionFailure(CONTENT.SERIES_CREATE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.SERIES_CREATE_SERVICE,
      action.payload
    )
    const series = yield select(selectSeries)
    const data = response.data.createVideoSeries
    const payload = [...series, data]
    yield put(
      setActionSuccess(CONTENT.SERIES_CREATE_STAGING_REQUEST, {
        stagingSeries: data,
      })
    )
    yield put(setActionListSuccess(CONTENT.SERIES_CREATE_SUCCESS, payload))
  } catch (err) {
    let error =
      "We're having some trouble creating the series. Please try again later."
    if (
      err.message.includes('There is already a series with that name.') ||
      err.message.includes('Unique constraint failed on the fields: (`name`)')
    ) {
      error =
        "We're having some trouble creating the series content. There is already a series with that name."
    }
    yield put(
      setActionFailure(CONTENT.SERIES_CREATE_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videoSeriesUpdate(
  action: PayloadedAction<InstructorSeriesCreateOrUpdateInterface>
): any {
  yield put(setActionFailure(CONTENT.SERIES_UPDATE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const { payload } = action
    const step = payload?.stepNumber

    if (payload?.stepNumber) {
      delete payload.stepNumber
    }

    const updatedSeries = yield select(selectUpdateSeries)
    const stagingSeries = yield select(selectStagingSeries)

    const response = yield call<any>(
      ContentService.SERIES_UPDATE_SERVICE,
      action.payload
    )
    const statePayload: any = {}
    const data = response.data.updateVideoSeries
    if (step === 3 && stagingSeries) {
      data.isComplete = step === 3
      statePayload.stagingSeries = data
      statePayload.updatedSeries = null
    } else if (step === 1) {
      data.coverPhotoUpdated = false
      statePayload.updatedSeries = data
      statePayload.stagingSeries = null
    } else if (updatedSeries && step === 3) {
      data.isComplete = step === 3
      statePayload.updatedSeries = data
    }

    yield put(
      setActionSuccess(CONTENT.SERIES_CREATE_STAGING_REQUEST, statePayload)
    )
  } catch (err) {
    let error =
      "We're having some trouble updating the series. Please try again later."
    if (
      err.message.includes('There is already a series with that name.') ||
      err.message.includes('Unique constraint failed on the fields: (`name`)')
    ) {
      error =
        "We're having some trouble creating the series content. There is already a series with that name."
    }
    yield put(
      setActionFailure(CONTENT.SERIES_UPDATE_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
    yield put(setActionRequest(CONTENT.INSTRUCTOR_SERIES_LIST_REQUEST, {}))
  }
}

function* seriesCoverImageUpload(
  action: PayloadedAction<VideoCrudInterface>
): any {
  yield put(setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.SERIES_COVER_IMAGE_UPLOAD_SERVICE,
      action.payload
    )

    const payload: any = {}

    const updatedSeries = yield select(selectUpdateSeries)
    const stagingSeries = yield select(selectStagingSeries)

    const data = response.data.uploadVideoSeriesCoverImage
    data.coverPhotoUpdated = true
    payload.updatedSeries = data

    if (stagingSeries?.id) {
      payload.stagingSeries = data
    }
    if (updatedSeries?.id) {
      data.coverPhotoUpdated = true
      payload.updatedSeries = data
    }
    if (!stagingSeries?.id && !updatedSeries?.id) {
      yield put(setActionRequest(CONTENT.INSTRUCTOR_SERIES_LIST_REQUEST, {}))
    }
    yield put(setActionSuccess(CONTENT.SERIES_CREATE_STAGING_REQUEST, payload))
  } catch (err) {
    let error =
      "We're having some trouble uploading the video. Please try again later."
    if (
      err.message.includes('There is already a video with that title.') ||
      err.message.includes('Unique constraint failed on the fields: (`title`)')
    ) {
      error =
        "We're having some trouble creating the video content. There is already a video with that title."
    }
    yield put(
      setActionFailure(CONTENT.CONTENT_UPLOAD_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videoThumbnailUpload(
  action: PayloadedAction<VideoCrudInterface>
): any {
  yield put(setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.VIDEO_THUMBNAIL_UPLOAD_SERVICE,
      action.payload
    )

    const data = response.data.uploadVideoThumbnail
    let selectedVideo: VideoInterface = yield select(selectUploadedVideo)
    selectedVideo = { ...selectedVideo, ...data }
    yield put(setActionSuccess(CONTENT.CONTENT_CREATE_SUCCESS, selectedVideo))
    yield put(
      setActionSuccess(
        CONTENT.VIDEO_THUMBNAIL_IMAGE_UPLOAD_SUCCESS,
        selectedVideo
      )
    )
  } catch (err) {
    let error =
      "We're having some trouble uploading the video. Please try again later."
    if (
      err.message.includes('There is already a video with that title.') ||
      err.message.includes('Unique constraint failed on the fields: (`title`)')
    ) {
      error =
        "We're having some trouble creating the video content. There is already a video with that title."
    }
    yield put(
      setActionFailure(CONTENT.CONTENT_UPLOAD_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videoInstructorCreate(
  action: PayloadedAction<InstructorSeriesCreateOrUpdateInterface>
): any {
  yield put(setActionFailure(CONTENT.INSTRUCTOR_CREATE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.INSTRUCTOR_CREATE_SERVICE,
      action.payload
    )
    const instructors = yield select(selectInstructors)
    const data = response.data.createVideoInstructor
    const payload = [...instructors, data]
    yield put(setActionListSuccess(CONTENT.INSTRUCTOR_CREATE_SUCCESS, payload))
  } catch (err) {
    let error =
      "We're having some trouble creating the instructor. Please try again later."
    if (
      err.message.includes('There is already a instructor with that name.') ||
      err.message.includes('Unique constraint failed on the fields: (`name`)')
    ) {
      error =
        "We're having some trouble creating the instructor content. There is already a instructor with that name."
    }
    yield put(
      setActionFailure(CONTENT.INSTRUCTOR_CREATE_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* contentGetS3Link(action: PayloadedAction<VideoS3LinkInterface>): any {
  yield put(
    setActionFailure(CONTENT.S3LINK_GET_S3_LINK_FAILED, { message: '' })
  )
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.VIDEO_GET_S3_LINK_SERVICE,
      action.payload
    )
    const data = response.data.getS3Link

    yield put(setActionSuccess(CONTENT.S3LINK_GET_S3_LINK_SUCCESS, data))
  } catch (err) {
    yield put(
      setActionFailure(CONTENT.S3LINK_GET_S3_LINK_FAILED, { message: 'error' })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* videoQuestionDelete(
  action: PayloadedAction<VideoQuestionCrudInterface>
): any {
  yield put(
    setActionFailure(CONTENT.VIDEO_QUESTION_DELETE_FAILED, { message: '' })
  )
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      ContentService.VIDEO_QUESTION_DELETE_SERVICE,
      action.payload
    )

    const videos: VideoInterface[] = yield select(selectVideoList)
    const newList = [...videos]
    const { id } = response.data.deleteVideoQuestion

    const video = {
      ...newList.filter((i) => i.VideoQuestion.some((i) => i.id === id))[0],
    }

    const questions = [...video.VideoQuestion]

    const question = video.VideoQuestion.findIndex((i) => i.id === id)

    questions.splice(question, 1)

    video.VideoQuestion = questions
    newList.splice(
      newList.findIndex((i) => i.id === video.id),
      1,
      video
    )

    yield put(setActionListSuccess(CONTENT.CONTENT_LIST_SUCCESS, newList))
  } catch (err) {
    console.log(err)
    const error =
      "We're having some trouble deleting the question. Please try again later."
    yield put(
      setActionFailure(CONTENT.VIDEO_QUESTION_DELETE_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
    yield put(
      setActionListRequest(CONTENT.CONTENT_LIST_REQUEST, {
        take: 10,
        skip: 0,
        orderBy: [
          {
            title: SortOrder.asc,
          },
        ],
        where: {
          AND: [
            {
              enabled: {
                equals: true,
              },
            },
          ],
        },
      })
    )
  }
}

function* contentSagas() {
  yield takeLatest(CONTENT.S3LINK_GET_S3_LINK_REQUEST, contentGetS3Link)

  yield takeLatest(CONTENT.CONTENT_CREATE_REQUEST, videoCreate)
  yield takeLatest(CONTENT.CONTENT_LIST_REQUEST, videosList)
  yield takeLatest(CONTENT.CONTENT_UPDATE_REQUEST, videoUpdate)
  yield takeLatest(CONTENT.CONTENT_ENABLE_REQUEST, videoEnable)
  yield takeLatest(CONTENT.CONTENT_DISABLE_REQUEST, videoDisable)
  yield takeLatest(
    CONTENT.INSTRUCTOR_SERIES_LIST_REQUEST,
    videoInstructorAndSeriesList
  )
  yield takeLatest(CONTENT.CONTENT_UPLOAD_REQUEST, videoUpload)
  yield takeLatest(CONTENT.SERIES_CREATE_REQUEST, videoSeriesCreate)
  yield takeLatest(CONTENT.SERIES_UPDATE_REQUEST, videoSeriesUpdate)
  yield takeLatest(CONTENT.SERIES_DELETE_REQUEST, videoSerieDeleteMany)
  yield takeLatest(CONTENT.INSTRUCTOR_CREATE_REQUEST, videoInstructorCreate)
  yield takeLatest(CONTENT.INSTRUCTOR_UPDATE_REQUEST, videoInstructorUpdate)
  yield takeLatest(CONTENT.INSTRUCTOR_DELETE_REQUEST, videoInstructorDelete)
  yield takeLatest(CONTENT.CONTENT_DELETE_REQUEST, videoDelete)
  yield takeLatest(CONTENT.VIDEO_LIST_METADATA_REQUEST, videosAggregate)
  yield takeLatest(CONTENT.VIDEO_QUESTION_DELETE_REQUEST, videoQuestionDelete)
  yield takeLatest(
    CONTENT.SERIES_COVER_IMAGE_UPLOAD_REQUEST,
    seriesCoverImageUpload
  )
  yield takeLatest(
    CONTENT.VIDEO_THUMBNAIL_IMAGE_UPLOAD_REQUEST,
    videoThumbnailUpload
  )
  yield takeLatest(
    CONTENT.VIDEO_SUBSCRIPTION_UPDATE_REQUEST,
    videoSubscriptionUpdate
  )
}

export default function* contentSaga() {
  yield all([contentSagas()])
}
