import React from 'react'
import { motion } from 'framer-motion'

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5,
}

const Page: React.FC = ({ children }) => (
  <motion.div
    style={{ width: '100%' }}
    transition={pageTransition}
    initial="initial"
    animate="in"
    exit="out"
  >
    {children}
  </motion.div>
)

export default Page
