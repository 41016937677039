import { Action } from 'redux'
import { PayloadedAction } from '../../../types'

import {
  AUTH,
  UserCredentials,
  AuthPayload,
  AuthFailPayload,
  ForgotPasswordRequest,
  ForgotPasswordVerifyRequest,
  ChangePasswordRequest,
  PasswordResetPayload,
  RefreshTokenPayload,
  RefreshTokenRequest,
  UserSignupCredentials,
} from './auth.types'

export const setAuthActionInProgress: (isLoading: boolean) => PayloadedAction =
  (isLoading) => ({
    type: AUTH.LOADING,
    payload: {
      isLoading,
    },
  })

export const setLoginRequest: (
  userCredentials: UserCredentials
) => PayloadedAction = (userCredentials) => ({
  type: AUTH.LOGIN_REQUEST,
  payload: userCredentials,
})

export const setLoginSuccess: (authPayload: AuthPayload) => PayloadedAction = (
  authPayload
) => ({
  type: AUTH.LOGIN_SUCCESS,
  payload: {
    ...authPayload,
  },
})

export const setLoginFailure: (
  failPayload: AuthFailPayload
) => PayloadedAction = (failPayload) => ({
  type: AUTH.LOGIN_FAILED,
  payload: {
    ...failPayload,
  },
})

export const setSignUpRequest: (
  userCredentials: UserSignupCredentials
) => PayloadedAction = (userCredentials) => ({
  type: AUTH.SIGNUP_REQUEST,
  payload: userCredentials,
})

export const setSignUpSuccess: (authPayload: AuthPayload) => PayloadedAction = (
  authPayload
) => ({
  type: AUTH.SIGNUP_SUCCESS,
  payload: {
    ...authPayload,
  },
})

export const setSignUpFailure: (
  failPayload: AuthFailPayload
) => PayloadedAction = (failPayload) => ({
  type: AUTH.SIGNUP_FAILED,
  payload: {
    ...failPayload,
  },
})

export const setLogoutSuccess: () => Action = () => ({
  type: AUTH.LOGOUT_SUCCESS,
})

export const setForgotPasswordRequest: (
  request: ForgotPasswordRequest
) => PayloadedAction = (request) => ({
  type: AUTH.FORGOT_PASSWORD_REQUEST,
  payload: request,
})

export const setForgotPasswordVerifyRequest: (
  request: ForgotPasswordVerifyRequest
) => PayloadedAction = (request) => ({
  type: AUTH.FORGOT_PASSWORD_VERIFY_REQUEST,
  payload: request,
})

export const setChangePasswordRequest: (
  request: ChangePasswordRequest
) => PayloadedAction = (request) => ({
  type: AUTH.CHANGE_PASSWORD_REQUEST,
  payload: request,
})

export const setPasswordResetSuccess: (
  payload: PasswordResetPayload
) => PayloadedAction = (payload) => ({
  type: AUTH.FORGOT_PASSWORD_SUCCESS,
  payload: {
    ...payload,
  },
})

export const setPasswordResetFailure: (
  failPayload: AuthFailPayload
) => PayloadedAction = (failPayload) => ({
  type: AUTH.FORGOT_PASSWORD_FAILED,
  payload: {
    ...failPayload,
  },
})

export const setTokenRefreshRequest: (
  request: RefreshTokenRequest
) => PayloadedAction = (request) => ({
  type: AUTH.REFRESH_TOKEN_REQUEST,
  payload: request,
})

export const setTokenRefreshSuccess: (
  payload: RefreshTokenPayload
) => PayloadedAction = (payload) => ({
  type: AUTH.REFRESH_TOKEN_SUCCESS,
  payload: {
    ...payload,
  },
})

export const setTokenRefreshFailure: (
  failPayload: AuthFailPayload
) => PayloadedAction = (failPayload) => ({
  type: AUTH.REFRESH_TOKEN_FAILED,
  payload: {
    ...failPayload,
  },
})

export const setUpdateUser: (payload: any) => PayloadedAction = (payload) => ({
  type: AUTH.REFRESH_USER,
  payload,
})

export const setUpdateUserTeam: (payload: any) => PayloadedAction = (
  payload
) => ({
  type: AUTH.REFRESH_USER_TEAM,
  payload,
})

export const setUpdateUserGroups: (payload: any) => PayloadedAction = (
  payload
) => ({
  type: AUTH.UPDATE_USER_GROUP,
  payload: {
    ...payload,
  },
})

export const setTeamSeats: (payload: any) => PayloadedAction = (payload) => ({
  type: AUTH.UPDATE_TEAM_SEATS,
  payload: {
    ...payload,
  },
})

export const setRedirectUrl: (payload: {
  redirectTo: string
}) => PayloadedAction = (payload) => ({
  type: AUTH.SET_REDIRECT_URL,
  payload,
})
