import React from 'react'
import {
  HStack,
  Box,
  Tag,
  TagLabel,
  TagCloseButton,
  Stack,
  Text,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import { AnimateSharedLayout, motion } from 'framer-motion'
import DropdownFilter from '../../components/shared/ui/dropdown-filter/DropdownFilter'
import SearchBox from '../../components/shared/ui/search/Search'
import CustomSelect from '../../components/shared/ui/select'

export default function SearchAndFilter({
  selectedFilters,
  filterOptions,
  filters,
  setFilters,
  handleClearFilters,
  handleDeleteFilters,
  setSearch,
  search,
  actions,
  setEnabledFilter,
  enabledFilter,
}: any) {
  const handleLabel = (label: string) => {
    let labelName: string

    switch (label) {
      case 'DIRECTOR':
        labelName = 'Director'
        break
      case 'COACH':
        labelName = 'Coach'
        break
      case 'FITGMR_FREE':
        labelName = 'FITGMR Free'
        break
      case 'FITGMR_PRO':
        labelName = 'FITGMR Pro'
        break
      case 'FITGMR_PRO_BYTEAM':
        labelName = 'FITGMR Pro By Team'
        break
      default:
        labelName =
          label.charAt(0).toUpperCase() +
          label.substr(1, label.length).toLowerCase().replace('_', ' ')
        break
    }
    return labelName
  }

  const activeSelectOptions = [
    // Nice to have this in the future (we need to change the api)
    {
      value: '',
      label: 'All',
    },
    {
      value: true,
      label: 'Active',
    },
    {
      value: false,
      label: 'Inactive',
    },
  ]

  return (
    <Box display="flex" justifyContent="space-between">
      <HStack>
        <Box>
          <FormLabel margin="0" fontSize="14px" htmlFor="filters">
            Filter
          </FormLabel>
          <AnimateSharedLayout>
            <>
              {!selectedFilters.length ? (
                <DropdownFilter
                  type="filter"
                  filterOptions={filterOptions}
                  filterState={filters}
                  onSetFilters={setFilters}
                  handleLabel={handleLabel}
                />
              ) : (
                <Box
                  as={motion.div}
                  initial={{ opacity: 0, width: 'auto' }}
                  exit={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 'auto' }}
                  layoutId="filter-button"
                  overflow="auto"
                  whiteSpace="nowrap"
                  className="tag-container"
                  bgColor="#272727"
                  borderRadius="4px"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  height="34px"
                  maxWidth="100%"
                >
                  <Box
                    overflow="auto"
                    whiteSpace="nowrap"
                    className="tag-container"
                  >
                    {selectedFilters.map((i: any) => {
                      const label = i.value
                      return (
                        <Tag
                          key={i.value}
                          height="20px"
                          position="relative"
                          padding="3px 10px"
                          borderRadius="6px"
                          bgColor="#424242"
                          margin="2px"
                          visibility="visible"
                        >
                          <TagLabel fontWeight="500" fontSize="12px">
                            {handleLabel(label)}
                          </TagLabel>
                          <TagCloseButton
                            onClick={() => handleDeleteFilters(i)}
                          />
                        </Tag>
                      )
                    })}
                  </Box>
                  <Text
                    onClick={handleClearFilters}
                    cursor="pointer"
                    margin="0 14px"
                    fontSize="10px"
                    fontWeight="500"
                  >
                    See All
                  </Text>
                </Box>
              )}
            </>
          </AnimateSharedLayout>
        </Box>
      </HStack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className="user-tabs__user_list__tabs__tab-panels__panel__header"
        margin="0px"
      >
        <HStack>
          <FormControl id="enable-filter" width="300px">
            <FormLabel margin="0" fontSize="14px">
              Status
            </FormLabel>
            <CustomSelect
              name="enable-filter"
              defaultValue={enabledFilter}
              onSelect={(val: any) => setEnabledFilter(val)}
              options={activeSelectOptions}
            />
          </FormControl>
        </HStack>
        <FormControl id="search-filter">
          <FormLabel margin="0" fontSize="14px">
            Search
          </FormLabel>
          <SearchBox
            className="search"
            placeholder=" "
            val={search}
            icon
            onChange={setSearch}
          />
        </FormControl>

        <Stack direction={['column', 'row']}>{actions}</Stack>
      </Stack>
    </Box>
  )
}
