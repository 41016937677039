import { gql } from '@apollo/client'

const updateTeam = gql`
  mutation UpdateTeam(
    $data: TeamUpdateInput!
    $where: TeamWhereUniqueInput!
    $usersWhere: UserWhereInput
    $usersOrderBy: [UserOrderByWithRelationInput!]
    $usersTake: Int
    $usersSkip: Int
  ) {
    updateTeam(data: $data, where: $where) {
      id
      name
      description
      avatar
      game
      createdAt
      updatedAt
      users(
        where: $usersWhere
        orderBy: $usersOrderBy
        take: $usersTake
        skip: $usersSkip
      ) {
        id
        usedDiscord
        email
        fullName
        username
        birthGender
        enabled
        role
        createdAt
        updatedAt
      }
      TeamInvites {
        id
        createdAt
        updatedAt
        inviteeEmail
        inviteeName
        inviterUserId
        role
      }
    }
  }
`

const createTeam = gql`
  mutation CreateTeam($data: TeamCreateWithoutUsersInput!) {
    createTeam(data: $data) {
      id
      name
      description
      avatar
      game
      createdAt
      updatedAt
      users {
        id
        usedDiscord
        email
        fullName
        username
        birthGender
        enabled
        role
        createdAt
        updatedAt
        score {
          score
          prevDayScore
          todayScore

          pillarsIsolatedScore {
            score
            pillarSlug
          }
          extraPillarsTodayScores {
            pillarSlug
            score
          }
          pillars {
            avgCompletion
          }
        }
      }
      TeamInvites {
        id
        createdAt
        updatedAt
        inviteeEmail
        inviteeName
        role
      }
    }
  }
`

const uploadTeamAvatar = gql`
  mutation UploadTeamAvatar($file: Upload!, $teamId: String) {
    uploadTeamAvatar(file: $file, teamId: $teamId)
  }
`

const uploadAvatar = gql`
  mutation UploadAvatar($file: Upload!) {
    uploadAvatar(file: $file)
  }
`

const sendInvite = gql`
  mutation SendInvitation(
    $inviteeName: String!
    $inviteeEmail: String!
    $role: Role
    $teamId: String
  ) {
    sendInvite(
      inviteeName: $inviteeName
      inviteeEmail: $inviteeEmail
      role: $role
      teamId: $teamId
    ) {
      id
      teamId
    }
  }
`

const acceptInvite = gql`
  mutation acceptInvite($inviteId: String!) {
    acceptInvite(inviteId: $inviteId) {
      id
    }
  }
`

const rejectInvite = gql`
  mutation rejectInvite($inviteId: String!) {
    rejectInvite(inviteId: $inviteId) {
      id
    }
  }
`

const deleteTeamInvite = gql`
  mutation deleteTeamInvite($id: String!) {
    deleteTeamInvite(id: $id) {
      id
    }
  }
`

const removeUserFromTeam = gql`
  mutation RemoveTeamMember($userId: String!, $teamId: String!) {
    removeTeamMember(userId: $userId, teamId: $teamId) {
      teamId
      id
      email
      fullName
    }
  }
`

const deleteTeam = gql`
  mutation DeleteTeam($teamId: String!) {
    deleteTeam(teamId: $teamId) {
      id
    }
  }
`

const deleteTeamSeat = gql`
  mutation deleteTeamSeat($id: String!) {
    deleteTeamSeat(id: $id) {
      id
    }
  }
`

const setSelectedTeam = gql`
  mutation setSelectedTeam($teamId: String!) {
    setSelectedTeam(teamId: $teamId) {
      id
    }
  }
`

export const TEAM_MUTATIONS = {
  UPDATE_TEAM: updateTeam,
  CREATE_TEAM: createTeam,
  UPLOAD_TEAM_AVATAR: uploadTeamAvatar,
  UPLOAD_AVATAR: uploadAvatar,
  SEND_INVITATION: sendInvite,
  DELETE_INVITATION: deleteTeamInvite,
  REMOVE_FROM_TEAM: removeUserFromTeam,
  DELETE_TEAM: deleteTeam,
  DELETE_SEAT: deleteTeamSeat,
  SET_SELECTED_TEAM: setSelectedTeam,
  ACCEPT_INVITE: acceptInvite,
  REJECT_INVITE: rejectInvite,
}
