import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Flex,
  Button,
} from '@chakra-ui/react'

export interface IDeleteDashboardModalProps {
  title: string
  subtitle: string
  isOpen: boolean
  isSubmiting: boolean
  onClose: () => void
  onSubmit: () => void
}

export const DeleteModal: React.FC<IDeleteDashboardModalProps> = ({
  title,
  subtitle,
  isOpen,
  isSubmiting,
  onClose,
  onSubmit,
}) => (
  <Modal
    onClose={() => {}}
    isOpen={isOpen}
    scrollBehavior="outside"
    size={'lg'}
    isCentered
  >
    <ModalOverlay />
    <ModalContent
      bgColor="#272727"
      color="white"
      border="none"
      borderRadius="4px"
    >
      <ModalHeader textAlign="center">{title}</ModalHeader>
      <ModalBody>
        <Center pb={8}>{subtitle}</Center>
        <Flex justifyContent="center" pb={4}>
          <Button
            color="white"
            colorScheme="fitgmrRed"
            mr={4}
            onClick={onClose}
          >
            No
          </Button>
          <Button
            color="white"
            colorScheme="brand"
            onClick={onSubmit}
            isLoading={isSubmiting}
          >
            Yes
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  </Modal>
)
