import { useQuery } from '@apollo/client'
import { Box, Button, HStack, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { PAYMENT_QUERIES } from '../../components/payments/queries/payments.queries'
import { METHODS, MethodTypes } from './Payments'

type Props = {
  where: any
  totalCount: number
  onSelectCoupon(coupon: string): void
}

type PaymentStatsResult = {
  coupons: {
    coupon: string
    count: number
  }[]

  paymentMethods: {
    method: string
    count: number
  }[]

  extra: {
    teamInvites: number
    newSubscriptions: number
  }
}

function PaymentStats({ where, totalCount, onSelectCoupon }: Props) {
  const paymentStatsResult = useQuery(PAYMENT_QUERIES.GET_PAYMENT_STATS, {
    variables: {
      where,
    },
  })

  const paymentsStatsData = paymentStatsResult?.data
    ?.getPaymentStats as PaymentStatsResult

  const sortedCoupons = useMemo(() => {
    if (!paymentsStatsData) return []

    return paymentsStatsData.coupons
      .filter(({ coupon }) => coupon !== 'No Coupon')
      .sort((a, b) => b.count - a.count)
  }, [paymentsStatsData])

  const sortedPaymentMethods = useMemo(() => {
    if (!paymentsStatsData) return []

    return paymentsStatsData.paymentMethods.sort((a, b) => b.count - a.count)
  }, [paymentsStatsData])

  return (
    <HStack alignItems="baseline" gridGap={10}>
      <Box>
        <Text fontSize="14px" color="#A3A3A3">
          Total Payments
        </Text>
        <Text fontSize="20px" color="#FFFFFF">
          {totalCount}
        </Text>
        <Text fontSize="14px" color="#A3A3A3">
          Team Invites
        </Text>
        <Text fontSize="20px" color="#FFFFFF">
          {paymentsStatsData?.extra.teamInvites}
        </Text>
        <Text fontSize="14px" color="#A3A3A3">
          New Subscriptions
        </Text>
        <Text fontSize="20px" color="#FFFFFF">
          {paymentsStatsData?.extra.newSubscriptions}
        </Text>
      </Box>
      <Box>
        <Text fontSize="14px" color="#A3A3A3">
          By Coupon
        </Text>
        <Text fontSize="20px" color="#FFFFFF">
          {sortedCoupons.map(({ coupon, count }: any) => (
            <Text key={coupon}>
              <Button variant="link" onClick={() => onSelectCoupon(coupon)}>
                {coupon}
              </Button>
              : {count}
            </Text>
          ))}
        </Text>
      </Box>
      <Box>
        <Text fontSize="14px" color="#A3A3A3">
          By Payment Method
        </Text>
        <Text fontSize="20px" color="#FFFFFF">
          {sortedPaymentMethods.map(({ method, count }: any) => (
            <Text key={method}>
              {METHODS[method as MethodTypes]}: {count}
            </Text>
          ))}
        </Text>
      </Box>
    </HStack>
  )
}

export default PaymentStats
