import React from 'react'

import {
  VStack,
  Container,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  Box,
  FormErrorMessage,
} from '@chakra-ui/react'
import { FaPen, FaUser } from 'react-icons/fa'

const CouponForm: React.FC<{
  onSubmit: (values: { email: string; coupon: string }) => void
  showError: boolean
}> = ({ onSubmit, showError }) => {
  const [email, setEmail] = React.useState('')
  const [coupon, setCoupon] = React.useState('')

  return (
    <Box
      p={5}
      shadow="md"
      borderRadius="md"
      background="#0c0c0c"
      minW="260px"
      w="300px"
    >
      <VStack mb={8} mt={4} spacing={5}>
        <Container>
          <FormControl>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <FaUser />
              </InputLeftElement>
              <Input
                variant="flushed"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </FormControl>
        </Container>
        <Container>
          <FormControl isInvalid={showError}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <FaPen />
              </InputLeftElement>
              <Input
                variant="flushed"
                placeholder="Coupon"
                onChange={(e) => setCoupon(e.target.value)}
              />
              <FormErrorMessage>Invalid coupon</FormErrorMessage>
            </InputGroup>
          </FormControl>
        </Container>
      </VStack>
      <VStack>
        <Container mb={4} centerContent>
          <Button
            loadingText="Please wait"
            isFullWidth
            color="white"
            colorScheme="brand"
            onClick={() => {
              onSubmit({ email, coupon })
            }}
          >
            Apply coupon
          </Button>
        </Container>
      </VStack>
    </Box>
  )
}

export default CouponForm
