import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/react'
import React from 'react'

import Select from 'react-select'

interface CustomSelectProps {
  options?: { value: string | boolean | undefined; label: string }[]
  name: string
  className?: string
  onSelect: any
  defaultValue?: any
  placeholder?: string
  sortDirection?: boolean | null
  setSortDirection?: React.Dispatch<React.SetStateAction<boolean | null>>
  isClearable?: boolean
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  name,
  className,
  onSelect,
  defaultValue,
  placeholder,
  sortDirection,
  setSortDirection,
  isClearable,
}) => {
  const customStyles = () => ({
    control: (styles: any) => ({
      ...styles,
      cursor: 'pointer',
      border: '1px solid #444',
      background: 'transparent',
      height: 'var(--chakra-sizes-10)',

      '&:active': {
        color: '#6a6a6a',
      },
    }),
    container: (styles: any) => ({
      ...styles,
      background: 'transparent',
      width: '100%',
    }),
    input: (styles: any) => ({
      ...styles,
      color: '#fff',
      background: 'transparent',
    }),
    menu: (styles: any) => ({
      ...styles,
      zIndex: 100,
      backgroundColor: '#2c2c2c',
      color: 'inherit',
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: '#fff',
      background: 'transparent',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: 'inherit',
    }),
    option: (styles: any, { isSelected }: any) => ({
      ...styles,
      cursor: 'pointer',
      backgroundColor: isSelected ? '#4a4a4a' : undefined,
      ':active': {
        ...styles[':active'],
        backgroundColor: isSelected ? '#222' : undefined,
      },
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#256a91',
      },
    }),
  })

  const handleSortDirection = () => {
    if (setSortDirection) setSortDirection(!sortDirection)
  }

  return (
    <Box position="relative" style={{ width: '100%' }}>
      <Select
        className={className || ''}
        onChange={(input: any) => onSelect(input?.value)}
        name={name}
        styles={customStyles()}
        options={options}
        placeholder={placeholder || 'Select'}
        value={options?.filter((option: any) => option?.value === defaultValue)}
        menuPlacement="auto"
        isClearable={isClearable}
      />
      <Box
        position="absolute"
        top="10px"
        right="57px"
        onClick={handleSortDirection}
      >
        {sortDirection === null || sortDirection === undefined ? (
          <div />
        ) : sortDirection ? (
          <ArrowDownIcon
            style={{ cursor: 'pointer' }}
            aria-label="sorted descending"
            color="#fff"
          />
        ) : (
          <ArrowUpIcon
            style={{ cursor: 'pointer' }}
            aria-label="sorted descending"
            color="#fff"
          />
        )}
      </Box>
    </Box>
  )
}

export default CustomSelect
