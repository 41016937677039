import { useLazyQuery } from '@apollo/client'
import { TEAM_QUERIES } from '../components/coaches/queries/teams.queries'

export const useCoachTeam = (userId?: string) => {
  const variables = userId ? { id: userId } : undefined
  const [getCoachTeams, { data, loading, error }] = useLazyQuery(
    TEAM_QUERIES.GET_TEAMS_BY_COACH,
    {
      fetchPolicy: 'cache-and-network',
      variables,
    }
  )

  return {
    getCoachTeams,
    teams: data,
    loading,
    error,
  }
}
