import { all } from 'redux-saga/effects'

import authSaga from '../components/auth/services/auth.sagas'
import challengeSaga from '../components/weekly-challenge/services/challenge.sagas'
import userSaga from '../components/users/services/users.sagas'
import contentSaga from '../components/content/services/content.sagas'
import coachesSaga from '../components/coaches/services/coaches.sagas'

export default function* rootSaga() {
  yield all([
    authSaga(),
    challengeSaga(),
    userSaga(),
    contentSaga(),
    coachesSaga(),
  ])
}
