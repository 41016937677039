import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Center, Image, VStack, Container } from '@chakra-ui/react'
import logo from '../../assets/images/logo.svg'
import Page from '../../components/shared/ui/page/Page'
import SignupForm from '../../components/auth/ui/SignupForm'
import { useGetUserOnLogin } from '../../hooks/useGetUserOnLogin'
import { selectIsAuthenticated } from '../../components/auth/store/auth.selectors'

const Signup: React.FC = () => {
  const { getUser } = useGetUserOnLogin({ fromSignUp: true })
  const isAuthenticated = useSelector(selectIsAuthenticated)
  useEffect(() => {
    if (isAuthenticated) {
      getUser()
    }
  }, [isAuthenticated])

  return (
    <Page>
      <Center h="100vh" color="Gray" bg="#171717">
        <VStack>
          <Container centerContent pb={8}>
            <Image
              htmlWidth="300px"
              objectFit="contain"
              alt="FitGMR Logo"
              src={logo}
            />
          </Container>
          <SignupForm />
        </VStack>
      </Center>
    </Page>
  )
}

export default Signup
