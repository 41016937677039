import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
} from '@chakra-ui/react'

export interface IPaymentFailurePromptProps {
  isOpen: boolean
  onClose: () => void
}

const PaymentFailurePrompt: React.FC<{ isOpen: boolean; onClose: () => void }> =
  ({ isOpen, onClose }) => (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior="outside"
      size={'lg'}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bgColor="#272727"
        color="white"
        border="none"
        borderRadius="4px"
      >
        <ModalHeader textAlign="center">Subscription payment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center pb={4}>Payment failed!</Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )

export default PaymentFailurePrompt
