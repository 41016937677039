import { gql } from '@apollo/client'

const getVideo = gql`
  query Video($where: VideoWhereUniqueInput!) {
    video(where: $where) {
      id
      title
      category
      pillarCategory
      description
      difficulty
      tags
      enabled
      instructorId
      seriesId
      episode
      selectedThumbnail
      availableFrom
      autoComplete
      filename
      resolutionWidth
      duration
      resolutionHeight
      sourceUrl
      url
      thumbnailUrl
      workflowStatus
      VideoInstructor {
        id
        fullName
      }
      VideoSeries {
        name
        id
      }
      VideoQuestion {
        id
        text
        type
        subType
        defineWatched
      }
    }
  }
`

const getVideos = gql`
  query Videos(
    $where: VideoWhereInput
    $take: Int
    $skip: Int
    $orderBy: [VideoOrderByWithRelationInput!]
  ) {
    videos(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      title
      category
      pillarCategory
      description
      difficulty
      tags
      enabled
      instructorId
      seriesId
      episode
      selectedThumbnail
      availableFrom
      autoComplete
      filename
      resolutionWidth
      duration
      resolutionHeight
      sourceUrl
      url
      thumbnailUrl
      workflowStatus
      VideoInstructor {
        id
        fullName
      }
      VideoSeries {
        name
        id
      }
      VideoQuestion {
        id
        text
        type
        subType
        defineWatched
      }

      updatedAt
      createdAt
    }
  }
`

const getAggregatedVideos = gql`
  query AggregateVideo($where: VideoWhereInput) {
    aggregateVideo(where: $where) {
      _count {
        id
      }
    }

    allVideoTags
  }
`

const getInstructorsAndSeries = gql`
  query VideoCreateOrUpdateOption {
    videoInstructors {
      id
      fullName
    }
    findManyVideoSeries {
      id
      name
      description
      coverImage
      basic
      skippable
      skipText
    }
  }
`

const getS3Link = gql`
  query getS3Link($where: VideoWhereUniqueInput!, $fileExtension: String) {
    getS3Link(where: $where, fileExtension: $fileExtension) {
      url
      fields
    }
  }
`

export const currentUserVideosMethod = 'myVideos'
const userVideos = gql`
  query Videos($where: VideoWhereInput) {
    videos(where: $where) {
      id
      title
      selectedThumbnail
      description
    }
  }
`

export const CONTENT_QUERIES = {
  GET_VIDEO: getVideo,
  GET_VIDEOS: getVideos,
  GET_USER_VIDEOS: userVideos,
  GET_INSTRUCTORS_AND_SERIES: getInstructorsAndSeries,
  GET_AGGREGATED_VIDEOS: getAggregatedVideos,
  GET_S3_LINK: getS3Link,
}
