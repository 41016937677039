import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyQuery } from '@apollo/client'

import { selectRedirectTo } from '../components/auth/store/auth.selectors'

import {
  setRedirectUrl,
  setUpdateUser,
} from '../components/auth/store/auth.actions'
import { IUser } from '../components/auth/store/auth.types'
import {
  AUTH_QUERIES,
  userKey,
} from '../components/auth/mutations/auth.queries'

export const useGetUserOnLogin = ({ fromSignUp = false }) => {
  const route = useHistory()
  const dispatch = useDispatch()
  const redirectTo = useSelector(selectRedirectTo)

  const [getUser] = useLazyQuery(AUTH_QUERIES.GET_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted(userData: { [userKey]: IUser }) {
      if (userData?.[userKey]) {
        dispatch(setUpdateUser(userData[userKey]))
        const redirectFromSingUp = redirectTo?.includes('home/team-invite')
          ? redirectTo
          : '/pricing'
        const redirectFromSingIn = redirectTo?.includes('home/team-invite')
          ? redirectTo
          : '/home'
        if (fromSignUp) route.push(redirectFromSingUp)
        else route.push(redirectFromSingIn)
        dispatch(setRedirectUrl({ redirectTo: '' }))
      }
    },
    onError() {},
  })

  return {
    getUser,
  }
}
