import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import { PersistGate } from 'redux-persist/integration/react'
import { ChakraProvider as UIProvider, ColorModeScript } from '@chakra-ui/react'
import store, { persistor } from './store/store'
import client from './gql/client'

// Routes
import Routes from './Routes'

import theme from './assets/theme'

import './assets/theme.scss'
import { TeamProvider } from './context/TeamContext/Context'
import { SidebarProvider } from './context/SidebarContext/Context'
import { PaymentProvider } from './context/PaymentContext/Context'

function App() {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <PersistGate loading={null} persistor={persistor}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />

          <UIProvider theme={theme}>
            <Router>
              <SidebarProvider>
                <TeamProvider>
                  <PaymentProvider>
                    <Routes />
                  </PaymentProvider>
                </TeamProvider>
              </SidebarProvider>
            </Router>
          </UIProvider>
        </PersistGate>
      </ApolloProvider>
    </Provider>
  )
}

export default App
