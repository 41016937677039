import React, { useCallback, useEffect, useRef } from 'react'

import CreateTeamModal from '../../shared/ui/create-team-modal/CreateTeamModal'
import useFeedbackModal from '../../shared/ui/feedback/useFeedbackModal'
import {
  TeamCreatePayload,
  TeamCreateWithoutUsersInput,
} from '../store/coaches.types'
import useTeam from '../../../hooks/useTeam'

interface AddTeamDashboardProps {
  isOpen: boolean
  onClose: () => void
  coachSubscriptionPackage: string | null | undefined
}

const AddTeamDashboard: React.FC<AddTeamDashboardProps> = ({
  isOpen,
  onClose,
}) => {
  const handleCloseModal = useCallback(() => {
    onClose()
  }, [onClose])
  const { createTeam, createTeamLoading, teamError } = useTeam(
    false,
    '',
    handleCloseModal
  )
  const { setMessage, setTitle, setIsOpen, renderedComponent } =
    useFeedbackModal()
  const btnRef = useRef(null)

  const handleSubmit = async (
    values: TeamCreateWithoutUsersInput,
    setSubmitting: (val: boolean) => void
  ) => {
    const data: TeamCreatePayload = {
      data: {
        name: values.name,
        description: values.description || '',
        game: values.game || '',
        avatar: values.avatar || '',
      },
    }
    createTeam(data)

    setSubmitting(false)
  }

  useEffect(() => {
    if (teamError) {
      setMessage(teamError?.message)
      setTitle('There was an error')
      setIsOpen(true)
    }
  }, [teamError])

  return (
    <>
      <CreateTeamModal
        handleCloseModal={handleCloseModal}
        btnRef={btnRef}
        isOpen={isOpen}
        handleSubmit={handleSubmit}
        isTeamCreating={createTeamLoading}
      />
      {renderedComponent}
    </>
  )
}

export default AddTeamDashboard
