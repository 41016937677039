import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx-js-style'

function formatHeader(header: string): string {
  // Replace camelCase with spaces and capitalize the first letter
  return header
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

function convertTableToSheet(tables: any[]) {
  const workSheet = XLSX.utils.aoa_to_sheet([['User Statistics']])
  let TopTableRows = 0
  let currentRow = 0 // This will keep track of the current row in the worksheet
  tables.forEach((table) => {
    const filteredTable = table.map((row: any) => {
      const { __typename, ...rest } = row
      console.debug(__typename)
      return rest
    })

    // Add table data to the worksheet
    XLSX.utils.sheet_add_json(workSheet, filteredTable, {
      origin: { r: currentRow, c: 0 },
    })
    XLSX.utils.sheet_add_json(workSheet, [], { origin: -1 })

    // Bold the headers
    const headers = Object.keys(filteredTable[0])
    if (workSheet['!ref']) {
      const range = XLSX.utils.decode_range(workSheet['!ref'])
      headers.forEach((header, columnIndex) => {
        const cellRef = XLSX.utils.encode_cell({
          r: TopTableRows,
          c: columnIndex,
        })
        if (workSheet[cellRef] && typeof workSheet[cellRef]?.v === 'string') {
          workSheet[cellRef] = {
            v: formatHeader(workSheet[cellRef].v),
            t: 's',
            s: { font: { bold: true } },
          }
        }
      })
      TopTableRows = range?.e.r + 1
    }
    currentRow += filteredTable.length + 2 // move to the next row position (including one empty row)
  })

  return workSheet
}

function setColumnWidth(headerArray: any) {
  return headerArray.map((header: string) => {
    let width = 18
    if (header === 'id') {
      width = 35
    } else if (header === 'completenessScoreForRequiredOnly') {
      width = 30
    }
    return { wch: width }
  })
}

const exportData = (
  fileFormat: string,
  dataToSave: any,
  fileName?: string,
  tables = 1
) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

  const fileExtension = `.${fileFormat}`
  let ws
  if (tables > 1) {
    ws = convertTableToSheet(dataToSave)
  } else {
    ws = convertTableToSheet([dataToSave]) // Convert dataToSave into an array of tables for consistency
  }

  const columnHeader = XLSX.utils.sheet_to_json(ws, { header: 1 })

  ws['!cols'] = Array.isArray(columnHeader[1])
    ? setColumnWidth(columnHeader[1])
    : [{ wch: 20 }]

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet 01')

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  let data = new Blob([excelBuffer], { type: fileType })
  if (fileFormat === 'csv') {
    const dataCSV = XLSX.utils.sheet_to_csv(ws)
    data = new Blob([dataCSV], { type: 'text/csv;charset=utf-8' })
  }
  saveAs(data, `${fileName} ${fileExtension}`)
}

export default exportData
