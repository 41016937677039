import React, { ChangeEvent, FormEvent, useMemo } from 'react'
import {
  VStack,
  FormLabel,
  FormControl,
  Button,
  Box,
  Text,
} from '@chakra-ui/react'

import TextInput from '../../../shared/ui/text-input/TextInput'
import { RoleEnum, UserInterface } from '../../store/users.types'

import CustomSelect from '../../../shared/ui/select'
import { EAppSubscriptionReasonPackage } from './types'

export interface UserFormValues {
  fullName: string
  username: string
  email: string
  role: string
}

interface UserFormProps {
  values: UserFormValues
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  errors: UserFormValues
  touched: {
    fullName: boolean
    username: boolean
    email: boolean
    role: boolean
  }
  isValid: boolean
  handleChange: (
    event: FormEvent<HTMLParagraphElement> | ChangeEvent<HTMLSelectElement>
  ) => void
  handleBlur: (event: FormEvent<HTMLParagraphElement>) => void
  handleReset: () => void
  dirty: boolean
  isSubmitting: boolean
  setFieldValue: (field: string, value: string) => void
  selectedRow: UserInterface
  setIsUpdated: (val: boolean) => void
  setEnabled: (value: boolean) => void
  handleDisableItem: (id: string) => void
  handleEnableItem: (id: string) => void
}

const UserForm: React.FC<UserFormProps> = ({
  values = {
    fullName: '',
    username: '',
    email: '',
    role: '',
  },
  errors,
  touched = {},
  isValid,

  isSubmitting = false,
  handleSubmit,
  handleChange,
  handleBlur,
  handleReset,
  setFieldValue,
  selectedRow,
  setIsUpdated,
  setEnabled,
  handleDisableItem,
  handleEnableItem,
}) => {
  const { fullName, username, email, role } = values

  const renderRole = (role: RoleEnum) => {
    switch (role) {
      case RoleEnum.DIRECTOR:
        return 'Director'

      case RoleEnum.COACH:
        return 'Coach'

      case RoleEnum.GAMER:
        return 'Gamer'

      case RoleEnum.ADMIN:
        return 'Admin'

      case RoleEnum.SELF:
        return 'Self'

      default:
        return 'No-Role'
    }
  }

  const roleOptions = Object.keys(RoleEnum).map((key) => ({
    label: renderRole(key as RoleEnum),
    value: key,
  }))

  const userSubscription = useMemo(() => {
    const FITGMR = 'FITGMR'
    const FITGMR_FREE = `${FITGMR} Free`
    const FITGMR_PRO = `${FITGMR} Pro`
    const BY_TEAM = 'by Team'
    switch (selectedRow?.appSubscriptionReason) {
      case EAppSubscriptionReasonPackage.FITGMRFREE:
        return `${FITGMR_FREE}`
      case EAppSubscriptionReasonPackage.FITGMRFREE_BYTEAM:
        return `${FITGMR_FREE} ${BY_TEAM}`
      case EAppSubscriptionReasonPackage.FITGMRPRO:
        return `${FITGMR_PRO}`
      case EAppSubscriptionReasonPackage.FITGMRPRO_BYTEAM:
        return `${FITGMR_PRO} ${BY_TEAM}`
      case EAppSubscriptionReasonPackage.FITGMRPRO_ADMIN:
        return `${FITGMR_PRO} Admin`
      default:
        return ''
    }
  }, [selectedRow])

  const coachSubscription = useMemo(() => {
    const FITGMR = 'FITGMR'
    const FITGMR_PERFORMANCE = `${FITGMR} Team Performance`
    return FITGMR_PERFORMANCE
  }, [selectedRow])

  return (
    <>
      <form onSubmit={handleSubmit} onReset={handleReset} autoComplete="off">
        <VStack display="block">
          <TextInput
            name="fullName"
            id="fullName"
            label="Name"
            value={fullName || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={fullName}
            error={Boolean(errors.fullName) && Boolean(touched.fullName)}
            style={{ maxWidth: '500px' }}
            helperText={
              Boolean(errors.fullName) && Boolean(touched.fullName)
                ? errors.fullName
                : ''
            }
          />
          <TextInput
            name="username"
            label="Username"
            id="username"
            value={username || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={username}
            error={Boolean(errors.username) && Boolean(touched.username)}
            style={{ maxWidth: '500px' }}
            helperText={
              Boolean(errors.username) && Boolean(touched.username)
                ? errors.username
                : ''
            }
          />
          <TextInput
            name="email"
            label="Email"
            id="email"
            value={email}
            disabled
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={email}
            error={Boolean(errors.email) && Boolean(touched.email)}
            style={{ maxWidth: '500px' }}
            helperText={
              Boolean(errors.email) && Boolean(touched.email)
                ? errors.email
                : ''
            }
          />
          <FormControl id="role">
            <FormLabel>Role</FormLabel>

            <CustomSelect
              defaultValue={role}
              name="role"
              options={roleOptions}
              onSelect={(input: any) => setFieldValue('role', input)}
              // disabled={!RoleRule[user!.role].includes(roleOptions.value)}
            />

            <TextInput
              name="subType"
              label="App Subscription Type"
              id="appSubscriptionType"
              value={userSubscription || 'No subscription'}
              disabled
              style={{ maxWidth: '500px', marginTop: 10 }}
            />

            {selectedRow.role === RoleEnum.DIRECTOR && (
              <TextInput
                name="subType"
                label="Coach Subscription Type"
                id="coachSubscriptionType"
                value={coachSubscription}
                disabled
                style={{ maxWidth: '500px', marginTop: 10 }}
              />
            )}

            <Box display="flex" mt="20px">
              <Button
                display="block"
                width="50%"
                borderRadius="8px"
                id="video-enabled-button"
                color="#fff"
                colorScheme={selectedRow?.enabled ? 'fitgmrRed' : 'fitgmrGreen'}
                onClick={() => {
                  setIsUpdated(true)
                  setEnabled(!selectedRow?.enabled)
                  if (selectedRow?.enabled)
                    handleDisableItem(selectedRow ? selectedRow?.id : '')
                  else handleEnableItem(selectedRow ? selectedRow?.id : '')
                }}
              >
                {selectedRow?.enabled ? (
                  <Text pt="1">Disable user</Text>
                ) : (
                  <Text pt="1">Enable user</Text>
                )}
              </Button>
              <Button
                display="block"
                width="100%"
                ml="10"
                colorScheme="fitgmrPurple"
                color="white"
                borderRadius="8px"
                disabled={isSubmitting || !isValid}
                type="submit"
              >
                <Text pt="1">Save</Text>
              </Button>
            </Box>
          </FormControl>
        </VStack>
      </form>
    </>
  )
}

export default UserForm
