import { useSelector } from 'react-redux'
import { selectUser } from '../components/auth/store/auth.selectors'
import { RoleEnum, UserInterface } from '../components/users/store/users.types'
import { useCoachTeam } from './useCoachTeam'
import useTeam from './useTeam'

export const useSidebarTeams = () => {
  const user: UserInterface | undefined = useSelector(selectUser)
  const { managerTeams, managerTeamsLoading, initialLoading, refetchTeams } =
    useTeam()
  const { getCoachTeams, teams, loading } = useCoachTeam(user?.id)

  return {
    data:
      user?.role === RoleEnum.COACH ? teams?.me?.coachedTeams : managerTeams,
    loading: user?.role === RoleEnum.COACH ? loading : managerTeamsLoading,
    initialLoading: user?.role === RoleEnum.COACH ? false : initialLoading,
    getData: user?.role === RoleEnum.COACH ? getCoachTeams : refetchTeams,
  }
}
