import React from 'react'
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowRightIcon,
} from '@chakra-ui/icons'
import {
  Flex,
  Tooltip,
  IconButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Text,
} from '@chakra-ui/react'

export default function Pagination({
  setPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  page,
  rowsPerPage,
  setPageSize,
}: any) {
  const previousPage = () => setPage && setPage(page - 1)
  const nextPage = () => setPage && setPage(page + 1)

  return (
    <Flex justifyContent="space-between" m={4} alignItems="center">
      <Flex>
        <Tooltip label="First Page">
          <IconButton
            aria-label="First Page"
            onClick={() => setPage && setPage(1)}
            isDisabled={!canPreviousPage}
            icon={<ArrowLeftIcon h={3} w={3} />}
            mr={4}
          />
        </Tooltip>
        <Tooltip label="Previous Page">
          <IconButton
            aria-label="Previous Page"
            onClick={previousPage}
            isDisabled={!canPreviousPage}
            icon={<ChevronLeftIcon h={6} w={6} />}
          />
        </Tooltip>
      </Flex>

      <Flex alignItems="center">
        <Text flexShrink={0} mr={8}>
          Page{' '}
          <Text fontWeight="bold" as="span">
            {page}{' '}
          </Text>
          of{' '}
          <Text fontWeight="bold" as="span">
            {pageCount}
          </Text>
        </Text>
        <Text flexShrink={0}>Go to page:</Text>
        <NumberInput
          ml={2}
          mr={8}
          w={28}
          min={1}
          max={pageCount}
          onChange={(valueAsString, valueAsNumber) => {
            const pageToChange = valueAsNumber || 1
            if (setPage) {
              setPage(pageToChange)
            }
          }}
          defaultValue={page}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        {rowsPerPage > 5 && (
          <Select
            w={32}
            value={rowsPerPage}
            onChange={(e) => {
              if (setPageSize) {
                setPageSize(Number(e.target.value))
              }
            }}
          >
            {[10, 20, 30, 40, 50].map((rows) => (
              <option key={rows} value={rows}>
                Show {rows}
              </option>
            ))}
          </Select>
        )}
      </Flex>

      <Flex>
        <Tooltip label="Next Page">
          <IconButton
            aria-label="Next Page"
            onClick={nextPage}
            isDisabled={!canNextPage}
            icon={<ChevronRightIcon h={6} w={6} />}
          />
        </Tooltip>
        <Tooltip label="Last Page">
          <IconButton
            aria-label="Last Page"
            onClick={() => setPage && setPage(pageCount)}
            isDisabled={!canNextPage}
            icon={<ArrowRightIcon h={3} w={3} />}
            ml={4}
          />
        </Tooltip>
      </Flex>
    </Flex>
  )
}
