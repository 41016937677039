import React, { PropsWithChildren, useEffect, useState } from 'react'
import * as Yup from 'yup'

import {
  Box,
  Button,
  Flex,
  Spacer,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  Text,
  useNumberInput,
  Select,
  HStack,
} from '@chakra-ui/react'

import { Form, Formik, Field } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import ReactQuill from 'react-quill'
import { countTotalChars } from '../helpers/functions'
import {
  CONTENT,
  InstructorSeriesCreateOrUpdateInterface,
  VideoInterface,
  VideoSeriesInterface,
} from '../store/content.types'
import { selectInstructors, selectSeries } from '../store/content.selectors'
import useAddModal from '../../shared/ui/add-modal/useAddModal'
import { setActionRequest } from '../store/content.actions'
import useSeriesModal from '../../shared/ui/add-modal/useSeriesModal'
import 'react-quill/dist/quill.snow.css' // ES6
import './react-quill.scss'
import { MAX_DESCRIPTION_CHARS } from '../helpers/constants'

interface VideoUploadComponentProps extends PropsWithChildren<any> {
  onDataChange?: (data: any) => void
  onSubmit: (data: any) => void
  initialData?: VideoInterface
  isSubmitting?: boolean
  isDisabled?: boolean
}

const metadataFormSchema = Yup.object().shape({
  description: Yup.string()
    .max(1000, 'Description should be less than 1000 characters.')
    .nullable(),
  instructor: Yup.string().nullable(),
  tags: Yup.array().of(Yup.string()),
  series: Yup.string().nullable(),
  episode: Yup.string().nullable(),
})

const baseSeries = {
  id: '',
  name: '',
  description: '',
  coverImage: '',
  basic: false,
}

const VideoCreateForm: React.FC<VideoUploadComponentProps> = ({
  onSubmit,
  isSubmitting,
  isDisabled,
}) => {
  const dispatch = useDispatch()
  const series = useSelector(selectSeries)
  const instructors = useSelector(selectInstructors)

  const [selectedSeries, setSelectedSeries] =
    useState<VideoSeriesInterface>(baseSeries)

  const instructorModal = useAddModal({
    title: 'Add an instructor',
    fieldName: 'fullName',
    isSubmitting,
    callback: (e: any) => {
      const data: InstructorSeriesCreateOrUpdateInterface = {
        data: e,
      }

      dispatch(setActionRequest(CONTENT.INSTRUCTOR_CREATE_REQUEST, data))
    },
  })

  const seriesModal = useSeriesModal({
    isSubmitting,
    selectedSeries,
    onSubmit: (e: any, action) => {
      if (action === 'CREATE') {
        const data: InstructorSeriesCreateOrUpdateInterface = {
          data: e,
        }

        dispatch(setActionRequest(CONTENT.SERIES_CREATE_REQUEST, data))
      }

      if (action === 'UPDATE') {
        const data: InstructorSeriesCreateOrUpdateInterface = e
        dispatch(setActionRequest(CONTENT.SERIES_UPDATE_REQUEST, data))
      }
    },
  })

  useEffect(() => {
    instructorModal.onClose()
  }, [instructors])

  const episodeInput = useNumberInput({
    min: 1,
    max: 20,
  })

  const modules = {
    toolbar: [[{ header: '1' }]],
  }

  return (
    <>
      <Flex maxWidth="540px" alignItems="start" marginLeft="auto">
        <Box width="100%">
          <Box
            height="100%"
            padding="10"
            background="#363636"
            border="1px solid #555"
            borderRadius="2px"
            minWidth="400px"
          >
            <Flex
              direction="column"
              height="100%"
              minHeight="600px"
              textAlign="start"
            >
              <Box>
                <Text
                  color="white"
                  fontWeight="bold"
                  fontSize="1.15rem"
                  marginBottom="1.15rem"
                >
                  Video details
                </Text>
              </Box>
              <Formik
                validationSchema={metadataFormSchema}
                onSubmit={onSubmit}
                initialValues={{
                  description: '',
                  instructor: '',
                  series: '',
                  tags: [],
                  episode: 1,
                }}
              >
                {({ setFieldValue }) => (
                  <Form>
                    <Flex direction="column" height="100%">
                      <Spacer />
                      <Field name="description">
                        {({ form }: any) => (
                          <FormControl
                            marginBottom="40px"
                            isDisabled={isSubmitting && !isDisabled}
                            isInvalid={
                              form.errors.description &&
                              form.touched.description
                            }
                          >
                            <FormLabel>Description</FormLabel>

                            <ReactQuill
                              onChange={(value: string) =>
                                setFieldValue('description', value)
                              }
                              className="description-react-quill"
                              theme={'snow'}
                              modules={modules}
                              id="description"
                            />
                            <p style={{ textAlign: 'right' }}>
                              {countTotalChars(form.values?.description)}/
                              {MAX_DESCRIPTION_CHARS}
                            </p>
                            <FormErrorMessage>
                              {form.errors.description}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Spacer />
                      <Spacer />
                      <Field name="instructor">
                        {({ field, form }: any) => (
                          <FormControl
                            marginBottom="40px"
                            isDisabled={isSubmitting && !isDisabled}
                            isInvalid={
                              form.errors.category && form.touched.category
                            }
                          >
                            <FormLabel>Instructor</FormLabel>
                            <InputGroup>
                              <Select {...field} id="instructor">
                                <option value="">Select below</option>
                                {instructors.map((i) => (
                                  <option value={i.id} key={i.id}>
                                    {i.fullName}
                                  </option>
                                ))}
                              </Select>
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.category}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Box>
                        <Text
                          color="white"
                          fontWeight="bold"
                          fontSize="1.15rem"
                          marginBottom="1.15rem"
                        >
                          Series details
                        </Text>
                      </Box>
                      <Field name="series">
                        {({ field, form }: any) => (
                          <FormControl
                            marginBottom="40px"
                            isDisabled={isSubmitting && !isDisabled}
                            isInvalid={
                              form.errors.category && form.touched.category
                            }
                          >
                            <FormLabel>Series</FormLabel>
                            <InputGroup>
                              <Select
                                {...field}
                                id="series"
                                width="100%"
                                onChange={(e) => {
                                  const selectedId = e.target.value
                                  const selectedSeries = series.find(
                                    (item) => item.id === selectedId
                                  )
                                  const newData: any = {
                                    series: selectedId,
                                  }
                                  if (!selectedId) {
                                    newData.episode = ''
                                    setFieldValue('episode', null)
                                    setSelectedSeries(baseSeries)
                                  } else {
                                    setSelectedSeries(selectedSeries!)
                                  }
                                  setFieldValue('series', selectedId)
                                }}
                              >
                                <option value="">Select below</option>
                                {series.map((i) => (
                                  <option value={i.id} key={i.id}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.category}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Spacer />
                      {selectedSeries?.name && (
                        <HStack marginBottom="40px">
                          <Field name="episode">
                            {({ field, form }: any) => (
                              <FormControl
                                isDisabled={isSubmitting && !isDisabled}
                                isInvalid={
                                  form.errors.episode && form.touched.episode
                                }
                              >
                                <FormLabel>Episode</FormLabel>
                                <InputGroup>
                                  <HStack maxW="150px">
                                    <Button
                                      {...episodeInput.getIncrementButtonProps()}
                                    >
                                      +
                                    </Button>
                                    <Input
                                      {...field}
                                      id="episode"
                                      {...episodeInput.getInputProps({
                                        onBlur: (e) =>
                                          setFieldValue(
                                            'episode',
                                            parseInt(e.target.value || '0', 10)
                                          ),
                                      })}
                                    />
                                    <Button
                                      {...episodeInput.getDecrementButtonProps()}
                                    >
                                      -
                                    </Button>
                                  </HStack>
                                </InputGroup>
                                <FormErrorMessage>
                                  {form.errors.episode}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </HStack>
                      )}
                      <Spacer />
                      <Button
                        loadingText="Please wait"
                        type="submit"
                        isFullWidth
                        color="white"
                        colorScheme="fitgmrPurple"
                        isLoading={isSubmitting}
                        disabled={isDisabled}
                      >
                        Update
                      </Button>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Flex>
          </Box>
        </Box>
      </Flex>
      <instructorModal.AddModal />
      <seriesModal.AddModal />
    </>
  )
}

export default VideoCreateForm
