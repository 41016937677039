import React from 'react'
import { Tbody, Tr, Td, Checkbox, Flex } from '@chakra-ui/react'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { VideoInterface } from '../../../content/store/content.types'
import { TableHeader } from './types'

type BodyType = {
  headers: TableHeader[]
  items: { id: string; [key: string]: any }[]
  selected?: string[]
  selectable: boolean
  selectableClick?: boolean
  showId: boolean
  onRowClick?: (val: any) => void
  onColumnClick?: (val: any, val2: any) => void
  selectedRow?: VideoInterface | null
  handleSelect?: (val: string[]) => void
  setSelectedRow?: (val: VideoInterface | null) => void
  selectOnce?: boolean
  setSelectedItemOnce?: (val: any) => void
}

function Body({
  items,
  selectedRow,
  selectableClick,
  onRowClick,
  selectOnce,
  setSelectedItemOnce,
  headers,
  selected,
  selectable,
  onColumnClick,
  showId,
  handleSelect,
  setSelectedRow,
}: BodyType) {
  const setCheckedItem = (item: string, isChecked: boolean) => {
    if (handleSelect) {
      if (isChecked && selected) {
        handleSelect([...selected, item])
      } else {
        handleSelect(selected ? selected.filter((i) => i !== item) : [])
      }
    }
  }

  const setSelectedItem = (item: any) => {
    setCheckedItem(item.id, true)
  }

  const handleRowClick = (item: any, column: any) => {
    if (onRowClick) onRowClick(item)

    if (selectableClick && !selectOnce) {
      setSelectedItem(item)
    } else if (selectOnce && setSelectedItemOnce) {
      setSelectedItemOnce(item)
    }
    if (selectableClick) setSelectedItem(item)
    if (onColumnClick) onColumnClick(column, item)

    if (setSelectedRow) setSelectedRow(item)
  }

  return (
    <Tbody>
      {items.map((item) => (
        <Tr
          key={item.id}
          className={
            selectedRow?.id === item.id
              ? 'table-row table-selected'
              : 'table-row'
          }
        >
          {selectable && (
            <Td data-column="global-selector">
              <Checkbox
                defaultIsChecked={selected?.includes(item.id)}
                isChecked={selected?.includes(item.id)}
                onChange={(e) => setCheckedItem(item.id, e.target.checked)}
              />
            </Td>
          )}

          {headers
            .filter((head) => {
              const withId = !showId ? head.id !== 'id' : true
              const withoutActions = head.id !== 'actions'
              return withId && withoutActions
            })
            .map((column) => {
              let content: any
              if (column.type === 'boolean') {
                content = item[column.id] ? (
                  <Flex justifyContent="center">
                    <FaCheckCircle color="#1FB584" fontSize="1.15rem" />
                  </Flex>
                ) : (
                  <Flex justifyContent="center">
                    <FaTimesCircle color="#ae3552" fontSize="1.15rem" />
                  </Flex>
                )
              } else if (column.render) {
                content = column.render(item)
              } else {
                content = item[column.id] || ''
              }
              return (
                <Td
                  key={column.id}
                  data-column={column.id}
                  onClick={() => handleRowClick(item, column)}
                  position={column?.position ? 'sticky' : 'relative'}
                  left={column?.position ? '1' : '0'}
                  backgroundColor={column?.position ? '#1C1C1D' : 'transparent'}
                  zIndex={column?.position ? 1 : 0}
                >
                  {content}
                </Td>
              )
            })}
        </Tr>
      ))}
    </Tbody>
  )
}

export default React.memo(Body)
