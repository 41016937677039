import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CircularProgressLabel,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import DayPicker from 'react-day-picker'
import { calculateFinalScore } from './algorithm'

type DayData = {
  score: number
  watchedVideos: boolean
}

export default function ScoreSimulator() {
  const [dailyScores, setDailyScores] = useState<Map<string, DayData>>(
    new Map()
  )
  const [selectedDay, setSelectedDay] = useState<Date>()

  const selectedDayData = useMemo(() => {
    if (!selectedDay) return null

    const dayString = selectedDay.toISOString()
    return dailyScores.get(dayString)
  }, [dailyScores, selectedDay])
  const daysWithScores = useMemo(
    () => Array.from(dailyScores.keys()).map((day) => new Date(day)),
    [dailyScores]
  )

  const handleSelect = (day: Date) => {
    setSelectedDay(day)
  }

  const handleUpdateDayScore = (day: Date, score: number) => {
    const dayString = day.toISOString()
    const dayData = dailyScores.get(dayString) || {
      score: 0,
      watchedVideos: false,
    }
    setDailyScores(new Map(dailyScores).set(dayString, { ...dayData, score }))
  }

  const handleUpdateDayWatchedVideos = (day: Date, watchedVideos: boolean) => {
    const dayString = day.toISOString()
    const dayData = dailyScores.get(dayString) || {
      score: 0,
      watchedVideos: false,
    }
    setDailyScores(
      new Map(dailyScores).set(dayString, { ...dayData, watchedVideos })
    )
  }

  const handleResetAllDays = () => {
    setDailyScores(new Map())
  }

  const handleResetDayData = (day: Date) => {
    const dayString = day.toISOString()
    const newDailyScores = new Map(dailyScores)
    newDailyScores.delete(dayString)
    setDailyScores(newDailyScores)
  }

  const { streakDays, finalScore } = useMemo(
    () => calculateFinalScore(dailyScores),
    [dailyScores]
  )

  return (
    <Box pt={50} px={300}>
      <Heading>Score Simulator</Heading>
      <Stack direction="row" spacing={4}>
        <Box w="300px">
          <DayPicker
            selectedDays={selectedDay}
            modifiers={{
              hasScore: daysWithScores,
            }}
            modifiersStyles={{
              hasScore: {
                backgroundColor: 'var(--chakra-colors-green-500)',
              },
            }}
            onDayClick={handleSelect}
            showOutsideDays
            canChangeMonth
          />
          <Button onClick={handleResetAllDays}>Reset All Days</Button>
        </Box>
        <Box>
          <div>
            <label htmlFor="score">Daily Score</label>
            <NumberInput
              value={selectedDayData?.score ?? 0}
              max={100}
              min={0}
              onChange={(_string, value) => {
                if (!selectedDay) return

                handleUpdateDayScore(selectedDay, value)
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </div>
          <div>
            <Checkbox
              isChecked={selectedDayData?.watchedVideos ?? false}
              onChange={(e) => {
                if (!selectedDay) return

                handleUpdateDayWatchedVideos(selectedDay, e.target.checked)
              }}
            >
              Watched Videos
            </Checkbox>
          </div>
          <Button
            onClick={() => {
              if (!selectedDay) return

              handleResetDayData(selectedDay)
            }}
          >
            Reset Day
          </Button>
        </Box>
        <Box pl="50">
          <Text>Final Score</Text>
          <CircularProgress value={finalScore} size="200px">
            <CircularProgressLabel>{finalScore}</CircularProgressLabel>
          </CircularProgress>
        </Box>
        <Box pl="50">
          <Text>Streak Days</Text>
          <Text>{streakDays}</Text>
        </Box>
      </Stack>
    </Box>
  )
}
