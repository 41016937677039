import { combineReducers } from 'redux'
import auth from '../components/auth/store/auth.reducer'
import weeklyChallenge from '../components/weekly-challenge/store/challenge.reducer'
import user from '../components/users/store/users.reducer'
import content from '../components/content/store/content.reducer'
import team from '../components/coaches/store/coaches.reducer'

export default combineReducers({
  auth,
  weeklyChallenge,
  user,
  content,
  team,
})
