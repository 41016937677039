import React, { useEffect, useState } from 'react'
import { Avatar, Tooltip, Wrap } from '@chakra-ui/react'
import useActivityScoreStats from '../../../coaches/hooks/useActivityScoreStats'
import { TeamInterface } from '../../../coaches/store/coaches.types'

type IProps = {
  team: TeamInterface | undefined
  selectedTeam: TeamInterface | null
  setSelectedTeam: (team: TeamInterface | undefined) => void
  onClick: () => void
}

export const DashboardItem = ({
  team,
  selectedTeam,
  setSelectedTeam,
  onClick,
}: IProps) => {
  const [fitGMRScore, setFitGMRScore] = useState<number>()
  const { loadTeamScoreStatistics, teamScoresStats } = useActivityScoreStats()

  const handleSelectTeam = async () => {
    onClick()
    await setSelectedTeam(team)
  }

  useEffect(() => {
    if (team?.id) {
      loadTeamScoreStatistics('fitgmr', 1, team.id)
    }
  }, [team?.id])

  useEffect(() => {
    if (teamScoresStats?.mainScoreStatistics?.data[1]) {
      setFitGMRScore(teamScoresStats?.mainScoreStatistics?.data[1].score)
    }
  }, [teamScoresStats])

  return (
    <Tooltip
      hasArrow
      label={
        Number(fitGMRScore) >= 0 ? `${team?.name} - ${fitGMRScore}` : team?.name
      }
      placement="right"
      // trying to avoid dupicates key error
      key={`${team?.name}-sidebar-item`}
    >
      <Wrap
        className={`dashboard-icon-container ${
          team?.id === selectedTeam?.id && 'selected'
        }`}
        onClick={handleSelectTeam}
      >
        <Avatar
          className={`dashboard-icon ${
            team?.id === selectedTeam?.id && 'selected'
          }`}
          name={team?.name}
          src={team?.avatar}
          size="sm"
        />
      </Wrap>
    </Tooltip>
  )
}
