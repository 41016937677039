import { PayloadedAction } from '../../../types'
import { AUTH } from '../../auth/store/auth.types'
import { CONTENT, VideoStateInterface } from './content.types'

const initialState: VideoStateInterface = {
  isLoading: false,
  isUploading: false,
  error: {
    message: '',
  },
  videos: [],
  selectedVideo: null,
  uploadedVideo: null,
  stagingVideo: null,
  series: [],
  instructors: [],
  totalVideos: 0,
  totalInstructors: 0,
  tags: [],
  stagingSeries: null,
  s3Video: {
    url: '',
    fields: '',
  },
}

export default function VideoState(
  state: VideoStateInterface = initialState,
  { type, payload }: PayloadedAction
) {
  switch (type) {
    case CONTENT.LOADING:
      return {
        ...state,
        ...payload,
      }
    case CONTENT.S3LINK_GET_S3_LINK_FAILED:
    case CONTENT.CONTENT_UPDATE_FAILED:
    case CONTENT.CONTENT_RETRIEVE_FAILED:
    case CONTENT.CONTENT_LIST_FAILED:
    case CONTENT.CONTENT_DELETE_FAILED:
    case CONTENT.CONTENT_CREATE_FAILED:
    case CONTENT.INSTRUCTOR_SERIES_LIST_FAILED:
    case CONTENT.CONTENT_UPLOAD_FAILED:
    case CONTENT.SERIES_CREATE_FAILED:
    case CONTENT.INSTRUCTOR_CREATE_FAILED:
    case CONTENT.VIDEO_LIST_METADATA_FAILED:
    case CONTENT.VIDEO_QUESTION_DELETE_FAILED:
      return {
        ...state,
        ...payload,
      }
    case CONTENT.CONTENT_DELETE_SUCCESS:
    case CONTENT.INSTRUCTOR_SERIES_LIST_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case CONTENT.CONTENT_RETRIEVE_SUCCESS:
      return {
        ...payload,
        videos: payload,
      }
    case CONTENT.CONTENT_UPDATE_SUCCESS: {
      const updatedVideos = state.videos.map((video: any) => {
        if (video.id === payload.id) {
          return payload
        }
        return video
      })

      const options = updatedVideos.sort((a: any, b: any) => {
        if (a.updatedAt < b.updatedAt) return 1
        if (a.updatedAt > b.updatedAt) return -1
        return 0
      })

      const set = new Set([...options])

      return {
        ...state,
        videos: Array.from(set),
      }
    }
    case CONTENT.VIDEO_QUESTION_CREATE_SUCCESS:
    case CONTENT.VIDEO_QUESTION_DELETE_SUCCESS:
    case CONTENT.VIDEO_THUMBNAIL_IMAGE_UPLOAD_SUCCESS:
    case CONTENT.CONTENT_ENABLE_SUCCESS:
    case CONTENT.CONTENT_DISABLE_SUCCESS: {
      const vids = [...state.videos]
      const index = vids.findIndex((i) => i.id === payload.id)
      vids.splice(index, 1, payload)

      return {
        ...state,
        videos: vids,
      }
    }
    case CONTENT.SERIES_CREATE_STAGING_REQUEST:
      return {
        ...state,
        ...payload,
      }
    case CONTENT.SERIES_CREATE_SUCCESS:
      return {
        ...state,
        series: payload,
      }
    case CONTENT.INSTRUCTOR_CREATE_SUCCESS:
      return {
        ...state,
        instructors: payload,
      }
    case CONTENT.CONTENT_CREATE_SUCCESS:
      return {
        ...state,
        uploadedVideo: payload,
      }
    case CONTENT.CONTENT_UPLOAD_SUCCESS:
    case CONTENT.S3LINK_GET_S3_LINK_SUCCESS:
      return {
        ...state,
        s3Video: {
          url: payload.url,
          fields: payload.fields,
        },
        uploadedVideo: {
          ...state.uploadedVideo,
          ...payload,
        },
      }
    case CONTENT.CONTENT_UPLOAD_CLEAR_STATE:
      return {
        ...state,
        uploadedVideo: null,
        uploadedVideoId: null,
        s3Video: null,
      }
    case CONTENT.CONTENT_LIST_SUCCESS:
      return {
        ...state,
        videos: payload,
      }
    case CONTENT.INSTRUCTOR_LIST_SUCCESS:
      return {
        ...state,
        instructors: payload,
      }
    case CONTENT.INSTRUCTOR_UPDATE_SUCCESS: {
      const newInstructors = state.instructors.map((i) => {
        if (i.id === payload.id) {
          return payload
        }
        return i
      })
      return {
        ...state,
        instructors: newInstructors,
        videos: state.videos.map((video) => {
          if (video?.VideoInstructor?.id === payload.id) {
            return { ...video, VideoInstructor: payload }
          }
          return video
        }),
      }
    }
    case CONTENT.INSTRUCTOR_DELETE_SUCCESS: {
      const newInstructors = state.instructors.filter(
        (i) => i.id !== payload.id
      )
      return {
        ...state,
        instructors: newInstructors,
        videos: state.videos.map((video) => {
          if (video?.VideoInstructor?.id === payload.id) {
            return { ...video, VideoInstructor: null }
          }
          return video
        }),
      }
    }
    case CONTENT.SERIES_UPDATE_SUCCESS: {
      const newSeries = state.series.map((i) => {
        if (i.id === payload.id) {
          return payload
        }
        return i
      })
      return {
        ...state,
        series: newSeries,
        videos: state.videos.map((video) => {
          if (video?.VideoSeries?.id === payload.id) {
            return { ...video, VideoSeries: payload }
          }
          return video
        }),
      }
    }

    case CONTENT.SERIES_DELETE_SUCCESS: {
      const newSeries = state.series.filter((i) => i.id !== payload.id)
      return {
        ...state,
        series: newSeries,
        videos: state.videos.map((video) => {
          if (video?.VideoSeries?.id === payload.id) {
            return { ...video, VideoSeries: null }
          }
          return video
        }),
      }
    }
    case CONTENT.VIDEO_LIST_METADATA_SUCCESS:
      return {
        ...state,
        totalVideos: payload,
      }
    case CONTENT.INSTRUCTOR_LIST_METADATA_SUCCESS:
      return {
        ...state,
        totalInstructors: payload,
      }

    case CONTENT.VIDEO_TAGS_LIST_SUCCESS:
      return {
        ...state,
        tags: payload,
      }
    case AUTH.LOGOUT_SUCCESS:
      return initialState
    default:
      return state
  }
}
