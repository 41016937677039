import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Button,
  Box,
} from '@chakra-ui/react'

import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { useDispatch } from 'react-redux'

import { formatStripeMoney } from '../../../helpers/utils'
import { setLogoutSuccess } from '../../auth/store/auth.actions'
import PaymentPendingPrompt from './PaymentPendingPrompt'

export interface ICheckoutModalModalProps {
  clientSecret: string
  amountDue: number | undefined
  plan: any
  isOpen: boolean
  planType: string
  onSuccess: () => void
  onClose: () => void
  onFail: () => void
}

const CheckoutModal: React.FC<ICheckoutModalModalProps> = ({
  amountDue,
  plan,
  isOpen,
  planType,
  onSuccess,
  onClose,
  onFail,
}) => {
  const elements = useElements()
  const stripe = useStripe()
  const [isLoading, setIsLoading] = useState(true)
  const [pendingPromptOpen, setPendingPromptOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (stripe != null) {
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    }
  }, [stripe])

  const handleCancel = async () => {
    onClose()
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)

    stripe
      ?.confirmPayment({
        elements,
        redirect: 'if_required',
      })
      .then((response) => {
        if (response.paymentIntent?.status === 'requires_action') {
          setPendingPromptOpen(true)
        } else if (!response.error) {
          onSuccess()
        } else {
          onFail()
        }
      })
      .catch(() => {
        onFail()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior="outside"
      size={'lg'}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bgColor="#272727"
        color="white"
        border="none"
        borderRadius="4px"
      >
        <ModalHeader textAlign="center">
          Subscription payment - {plan?.title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <PaymentElement />
            <Box py={2}>
              <Text htmlFor="coupon" fontSize="xl">
                Total: {formatStripeMoney(amountDue)}
              </Text>
            </Box>
            <Box my={2}>
              <Text color="darkgray" fontSize="sm">
                By subscribing you agree to our terms and conditions.
              </Text>
              <Text color="darkgray" fontSize="sm">
                We’ll renew your subsciption each{' '}
                {planType.toLowerCase().includes('year') ? 'year' : 'month'}.
                You can cancel your subscription at any time.
              </Text>
            </Box>
            <Flex justifyContent="center" my={4}>
              <Button
                isLoading={isLoading}
                disabled={!stripe || !elements}
                id="submit"
                type="submit"
                color="white"
                colorScheme="brand"
              >
                Subscribe
              </Button>
              <Button onClick={handleCancel} ml={4}>
                Cancel
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
      <PaymentPendingPrompt
        isOpen={pendingPromptOpen}
        onSuccess={() => dispatch(setLogoutSuccess())}
      />
    </Modal>
  )
}

export default CheckoutModal
