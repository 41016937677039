import { RoleEnum, UserInterface } from '../../users/store/users.types'

export interface AuthState {
  isLoading: boolean
  error: AuthFailPayload | null
  auth: AuthPayload
  passwordReset: PasswordResetPayload
  redirectTo: string
}

export interface UserCredentials {
  email: string
  password?: string
  token?: string

  accessToken?: string
  refreshToken?: string
  expiresIn?: number
  id?: string
  groups?: string[]
  user?: any
}

interface UserSignUpInput {
  fullName: string
  email: string
  role?: RoleEnum
}

export interface UserSignupCredentials {
  data: UserSignUpInput
  password: string
  confirmPassword: string
}

export interface ForgotPasswordRequest {
  email: string
}

export interface ForgotPasswordVerifyRequest {
  code: string
  email?: string
}

export interface ChangePasswordRequest {
  code?: string
  email?: string
  password: string
}

export interface PasswordResetPayload {
  requestSent?: boolean
  codeVerified?: boolean
  passwordReset?: boolean
  email?: string
  code?: string
}

export interface AuthPayload {
  id?: string
  isAuthenticated: boolean
  accessToken?: string
  groups: string[]
  refreshToken?: string
  expiresIn?: string
  user?: UserInterface
  v?: number
}

export interface AuthFailPayload {
  graphQLErrors?: []
  networkError?: number
  message: string
  isAuthenticated?: boolean
}

export interface RefreshTokenRequest {
  accessToken: string
  refreshToken: string
  expiresIn: string
}

export interface RefreshTokenPayload {
  accessToken: string
  refreshToken: string
  expiresIn: string
  id: number
  groups: string[]
}

export interface IUser {
  id?: string
  authId?: string
  username?: string
  fullName: string
  password?: string
  fitbitUserId?: string
  fitbitAccessToken?: string
  fitbitRefreshToken?: string
  country?: string
  email: string
  usedDiscord?: boolean
  createdAt?: Date
  updatedAt?: Date
  avatar?: string
  groups?: string[]
  preferMetricUnits?: boolean
  waterIntakePreference?: number
  birthDate?: string
  gameRoutines?: string
  sleepRoutines?: string
  weight?: number
  height?: number
  timeToBed?: number
  timeOutOfBed?: number
  hoursAtDesk?: number
  waistCircumference?: number
  hipCircumference?: number
  teamSeats?: number
  pathToFitgmr?: string
  healthyMeals?: number
  onboardingStatus?: string
  shownGettingStartedCompleted?: boolean
  coachSubscription?: any
  role?: RoleEnum
  [key: string]: any
}

export enum AUTH {
  LOADING = 'auth:loading',
  LOGIN_REQUEST = 'auth:login:request',
  LOGIN_SUCCESS = 'auth:login:success',
  LOGIN_FAILED = 'auth:login:failed',
  DISCORD_LOGIN_REQUEST = 'auth:discord:login:request',
  DISCORD_LOGIN_SUCCESS = 'auth:discord:login:success',
  DISCORD_LOGIN_FAILED = 'auth:discord:login:failed',
  SIGNUP_REQUEST = 'auth:signup:request',
  SIGNUP_SUCCESS = 'auth:signup:success',
  SIGNUP_FAILED = 'auth:signup:failed',
  LOGOUT_REQUEST = 'auth:logout:request',
  LOGOUT_SUCCESS = 'auth:logout:success',
  LOGOUT_FAILED = 'auth:logout:failed',
  FORGOT_PASSWORD_REQUEST = 'auth:forgot_password:request',
  FORGOT_PASSWORD_SUCCESS = 'auth:forgot_password:success',
  FORGOT_PASSWORD_FAILED = 'auth:forgot_password:failed',
  FORGOT_PASSWORD_VERIFY_REQUEST = 'auth:forgot_password_verify:request',
  CHANGE_PASSWORD_REQUEST = 'auth:change_password:request',
  REFRESH_TOKEN_REQUEST = 'auth:refresh_token:request',
  REFRESH_TOKEN_SUCCESS = 'auth:refresh_token:success',
  REFRESH_TOKEN_FAILED = 'auth:refresh_token:failed',
  REFRESH_USER_TEAM = 'auth:refresh:user:team',
  REFRESH_USER = 'auth:refresh:user',
  UPDATE_USER_GROUP = 'auth:update:user:group',
  UPDATE_TEAM_SEATS = 'auth:update:user:team_seats',
  SET_REDIRECT_URL = 'auth:set:url',
}
