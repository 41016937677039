import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import reducer from './reducer'
import saga from './saga'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const config: any = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(config, reducer)

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
)
sagaMiddleware.run(saga)

export const persistor = persistStore(store)

export default store
