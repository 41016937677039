import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required')
    .nullable(),
  username: Yup.string().min(2, 'Too Short!').max(20, 'Too Long!').nullable(),
  email: Yup.string().email('Invalid email').required('Required').nullable(),
  role: Yup.string().required('Required'),
})

export default validationSchema
