import { format } from 'date-fns'

export const GAME_PERFORMANCE_SCORE = {
  GREAT: 'Peak Form',
  GOOD: 'Pretty Solid',
  REGULAR: 'Not Ideal',
  DANGER_ZONE: 'Danger Zone',
  NO_DATA: 'No data',
  NO_GAMING: 'No Gaming',
}

export const GAME_READINESS_SCORE = {
  GREAT: 'Game On',
  GOOD: 'Moderately Ready',
  REGULAR: 'Subpar Ready',
  NOT_READY: 'Not Ready',
}

export const CLASSNAME_SCORE = {
  GREAT: 'great',
  GOOD: 'good',
  REGULAR: 'regular',
  NOT_READY: 'not-ready',
  DANGER_ZONE: 'danger-zone',
  NO_DATA: 'no-data',
  NO_GAMING: 'no-gaming',
}

export const capitalize = (text: string): string =>
  text.charAt(0).toUpperCase() + text.substr(1)

export const convertGameReadinessScoreToString = (
  score: number | undefined
): string | undefined => {
  if (score == null || score === 0) {
    return undefined
  }

  if (score >= 80) {
    return GAME_READINESS_SCORE.GREAT
  }
  if (score >= 60) {
    return GAME_READINESS_SCORE.GOOD
  }
  if (score >= 45) {
    return GAME_READINESS_SCORE.REGULAR
  }
  return GAME_READINESS_SCORE.NOT_READY
}

export const convertGameReadinessScoreToClassName = (
  score: number | undefined
): string | undefined => {
  if (score == null || score === 0) {
    return 'no-data'
  }

  if (score >= 80) {
    return CLASSNAME_SCORE.GREAT
  }
  if (score >= 60) {
    return CLASSNAME_SCORE.GOOD
  }
  if (score >= 45) {
    return CLASSNAME_SCORE.REGULAR
  }
  return CLASSNAME_SCORE.NOT_READY
}

export const convertGamePerformanceScoreToString = (
  score: number | undefined,
  didNotPlayToday?: boolean
): string | undefined => {
  if (didNotPlayToday) {
    return GAME_PERFORMANCE_SCORE.NO_GAMING
  }
  if (score == null || score === 0) {
    return GAME_PERFORMANCE_SCORE.NO_DATA
  }
  if (score >= 80) {
    return GAME_PERFORMANCE_SCORE.GREAT
  }
  if (score >= 60) {
    return GAME_PERFORMANCE_SCORE.GOOD
  }
  if (score >= 40) {
    return GAME_PERFORMANCE_SCORE.REGULAR
  }
  return GAME_PERFORMANCE_SCORE.DANGER_ZONE
}

export const convertGamePerformanceScoreToClassName = (
  score: number | undefined,
  didNotPlayToday?: boolean
): string | undefined => {
  if (didNotPlayToday) {
    return CLASSNAME_SCORE.NO_GAMING
  }
  if (score == null || score === 0) {
    return CLASSNAME_SCORE.NO_DATA
  }
  if (score >= 80) {
    return CLASSNAME_SCORE.GREAT
  }
  if (score >= 60) {
    return CLASSNAME_SCORE.GOOD
  }
  if (score >= 40) {
    return CLASSNAME_SCORE.REGULAR
  }
  return CLASSNAME_SCORE.DANGER_ZONE
}

export const formatStripeMoney = (amount: number | undefined): string =>
  amount ? `$${(amount / 100).toFixed(2)}` : 'N/A'

export const parsedDate = (date?: string) =>
  format(date ? new Date(date) : new Date(), 'MM/dd/yyyy - hh:mm a')
