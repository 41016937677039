import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Button,
  Link,
} from '@chakra-ui/react'

import { config } from '../../../config'

export interface IGamerLoginPromptProps {
  isOpen: boolean
  onSuccess: () => void
}

const GamerLoginPrompt: React.FC<IGamerLoginPromptProps> = ({
  isOpen,
  onSuccess,
}) => (
  <Modal
    onClose={() => {}}
    isOpen={isOpen}
    scrollBehavior="outside"
    size={'lg'}
    isCentered
  >
    <ModalOverlay />
    <ModalContent
      bgColor="#272727"
      color="white"
      border="none"
      borderRadius="4px"
    >
      <ModalHeader textAlign="center">Login failed</ModalHeader>
      <ModalBody textAlign="center">
        Only Directors and Coaches can access the dashboard. <br />
        As a Gamer & Team Member, you can access all your information and
        features directly on the{' '}
        <Link
          fontWeight="bold"
          color="brand.50"
          href={`${config.url}api/v1/storeLink`}
          isExternal
        >
          FitGMR mobile app
        </Link>
        .
        <Flex justifyContent="center" pb={4} pt={8}>
          <Button color="white" colorScheme="brand" onClick={onSuccess}>
            Close
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  </Modal>
)
export default GamerLoginPrompt
