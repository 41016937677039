import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import App from './App'
import reportWebVitals from './reportWebVitals'

if (
  process.env.REACT_APP_ENV !== 'local' &&
  process.env.REACT_APP_ENV !== 'localdev' &&
  process.env.REACT_APP_ENV != null
) {
  Sentry.init({
    dsn: 'https://87a2b727d3fb45dbac5a6fdcf7d87e53@sentry.cleverbuild.biz/72',
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
