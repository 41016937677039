import React, { useCallback, useEffect, useRef } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  VStack,
  FormControl,
  FormLabel,
  Select,
  Button,
  Input,
  Image,
} from '@chakra-ui/react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import { format } from 'date-fns'
import { PillarVideoCategories } from '../../components/video-recommendation/Video.recommendation.types'
import { CONTENT_QUERIES } from '../../components/content/queries/content.queries'
import { RECOMMENDATION_MUTATIONS } from '../../components/video-recommendation/mutations/recommendation.mutations'

const AddRecommendationModal: React.FC<{
  open: boolean
  closeModal: () => void
}> = ({ open, closeModal }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ isOpen: open })
  const btnRef = useRef(null)

  const handleCloseModal = useCallback(() => {
    closeModal()
  }, [closeModal])

  const [addRecommendation] = useMutation(
    RECOMMENDATION_MUTATIONS.CREATE_RECOMMENDATION,
    {
      onCompleted: () => {
        handleCloseModal()
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        formik.resetForm()
      },
    }
  )

  const formik = useFormik({
    initialValues: {
      title: '',
      pillarSlug: '',
      questionSlug: '',
      answer: '',
      videoId: '',
    },
    onSubmit: (values) => {
      addRecommendation({
        variables: {
          data: {
            title: values.title,
            videoId: values.videoId,
            pillarSlug: values.pillarSlug,
            questionSlug: values.questionSlug,
            answer: values.answer,
          },
        },
      })
    },
  })

  /**
   * On Mount
   */
  useEffect(() => {
    if (open) {
      onOpen()
    } else {
      onClose()
    }
  }, [open])

  const [fetchDailyVideo, pillarVideoResponse] = useLazyQuery(
    CONTENT_QUERIES.GET_USER_VIDEOS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  )

  const getActivities = (selectedKey: string) => {
    const selectedObject = PillarVideoCategories.find(
      (obj) => Object.keys(obj)[0] === selectedKey
    )

    if (selectedObject) {
      const activitiesArray = selectedObject[selectedKey].map(
        (activityObj: any) => activityObj.activity
      )
      return activitiesArray
    }

    return []
  }

  const getAnswers = (selectedKey: string, selectedActivity: string) => {
    const selectedObject = PillarVideoCategories.find(
      (obj) => Object.keys(obj)[0] === selectedKey
    )

    if (selectedObject) {
      const selectedActivityObject = selectedObject[selectedKey].find(
        (activityObj: any) => activityObj.activity === selectedActivity
      )
      return selectedActivityObject?.options || []
    }

    return []
  }
  useEffect(() => {
    if (formik.values.pillarSlug)
      fetchDailyVideo({
        variables: {
          where: {
            availableFrom: {
              lte: format(new Date(), 'yyyy-MM-dd'),
            },
            pillarCategory: {
              equals: formik.values.pillarSlug.toUpperCase(),
            },
          },
        },
      })
  }, [formik.values.pillarSlug])

  const getVideoOptions = () => pillarVideoResponse?.data?.videos || []

  const showPreviewVideo = () => {
    const video = pillarVideoResponse?.data?.videos.find(
      (video: any) => video.id === formik.values.videoId
    )
    if (video) {
      return (
        <Image
          maxWidth="350px"
          objectFit="contain"
          borderRadius="10px"
          src={video.selectedThumbnail || video.thumbnailUrl}
          alt="thumbnail image"
        />
      )
    }
    return null
  }

  return (
    <>
      <Modal
        onClose={handleCloseModal}
        finalFocusRef={btnRef}
        isOpen={isOpen}
        scrollBehavior="outside"
        size="lg"
      >
        <ModalOverlay />
        <ModalContent
          bgColor="#272727"
          color="white"
          border="none"
          borderRadius="4px"
          className="team-invite"
        >
          <ModalHeader textAlign="center">Add Video Recommendation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex w="100%" align="center" justify="center" direction="column">
              <div />
              <form onSubmit={formik.handleSubmit}>
                <VStack spacing={4} align="flex-start">
                  <FormControl w="350px">
                    <FormLabel htmlFor="title">Title</FormLabel>
                    <Input
                      id="title"
                      name="title"
                      type="title"
                      variant="filled"
                      onChange={formik.handleChange}
                      value={formik.values.title}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="pillar">Pillar</FormLabel>
                    <Select
                      id="pillarSlug"
                      name="pillarSlug"
                      type="pillarSlug"
                      variant="filled"
                      onChange={formik.handleChange}
                      value={formik.values.pillarSlug}
                      disabled={!formik.values.title}
                      placeholder="Select Pillar"
                    >
                      {PillarVideoCategories.map((pillar: any) => (
                        <option key={Object.keys(pillar)[0]}>
                          {Object.keys(pillar)[0]}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="questionSlug">Question</FormLabel>
                    <Select
                      id="questionSlug"
                      name="questionSlug"
                      type="questionSlug"
                      variant="filled"
                      onChange={formik.handleChange}
                      value={formik.values.questionSlug}
                      disabled={!formik.values.pillarSlug}
                      placeholder="Select Question"
                    >
                      {getActivities(formik.values.pillarSlug).map(
                        (activity: string) => (
                          <option key={activity}>{activity}</option>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="answer">Answer Type</FormLabel>
                    <Select
                      id="answer"
                      name="answer"
                      type="answer"
                      variant="filled"
                      onChange={formik.handleChange}
                      value={formik.values.answer}
                      disabled={!formik.values.questionSlug}
                      placeholder="Select Answer Type"
                    >
                      {getAnswers(
                        formik.values.pillarSlug,
                        formik.values.questionSlug
                      ).map((activity: string) => (
                        <option key={activity}>{activity}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="video">Video Link</FormLabel>
                    <Select
                      id="videoId"
                      name="videoId"
                      type="videoId"
                      variant="filled"
                      onChange={formik.handleChange}
                      value={formik.values.videoId}
                      placeholder="Select Video"
                    >
                      {getVideoOptions().map((video: any) => (
                        <option key={video.id} value={video.id}>
                          {video.title}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.values.videoId && showPreviewVideo()}

                  <Button
                    type="submit"
                    colorScheme="purple"
                    width="full"
                    disabled={!formik.values.videoId}
                  >
                    Save
                  </Button>
                </VStack>
              </form>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddRecommendationModal
