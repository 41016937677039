import { createSelector } from 'reselect'
import { RoleEnum } from '../../users/store/users.types'

import { AuthState } from './auth.types'
import { RootState } from '../../../types'

export const selectAuthState: (state: RootState) => AuthState = (state) =>
  state.auth

export const selectIsLoading = createSelector(
  selectAuthState,
  (state: AuthState) => state.isLoading
)

export const selectError = createSelector(selectAuthState, (state: AuthState) =>
  state.error ? state.error.message : ''
)

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => state.auth.isAuthenticated
)

export const selectUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.auth.user
)

export const selectPasswordReset = createSelector(
  selectAuthState,
  (state: AuthState) => state.passwordReset
)

export const selectTokens = createSelector(
  selectAuthState,
  (state: AuthState) => state.auth || null
)

export const selectIsAdmin = createSelector(
  selectAuthState,
  (state: AuthState) =>
    state?.auth?.user?.role === RoleEnum.ADMIN ||
    state?.auth?.user?.role === RoleEnum.SUPERADMIN
)

export const selectHasCoachAccess = createSelector(
  selectAuthState,
  (state: AuthState) =>
    state?.auth?.user?.role === RoleEnum.DIRECTOR ||
    state?.auth?.user?.role === RoleEnum.COACH
)

export const selectIsTeamManager = createSelector(
  selectAuthState,
  (state: AuthState) => state?.auth?.user?.role === RoleEnum.DIRECTOR
)
export const selectIsCoach = createSelector(
  selectAuthState,
  (state: AuthState) => state?.auth?.user?.role === RoleEnum.COACH
)

export const selectRedirectTo = createSelector(
  selectAuthState,
  (state: AuthState) => state.redirectTo
)
