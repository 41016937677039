import {
  gql,
  // Reference
} from '@apollo/client'
import client from '../../../gql/client'

import {
  VideoEnableDisableRequestInterface,
  VideoRetrieveRequestInterface,
  VideoUpdateRequestInterface,
  VideoCreateRequestInterface,
  VideoUploadRequestInterface,
  InstructorSeriesCreateOrUpdateInterface,
  VideoDeleteRequestInterface,
  VideoListRequest,
  VideoAggregatedRequestInterface,
  VideoQuestionCrudInterface,
  VideoSeriesCoverImageCreateInterace,
  InstructorUpdateInterface,
  InstructorDeleteInterface,
  SerieDeleteInterface,
  VideoWhereUniqueInput,
} from '../store/content.types'

import { CONTENT_MUTATIONS } from '../mutations/content.mutations'
import { CONTENT_QUERIES } from '../queries/content.queries'

const videoCreateService = ({
  data,
  file,
}: VideoCreateRequestInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.CREATE_VIDEO,
    variables: {
      data,
      file,
    },
    update: (cache, { data: { createVideo } }) => {
      cache.modify({
        fields: {
          videos: (existingVideos = []) => {
            const newVideoRef = cache.writeFragment({
              data: createVideo,
              fragment: gql`
                fragment NewVideo on Videos {
                  id
                  title
                  category
                }
              `,
            })
            return [...existingVideos, newVideoRef]
          },
        },
      })
    },
  })

const videoS3LinkService = ({
  where,
  fileExtension,
}: {
  where: VideoWhereUniqueInput
  fileExtension: string
}): Promise<any> =>
  client.query({
    query: CONTENT_QUERIES.GET_S3_LINK,
    variables: {
      where,
      fileExtension,
    },
  })

const videoUploadService = ({
  where,
  file,
}: VideoUploadRequestInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.UPLOAD_VIDEO,
    variables: {
      where,
      file,
    },
  })

const videoUpdateService = ({
  where,
  data,
}: VideoUpdateRequestInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.UPDATE_VIDEO,
    variables: {
      where,
      data,
    },
  })

const videoRetrieveService = ({
  where,
}: VideoRetrieveRequestInterface): Promise<any> =>
  client.query({
    query: CONTENT_QUERIES.GET_VIDEO,
    variables: {
      where,
    },
  })

const videoListService = (data: VideoListRequest): Promise<any> =>
  client.query({
    query: CONTENT_QUERIES.GET_VIDEOS,
    variables: data,
  })

const videoListMetadataService = (
  data: VideoAggregatedRequestInterface
): Promise<any> =>
  client.query({
    query: CONTENT_QUERIES.GET_AGGREGATED_VIDEOS,
    variables: data,
  })

const instructorsAndSeriesService = (): Promise<any> =>
  client.query({
    query: CONTENT_QUERIES.GET_INSTRUCTORS_AND_SERIES,
  })

const instructorCreateService = ({
  data,
}: InstructorSeriesCreateOrUpdateInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.CREATE_INSTRUCTOR,
    variables: {
      data,
    },
    update(cache, { data: { createVideoInstructor } }) {
      cache.modify({
        fields: {
          videoInstructors(instructors = []) {
            const newRef = cache.writeFragment({
              data: createVideoInstructor,
              fragment: gql`
                fragment newVideoInstructor on VideoCreateOrUpdateOption {
                  id
                  fullName
                }
              `,
            })
            return [...instructors, newRef]
          },
        },
      })
    },
  })
const instructorUpdateService = ({
  data,
  where,
}: InstructorUpdateInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.UPDATE_INSTRUCTOR,
    variables: {
      data,
      where,
    },
  })

const instructorDeleteService = ({
  where,
}: InstructorDeleteInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.DELETE_INSTRUCTOR,
    variables: {
      where,
    },
  })

const serieDeleteService = ({ where }: SerieDeleteInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.DELETE_SERIES,
    variables: {
      where,
    },
  })

const seriesCreateService = ({
  data,
}: InstructorSeriesCreateOrUpdateInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.CREATE_SERIES,
    variables: {
      data,
    },
    update(cache, { data: { createVideoSeries } }) {
      cache.modify({
        fields: {
          findManyVideoSeries(series = []) {
            const newRef = cache.writeFragment({
              data: createVideoSeries,
              fragment: gql`
                fragment newVideoSeries on VideoCreateOrUpdateOption {
                  id
                  name
                  description
                  coverImage
                  basic
                }
              `,
            })
            return [...series, newRef]
          },
        },
      })
    },
  })

const seriesUpdateService = ({
  data,
  where,
}: InstructorSeriesCreateOrUpdateInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.UPDATE_SERIES,
    variables: {
      data,
      where,
    },
  })

const seriesCoverImageUploadService = ({
  where,
  file,
}: VideoSeriesCoverImageCreateInterace): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.UPLOAD_SERIES_COVER_IMAGE,
    variables: {
      where,
      file,
    },
  })

const videoThumbnailUploadService = ({
  where,
  file,
}: VideoSeriesCoverImageCreateInterace): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.UPLOAD_VIDEO_THUMBNAIL,
    variables: {
      where,
      file,
    },
  })

const videoEnableService = ({
  where,
}: VideoEnableDisableRequestInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.ENABLE_VIDEO,
    variables: {
      where,
    },
  })

const videoDisableService = ({
  where,
}: VideoEnableDisableRequestInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.DISABLE_VIDEO,
    variables: {
      where,
    },
  })

const videoDeleteService = ({
  where,
}: VideoDeleteRequestInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.DELETE_VIDEO,
    variables: {
      where,
    },
    // update(cache, { data: { deleteVideoInstructor } }) {
    //   cache.modify({
    //     id: cache.identify({
    //       __ref: `VideoCreateOrUpdateOption:${deleteVideoInstructor.id}`,
    //     }),
    //     fields: {
    //       users(ref: Reference[]) {
    //         const reference = cache.identify(deleteVideoInstructor)
    //         return ref.filter((refs) => refs.__ref !== reference)
    //       },
    //     },
    //   })
    // },
  })

const videoQuestionCreateService = ({
  data,
}: VideoQuestionCrudInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.CREATE_VIDEO_QUESTION,
    variables: {
      data,
    },
  })

const videoQuestionUpdateService = ({
  data,
  where,
}: VideoQuestionCrudInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.UPDATE_VIDEO_QUESTION,
    variables: {
      data,
      where,
    },
  })

const videoQuestionDeleteService = ({
  where,
}: VideoQuestionCrudInterface): Promise<any> =>
  client.mutate({
    mutation: CONTENT_MUTATIONS.DELETE_VIDEO_QUESTION,
    variables: {
      where,
    },
  })

export default {
  VIDEO_CREATE_SERVICE: videoCreateService,
  VIDEO_UPLOAD_SERVICE: videoUploadService,
  VIDEO_UPDATE_SERVICE: videoUpdateService,
  VIDEO_RETRIEVE_SERVICE: videoRetrieveService,
  VIDEO_GET_S3_LINK_SERVICE: videoS3LinkService,
  VIDEO_DELETE_SERVICE: videoDeleteService,
  VIDEO_LIST_SERVICE: videoListService,
  VIDEO_ENABLE_SERVICE: videoEnableService,
  VIDEO_DISABLE_SERVICE: videoDisableService,
  INSTRUCTOR_AND_SERIES_RETRIEVE_SERVICE: instructorsAndSeriesService,
  INSTRUCTOR_CREATE_SERVICE: instructorCreateService,
  INSTRUCTOR_UPDATE_SERVICE: instructorUpdateService,
  INSTRUCTOR_DELETE_SERVICE: instructorDeleteService,
  SERIE_DELETE_SERVICE: serieDeleteService,
  SERIES_CREATE_SERVICE: seriesCreateService,
  SERIES_UPDATE_SERVICE: seriesUpdateService,
  SERIES_COVER_IMAGE_UPLOAD_SERVICE: seriesCoverImageUploadService,
  VIDEO_AGGREGATE_SERVICE: videoListMetadataService,
  VIDEO_QUESTION_CREATE_SERVICE: videoQuestionCreateService,
  VIDEO_QUESTION_UPDATE_SERVICE: videoQuestionUpdateService,
  VIDEO_QUESTION_DELETE_SERVICE: videoQuestionDeleteService,
  VIDEO_THUMBNAIL_UPLOAD_SERVICE: videoThumbnailUploadService,
}
