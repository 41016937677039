import React, { useState, useEffect } from 'react'

import {
  VStack,
  Container,
  Button,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Link,
  FormControl,
  FormErrorMessage,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react'
import { FaEye, FaEyeSlash, FaLock } from 'react-icons/fa'

import { Link as RouterLink } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import useFeedback from '../../shared/ui/feedback/useFeedbackModal'
import Feedback from '../../shared/ui/feedback/Feedback'

import {
  selectIsLoading,
  selectError,
  selectPasswordReset,
} from '../store/auth.selectors'

import {
  setChangePasswordRequest,
  setLoginFailure,
} from '../store/auth.actions'
import { ChangePasswordRequest } from '../store/auth.types'

const ChangePasswordForm: React.FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsLoading)
  const errorMessage = useSelector(selectError)
  const { email, code } = useSelector(selectPasswordReset)

  const [credentials, setCredentials] = useState<ChangePasswordRequest>({
    code,
    email,
    password: '',
  })
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [show, setShow] = useState<boolean>(false)

  const { title, setTitle, message, setMessage, isOpen, setIsOpen, returnUrl } =
    useFeedback()

  useEffect(() => {
    if (errorMessage) {
      setTitle('Password Reset Failed')
      setMessage(errorMessage)
      setIsOpen(true)
    } else {
      setTitle('')
      setMessage('')
      setIsOpen(false)
    }
  }, [errorMessage])

  const checkConfirmPassword = () => credentials.password !== confirmPassword

  const resetError = () => {
    setTitle('')
    setMessage('')
    setIsOpen(false)
  }

  const submit = () => {
    resetError()
    if (credentials.password) {
      dispatch(setChangePasswordRequest(credentials))
    } else {
      setTitle('Password Reset Failed')
      setMessage('Please enter a password.')
      setIsOpen(true)
    }
  }

  const handleClose = () => {
    dispatch(setLoginFailure({ message: '' }))
    resetError()
  }

  return (
    <>
      <Box
        p={5}
        shadow="md"
        borderRadius="md"
        background="#0c0c0c"
        minW="260px"
        w="300px"
      >
        <VStack mb={8} mt={4} spacing={5}>
          <Container>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <FaLock />
              </InputLeftElement>
              <Input
                variant="flushed"
                placeholder="Password"
                type={show ? 'text' : 'password'}
                id="signup-password"
                onChange={(e) => {
                  setCredentials({
                    ...credentials,
                    password: e.target.value,
                  })
                }}
              />
              <InputRightElement>
                <IconButton
                  aria-label="show password"
                  onClick={() => setShow(!show)}
                  icon={!show ? <FaEye /> : <FaEyeSlash />}
                />
              </InputRightElement>
            </InputGroup>
          </Container>
          <Container>
            <FormControl isInvalid={Boolean(checkConfirmPassword())}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaLock />
                </InputLeftElement>
                <Input
                  variant="flushed"
                  placeholder="Confirm Password"
                  type={show ? 'text' : 'password'}
                  id="signup-conform-password"
                  onBlur={() => checkConfirmPassword()}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value)
                  }}
                />
              </InputGroup>
              <FormErrorMessage>Passwords do not match</FormErrorMessage>
            </FormControl>
          </Container>
          <Container mb={2} centerContent>
            <Text fontSize="sm">
              Please enter and confirm your new password.
            </Text>
          </Container>
        </VStack>
        <VStack>
          <Container mb={1} centerContent>
            <Button
              isLoading={isLoading}
              loadingText="Please wait"
              isFullWidth
              onClick={submit}
              color="white"
              colorScheme="brand"
            >
              Reset
            </Button>
          </Container>
          <Container mb={6} centerContent>
            <Text fontSize="sm">
              Click to{' '}
              <Link
                as={RouterLink}
                fontWeight="bold"
                color="brand.50"
                to="/auth/login/"
              >
                Sign In
              </Link>
            </Text>
          </Container>
        </VStack>
      </Box>
      <Feedback
        title={title}
        message={message}
        isOpen={isOpen}
        returnUrl={returnUrl}
        onClose={handleClose}
      />
    </>
  )
}

export default ChangePasswordForm
