import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  FormErrorMessage,
} from '@chakra-ui/react'
import React from 'react'
import * as Yup from 'yup'

import { Form, Formik, Field } from 'formik'

interface UseModalInterface {
  title: string
  fieldName: string
  callback: (arg: any) => void
  isSubmitting?: boolean
}

const useAddModal = ({
  title,
  fieldName,
  callback,
  isSubmitting,
}: UseModalInterface) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const text = fieldName.replace(/([A-Z])/g, ' $1')
  const initialValues: any = {}
  initialValues[fieldName] = ''

  const formSchema: any = {}
  formSchema[fieldName] = Yup.string().required(
    `A ${
      text.substr(0, 1).toUpperCase() + text.substr(1, text.length)
    } is required.`
  )

  const schema = Yup.object().shape({
    ...formSchema,
  })

  const AddModal: React.FC = () => (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        bgColor="#353535"
        color="white"
        border="1px"
        borderColor="whiteAlpha.300"
        borderRadius="1px"
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <Formik
          validationSchema={schema}
          onSubmit={callback}
          initialValues={initialValues}
        >
          {() => (
            <Form>
              <ModalBody>
                <Field name={fieldName}>
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={
                        form.touched[fieldName] && form.errors[fieldName]
                      }
                      marginBottom="40px"
                      isDisabled={isSubmitting}
                    >
                      <FormLabel>
                        {text.substr(0, 1).toUpperCase() +
                          text.substr(1, text.length)}
                      </FormLabel>
                      <InputGroup>
                        <Input {...field} id={fieldName} placeholder="Title" />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors[fieldName]}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </ModalBody>

              <ModalFooter>
                <Button
                  mr={3}
                  onClick={() => onClose()}
                  color="white"
                  colorScheme="fitgmrRed"
                >
                  Cancel
                </Button>
                <Button
                  mr={3}
                  color="white"
                  colorScheme="brand"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Create
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )

  return {
    onOpen,
    AddModal,
    onClose,
  }
}

export default useAddModal
