import { Center, VStack, Icon, Spacer, Button, Box } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  VideoQuestionCrudInterface,
  CONTENT,
} from '../../../components/content/store/content.types'
import { setActionRequest } from '../../../components/content/store/content.actions'

type Step2UploadVideoProps = {
  isDragActive: boolean
  isDragAccept: boolean
  isDragReject: boolean
  handleUploadVideo: (file: File) => void
  uploadData: any
  loading: boolean
  getRootProps: any
  getInputProps: any
  uploadedVideoId: string
}

export default function Step2UploadVideo({
  isDragActive,
  isDragAccept,
  isDragReject,
  handleUploadVideo,
  uploadData,
  loading,
  getRootProps,
  getInputProps,
  uploadedVideoId,
}: Step2UploadVideoProps) {
  const dispatch = useDispatch()
  const history = useHistory()
  const handleDeleteVideoById = (uploadedVideoId: string) => {
    const data: VideoQuestionCrudInterface = {
      where: {
        id: uploadedVideoId,
      },
    }

    dispatch(setActionRequest(CONTENT.CONTENT_DELETE_REQUEST, data))
  }

  return (
    <Center>
      <VStack width="100%">
        <div
          className={`upload-container ${
            isDragActive && isDragAccept ? '--active' : ''
          } ${uploadData.file ? '--complete' : ''} ${
            isDragActive && isDragReject ? '--error' : ''
          }`}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <VStack>
            <Icon boxSize="8em" as={AiOutlineCloudUpload} />
            {uploadData.file ? (
              <p>
                Click next to upload: <span>{uploadData.file.name}</span>
              </p>
            ) : isDragActive && isDragAccept ? (
              <>
                <p>Drop your file here to upload</p>
                <em>(Only *.mp4, *.mov and *.ts videos will be accepted)</em>
              </>
            ) : isDragActive && isDragReject ? (
              <>
                <p>This file type is not supported</p>
                <em>(Only *.mp4, *.mov and *.ts videos will be accepted)</em>
              </>
            ) : (
              <>
                <p>Drag and drop your file, or click to select files</p>
                <em>(Only *.mp4, *.mov and *.ts videos will be accepted)</em>
              </>
            )}
          </VStack>
        </div>
        <Spacer />
        <Box display="flex">
          <Button
            loadingText="Please wait"
            onClick={() => {
              handleDeleteVideoById(uploadedVideoId)
              history.push(`/home/videos/`)
            }}
            mr="5"
            isFullWidth
            width="200px"
            color="white"
            colorScheme="fitgmrPurple"
            isLoading={loading}
          >
            Cancel
          </Button>
          <Button
            loadingText="Please wait"
            onClick={() => handleUploadVideo(uploadData.file)}
            ml="5"
            isFullWidth
            width="200px"
            disabled={!uploadData.file}
            color="white"
            colorScheme="fitgmrPurple"
            isLoading={loading}
          >
            Next
          </Button>
        </Box>
      </VStack>
    </Center>
  )
}
