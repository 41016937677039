import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  AspectRatio,
} from '@chakra-ui/react'
import React, { useState } from 'react'

import Player from 'react-player'

const MediaModal = () => {
  const [mediaData, setMediaData] = useState({
    url: '',
    title: '',
  })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const MediaModal: React.FC = () => (
    <Modal
      size="full"
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      colorScheme="brand"
    >
      <ModalOverlay />
      <ModalContent backgroundColor="black">
        <ModalHeader>{mediaData.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          paddingTop={5}
        >
          <AspectRatio
            marginTop="5rem"
            width="60%"
            maxWidth="60%"
            ratio={16 / 9}
          >
            <Player
              controls
              playing
              volume={0.4}
              url={mediaData.url}
              width="100%"
              height="100%"
              config={{ file: { forceHLS: true } }}
            />
          </AspectRatio>
        </ModalBody>
      </ModalContent>
    </Modal>
  )

  return {
    setMediaData,
    onOpen,
    MediaModal,
  }
}

export default MediaModal
