import React, { useEffect } from 'react'
import { Center, Image, VStack, Container } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import logo from '../../assets/images/logo.svg'

import Page from '../../components/shared/ui/page/Page'

import ForgortPasswordForm from '../../components/auth/ui/ForgotPasswordForm'
import OneTimePasscode from '../../components/auth/ui/OneTimePasscode'
import ChangePasswordForm from '../../components/auth/ui/ChangePasswordForm'
import { selectPasswordReset } from '../../components/auth/store/auth.selectors'
import Feedback from '../../components/shared/ui/feedback/Feedback'
import useFeedback from '../../components/shared/ui/feedback/useFeedbackModal'

const Login: React.FC = () => {
  const { requestSent, codeVerified, passwordReset } =
    useSelector(selectPasswordReset)
  const {
    title,
    setTitle,
    message,
    setMessage,
    isOpen,
    setIsOpen,
    returnUrl,
    setReturnUrl,
  } = useFeedback()

  useEffect(() => {
    if (passwordReset) {
      setTitle('Password changed')
      setMessage(
        "We've updated your password. You may now login with your new password. Close this message to go to the login page."
      )
      setReturnUrl('/auth/login')
      setIsOpen(passwordReset)
    }
  }, [[passwordReset]])
  return (
    <Page>
      <Center h="100vh" color="Gray" bg="#171717">
        <VStack>
          <Container centerContent pb={8}>
            <Image
              htmlWidth="300px"
              objectFit="contain"
              alt="FitGMR Logo"
              src={logo}
            />
          </Container>
          {!requestSent ? (
            <ForgortPasswordForm />
          ) : !codeVerified ? (
            <OneTimePasscode />
          ) : (
            <ChangePasswordForm />
          )}
          <Feedback
            title={title}
            message={message}
            isOpen={isOpen}
            returnUrl={returnUrl}
          />
        </VStack>
      </Center>
    </Page>
  )
}

export default Login
