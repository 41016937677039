import { PayloadedAction } from '../../../types'

import {
  TEAM,
  TeamCreatePayload,
  TeamErrorInterface,
  TeamFetchRequest,
  TeamInterface,
  TeamInvitePayload,
  TeamRemoveUserPayload,
  TeamUpdatePayload,
} from './coaches.types'
import { UserInterface } from '../../users/store/users.types'

export const setActionInProgress = (isLoading: boolean): PayloadedAction => ({
  type: TEAM.TEAM_ACTION_LOADING,
  payload: {
    isLoading,
  },
})

export const setFetchTeamRequest = (
  payload: TeamFetchRequest
): PayloadedAction => ({
  type: TEAM.TEAM_FETCH_REQUEST,
  payload,
})

export const setFetchTeamSuccess = (
  type: TEAM.TEAM_FETCH_SUCCESS,
  payload: TeamInterface
): PayloadedAction => ({
  type,
  payload,
})

export const setFetchTeamFailure = (
  type: TEAM.TEAM_FETCH_FAILED,
  payload: TeamErrorInterface
): PayloadedAction => ({
  type,
  payload: {
    error: payload,
  },
})

export const setUpdateTeamRequest = (
  payload: TeamUpdatePayload
): PayloadedAction => ({
  type: TEAM.TEAM_UPDATE_REQUEST,
  payload,
})

export const setUpdateTeamSuccess = (
  payload: TeamInterface
): PayloadedAction => ({
  type: TEAM.TEAM_UPDATE_SUCCESS,
  payload,
})

export const setUpdateTeamFailure = (
  type: TEAM.TEAM_UPDATE_FAILED,
  payload: TeamErrorInterface
): PayloadedAction => ({
  type,
  payload: {
    error: payload,
  },
})

export const setCreateTeamRequest = (
  payload: TeamCreatePayload
): PayloadedAction => ({
  type: TEAM.TEAM_CREATE_REQUEST,
  payload,
})

export const setCreateTeamSuccess = (
  payload: TeamInterface
): PayloadedAction => ({
  type: TEAM.TEAM_CREATE_SUCCESS,
  payload,
})

export const setCreateTeamFailure = (
  payload: TeamErrorInterface
): PayloadedAction => ({
  type: TEAM.TEAM_CREATE_FAILED,
  payload: {
    error: payload,
  },
})

export const sendTeamInviteRequest = (
  payload: TeamInvitePayload
): PayloadedAction => ({
  type: TEAM.TEAM_INVITE_REQUEST,
  payload,
})

export const deleteTeamInviteRequest = (payload: String): PayloadedAction => ({
  type: TEAM.TEAM_DELETE_INVITE_REQUEST,
  payload,
})

export const sendTeamInviteFailure = (
  payload: TeamErrorInterface
): PayloadedAction => ({
  type: TEAM.TEAM_INVITE_FAILED,
  payload,
})

export const setRemoveUserRequest = (
  payload: TeamRemoveUserPayload
): PayloadedAction => ({
  type: TEAM.TEAM_REMOVE_USER_REQUEST,
  payload,
})

export const setRemoveUserSuccess = (
  payload: UserInterface
): PayloadedAction => ({
  type: TEAM.TEAM_REMOVE_USER_SUCCESS,
  payload,
})

export const setRemoveTeamInviteSuccess = (
  payload: UserInterface
): PayloadedAction => ({
  type: TEAM.TEAM_REMOVE_USER_INVITE_SUCCESS,
  payload,
})

export const setRemoveUserFailure = (
  payload: TeamErrorInterface
): PayloadedAction => ({
  type: TEAM.TEAM_REMOVE_USER_FAILED,
  payload: {
    error: payload,
  },
})

export const setTeamSeats: (payload: any) => PayloadedAction = (payload) => ({
  type: TEAM.TEAM_SET_SEATS,
  payload,
})
