import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

interface FeedbackProps {
  title: string
  message: string
  isOpen: boolean
  returnUrl?: string
  onClose?: () => void
}

const Feedback: React.FC<FeedbackProps> = ({
  title,
  message,
  isOpen,
  returnUrl,
  onClose,
}) => {
  const [open, setOpen] = useState(isOpen)
  const route = useHistory()

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const handleRedirect = () => {
    if (returnUrl) route.push(returnUrl)
  }

  const handleClose = () => {
    setOpen(false)
    if (returnUrl) {
      handleRedirect()
    } else if (typeof onClose !== 'undefined') {
      onClose()
    }
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={open}
      onClose={handleRedirect}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bgColor="#353535"
        color="white"
        border="1px"
        borderColor="whiteAlpha.300"
        borderRadius="1px"
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Text>{message}</Text>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={handleClose}
            color="white"
            colorScheme="brand"
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default Feedback
