import React, { useState } from 'react'
import {
  GridItem,
  Grid,
  Text,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import DatePicker, { DayRange, utils } from 'react-modern-calendar-datepicker'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'

import { AddIcon } from '@chakra-ui/icons'
import { fitgmrMainColor, pillarColors } from '../../helpers/constants'
import { Table } from '../../components/shared/ui/table'
import CustomSelect from '../../components/shared/ui/select'

import { PAYMENT_QUERIES } from '../../components/payments/queries/payments.queries'

import './Payments.scss'
import { PaymentInterface } from '../../components/payments/store/payments.types'
import AddPaymentForm from './AddPaymentForm'
import PaymentStats from './PaymentStats'
import { RoleEnum } from '../../components/users/store/users.types'
import { SortOrder } from '../../types'

function dateToObj(date: Date) {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  }
}

export const METHODS = {
  'ios-appstore': 'App Store',
  'android-playstore': 'Play Store',
  manual: 'Manual',
  card: 'Card',
  coupon: 'Coupon',
  unknown: 'Stripe',
}

export type MethodTypes = keyof typeof METHODS

const headers = [
  {
    id: 'id',
    title: 'ID',
  },
  {
    id: 'User.id',
    title: 'User Email',
    render: (payment: PaymentInterface) => {
      const { User } = payment

      return <Text>{User?.email}</Text>
    },
  },
  {
    id: 'User.role',
    title: 'User Role',
    render: (payment: PaymentInterface) => {
      const { User } = payment

      return <Text>{User?.role}</Text>
    },
  },
  {
    id: 'method',
    title: 'Method',
    render: (payment: PaymentInterface) => {
      const { method } = payment

      return (
        <Text>{METHODS[(method as MethodTypes) ?? 'unknown'] ?? 'N/A'}</Text>
      )
    },
  },
  {
    id: 'coupon',
    title: 'Coupon',
    render: (payment: PaymentInterface) => {
      const { method, coupon, productId } = payment

      if (method === 'coupon') {
        return <Text>{productId}</Text>
      }

      return <Text>{coupon}</Text>
    },
  },
  {
    id: 'paymentFrequency',
    title: 'Frequency',
    render: (payment: PaymentInterface) => {
      const { paymentFrequency } = payment

      return (
        <Text>{paymentFrequency === 'monthly' ? 'Monthly' : 'Yearly'}</Text>
      )
    },
  },
  {
    id: 'product',
    title: 'Product',
    render: (payment: PaymentInterface) => {
      const { SubscriptionConfig } = payment

      return <Text>{SubscriptionConfig.name}</Text>
    },
  },
  {
    id: 'status',
    title: 'Status',
  },
  {
    id: 'createdAt',
    title: 'Start Date',
    render: (payment: PaymentInterface) => {
      const { createdAt } = payment

      return <Text>{new Date(createdAt).toDateString()}</Text>
    },
  },
  {
    id: 'endSubscriptionAt',
    title: 'End Date',
    render: (payment: PaymentInterface) => {
      const { createdAt, paymentFrequency, endSubscriptionAt } = payment

      if (!endSubscriptionAt) {
        const endSubscriptionDate = new Date(createdAt)

        if (paymentFrequency === 'monthly') {
          endSubscriptionDate.setMonth(endSubscriptionDate.getMonth() + 1)
        } else {
          endSubscriptionDate.setFullYear(endSubscriptionDate.getFullYear() + 1)
        }
        return <Text>{endSubscriptionDate.toDateString()}</Text>
      }

      return <Text>{new Date(endSubscriptionAt).toDateString()}</Text>
    },
  },
]

const statusOptions = [
  {
    value: undefined,
    label: 'All',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'canceled',
    label: 'Canceled',
  },
  {
    value: 'expired',
    label: 'Expired',
  },
]

const roleOptions = [
  {
    value: undefined,
    label: 'All',
  },
  {
    value: RoleEnum.GAMER,
    label: 'Gamer',
  },
  {
    value: RoleEnum.DIRECTOR,
    label: 'Director',
  },
]

const dateRangeOptions = [
  {
    value: 'custom',
    label: 'Custom',
  },
  {
    value: 'last-week',
    label: 'Last Week',
  },
  {
    value: 'last-month',
    label: 'Last Month',
  },
  {
    value: 'last-3-months',
    label: 'Last 3 Months',
  },
  {
    value: 'last-year',
    label: 'Last Year',
  },
  {
    value: 'all-time',
    label: 'All Time',
  },
]

const Payments: React.FC = () => {
  const [page, setPage] = useState<number>(1)
  const [status, setStatus] = useState<string>('active')
  const [role, setRole] = useState<string>('GAMER')
  const [userEmail, setUserEmail] = useState<string>('')
  const [coupon, setCoupon] = useState<string>('')
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState<boolean>(false)

  // last week
  const lastWeek = new Date()

  lastWeek.setDate(new Date().getDate() - 7)

  const [selectedPredefinedRange, setSelectedPredefinedRange] = useState<
    string | undefined
  >('last-week')
  const [selectedDayRange, setSelectedDayRange] = useState<DayRange>({
    from: dateToObj(lastWeek),
    to: utils('en').getToday(),
  })

  const where = {
    status: { equals: status },
    createdAt: {
      gte: selectedDayRange.from
        ? new Date(
            selectedDayRange.from.year,
            selectedDayRange.from.month - 1,
            selectedDayRange.from.day
          )
        : undefined,
      lte: selectedDayRange.to
        ? new Date(
            selectedDayRange.to.year,
            selectedDayRange.to.month - 1,
            selectedDayRange.to.day + 1
          )
        : undefined,
    },
    OR: coupon
      ? [{ coupon: { contains: coupon } }, { productId: { contains: coupon } }]
      : undefined,
    User: {
      is: {
        email: { contains: userEmail || undefined },
        role: { equals: role },
      },
    },
  }

  const aggregateResult = useQuery(PAYMENT_QUERIES.GET_ALL_AGGREGATE_PAYMENTS, {
    variables: {
      where,
    },
  })

  const totalCount = aggregateResult?.data?.aggregatePayments.count?.id || 0

  const { loading, error, data, refetch } = useQuery(
    PAYMENT_QUERIES.GET_ALL_PAYMENTS,
    {
      variables: {
        where,
        orderBy: [{ createdAt: SortOrder.desc }],
        take: rowsPerPage,
        skip: (page - 1) * rowsPerPage,
      },
    }
  )

  const paymentsData = data?.findManyPayments

  const handleSelectPredefinedRange = (val: any) => {
    if (val === 'last-week') {
      const lastWeek = new Date()

      lastWeek.setDate(new Date().getDate() - 7)

      setSelectedDayRange({
        from: dateToObj(lastWeek),
        to: utils('en').getToday(),
      })
    } else if (val === 'last-month') {
      const lastMonth = new Date()

      lastMonth.setMonth(new Date().getMonth() - 1)

      setSelectedDayRange({
        from: dateToObj(lastMonth),
        to: utils('en').getToday(),
      })
    } else if (val === 'last-3-months') {
      const last3Months = new Date()

      last3Months.setMonth(new Date().getMonth() - 3)

      setSelectedDayRange({
        from: dateToObj(last3Months),
        to: utils('en').getToday(),
      })
    } else if (val === 'last-year') {
      const lastYear = new Date()

      lastYear.setFullYear(new Date().getFullYear() - 1)

      setSelectedDayRange({
        from: dateToObj(lastYear),
        to: utils('en').getToday(),
      })
    } else if (val === 'all-time') {
      setSelectedDayRange({
        from: undefined,
        to: undefined,
      })
    }

    setSelectedPredefinedRange(val)
  }

  if (error) return <>Error! ${error.message}</>

  return (
    <>
      <Modal
        isOpen={addPaymentModalOpen}
        onClose={() => setAddPaymentModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Payment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddPaymentForm
              onSuccess={() => {
                refetch()
                setAddPaymentModalOpen(false)
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Grid templateColumns="repeat(5, 1fr)">
        <GridItem
          colSpan={6}
          bgColor="#1C1C1D"
          marginTop={3}
          marginRight={3}
          marginBottom={3}
          marginLeft={3}
          padding={5}
          borderRadius={8}
        >
          <HStack>
            <FormControl id="date-filter" width="150px">
              <FormLabel margin="0" fontSize="14px">
                Range
              </FormLabel>
              <CustomSelect
                name="date-selection-filter"
                defaultValue={selectedPredefinedRange}
                onSelect={handleSelectPredefinedRange}
                options={dateRangeOptions}
              />
            </FormControl>
            <FormControl id="date-filter" width="200px">
              <FormLabel margin="0" fontSize="14px">
                Start Date
              </FormLabel>
              <DatePicker
                calendarClassName="custom-calendar"
                value={selectedDayRange}
                onChange={(value) => {
                  setSelectedDayRange(value)
                  setSelectedPredefinedRange('custom')
                }}
                shouldHighlightWeekends
                maximumDate={utils('en').getToday()}
                colorPrimary={fitgmrMainColor}
                colorPrimaryLight={pillarColors.sleep}
                renderInput={({ ref }: any) => (
                  <Button variant="outline" size="md" ref={ref}>
                    {selectedDayRange.from
                      ? `${selectedDayRange.from.day}/${selectedDayRange.from.month}/${selectedDayRange.from.year}`
                      : '∞'}

                    {' - '}
                    {selectedDayRange.to
                      ? `${selectedDayRange.to.day}/${selectedDayRange.to.month}/${selectedDayRange.to.year}`
                      : '∞'}
                  </Button>
                )}
              />
            </FormControl>
            <FormControl id="enable-filter" width="150px">
              <FormLabel margin="0" fontSize="14px">
                Status
              </FormLabel>
              <CustomSelect
                name="enable-filter"
                defaultValue={status}
                onSelect={(val: any) => setStatus(val)}
                options={statusOptions}
              />
            </FormControl>
            <FormControl id="role-filter" width="150px">
              <FormLabel margin="0" fontSize="14px">
                Role
              </FormLabel>
              <CustomSelect
                name="role-filter"
                defaultValue={role}
                onSelect={(val: any) => setRole(val)}
                options={roleOptions}
              />
            </FormControl>
            <FormControl id="search-filter" width="200px">
              <FormLabel margin="0" fontSize="14px">
                User
              </FormLabel>
              <Input
                placeholder=""
                value={userEmail}
                icon
                onChange={(val) => setUserEmail(val.target.value)}
              />
            </FormControl>
            <FormControl id="search-filter" width="100px">
              <FormLabel margin="0" fontSize="14px">
                Coupon
              </FormLabel>
              <Input
                placeholder=""
                value={coupon}
                icon
                onChange={(val) => setCoupon(val.target.value)}
              />
            </FormControl>
            <Button
              position="absolute"
              right="30px"
              bgColor={fitgmrMainColor}
              rightIcon={<AddIcon />}
              onClick={() => {
                setAddPaymentModalOpen(true)
              }}
            >
              Add
            </Button>
          </HStack>
        </GridItem>
        <GridItem
          colSpan={6}
          bgColor="#1C1C1D"
          marginTop={3}
          marginRight={3}
          marginBottom={3}
          marginLeft={3}
          padding={5}
          borderRadius={8}
          maxHeight="300px"
          overflow="auto"
        >
          <PaymentStats
            where={where}
            totalCount={totalCount}
            onSelectCoupon={(selectedCoupon) =>
              setCoupon(coupon === selectedCoupon ? '' : selectedCoupon)
            }
          />
        </GridItem>
        <GridItem
          colSpan={6}
          bgColor="#1C1C1D"
          marginTop={3}
          marginRight={3}
          marginBottom={3}
          marginLeft={3}
          padding={5}
          borderRadius={8}
        >
          <Table
            className="payments-table"
            showId={false}
            selectable={false}
            headers={headers}
            items={paymentsData}
            withPagination
            pageCount={Math.ceil(totalCount / rowsPerPage)}
            page={page}
            setPage={setPage}
            canNextPage={page < Math.ceil(totalCount / rowsPerPage)}
            canPreviousPage={page > 1}
            rowsPerPage={rowsPerPage}
            setPageSize={setRowsPerPage}
            totalRows={100}
            isLoading={loading}
          />
        </GridItem>
      </Grid>
    </>
  )
}

export default Payments
