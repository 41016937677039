import moment from 'moment'

export function getCurrentDateFormat(): string {
  return 'YYYY-MM-DD'
}

export function getCurrentMoment(): moment.Moment {
  const currentDate = moment()
  // we should switch days at 4am
  return currentDate.subtract(4, 'hours')
}

export function getCurrentDate(): string {
  const currentDate = getCurrentMoment()
  return currentDate.format(getCurrentDateFormat())
}
