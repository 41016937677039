import React, { useEffect, useRef, useState } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import { split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { useDispatch, useSelector } from 'react-redux'
import { setContext } from '@apollo/client/link/context'
import jwtDecode from 'jwt-decode'

// Pages
import Login from './pages/Login/Login'
import Signup from './pages/Signup/Signup'
import StoreRedirect from './pages/StoreRedirect/StoreRedirect'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import Index from './pages/Index/Index'
import Coupon from './pages/Coupon/Coupon'
import Pricing from './pages/Pricing/Pricing'
import ScoreSimulator from './pages/ScoreSimulator/ScoreSimulator'

import { PrivateRouteProps } from './types'
import {
  selectIsAuthenticated,
  selectTokens,
} from './components/auth/store/auth.selectors'
import {
  setLogoutSuccess,
  setRedirectUrl,
  setTokenRefreshRequest,
} from './components/auth/store/auth.actions'

import client, { defaultLink, defaultSocketLink } from './gql/client'
import { AuthPayload } from './components/auth/store/auth.types'
import { AnalyticEvents, Analytics } from './analytics/AnalyticsService'
import useLoginWithDiscord from './hooks/useLoginWithDiscord'

const MAX_REFRESH_TOKEN_ATTEMPTS = 3

const ProtectedRoute: React.FC<PrivateRouteProps> = ({
  children,
  isAuthenticated,
  ...rest
}) => {
  const dispatch = useDispatch()
  const { search, pathname } = useLocation()

  const handleSetRedirect = () => {
    const url = pathname + search
    dispatch(setRedirectUrl({ redirectTo: url }))
  }

  useEffect(() => {
    if (!isAuthenticated) handleSetRedirect()
  }, [])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const Routes: React.FC = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const tokens: AuthPayload = useSelector(selectTokens)
  const location = useLocation()
  const refreshTokenAttemptsRef = useRef<number>(0)
  const authRefreshingRef = useRef<boolean>(false)
  const [apolloOAuth, setApolloOAuth] = useState<AuthPayload | null>(tokens)
  const { loginWithDiscord } = useLoginWithDiscord()

  const tokenExpiredCallback = () => {
    console.log(`[App] => tokenExpiredCallback called`)
    if (refreshTokenAttemptsRef.current < MAX_REFRESH_TOKEN_ATTEMPTS) {
      console.log(
        `[App] => tokenExpiredCallback try to refreshToken. Attempt: ${
          refreshTokenAttemptsRef.current + 1
        }`
      )
      refreshTokenAttemptsRef.current += 1
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setClientAuthorization(tokens, true)
    } else {
      console.log(
        `[App] => tokenExpiredCallback exceeded max attempts of tries`
      )
    }
  }

  const setClientAuthorization = (
    userOAuth?: AuthPayload,
    forceRefresh = false
  ) => {
    let isTokenValid: boolean = !!userOAuth
    if (userOAuth?.isAuthenticated) {
      const decodedToken: { exp: number } = jwtDecode(userOAuth.accessToken!)
      const thirtyMinsInMilliseconds = 4 * 60 * 1000
      if (
        (forceRefresh ||
          decodedToken.exp * 1000 < Date.now() + thirtyMinsInMilliseconds) &&
        userOAuth?.refreshToken
      ) {
        isTokenValid = false
        authRefreshingRef.current = true
        dispatch(
          setTokenRefreshRequest({
            accessToken: tokens.accessToken!,
            expiresIn: tokens.expiresIn!,
            refreshToken: tokens.refreshToken!,
          })
        )
      }
    }

    if (isTokenValid) {
      console.log('[APOLLO_CLIENT] Setting Authorization Header')
      const authLink = setContext((_, { headers }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${userOAuth?.accessToken}`,
        },
      }))

      const link = split(
        ({ query }) => {
          const { kind, operation }: any = getMainDefinition(query)
          return kind === 'OperationDefinition' && operation === 'subscription'
        },
        authLink.concat(
          defaultSocketLink(tokenExpiredCallback, userOAuth?.accessToken)
        ),
        authLink.concat(defaultLink(tokenExpiredCallback))
      )
      client.setLink(link)
      setApolloOAuth(tokens)
      refreshTokenAttemptsRef.current = 0
    } else if (!authRefreshingRef.current) {
      console.log('[APOLLO_CLIENT] No Valid Authorization Header Provided')
      setApolloOAuth(null)
      client.setLink(defaultLink())
      client.clearStore()
      dispatch(setLogoutSuccess())
    }
  }

  useEffect(() => {
    const code = location.search.split('code=')[1]
    if (code) {
      console.log(`[App] => code found in params: ${code}`)
      console.log(`[App] => loginWithDiscord function hook called`)
      loginWithDiscord(code)
    }
  }, [])

  useEffect(() => {
    setClientAuthorization(tokens)

    Analytics.track(AnalyticEvents.USER_NAVIGATE_TO + location.pathname, {
      url: location.pathname,
    })
  }, [location.pathname])

  useEffect(() => {
    authRefreshingRef.current = false
    if (apolloOAuth?.accessToken !== tokens.accessToken) {
      setClientAuthorization(tokens)
    }
  }, [tokens])

  useEffect(() => {
    setClientAuthorization(tokens)
  }, [])

  return (
    <AnimatePresence>
      <Switch location={location} key={location.pathname}>
        <Route path="/activate/coupon" component={StoreRedirect} />
        <Redirect to="/auth/login" path="/" exact />
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/signup" component={Signup} />
        <Route path="/auth/forgot-password" component={ForgotPassword} />
        <Route path="/coupon" component={Coupon} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/simulator" component={ScoreSimulator} />
        <ProtectedRoute path="/home" isAuthenticated={isAuthenticated}>
          <Index />
        </ProtectedRoute>
      </Switch>
    </AnimatePresence>
  )
}

export default Routes
