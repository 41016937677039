import { PayloadedAction } from '../../../types'

import {
  CONTENT,
  VideoCrudInterface,
  VideoInterface,
  VideoListRequest,
  VideoQuestionCrudInterface,
  VideoResponseErrorInterface,
  VideoSeriesAndInstructorResponseInterface,
  VideoSeriesInterface,
} from './content.types'

export const setActionInProgress = (isLoading: boolean): PayloadedAction => ({
  type: CONTENT.LOADING,
  payload: {
    isLoading,
  },
})

export const setUploadInProgress = (isUploading: boolean): PayloadedAction => ({
  type: CONTENT.LOADING,
  payload: {
    isUploading,
  },
})

export const setActionListSuccess = (
  type: CONTENT,
  payload: VideoInterface[] | VideoSeriesAndInstructorResponseInterface
): PayloadedAction => ({
  type,
  payload,
})

export const setActionListRequest = (
  type: CONTENT,
  payload: VideoListRequest
): PayloadedAction => ({
  type,
  payload,
})

export const setActionClearState = (type: CONTENT): PayloadedAction => ({
  type,
})

export const setActionRequest = (
  type: CONTENT,
  payload: VideoCrudInterface | VideoQuestionCrudInterface
): PayloadedAction => ({
  type,
  payload,
})

export const setActionSuccess = (
  type: CONTENT,
  payload: VideoInterface | VideoSeriesInterface | any | null
): PayloadedAction => ({
  type,
  payload,
})

export const setActionFailure = (
  type: CONTENT,
  payload: VideoResponseErrorInterface
): PayloadedAction => ({
  type,
  payload: {
    error: payload,
  },
})
