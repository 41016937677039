import React, { useState } from 'react'
import Feedback from './Feedback'

const useFeedback = () => {
  const [title, setTitle] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [returnUrl, setReturnUrl] = useState<string>('')

  const FeedbackComponent = (
    <Feedback
      title={title}
      message={message}
      isOpen={isOpen}
      returnUrl={returnUrl}
      onClose={() => {
        setIsOpen(false)
      }}
    />
  )

  return {
    title,
    message,
    isOpen,
    returnUrl,
    setTitle,
    setMessage,
    setIsOpen,
    setReturnUrl,
    renderedComponent: FeedbackComponent,
  }
}

export default useFeedback
