import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Center, Image, VStack, Container } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import logo from '../../assets/images/logo.svg'

import LoginForm from '../../components/auth/ui/LoginForm'

import Page from '../../components/shared/ui/page/Page'
import { useGetUserOnLogin } from '../../hooks/useGetUserOnLogin'
import {
  selectIsAuthenticated,
  selectUser,
} from '../../components/auth/store/auth.selectors'
import {
  RoleEnum,
  UserInterface,
} from '../../components/users/store/users.types'

const Login: React.FC = () => {
  const { getUser } = useGetUserOnLogin({})
  const route = useHistory()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const user: UserInterface | undefined = useSelector(selectUser)

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.role === RoleEnum.ADMIN || user?.role === RoleEnum.DIRECTOR)
        route.push('/home')
      else getUser()
    }
  }, [isAuthenticated, user?.id])

  return (
    <Page>
      <Center h="100vh" color="Gray" bg="#171717">
        <VStack>
          <Container centerContent pb={8}>
            <Image
              htmlWidth="300px"
              objectFit="contain"
              alt="FitGMR Logo"
              src={logo}
            />
          </Container>
          <LoginForm />
        </VStack>
      </Center>
    </Page>
  )
}

export default Login
