import { all, call, put, takeLatest } from 'redux-saga/effects'
import { PayloadedAction } from '../../../types'
import WeeklyChallengeService from './challenge.services'
import {
  WEEKLY_CHALLENGE,
  WeeklyChallengeCreateRequest,
  WeeklyChallengeListRequest,
  WeeklyChallengeUpdateRequest,
  WeeklyChallengeDeleteRequest,
  SortOrder,
} from '../store/challenge.types'

import {
  setWeeklyChallengeActionInProgress,
  setWeeklyChallengeSuccess,
  setWeeklyChallengeFailure,
  setWeeklyChallengeListRetrieveSuccess,
  setWeeklyChallengeListRetrieveFailure,
  setWeeklyChallengeDeleteFailure,
  setWeeklyChallengeUpdateFailure,
  setWeeklyChallengeListRetrieveRequest,
} from '../store/challenge.actions'

function* weeklyChallenge(
  action: PayloadedAction<WeeklyChallengeCreateRequest>
): any {
  yield put(setWeeklyChallengeActionInProgress(true))
  try {
    const response = yield call<any>(
      WeeklyChallengeService.WEEKLY_CHALLENGE_CREATE_SERVICE,
      action.payload
    )
    const data = response.data.createWeeklyChallenge
    yield put(setWeeklyChallengeSuccess(data))
  } catch (err) {
    let error =
      "We're having some trouble creating that challenge. Please try again later."
    if (err.message.includes('Unique constraint failed')) {
      error =
        'You may be trying to create a challenge that already exists for that week'
    }
    yield put(setWeeklyChallengeFailure({ message: error }))
  } finally {
    yield put(setWeeklyChallengeActionInProgress(false))
  }
}

function* weeklyChallengeList(
  action: PayloadedAction<WeeklyChallengeListRequest>
): any {
  yield put(setWeeklyChallengeActionInProgress(true))
  try {
    const response = yield call<any>(
      WeeklyChallengeService.WEEKLY_CHALLENGE_LIST_SERVICE,
      action.payload
    )
    const data = response.data.weeklyChallenges
    yield put(setWeeklyChallengeListRetrieveSuccess(data))
  } catch (err) {
    const error =
      "We're having some trouble getting all the challenges. Please try again later."
    yield put(setWeeklyChallengeListRetrieveFailure({ message: error }))
  } finally {
    yield put(setWeeklyChallengeActionInProgress(false))
  }
}

function* weeklyChallengeUpdate(
  action: PayloadedAction<WeeklyChallengeUpdateRequest>
): any {
  yield put(setWeeklyChallengeActionInProgress(true))
  try {
    yield call<any>(
      WeeklyChallengeService.WEEKLY_CHALLENGE_UPDATE_SERVICE,
      action.payload
    )
    const data: WeeklyChallengeListRequest = {
      orderBy: {
        year: SortOrder.asc,
      },
    }
    yield put(setWeeklyChallengeListRetrieveRequest(data))
  } catch (err) {
    const error =
      "We're having some trouble updating that challenge. Please try again later."
    yield put(setWeeklyChallengeUpdateFailure({ message: error }))
  } finally {
    yield put(setWeeklyChallengeActionInProgress(false))
  }
}

function* weeklyChallengeDelete(
  action: PayloadedAction<WeeklyChallengeDeleteRequest>
): any {
  yield put(setWeeklyChallengeActionInProgress(true))
  try {
    yield call<any>(
      WeeklyChallengeService.WEEKLY_CHALLENGE_DELETE_SERVICE,
      action.payload
    )
    const data: WeeklyChallengeListRequest = {
      orderBy: {
        year: SortOrder.asc,
      },
    }
    yield put(setWeeklyChallengeListRetrieveRequest(data))
  } catch (err) {
    const error =
      "We're having some trouble deleting that challenge. Please try again later."
    yield put(setWeeklyChallengeDeleteFailure({ message: error }))
    yield put(setWeeklyChallengeActionInProgress(false))
  }
}

function* weeklyChallengeSaga() {
  yield takeLatest(WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_REQUEST, weeklyChallenge)
  yield takeLatest(
    WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_LIST_REQUEST,
    weeklyChallengeList
  )
  yield takeLatest(
    WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_UPDATE_REQUEST,
    weeklyChallengeUpdate
  )
  yield takeLatest(
    WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_DELETE_REQUEST,
    weeklyChallengeDelete
  )
}

export default function* challengeSaga() {
  yield all([weeklyChallengeSaga()])
}
