import { ApolloError, useMutation } from '@apollo/client'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AUTH_MUTATIONS,
  discordLoginMethod,
} from '../components/auth/mutations/auth.mutations'
import {
  setAuthActionInProgress,
  setLoginFailure,
  setLoginSuccess,
} from '../components/auth/store/auth.actions'

import { config } from '../config'

export enum DISCORD_ERRORS {
  CANCELLED = 'cancelled',
}

const useLoginWithDiscord = () => {
  const [error, setError] = useState<ApolloError | any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const user = useSelector((state: any) => state.auth.user)
  const authLoading = useSelector((state: any): boolean => state.auth.isLoading)
  const dispatch = useDispatch()
  const [loginWithDiscordCode, { loading: dcLoading }] = useMutation(
    AUTH_MUTATIONS.LOGIN_DISCORD,
    {
      onCompleted(userData) {
        const authResponse = userData[discordLoginMethod]
        localStorage.setItem('accessToken', authResponse.accessToken)
        localStorage.setItem('refreshToken', authResponse.refreshToken)
        localStorage.setItem('expiresIn', authResponse.expiresIn)
        authResponse.isAuthenticated = true

        dispatch(setLoginSuccess(authResponse))
        dispatch(setAuthActionInProgress(false))
      },
      onError(err) {
        console.log(`[loginWithDiscordCode] => error: ${err} `)
        const error = {
          message: err.message,
        }
        dispatch(setLoginFailure(error))
        dispatch(setAuthActionInProgress(false))
        setError(err)
      },
    }
  )

  const generateRandomString = () => {
    const rand = Math.floor(Math.random() * 10)
    let randStr = ''
    for (let i = 0; i < 20 + rand; i++) {
      randStr += String.fromCharCode(33 + Math.floor(Math.random() * 94))
    }
    return randStr
  }

  const loginWithDiscord = (code?: string) => {
    setError(undefined)
    dispatch(setAuthActionInProgress(true))
    const stateIdentifier = btoa(generateRandomString())
    const discordUrl = `${config.discordOauthUrl}&state=${stateIdentifier}`

    if (code) {
      loginWithDiscordCode({
        variables: {
          code,
          redirect_uri: config.discordRedirectUrl,
        },
      })
    } else {
      window.location.href = discordUrl
    }
  }

  useEffect(() => {
    setIsLoading(dcLoading || authLoading)
  }, [dcLoading, authLoading])

  return { loginWithDiscord, user, isLoading, error }
}

export default useLoginWithDiscord
