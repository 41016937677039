import React, { useCallback, useState, useEffect, useMemo } from 'react'
import {
  Container,
  Heading,
  Divider,
  Text,
  Progress,
  Button,
  Box,
  Spacer,
  HStack,
  useToast,
  AspectRatio,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
} from '@chakra-ui/react'

import { useDropzone } from 'react-dropzone'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import axios from 'axios'

import useFeedback from '../../components/shared/ui/feedback/useFeedbackModal'
import Feedback from '../../components/shared/ui/feedback/Feedback'
import VideoQuestions from '../../components/content/ui/VideoQuestions'
import VideoCalendar from '../../components/content/ui/VideoCalendar'

import {
  selectError,
  selectS3Data,
  selectIsLoading,
  selectIsUploading,
  selectUploadedVideo,
  selectUploadedVideoId,
  selectUploadedVideoStatus,
} from '../../components/content/store/content.selectors'

import Step1 from './UploadVideoSteps/Step1'
import Step2 from './UploadVideoSteps/Step2'

import './UploadVideo.scss'

// Components
import {
  CONTENT,
  VideoCreateInputInterface,
  VideoCrudInterface,
  VideoCreateRequestInterface,
  VideoUpdateInputInterface,
  mapVideoInterfaceToUpdateInterface,
} from '../../components/content/store/content.types'
import VideoCreateForm from '../../components/content/ui/VideoCreateForm'

// Actions
import {
  setActionRequest,
  setActionFailure,
} from '../../components/content/store/content.actions'

const UploadVideo: React.FC = () => {
  const dispatch = useDispatch()
  const toast = useToast()

  const history = useHistory()

  const s3Data = useSelector(selectS3Data)
  const error = useSelector(selectError)
  const loading = useSelector(selectIsLoading)
  const uploading = useSelector(selectIsUploading)
  const uploadedVideo = useSelector(selectUploadedVideo)
  const uploadedVideoId = useSelector(selectUploadedVideoId)
  const uploadedVideoStatus = useSelector(selectUploadedVideoStatus)

  const [pageIndex, setPageIndex] = useState<number>(1)
  const [uploadData, setUploadData] = useState<VideoCreateRequestInterface>({
    file: null,
  })

  const {
    title,
    setTitle,
    message,
    setMessage,
    isOpen,
    setIsOpen,
    returnUrl,
    setReturnUrl,
  } = useFeedback()

  const onDrop = useCallback((files) => {
    setUploadData({
      file: files[0],
    })
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: 'video/mp4, video/mov, video/ts',
  })

  useEffect(() => {
    dispatch(setActionRequest(CONTENT.INSTRUCTOR_SERIES_LIST_REQUEST, {}))
  }, [])

  useEffect(() => {
    if (uploadedVideoId) {
      setPageIndex(2)
    }
  }, [uploadedVideoId])

  useEffect((): any => {
    if (uploadedVideoStatus && !uploading) {
      toast({
        title: 'Great news!',
        description:
          'Your video was uploaded successfully and is being processed.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [uploadedVideoStatus, uploading])

  useEffect(() => {
    setReturnUrl('')
    if (error && !error.includes('There is already a series with that name')) {
      setTitle('Oops!')
      setMessage(error)
      setIsOpen(true)
    } else {
      setTitle('')
      setMessage('')
      setIsOpen(false)
    }
  }, [error])

  const handleClose = () => {
    if (error.includes('uploading the video')) {
      setPageIndex(2)
    }
    dispatch(setActionFailure(CONTENT.CONTENT_CREATE_FAILED, { message: '' }))
  }

  const handleCreateVideo = (data: VideoCreateInputInterface) => {
    const payload: VideoCrudInterface = {
      data,
    }
    dispatch(setActionRequest(CONTENT.CONTENT_CREATE_REQUEST, payload))
    dispatch(setActionRequest(CONTENT.CONTENT_UPLOAD_CLEAR_STATE, {}))
  }

  const handleSubmit = (data: any) => {
    const payload = { ...data }

    if (!data?.VideoQuestion?.isCreateOrDeleteQuestion) {
      payload.instructorShouldDisconnect = Boolean(
        uploadedVideo?.VideoInstructor?.id && !data.instructor
      )

      payload.seriesShouldDisconnect = Boolean(
        uploadedVideo?.VideoSeries?.id && !data.series
      )
    }

    delete payload.VideoQuestion?.isCreateOrDeleteQuestion

    const videoData: VideoUpdateInputInterface =
      mapVideoInterfaceToUpdateInterface(data)
    const requestData = {
      data: videoData,
      where: {
        id: uploadedVideoId,
      },
    }

    dispatch(setActionRequest(CONTENT.CONTENT_UPDATE_REQUEST, requestData))
  }

  const handleUploadVideo = () => {
    const payload: VideoCrudInterface = {
      ...uploadData,
      fileExtension: uploadData.file.path.split('.').pop(),
      where: {
        id: uploadedVideoId,
      },
    }

    dispatch(setActionRequest(CONTENT.S3LINK_GET_S3_LINK_REQUEST, payload))

    setPageIndex(3)
  }

  useEffect(() => {
    if (uploadData.file && s3Data) {
      const awsData = JSON.parse(s3Data.fields)
      const formData = new FormData()
      Object.keys(awsData).forEach((key) => {
        formData.append(key, awsData[key])
      })

      const payload: VideoCrudInterface = {
        data: {
          filename: {
            set: awsData.key,
          },
        },
        where: {
          id: uploadedVideoId,
        },
      }

      formData.append('file', uploadData.file)

      axios.post(s3Data.url, formData).then(() => {
        dispatch(setActionRequest(CONTENT.CONTENT_UPDATE_REQUEST, payload))
      })
    }
  }, [s3Data, uploadData])

  const url = useMemo(() => {
    if (uploadData.file) {
      return URL.createObjectURL(uploadData.file)
    }
    return ''
  }, [uploadData.file])

  return (
    <>
      {pageIndex !== 3 ? (
        <Container width="100%" maxW="85%">
          <Container width="100%" m="1rem auto 0rem 0">
            <Heading mb={2} as="h1" size="lg" color="white">
              Upload A Video
            </Heading>
          </Container>
          <Box width="100%" marginBottom="4rem">
            <Divider />
          </Box>

          {pageIndex === 1 ? (
            <Step1 onSubmit={handleCreateVideo} isSubmitting={loading} />
          ) : pageIndex === 2 ? (
            <Step2
              uploadedVideoId={uploadedVideoId}
              isDragActive={isDragActive}
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
              handleUploadVideo={handleUploadVideo}
              uploadData={uploadData}
              loading={loading}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
            />
          ) : null}
        </Container>
      ) : (
        <>
          {uploading ? (
            <Progress
              colorScheme="brand"
              marginBottom={20}
              size="sm"
              isIndeterminate
            />
          ) : (
            <Box height={28} />
          )}
          <Container width="100%" maxW="85%">
            {uploading ? (
              <Box>
                <Text>
                  Please wait while we upload your video. This may take some
                  time. While you wait, you can add the video details below.
                </Text>
              </Box>
            ) : (
              <Box width="100%" m="0 0 4rem 0">
                <Flex>
                  <HStack>
                    <Heading mb={2} as="h1" size="lg" color="white">
                      {uploadedVideo?.title}
                    </Heading>
                  </HStack>
                  <Spacer />
                  <Button
                    color="white"
                    colorScheme="fitgmrPurple"
                    onClick={() => history.push('/home/videos/')}
                  >
                    Done
                  </Button>
                </Flex>
                <Divider />
              </Box>
            )}
            <HStack
              width="full"
              alignItems="flex-start"
              justifyContent="space-between"
              marginTop={10}
            >
              <Box width="45%">
                <AspectRatio width="100%" ratio={16 / 9}>
                  <video controls src={url}>
                    <track
                      label="English"
                      kind="captions"
                      srcLang="en"
                      default
                    />
                  </video>
                </AspectRatio>
              </Box>
              <Spacer />
              <Box width="50%">
                <Tabs align="end" variant="unstyled">
                  <TabList>
                    <Tab _selected={{ color: 'white', bg: 'fitgmrBlue.400' }}>
                      Details
                    </Tab>
                    <Tab _selected={{ color: 'white', bg: 'fitgmrRed.400' }}>
                      Questions
                    </Tab>
                    <Tab _selected={{ color: 'white', bg: 'fitgmrOrange.400' }}>
                      Schedule
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <VideoCreateForm
                        onSubmit={handleSubmit}
                        isSubmitting={loading}
                      />
                    </TabPanel>
                    <TabPanel>
                      {uploadedVideo && (
                        <VideoQuestions
                          videoId={uploadedVideoId}
                          onSubmit={handleSubmit}
                          questions={uploadedVideo?.VideoQuestion || []}
                        />
                      )}
                    </TabPanel>
                    <TabPanel>
                      <VideoCalendar
                        onSubmit={handleSubmit}
                        selectedDay={uploadedVideo?.availableFrom || ''}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </HStack>
          </Container>
        </>
      )}
      <Feedback
        title={title}
        message={message}
        isOpen={isOpen}
        returnUrl={returnUrl}
        onClose={handleClose}
      />
    </>
  )
}

export default UploadVideo
