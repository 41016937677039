import React, { useMemo, useState } from 'react'
import { Grid, GridItem, TableContainer } from '@chakra-ui/react'
import { DayRange, utils } from 'react-modern-calendar-datepicker'
import { useQuery } from '@apollo/client'
import { addDays, format, isBefore, isSameDay } from 'date-fns'

import useDebounceState from '../../hooks/useDebounceState'
import SearchAndFilter from './SearchAndFilter'
import { Table } from '../../components/shared/ui/table'
import { PAYMENT_QUERIES } from '../../components/payments/queries/payments.queries'
import exportData from '../../components/coaches/ui/exportData/exportData'

function dateToObj(date: Date) {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  }
}

type DatePoint = {
  date: string
  value: number
}

type TeamsByCouponType = {
  points: DatePoint[] | []
  teamId: string
  teamName: string
}
interface FilterTypes {
  subscription: string[]
  role: string[]
}

interface TeamDataTypes {
  id: string
  teamName: string
  [date: string]: string
}

const TeamsPerformance = () => {
  // last week
  const lastWeek = new Date()
  lastWeek.setDate(new Date().getDate() - 7)

  const [selectedPredefinedRange, setSelectedPredefinedRange] = useState<
    string | undefined
  >('last-week')
  const [selectedDayRange, setSelectedDayRange] = useState<DayRange>({
    from: dateToObj(lastWeek),
    to: utils('en').getToday(),
  })
  const [selectedGame, setSelectedGame] = useState<string>('')
  const [coupon, setCoupon] = useDebounceState('', 600)

  const [filters, setFilters] = useState<FilterTypes>({
    subscription: [],
    role: [],
  })
  const [selected, setSelected] = useState<string[]>([])
  const [search, setSearch] = useDebounceState('', 1500)

  const { loading, data } = useQuery(PAYMENT_QUERIES.GET_TEAMS_BY_COUPON, {
    variables: {
      couponId: coupon,
      game: selectedGame,
      startDate: selectedDayRange.from
        ? format(
            new Date(
              selectedDayRange.from.year,
              selectedDayRange.from.month - 1,
              selectedDayRange.from.day
            ),
            'yyyy-MM-dd'
          )
        : undefined,
      endDate: selectedDayRange.to
        ? format(
            new Date(
              selectedDayRange.to.year,
              selectedDayRange.to.month - 1,
              selectedDayRange.to.day
            ),
            'yyyy-MM-dd'
          )
        : undefined,
    },
    skip: !coupon,
  })

  const handleClearFilters = () =>
    setFilters({
      subscription: [],
      role: [],
    })

  const handleDeleteFilters = (e: any) => {
    const currentFilters: any = { ...filters }
    const filtersList: string[] = currentFilters[e.name]
    filtersList.splice(filtersList.indexOf(e.value), 1)
    currentFilters[e.name] = filtersList
    setFilters(currentFilters)
  }

  const headers = [
    {
      id: 'id',
      title: 'ID',
    },
    {
      id: 'teamName',
      title: 'Team Name',
      position: true,
    },
  ]

  const startDate = selectedDayRange?.from
    ? new Date(
        selectedDayRange.from.year,
        selectedDayRange.from.month - 1,
        selectedDayRange.from.day
      )
    : new Date()

  const endDate = selectedDayRange?.to
    ? new Date(
        selectedDayRange.to.year,
        selectedDayRange.to.month - 1,
        selectedDayRange.to.day
      )
    : new Date()

  const teamTableData: TeamDataTypes[] | [] = useMemo(() => {
    // Initialize an empty array for the table data source
    const teamData: TeamDataTypes[] = []
    const uniqueDates = new Set()
    let currentDate = startDate

    while (isBefore(currentDate, endDate) || isSameDay(currentDate, endDate)) {
      const formattedDate = format(currentDate, 'yyyy-MM-dd')
      headers.push({ id: formattedDate, title: formattedDate })
      currentDate = addDays(currentDate, 1)
    }

    // Collect unique dates from the data
    data?.getTeamsFromCoupon.forEach((team: TeamsByCouponType) => {
      team?.points.forEach((point: DatePoint) => {
        uniqueDates.add(point.date)
      })
    })

    data?.getTeamsFromCoupon.forEach((team: TeamsByCouponType) => {
      const row: TeamDataTypes = {
        id: team.teamId,
        teamName: team.teamName,
      }
      uniqueDates.forEach((date) => {
        const point = team.points.find(
          (point: DatePoint) => point.date === date
        )
        if (point) {
          if (typeof date === 'string')
            row[date] = Number(point.value).toFixed(1)
        }
      })
      teamData.push(row)
    })
    return teamData
  }, [startDate, endDate, data?.getTeamsFromCoupon])

  const downloadTeamFGScoreInExcel = () => {
    exportData('xlsx', teamTableData, `${coupon}_teams_fg_score`)
  }

  return (
    <Grid templateColumns="repeat(5, 1fr)">
      <GridItem
        colSpan={6}
        bgColor="#1C1C1D"
        marginTop={3}
        marginRight={3}
        marginLeft={3}
        padding={5}
        borderRadius={8}
        maxHeight="150px"
      >
        <SearchAndFilter
          selectedFilters={[]}
          filters={filters}
          setFilters={setFilters}
          handleClearFilters={handleClearFilters}
          handleDeleteFilters={handleDeleteFilters}
          setSearch={setSearch}
          search={search}
          actions={[]}
          selectedPredefinedRange={selectedPredefinedRange}
          setSelectedPredefinedRange={setSelectedPredefinedRange}
          selectedDayRange={selectedDayRange}
          setSelectedDayRange={setSelectedDayRange}
          setSelectedGame={setSelectedGame}
          selectedGame={selectedGame}
          coupon={coupon}
          setCoupon={setCoupon}
          isDownloadDisable={!teamTableData?.length}
          downloadData={downloadTeamFGScoreInExcel}
        />
      </GridItem>
      <GridItem
        colSpan={6}
        bgColor="#1C1C1D"
        marginTop={3}
        marginRight={3}
        marginBottom={3}
        marginLeft={0}
        padding={5}
        borderRadius={8}
      >
        <TableContainer>
          <Table
            className="user-table"
            showId={false}
            selectable={false}
            selected={selected}
            handleSelect={setSelected}
            headers={headers}
            items={teamTableData}
            totalRows={teamTableData.length}
            isLoading={loading}
          />
        </TableContainer>
      </GridItem>
    </Grid>
  )
}
export default TeamsPerformance
