export enum ESubscriptionPackage {
  FITGMR_KIDS = 'FITGMR_KIDS',
  FITGMR_PRO = 'FITGMR_PRO',
}

export enum EAppSubscriptionReasonPackage {
  FITGMRFREE = 'FITGMRFREE', // User on Free subscription by choice
  FITGMRFREE_BYTEAM = 'FITGMRFREE_BYTEAM', // User on Free subscription for being part of a team with Basic plan
  FITGMRPRO = 'FITGMRPRO', // User on Pro Subscription by choice
  FITGMRPRO_BYTEAM = 'FITGMRPRO_BYTEAM', // User on Pro subscription for being part of a team with PRO plan
  FITGMRPRO_ADMIN = 'FITGMRPRO_ADMIN', // Admin user with Pro Subscription
  COACH_PERFORMANCE_PACKAGE = 'COACH_PERFORMANCE_PACKAGE', // Coach on Performance subscription
}
