import React from 'react'
import { AddIcon } from '@chakra-ui/icons'

import TeamStatisticsRouting from '../team-statistics/team-statistics.routing'
import UserStatisticsRouting from '../user-statistics/user-statistics.routing'
import TeamCreateRouting from '../create-team/create-team.routing'
import WaitForTeam from '../waiting-for-team/waiting-for-team.routing'

const DirectorPortal = React.lazy(
  () => import('../../../pages/DirectorPortal/DirectorPortal')
)
const CoachPortal = React.lazy(
  () => import('../../../pages/CoachPortal/CoachPortal')
)
const TeamStatisticsComponent = React.lazy(
  () => import('../team-statistics/team-statistics.component')
)

const ManageTeamComponent = React.lazy(
  () => import('../manage-team/manage-team.component')
)

const Journal = React.lazy(() => import('../journal/journal.component'))

const TeamInvite = React.lazy(
  () => import('../team-invite/team-invite.component')
)

const WaitingForTeam = React.lazy(
  () => import('../waiting-for-team/waiting-for-team.component')
)

export default [
  {
    to: '/home/my-team/journal',
    component: Journal,
    exact: true,
    isAdmin: false,
    label: 'Journal',
    icon: <AddIcon />,
    level: 1,
    children: [],
  },
  {
    to: '/home/my-team/statistics',
    component: TeamStatisticsComponent,
    exact: true,
    isAdmin: false,
    label: 'Team Statistics',
    icon: <AddIcon />,
    level: 1,
    children: [],
  },
  {
    to: '/home/my-team',
    component: ManageTeamComponent,
    label: 'My Team',
    isAdmin: false,
    icon: <AddIcon />,
    exact: false,
    level: 1,
    children: [
      WaitForTeam,
      TeamCreateRouting,
      TeamStatisticsRouting,
      UserStatisticsRouting,
    ],
  },
  {
    to: '/home/manager-portal',
    component: DirectorPortal,
    label: 'My Team portal',
    isAdmin: false,
    icon: <AddIcon />,
    exact: false,
    level: 1,
    children: [
      WaitForTeam,
      TeamCreateRouting,
      TeamStatisticsRouting,
      UserStatisticsRouting,
    ],
  },
  {
    to: '/home/coach-portal',
    component: CoachPortal,
    label: 'My Team portal',
    isAdmin: false,
    icon: <AddIcon />,
    exact: false,
    level: 1,
    children: [
      WaitForTeam,
      TeamCreateRouting,
      TeamStatisticsRouting,
      UserStatisticsRouting,
    ],
  },
  {
    to: '/home/no-team/',
    component: WaitingForTeam,
    label: 'Waiting For Team',
    isAdmin: false,
    icon: <AddIcon />,
    exact: true,
    level: 1,
    children: [],
  },
  {
    to: '/home/team-invite',
    component: TeamInvite,
    label: 'Team Invite',
    isAdmin: false,
    icon: <AddIcon />,
    exact: true,
    level: 1,
    children: [],
  },
]
