import React from 'react'
import { AddIcon } from '@chakra-ui/icons'

const UserPerformance = React.lazy(
  () => import('../../../pages/UserPerformance/UserPerformance')
)

export default {
  to: '/home/user-performance',
  component: UserPerformance,
  label: 'User Performance',
  isAdmin: true,
  icon: <AddIcon />,
  exact: true,
  level: 1,
  children: [],
}
