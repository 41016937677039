import React, { useState, useEffect } from 'react'

import {
  VStack,
  Container,
  Button,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Link,
} from '@chakra-ui/react'
import { FaUser } from 'react-icons/fa'

import { Link as RouterLink } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import useFeedback from '../../shared/ui/feedback/useFeedbackModal'
import Feedback from '../../shared/ui/feedback/Feedback'

import { selectIsLoading, selectError } from '../store/auth.selectors'

import {
  setForgotPasswordRequest,
  setPasswordResetFailure,
} from '../store/auth.actions'

const ForgotPasswordForm: React.FC = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState<string>('')
  const isLoading = useSelector(selectIsLoading)
  const errorMessage = useSelector(selectError)

  const { title, setTitle, message, setMessage, isOpen, setIsOpen, returnUrl } =
    useFeedback()

  useEffect(() => {
    if (errorMessage) {
      setTitle('Password Reset Failed')
      setMessage(errorMessage)
      setIsOpen(true)
    } else {
      setTitle('')
      setMessage('')
      setIsOpen(false)
    }
  }, [errorMessage])

  const resetError = () => {
    setTitle('')
    setMessage('')
    setIsOpen(false)
  }

  const submit = () => {
    resetError()
    if (email) {
      dispatch(setForgotPasswordRequest({ email }))
    } else {
      setTitle('Password Reset Failed')
      setMessage('Please enter an email address.')
      setIsOpen(true)
    }
  }

  const handleClose = () => {
    dispatch(setPasswordResetFailure({ message: '' }))
    resetError()
  }

  return (
    <>
      <Box
        p={5}
        shadow="md"
        borderRadius="md"
        background="#0c0c0c"
        minW="260px"
        w="300px"
      >
        <VStack mb={8} mt={4} spacing={5}>
          <Container>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <FaUser />
              </InputLeftElement>
              <Input
                variant="flushed"
                placeholder="Email"
                onChange={(e) => {
                  resetError()
                  setEmail(e.target.value)
                }}
              />
            </InputGroup>
          </Container>
          <Container mb={2} centerContent>
            <Text fontSize="sm">
              If there is an account with the above email, we will send reset
              instructions.
            </Text>
          </Container>
        </VStack>
        <VStack>
          <Container mb={1} centerContent>
            <Button
              isLoading={isLoading}
              loadingText="Please wait"
              isFullWidth
              onClick={submit}
              color="white"
              colorScheme="brand"
            >
              Request
            </Button>
          </Container>
          <Container mb={6} centerContent>
            <Text fontSize="sm">
              Click to{' '}
              <Link
                as={RouterLink}
                fontWeight="bold"
                color="brand.50"
                to="/auth/login/"
              >
                Sign In
              </Link>
            </Text>
          </Container>
        </VStack>
      </Box>
      <Feedback
        title={title}
        message={message}
        isOpen={isOpen}
        returnUrl={returnUrl}
        onClose={handleClose}
      />
    </>
  )
}

export default ForgotPasswordForm
