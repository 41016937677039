import { useState, useEffect, useMemo, useCallback } from 'react'

/**
 * Receives functional props
 * @param {number} level defaults to 1
 * @returns state
 */

function useScreen(level = 1) {
  const [trans, setTrans] = useState(false)
  const [onExited, setOnExited] = useState(null)

  useEffect(() => {
    setTrans(true)
    return () => setTrans(false)
  }, [])

  const exit = useCallback(
    (cb) => {
      if (cb) setOnExited(() => cb)
      setTrans(false)
    },
    [setTrans, setOnExited]
  )

  const zIndex = useMemo(() => level * 10, [level])

  return { zIndex, trans, onExited, exit }
}
export default useScreen
