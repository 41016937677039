import React, { useEffect, useState } from 'react'

import {
  VStack,
  Container,
  Button,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Link,
  InputRightElement,
  IconButton,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react'
import { FaEye, FaEyeSlash, FaLock, FaUser } from 'react-icons/fa'

import { Link as RouterLink, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { config } from '../../../config'
// Types
import { UserCredentials } from '../store/auth.types'

import {
  setLoginFailure,
  setLoginRequest,
  setLogoutSuccess,
} from '../store/auth.actions'

import useFeedback from '../../shared/ui/feedback/useFeedbackModal'
import Feedback from '../../shared/ui/feedback/Feedback'

import {
  selectIsLoading,
  selectError,
  selectUser,
} from '../store/auth.selectors'
import PaymentPendingPrompt from '../../subscription/ui/PaymentPendingPrompt'
import GamerLoginPrompt from './GamerLoginPrompt'
import { RoleEnum } from '../../users/store/users.types'

const LoginForm: React.FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsLoading)
  const errorMessage = useSelector(selectError)
  const route = useHistory()
  const user = useSelector(selectUser)
  const [pendingPromptOpen, setPendingPromptOpen] = useState(false)
  const [gamerLoginPromptOpen, setGamerLoginPromptOpen] = useState(false)

  useEffect(() => {
    if (user && user.Team && user.role === RoleEnum.GAMER) {
      setGamerLoginPromptOpen(true)
      dispatch(setLogoutSuccess())
    } else if (user && user.Payments && user.role === RoleEnum.DIRECTOR) {
      const payments = user?.Payments.filter(
        (payment) => payment.status === 'active'
      )
      if (
        payments.length > 0 &&
        !payments.some((payment) => payment.productName?.includes('Dashboard'))
      ) {
        setPendingPromptOpen(true)
        dispatch(setLogoutSuccess())
      }
    }
  }, [user])

  const [show, setShow] = useState<boolean>(false)
  const [credentials, setCredentials] = useState<UserCredentials>({
    password: '',
    email: '',
  })

  const [passwordError, setPasswordError] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const { title, setTitle, message, setMessage, isOpen, setIsOpen, returnUrl } =
    useFeedback()

  useEffect(() => {
    if (errorMessage && errorMessage.includes('Incorrect password')) {
      setPasswordError(errorMessage)
    } else if (errorMessage && errorMessage.includes('Email not found')) {
      setEmailError(errorMessage)
    } else if (errorMessage) {
      setTitle('Oops!')
      setMessage(errorMessage)
      setIsOpen(true)
    } else {
      setTitle('')
      setMessage('')
      setEmailError('')
      setPasswordError('')
      setIsOpen(false)
    }
  }, [errorMessage])

  const handleClose = () => {
    dispatch(setLoginFailure({ message: '' }))
  }

  const submit = (e: any) => {
    e.preventDefault()
    dispatch(setLoginFailure({ message: '' }))
    dispatch(setLoginRequest(credentials))
  }

  const handleDiscordLogin = () => {
    window.location.href = config.discordOauthUrl || ''
  }

  return (
    <>
      <Box
        p={5}
        shadow="md"
        borderRadius="md"
        background="#0c0c0c"
        minW="260px"
        w="300px"
      >
        <form onSubmit={submit}>
          <VStack mb={8} mt={4} spacing={5}>
            <Container>
              <FormControl isInvalid={Boolean(emailError)}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <FaUser />
                  </InputLeftElement>
                  <Input
                    variant="flushed"
                    placeholder="Email"
                    onChange={(e) => {
                      setEmailError('')
                      setCredentials({ ...credentials, email: e.target.value })
                    }}
                  />
                </InputGroup>
                <FormErrorMessage>{emailError}</FormErrorMessage>
              </FormControl>
            </Container>
            <Container>
              <FormControl isInvalid={Boolean(passwordError)}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <FaLock />
                  </InputLeftElement>
                  <Input
                    variant="flushed"
                    placeholder="Password"
                    type={show ? 'text' : 'password'}
                    onChange={(e) => {
                      setPasswordError('')
                      setCredentials({
                        ...credentials,
                        password: e.target.value,
                      })
                    }}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label="show password"
                      onClick={() => setShow(!show)}
                      icon={!show ? <FaEye /> : <FaEyeSlash />}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{passwordError}</FormErrorMessage>
              </FormControl>
            </Container>
            <Container mb={2} centerContent>
              <Text fontSize="sm">
                Forgot your password?{' '}
                <Link
                  as={RouterLink}
                  fontWeight="bold"
                  color="brand.50"
                  to="/auth/forgot-password/"
                >
                  Click here
                </Link>
              </Text>
            </Container>
          </VStack>
          <VStack>
            <Container mb={4} centerContent>
              <Button
                isLoading={isLoading}
                loadingText="Please wait"
                isFullWidth
                type="submit"
                color="white"
                colorscheme="brand"
              >
                Login
              </Button>
            </Container>
          </VStack>
        </form>

        <VStack>
          <Container mb={4} centerContent>
            <Button
              isLoading={isLoading}
              loadingText="Please wait"
              isFullWidth
              onClick={handleDiscordLogin}
              color="white"
              bg="#5865F2"
              _hover={{ bg: '#5f6efa' }}
            >
              Login With Discord
            </Button>
          </Container>
        </VStack>
        <VStack>
          <Container mb={4} centerContent>
            <Button
              isLoading={isLoading}
              loadingText="Please wait"
              isFullWidth
              onClick={() => route.push('/auth/signup')}
              color="white"
              colorScheme="brand"
            >
              Sign Up
            </Button>
          </Container>
        </VStack>
      </Box>
      <Feedback
        title={title}
        message={message}
        isOpen={isOpen}
        returnUrl={returnUrl}
        onClose={handleClose}
      />
      <PaymentPendingPrompt
        isOpen={pendingPromptOpen}
        onSuccess={() => setPendingPromptOpen(false)}
      />
      <GamerLoginPrompt
        isOpen={gamerLoginPromptOpen}
        onSuccess={() => setGamerLoginPromptOpen(false)}
      />
    </>
  )
}

export default LoginForm
