import React from 'react'
import { Image } from '@chakra-ui/react'
import logo from '../../../../assets/images/small-logo.svg'
import './Sidebar.scss'
import { DashBoardSection } from './DashboardSection'
import { SideBarFooter } from './SideBarFooter'

type Props = {}

export const SideBar: React.FC<Props> = () => (
  <div className="sidebar">
    <div className="sidebar-logo">
      <Image htmlWidth="200px" objectFit="contain" alt="" src={logo} />
    </div>
    <DashBoardSection />
    <hr className="division" />
    <SideBarFooter />
  </div>
)
