import React, { useEffect, useMemo, useState } from 'react'

import {
  Center,
  Container,
  Text,
  VStack,
  Textarea,
  Button,
  Heading,
  Divider,
  Flex,
  Select,
  Spinner,
} from '@chakra-ui/react'

import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import Calendar from '../../components/weekly-challenge/ui/Calendar'
import Card from '../../components/weekly-challenge/ui/ChallengeCard'
import useFeedback from '../../components/shared/ui/feedback/useFeedbackModal'
import Feedback from '../../components/shared/ui/feedback/Feedback'

import {
  WeeklyChallenge,
  WeeklyChallengeCreateRequest,
  WeeklyChallengeListRequest,
  DateRange,
  SortOrder,
} from '../../components/weekly-challenge/store/challenge.types'
import './WeeklyChallenge.scss'

import {
  setWeeklyChallengeRequest,
  setWeeklyChallengeFailure,
  setWeeklyChallengeListRetrieveRequest,
} from '../../components/weekly-challenge/store/challenge.actions'

import {
  selectChallengeList,
  selectIsLoading,
  selectError,
} from '../../components/weekly-challenge/store/challenge.selectors'

const getWeekDays = (start: Date): Date[] => {
  const days = [start]
  for (let i = 1; i < 7; i++) {
    days.push(moment(start).add(i, 'days').toDate())
  }
  return days
}

const getWeekRange = (date: Date) => ({
  from: moment(date).startOf('week').toDate(),
  to: moment(date).endOf('week').toDate(),
})

const WeeklyChallengePage: React.FC = () => {
  const dispatch = useDispatch()

  const isLoading: boolean = useSelector(selectIsLoading)
  const challengeList: WeeklyChallenge[] = useSelector(selectChallengeList)
  const error: string = useSelector(selectError)

  const [selectedWeek, setSelectedWeek] = useState<number>(
    moment(new Date()).week()
  )
  const [selectedYear, setSelectedYear] = useState<number>(
    moment(new Date()).year()
  )
  const [challengeDescription, setChallengeDescription] = useState<string>('')

  const [hoverRange, setHoverRange] = useState<DateRange | undefined>(undefined)
  const [selectedDays, setSelectedDays] = useState<Date[]>([])

  const [selectedFilter, setSelectedFilter] = useState<string>('all')

  const { title, setTitle, message, setMessage, isOpen, setIsOpen } =
    useFeedback()

  useEffect(() => {
    if (error) {
      setTitle('Oops! Something is not right.')
      setMessage(error)
      setIsOpen(true)
    } else {
      setTitle('')
      setMessage('')
      setIsOpen(false)
    }
  }, [error])

  useEffect(() => {
    const data: WeeklyChallengeListRequest = {
      orderBy: {
        year: SortOrder.asc,
      },
    }
    dispatch(setWeeklyChallengeListRetrieveRequest(data))
  }, [])

  useEffect(() => {
    // refresh inputs from form
    setChallengeDescription('')
    setSelectedDays([])
  }, [challengeList.length])

  const resetError = () => {
    setTitle('')
    setMessage('')
    setIsOpen(false)
  }

  const submit = () => {
    const data: WeeklyChallengeCreateRequest = {
      year: selectedYear,
      week: selectedWeek,
      description: challengeDescription,
    }
    dispatch(setWeeklyChallengeRequest(data))
  }

  const handleClose = () => {
    dispatch(setWeeklyChallengeFailure({ message: '' }))
    resetError()
  }

  const handleDayChange = (date: Date) => {
    setSelectedDays(getWeekDays(getWeekRange(date).from))
    setSelectedYear(moment(date).year())
    // if the week clicked is the same as the already selected, we empty the selected days
    if (moment(date).week() === selectedWeek && selectedDays.length) {
      setSelectedDays([])
    } else {
      setSelectedWeek(moment(date).week())
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (_: any, days: any) => {
    const week = moment(days[1]).week()
    setSelectedDays(days)
    setSelectedYear(moment(days[1]).year())
    // if the week clicked is the same as the already selected, we empty the selected days
    if (week === selectedWeek && selectedDays.length) {
      setSelectedDays([])
    } else {
      setSelectedWeek(week)
    }
  }

  const handleDayEnter = (date: Date) => setHoverRange(getWeekRange(date))
  const handleDayLeave = () => setHoverRange(undefined)

  const hasSelectedDays = selectedDays.length > 0

  const range = useMemo(
    () =>
      challengeList.map((challenge) => {
        const challengeDateStart = moment()
          .year(challenge.year)
          .week(challenge.week)
          .day('Sunday')
          .subtract(1, 'day')
          .toDate()
        const challengeDateEnd = moment()
          .year(challenge.year)
          .week(challenge.week)
          .day('Saturday')
          .add(1, 'day')
          .toDate()
        return {
          after: challengeDateStart,
          before: challengeDateEnd,
        }
      }),
    [challengeList]
  )

  const modifiers = {
    range,
    hoverRange,
    selectedRange: hasSelectedDays && {
      from: selectedDays[0],
      to: selectedDays[6],
    },
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
    selectedRangeStart: hasSelectedDays && selectedDays[0],
    selectedRangeEnd: hasSelectedDays && selectedDays[6],
  }

  const challengeListSorted = useMemo(
    () =>
      challengeList
        .filter((i) => {
          const challengeDate = moment()
            .day('Monday')
            .year(i.year)
            .week(i.week)
            .toDate()
          if (selectedFilter === 'past') {
            return moment(challengeDate).isBefore(moment(new Date()))
          }
          if (selectedFilter === 'upcoming') {
            return moment(challengeDate).isSameOrAfter(moment(new Date()))
          }
          return true
        })
        .sort((a, b) => a.week - b.week)
        .sort((a, b) => a.year - b.year),
    [challengeList, selectedFilter]
  )

  const challengeListRefs = useMemo(
    () =>
      challengeListSorted.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: { [key: string]: React.RefObject<any> }, value) => {
          acc[value.id] = React.createRef()
          return acc
        },
        {}
      ),
    [challengeListSorted]
  )

  useEffect(() => {
    const challengeByWeek = challengeListSorted.find(
      (challenge) => challenge.week === selectedWeek
    )
    if (challengeByWeek) {
      if (challengeListRefs[challengeByWeek.id]?.current) {
        challengeListRefs[challengeByWeek.id].current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [selectedWeek, challengeListRefs, challengeListSorted])

  const challengeListContent = challengeListSorted.map((i) => (
    <Card
      ref={challengeListRefs[i.id]}
      key={i.id}
      {...i}
      active={selectedWeek === i.week}
    />
  ))

  return (
    <>
      <Center width="100%" color="Gray" bg="#171717">
        <VStack h="100%" w="80%">
          <Container width="100%" m="1rem auto 4rem 0">
            <Heading mb={2} as="h1" size="lg" color="white">
              Weekly Challenge
            </Heading>
            <Divider />
          </Container>
          <Flex
            width="100%"
            mt={4}
            alignItems="start"
            justifyContent="center"
            wrap="wrap"
          >
            <Flex height="100%" flex="1">
              <VStack
                border="1px"
                borderRadius="1px"
                bgColor="#363636"
                width="100%"
                alignItems="center"
                padding="0 5rem"
              >
                <Center mb={10}>
                  <Calendar
                    selectedWeek={selectedWeek}
                    onWeekClick={handleChange}
                    selectedDays={selectedDays}
                    modifiers={modifiers}
                    onDayClick={handleDayChange}
                    onDayMouseEnter={handleDayEnter}
                    onDayMouseLeave={handleDayLeave}
                  />
                </Center>
                <Text mb={4} color="white" as="p">
                  Please select a week then enter the challenge details:
                </Text>
                <Textarea
                  mb={4}
                  width="100%"
                  value={challengeDescription}
                  onChange={(e) => {
                    setChallengeDescription(e.target.value)
                  }}
                />
                <Container
                  centerContent
                  width="100%"
                  pt={4}
                  pb={10}
                  maxW="100%"
                >
                  <Button
                    isLoading={isLoading}
                    disabled={!challengeDescription || !selectedDays.length}
                    loadingText="Please wait"
                    onClick={submit}
                    width="150px"
                    color="black"
                    bg="white"
                    _hover={{
                      bg: '#ccc',
                    }}
                  >
                    Post
                  </Button>
                </Container>
              </VStack>
            </Flex>
            <Flex height="100%" flex="1" justifyContent="center">
              <VStack
                minW="300px"
                padding="0 1rem"
                alignItems="center"
                height="661px"
              >
                <Container>
                  <Select
                    marginBottom={4}
                    data-testid="dropdown-select"
                    onChange={(e) => setSelectedFilter(e.target.value)}
                  >
                    <option value="all">Show All</option>
                    <option value="past">Show Past</option>
                    <option value="upcoming">Show Upcoming</option>
                  </Select>
                </Container>
                {isLoading ? (
                  <Spinner
                    emptyColor="#4a4a4a"
                    color="blue.500"
                    size="xl"
                    speed="0.7s"
                    thickness="8px"
                  />
                ) : (
                  <VStack
                    minW="320px"
                    overflowY="auto"
                    spacing={5}
                    className="enhanced-scrollbar"
                  >
                    {challengeList.length ? (
                      challengeListContent
                    ) : (
                      <Text>No data available</Text>
                    )}
                  </VStack>
                )}
              </VStack>
            </Flex>
          </Flex>
        </VStack>
      </Center>
      <Feedback
        title={title}
        message={message}
        isOpen={isOpen}
        onClose={handleClose}
      />
    </>
  )
}

export default WeeklyChallengePage
