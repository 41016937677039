import React from 'react'
import {
  Container as ChakraContainer,
  ContainerProps as ChakraContainerProps,
} from '@chakra-ui/react'

import './container.scss'

interface ContainerProps extends ChakraContainerProps {
  zIndex?: number
  children: React.ReactNode
}

const Container: React.FC<ContainerProps> = ({
  children,
  className,
  ...props
}) => (
  <ChakraContainer {...props} className={`main-container ${className}`}>
    {children}
  </ChakraContainer>
)

export default Container
