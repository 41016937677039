import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  RadioGroup,
  Stack,
  Radio,
  Flex,
  Button,
  Center,
} from '@chakra-ui/react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import {
  AUTH_MUTATIONS,
  upgradeSubscriptionMethod,
} from '../../auth/mutations/auth.mutations'
import { AUTH_QUERIES } from '../../auth/mutations/auth.queries'
import { SubscriptionType } from '../../auth/types'
import { appearance } from '../../../helpers/constants'
// import { setTeamSeats } from '../../auth/store/auth.actions'

import CheckoutModal from './CheckoutModal'
import PaymentSuccessPrompt from './PaymentSuccessPrompt'
import PaymentFailurePrompt from './PaymentFailurePrompt'
import LoadingModal from './LoadingModal'
import CouponCode from '../../../pages/CouponCode/CouponCode'

import { config } from '../../../config'

export interface ITeamsPaymentProps {
  isOpen: boolean
  onClose: () => void
  addTeamHandler: () => void
  renewalSuccess: () => void
  teamId?: string | undefined
  isRenewal?: boolean
}

const FITGMR_COACH_PERFORMANCE = 'FITGMR Coach Performance'
const FITGMR_COACH_PERFORMANCE_YEARLY = 'FITGMR Coach Performance Yearly'

const { stripePublishableKey } = config
const stripePromise = loadStripe(stripePublishableKey)

const TeamsPayment: React.FC<ITeamsPaymentProps> = ({
  isOpen,
  onClose,
  addTeamHandler,
  renewalSuccess,
  teamId,
  isRenewal,
}) => {
  const [subscriptionConfigs, setSubscriptionConfigs] = useState<any>([])
  const [checkoutModalOpen, setCheckoutModalOpen] = useState<boolean>(false)
  const [clientSecret, setClientSecret] = useState<string>('')
  const [isCouponCodeOpen, setIsCouponCodeOpen] = useState<boolean>(false)
  const [amountDue, setAmountDue] = useState<number | undefined>()
  const [isDonePromptOpen, setIsDonePromptOpen] = useState<boolean>(false)
  const [isFailurePromptOpen, setIsFailurePromptOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [planType, setPlanType] = useState<string>(FITGMR_COACH_PERFORMANCE)

  const continueToManagerPortal = () => {
    setIsDonePromptOpen(false)
  }

  const paymentSuccess = async () => {
    onClose()
    setIsDonePromptOpen(false)
    setCheckoutModalOpen(false)
    if (isRenewal) {
      renewalSuccess()
      continueToManagerPortal()
    } else {
      addTeamHandler()
    }
  }

  const paymentFailure = () => {
    setIsFailurePromptOpen(true)
  }

  const [getCardLastFourDigits, { data: cardData }] = useLazyQuery(
    AUTH_QUERIES.GET_CARD_DETAILS
  )

  const [upgradeSubscription] = useMutation(
    AUTH_MUTATIONS.UPGRADE_SUBSCRIPTION,
    {
      onCompleted: (tData) => {
        setClientSecret(tData[upgradeSubscriptionMethod].clientSecret)
        if (tData[upgradeSubscriptionMethod].amountDue === 0) {
          setIsDonePromptOpen(true)
          setIsLoading(false)
        } else if (tData[upgradeSubscriptionMethod].status === 'active') {
          setIsDonePromptOpen(true)
          setIsLoading(false)
        } else {
          setAmountDue(tData[upgradeSubscriptionMethod].amountDue)
          setIsLoading(false)
          setCheckoutModalOpen(true)
        }
      },
      onError: () => {
        paymentFailure()
      },
    }
  )

  const createSubscription = (alternatePayment: boolean, coupon: any) => {
    const stripeSubscription = subscriptionConfigs.find(
      (item: SubscriptionType) => item.name === planType
    )
    upgradeSubscription({
      variables: {
        where: { id: stripeSubscription.id },
        couponId: coupon,
        teamId: isRenewal ? teamId : null,
        alternatePayment,
      },
    })
  }

  const [refetchMutation, { data: subsData }] = useLazyQuery(
    AUTH_QUERIES.GET_SUBSCRIPTIONS_STRIPE,
    {
      fetchPolicy: 'network-only',
    }
  )

  const openCheckoutModal = (alternatePayment: boolean, coupon: any) => {
    setIsLoading(true)
    createSubscription(alternatePayment, coupon)
  }

  useEffect(() => {
    getCardLastFourDigits()
    refetchMutation()
  }, [])

  useEffect(() => {
    if (subsData) {
      if (subsData.subscriptionConfigs) {
        setSubscriptionConfigs(subsData.subscriptionConfigs)
      }
    }
  }, [subsData])

  const openCouponCodeModal = () => {
    setIsCouponCodeOpen(true)
  }

  const handleCouponCodeSubmit = (coupon: any) => {
    setIsLoading(true)
    setIsCouponCodeOpen(false)
    openCheckoutModal(false, coupon)
  }

  const handleCouponCodeSkip = () => {
    setIsCouponCodeOpen(false)
    setIsLoading(true)
    openCheckoutModal(false, null)
  }

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  }
  const changePlanType = (event: any) => {
    setPlanType(event.target.value)
  }
  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        scrollBehavior="outside"
        size={'lg'}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          bgColor="#272727"
          color="white"
          border="none"
          borderRadius="4px"
        >
          <ModalHeader textAlign="center">Purchase Team</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup defaultValue={FITGMR_COACH_PERFORMANCE} mt={4}>
              <Stack spacing={5} direction="column">
                <Radio
                  colorScheme="brand"
                  value={FITGMR_COACH_PERFORMANCE}
                  onChange={changePlanType}
                >
                  <Flex>
                    <Flex alignItems="end">
                      <Text color="white" fontSize="3xl">
                        $39.99
                      </Text>
                      <Text color="#9e9e9e" fontSize="sm" pb={2}>
                        /month
                      </Text>
                    </Flex>
                  </Flex>
                </Radio>
                <Radio
                  colorScheme="brand"
                  value={FITGMR_COACH_PERFORMANCE_YEARLY}
                  onChange={changePlanType}
                >
                  <Flex>
                    <Flex alignItems="end">
                      <Text color="white" fontSize="3xl">
                        $399.99
                      </Text>
                      <Text color="#9e9e9e" fontSize="sm" pb={2}>
                        /year
                      </Text>
                    </Flex>
                  </Flex>
                </Radio>
              </Stack>
            </RadioGroup>
            {cardData?.cardDetails?.lastFour && (
              <Text color="#9e9e9e">
                {`Amount will be charged to card ending in 
             ${cardData.cardDetails.lastFour}`}
              </Text>
            )}
            <Center>
              <Button
                color="white"
                colorScheme="brand"
                size="md"
                mr={4}
                my={4}
                onClick={() => openCouponCodeModal()}
              >
                Purchase
              </Button>
              {cardData?.cardDetails?.lastFour && (
                <Button
                  color="white"
                  colorScheme="brand"
                  variant="outline"
                  size="md"
                  my={4}
                  onClick={() => openCheckoutModal(true, null)}
                >
                  Use different payment method
                </Button>
              )}
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
      {isLoading && <LoadingModal isOpen={isLoading} onClose={() => {}} />}
      {isDonePromptOpen && (
        <PaymentSuccessPrompt
          isOpen={isDonePromptOpen}
          onSuccess={() => paymentSuccess()}
        />
      )}
      {isFailurePromptOpen && (
        <PaymentFailurePrompt
          isOpen={isFailurePromptOpen}
          onClose={() => setIsFailurePromptOpen(false)}
        />
      )}
      {checkoutModalOpen && !!clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutModal
            clientSecret={clientSecret}
            amountDue={amountDue}
            plan={{ title: FITGMR_COACH_PERFORMANCE }}
            planType={planType}
            isOpen={checkoutModalOpen}
            onSuccess={paymentSuccess}
            onClose={() => {
              setCheckoutModalOpen(false)
            }}
            onFail={paymentFailure}
          />
        </Elements>
      )}
      {isCouponCodeOpen && (
        <CouponCode
          isOpen={isCouponCodeOpen}
          onSuccess={(coupon) => handleCouponCodeSubmit(coupon)}
          onClose={() => setIsCouponCodeOpen(false)}
          onSkip={handleCouponCodeSkip}
        />
      )}
    </>
  )
}

export default TeamsPayment
