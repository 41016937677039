import { gql } from '@apollo/client'

const getAllPayments = gql`
  query Payments(
    $where: PaymentsWhereInput
    $orderBy: [PaymentsOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    findManyPayments(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      userId
      subscriptionConfigId
      productName
      productPrice
      productId
      paymentFrequency
      subscriptionId
      status
      method
      endSubscriptionAt
      createdAt
      updatedAt
      coupon
      teamId
      User {
        id
        email
        role
      }
      SubscriptionConfig {
        id
        name
        type
      }
    }
  }
`

const getAllAggregatePayments = gql`
  query AggregatePayments($where: PaymentsWhereInput) {
    aggregatePayments(where: $where) {
      count: _count {
        id
      }
    }
  }
`

const getPaymentStats = gql`
  query Payments($where: PaymentsWhereInput) {
    getPaymentStats(where: $where) {
      coupons {
        coupon
        count
      }

      paymentMethods {
        method
        count
      }

      extra {
        teamInvites
        newSubscriptions
      }
    }
  }
`

const createPayment = gql`
  mutation CreatePayment($data: CreateManualPaymentInput!) {
    createManualPayment(data: $data)
  }
`

const getTeamsFromCoupon = gql`
  query TeamsFromCoupon(
    $couponId: String!
    $startDate: String!
    $endDate: String!
    $game: String
  ) {
    getTeamsFromCoupon(
      query: {
        couponId: $couponId
        startDate: $startDate
        endDate: $endDate
        game: $game
      }
    ) {
      teamName
      teamId
      points {
        date
        value
      }
    }
  }
`

const getGamersFromCoupon = gql`
  query GamersFromCoupon(
    $couponId: String!
    $startDate: String!
    $endDate: String!
  ) {
    getGamersFromCoupon(
      query: { couponId: $couponId, startDate: $startDate, endDate: $endDate }
    ) {
      userId
      userEmail
      points {
        date
        value
      }
    }
  }
`
export const PAYMENT_QUERIES = {
  GET_ALL_PAYMENTS: getAllPayments,
  GET_ALL_AGGREGATE_PAYMENTS: getAllAggregatePayments,
  GET_PAYMENT_STATS: getPaymentStats,
  GET_TEAMS_BY_COUPON: getTeamsFromCoupon,
  GET_GAMERS_BY_COUPON: getGamersFromCoupon,
  CREATE_PAYMENT: createPayment,
}
