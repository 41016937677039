import { gql } from '@apollo/client'

const getWeeklyChallenges = gql`
  query WeeklyChallenges($orderBy: [WeeklyChallengeOrderByWithRelationInput!]) {
    weeklyChallenges(orderBy: $orderBy) {
      id
      year
      week
      description
    }
  }
`

export const WEEKLY_CHALLENGE_QUERIES = {
  GET_WEEKLY_CHALLENGES: getWeeklyChallenges,
}
