import { PayloadedAction } from '../../../types'
import { UserState, USER } from './users.types'
import { AUTH } from '../../auth/store/auth.types'

const initialState: UserState = {
  isLoading: false,
  error: null,
  userList: [],
  selectedUser: null,
  totalUsers: 0,
}

export default function UserReducer(
  state: UserState = initialState,
  { type, payload }: PayloadedAction
) {
  switch (type) {
    case USER.USER_ACTION_LOADING:
    case USER.USER_LIST_REQUEST:
    case USER.USER_FETCH_REQUEST:
    case USER.USER_UPDATE_REQUEST:
    case USER.USER_DELETE_REQUEST:
      return {
        ...state,
        ...payload,
      }
    case USER.USER_DELETE_FAILED:
    case USER.USER_LIST_FAILED:
    case USER.USER_UPDATE_FAILED:
      return {
        ...state,
        ...payload,
      }
    case USER.USER_LIST_SUCCESS:
      return {
        ...state,
        userList: payload.users,
      }
    case USER.USER_UPDATE_SUCCESS:
    case USER.USER_DELETE_SUCCESS:
    case USER.USER_ENABLE_SUCCESS:
    case USER.USER_DISABLE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const userInList = Boolean(
        state.userList.find((user) => user.id === payload.id)
      )
      // eslint-disable-next-line no-case-declarations
      let userList = []
      if (userInList) {
        userList = state.userList.map((user) =>
          user.id !== payload.id ? user : { ...user, ...payload }
        )
      } else {
        userList = [
          ...state.userList.filter((user) => user.id !== payload.id),
          payload,
        ]
      }
      return {
        ...state,
        userList,
      }
    case USER.USER_FETCH_SUCCESS:
      return {
        ...state,
        selectedUser: payload.user,
      }
    case USER.USER_LIST_METADATA_SUCCESS:
      return {
        ...state,
        totalUsers: payload.totalUsers,
      }
    case USER.USER_FETCH_FAILED:
      return {
        ...state,
        selectedUser: null,
      }
    case AUTH.LOGOUT_SUCCESS:
      return initialState
    default:
      return state
  }
}
