import { PayloadedAction } from '../../../types'
import { TeamState, TEAM } from './coaches.types'
import { AUTH } from '../../auth/store/auth.types'

const initialState: TeamState = {
  isLoading: false,
  error: null,
  team: {
    id: '',
    name: '',
    description: '',
    avatar: '',
    game: '',
    createdAt: '',
    updatedAt: '',
    users: [],
    TeamInvites: [],
    Seats: [],
  },
  totalUsers: 0,
}

export default function TeamReducer(
  state: TeamState = initialState,
  { type, payload }: PayloadedAction
) {
  switch (type) {
    case TEAM.TEAM_ACTION_LOADING:
      return {
        ...state,
        isLoading: payload.isLoading,
      }
    case TEAM.TEAM_FETCH_REQUEST:
    case TEAM.TEAM_UPDATE_REQUEST:
      return {
        ...state,
        ...payload,
      }
    case TEAM.TEAM_CREATE_FAILED:
      return {
        ...state,
        ...payload,
      }
    case TEAM.TEAM_UPDATE_FAILED:
      return {
        ...state,
        ...payload,
      }
    case TEAM.TEAM_FETCH_FAILED:
      return {
        ...state,
        team: {
          id: '',
          name: '',
          description: '',
          avatar: '',
          game: '',
          createdAt: '',
          updatedAt: '',
          users: [],
          TeamInvites: [],
        },
      }
    case TEAM.TEAM_UPDATE_SUCCESS:
      return {
        ...state,
        team: payload,
      }
    case TEAM.TEAM_CREATE_SUCCESS:
      return {
        ...state,
        team: payload,
      }
    case TEAM.TEAM_FETCH_SUCCESS:
      return {
        ...state,
        team: payload.team,
      }
    case TEAM.TEAM_REMOVE_USER_INVITE_SUCCESS:
      return {
        ...state,
        team: {
          ...state.team,
          TeamInvites: state.team?.TeamInvites?.filter(
            (teamInvite) => teamInvite.id !== payload.id
          ),
        },
      }
    case TEAM.TEAM_REMOVE_USER_SUCCESS:
      return {
        ...state,
        team: {
          ...state.team,
          users: state.team?.users?.filter((user) => user.id !== payload.id),
        },
      }
    case TEAM.TEAM_SET_SEATS:
      return {
        ...state,
        team: {
          ...state.team,
          Seats: [...state.team.Seats, ...payload.seats],
        },
      }
    case AUTH.LOGOUT_SUCCESS:
      return initialState
    default:
      return state
  }
}
