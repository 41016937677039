import React from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react'

const TextInput: React.FC<{
  name: string
  id: string
  label: string
  disabled?: boolean
  value: string | number
  placeholder?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  error?: boolean
  helperText?: string
  style?: React.CSSProperties
}> = ({
  name,
  id,
  label,
  value,
  placeholder,
  onChange,
  onBlur,
  helperText,
  disabled,
  style,
}) => (
  <FormControl
    id={id}
    className="text-input"
    isInvalid={Boolean(helperText)}
    style={style}
  >
    <FormLabel color="white" className="text-input__label">
      {label}
    </FormLabel>
    <Input
      name={name}
      id={id}
      className="text-input__input"
      autoComplete="no"
      value={value}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      isTruncated={typeof value === 'string' ? value.length > 20 : false}
    />
    {helperText && <FormErrorMessage>{helperText}</FormErrorMessage>}
  </FormControl>
)

export default TextInput
