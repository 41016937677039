import React from 'react'

const TeamStatisticsComponent = React.lazy(
  () => import('./team-statistics.component')
)

export default {
  to: '/home/my-team/statistics',
  component: TeamStatisticsComponent,
  exact: true,
  isAdmin: false,
}
