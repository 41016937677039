import React from 'react'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import moment from 'moment'
import { CalendarComponentProps } from '../store/challenge.types'

const Calendar: React.FC<CalendarComponentProps> = ({
  onWeekClick,
  selectedDays,
  modifiers,
  onDayClick,
  onDayMouseEnter,
  onDayMouseLeave,
}) => {
  const renderWeek = (weekNumber: number, weeks: Date[]) => {
    const week = moment(weeks[0]).week()
    return <p>{week}</p>
  }

  return (
    <DayPicker
      showWeekNumbers
      showOutsideDays
      onWeekClick={onWeekClick}
      selectedDays={selectedDays}
      modifiers={modifiers}
      onDayClick={onDayClick}
      onDayMouseEnter={onDayMouseEnter}
      onDayMouseLeave={onDayMouseLeave}
      fixedWeeks
      renderWeek={renderWeek}
    />
  )
}

export default Calendar
