import React from 'react'
import {
  HStack,
  Box,
  Stack,
  FormControl,
  FormLabel,
  Button,
} from '@chakra-ui/react'
import DatePicker, { utils } from 'react-modern-calendar-datepicker'

import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import SearchBox from '../../components/shared/ui/search/Search'
import CustomSelect from '../../components/shared/ui/select'
import { fitgmrMainColor } from '../../helpers/constants'

function dateToObj(date: Date) {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  }
}

const dateRangeOptions = [
  {
    value: 'custom',
    label: 'Custom',
  },
  {
    value: 'last-week',
    label: 'Last Week',
  },
  {
    value: 'last-month',
    label: 'Last Month',
  },
  {
    value: 'last-3-months',
    label: 'Last 3 Months',
  },
  {
    value: 'last-year',
    label: 'Last Year',
  },
  {
    value: 'all-time',
    label: 'All Time',
  },
]

export default function SearchAndFilter({
  actions,
  selectedPredefinedRange,
  setSelectedPredefinedRange,
  selectedDayRange,
  setSelectedDayRange,
  setCoupon,
  coupon,
  isDownloadDisable = true,
  downloadData = () => {},
}: any) {
  const handleSelectPredefinedRange = (val: any) => {
    if (val === 'last-week') {
      const lastWeek = new Date()

      lastWeek.setDate(new Date().getDate() - 7)

      setSelectedDayRange({
        from: dateToObj(lastWeek),
        to: utils('en').getToday(),
      })
    } else if (val === 'last-month') {
      const lastMonth = new Date()

      lastMonth.setMonth(new Date().getMonth() - 1)

      setSelectedDayRange({
        from: dateToObj(lastMonth),
        to: utils('en').getToday(),
      })
    } else if (val === 'last-3-months') {
      const last3Months = new Date()

      last3Months.setMonth(new Date().getMonth() - 3)

      setSelectedDayRange({
        from: dateToObj(last3Months),
        to: utils('en').getToday(),
      })
    } else if (val === 'last-year') {
      const lastYear = new Date()

      lastYear.setFullYear(new Date().getFullYear() - 1)

      setSelectedDayRange({
        from: dateToObj(lastYear),
        to: utils('en').getToday(),
      })
    } else if (val === 'all-time') {
      setSelectedDayRange({
        from: undefined,
        to: undefined,
      })
    }

    setSelectedPredefinedRange(val)
  }

  return (
    <Box display="flex" justifyContent="space-between">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        className="user-tabs__user_list__tabs__tab-panels__panel__header"
        spacing={10}
      >
        <HStack>
          <FormControl id="date-filter" width="150px">
            <FormLabel margin="0" fontSize="14px">
              Range
            </FormLabel>
            <CustomSelect
              name="date-selection-filter"
              defaultValue={selectedPredefinedRange}
              onSelect={handleSelectPredefinedRange}
              options={dateRangeOptions}
            />
          </FormControl>
          <FormControl id="date-filter" width="200px">
            <FormLabel margin="0" fontSize="14px">
              Start Date
            </FormLabel>
            <DatePicker
              calendarClassName="custom-calendar"
              value={selectedDayRange}
              onChange={(value) => {
                setSelectedDayRange(value)
                setSelectedPredefinedRange('custom')
              }}
              shouldHighlightWeekends
              maximumDate={utils('en').getToday()}
              //   colorPrimary={fitgmrMainColor}
              //   colorPrimaryLight={pillarColors.sleep}
              renderInput={({ ref }: any) => (
                <Button variant="outline" size="md" ref={ref}>
                  {selectedDayRange.from
                    ? `${selectedDayRange.from.day}/${selectedDayRange.from.month}/${selectedDayRange.from.year}`
                    : '∞'}

                  {' - '}
                  {selectedDayRange.to
                    ? `${selectedDayRange.to.day}/${selectedDayRange.to.month}/${selectedDayRange.to.year}`
                    : '∞'}
                </Button>
              )}
            />
          </FormControl>
        </HStack>
        <HStack marginLeft="10px">
          <FormControl id="search-filter">
            <FormLabel margin="0" fontSize="14px">
              Coupon Search
            </FormLabel>

            <SearchBox
              className="search"
              placeholder=" "
              val={coupon}
              icon
              onChange={(val) => setCoupon(val)}
            />
          </FormControl>
        </HStack>
        <HStack>
          <Button
            position="absolute"
            right="30px"
            bgColor={fitgmrMainColor}
            disabled={isDownloadDisable}
            onClick={() => {
              downloadData()
            }}
          >
            Download Excel
          </Button>
        </HStack>
        <Stack direction={['column', 'row']}>{actions}</Stack>
      </Stack>
    </Box>
  )
}
