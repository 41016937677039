import React, { useEffect, useState } from 'react'
import { Box, Icon } from '@chakra-ui/react'
import { FaBell } from 'react-icons/fa'
import useActivityScoreStats from '../../hooks/useActivityScoreStats'
import { TeamInterface } from '../../store/coaches.types'
import './ManagedTeams.scss'

type teamType = {
  team?: TeamInterface
  setSelectedTeam: any
}

const ManagedTeams = ({ team, setSelectedTeam }: teamType) => {
  const [fitGMRScore, setFitGMRScore] = useState<number>()
  const { loadTeamScoreStatistics, teamScoresStats } = useActivityScoreStats()

  useEffect(() => {
    if (team?.id) {
      loadTeamScoreStatistics('fitgmr', 1, team.id)
    }
  }, [team?.id])

  useEffect(() => {
    if (teamScoresStats?.mainScoreStatistics?.data[1]) {
      setFitGMRScore(teamScoresStats?.mainScoreStatistics?.data[1].score)
    }
  }, [teamScoresStats])

  return (
    <Box
      id="team-background"
      className="managed-teams__team-box"
      onClick={() => setSelectedTeam(team)}
      style={{
        backgroundImage: `url(${team?.avatar})`,
      }}
    >
      <Box className="managed-teams__team-box__content-background">
        <Box className="managed-teams__team-box__content-background__team-name">
          {team?.name}
        </Box>
        <Box className="managed-teams__team-box__content-background__team-users">
          {fitGMRScore}
        </Box>
        {!!team?.notificationCount && (
          <Icon
            as={FaBell}
            boxSize={3}
            color="red.500"
            position="absolute"
            right="2"
            top="2"
          />
        )}
      </Box>
    </Box>
  )
}

export default ManagedTeams
