// import { adjustFinalScoreWithMultiplierV2 } from '../../../../api/src/helpers/scoreCalculation/index'

type DayData = {
  score: number
  watchedVideos: boolean
}

export interface ScoreMultiplier {
  day: number
  maxPossibleAccumulatedScore: number
  targetScore: number
  multiplier: number
}

export const MIN_SCORE_FIRST_DAY_V2 = 80
export const MAX_TARGET_USER_SCORE_V2 = 90
export const MAX_TARGET_USER_SCORE_REACHED_IN_DAYS_V2 = 15
export const DAILY_TARGET_INCREMENT_V2 =
  (MAX_TARGET_USER_SCORE_V2 - MIN_SCORE_FIRST_DAY_V2) /
  MAX_TARGET_USER_SCORE_REACHED_IN_DAYS_V2
export const DEFAULT_STREAK_PERCENTAGE = 86
export const MAX_PILLARS_BASED_SCORE_V2 = 90
// number of days we use for calculation
export const PILLARS_BASED_SCORE_HISTORY_LENGTH_V2 = 15
export const MAX_DAY_SCORE_V2 =
  MAX_PILLARS_BASED_SCORE_V2 / PILLARS_BASED_SCORE_HISTORY_LENGTH_V2

let staticMultiplierArrayV2: ScoreMultiplier[]

function scoreForDayV2(pillarsBasedScore: number): number {
  return (MAX_DAY_SCORE_V2 * pillarsBasedScore) / 100
}

function updateCalculatedArraysV2(): ScoreMultiplier[] {
  let currentDay = 0
  let currentTargetScore = MIN_SCORE_FIRST_DAY_V2

  const multipliersArray: ScoreMultiplier[] = []
  let multiplierNeeded = true

  while (multiplierNeeded) {
    let currentMultiplier = 1.0
    currentDay++

    let accumulatedScore = 0.0
    for (
      let processedDay = currentDay;
      processedDay >
      Math.max(0, currentDay - PILLARS_BASED_SCORE_HISTORY_LENGTH_V2);
      processedDay--
    ) {
      const streakFactor = Math.min(
        (1.0 * (processedDay + DEFAULT_STREAK_PERCENTAGE - 1)) / 100,
        1
      )
      accumulatedScore += MAX_DAY_SCORE_V2 * streakFactor
    }

    if (accumulatedScore < currentTargetScore - (MAX_DAY_SCORE_V2 * 0.01) / 2) {
      currentMultiplier = currentTargetScore / accumulatedScore
      multipliersArray.push({
        day: currentDay,
        maxPossibleAccumulatedScore: accumulatedScore,
        targetScore: currentTargetScore,
        multiplier: currentMultiplier,
      })
    } else {
      multiplierNeeded = false
    }

    if (currentTargetScore < MAX_TARGET_USER_SCORE_V2) {
      currentTargetScore += DAILY_TARGET_INCREMENT_V2
    }
  }

  staticMultiplierArrayV2 = multipliersArray
  // printMultipliersTable(multipliersArray);
  return staticMultiplierArrayV2
}

function prepareMultipliersArrayV2(): ScoreMultiplier[] {
  if (staticMultiplierArrayV2) {
    return staticMultiplierArrayV2
  }
  updateCalculatedArraysV2()
  return staticMultiplierArrayV2
}

export function adjustFinalScoreWithMultiplierV2(
  userScore: number,
  daysOfAppUsage: number
): number {
  const multipliers = prepareMultipliersArrayV2()
  if (multipliers.length < daysOfAppUsage) {
    return userScore * multipliers[multipliers.length - 1].multiplier
  }
  const multiplier = multipliers[daysOfAppUsage - 1]
  return Math.min(userScore * multiplier.multiplier, MAX_PILLARS_BASED_SCORE_V2)
}

export function calculateFinalScore(daysWithData: Map<string, DayData>) {
  if (daysWithData.size === 0) {
    return {
      finalScore: 0,
      streakDays: 0,
    }
  }

  // Get today's date without time
  const today = new Date(new Date().toISOString().split('T')[0])
  // Add timezone offset to get UTC time
  today.setHours(12 + today.getHours() + today.getTimezoneOffset() / 60)
  const todayKey = today.toISOString()
  // const daysOfAppUsage = daysWithData.size
  let userScore = 0

  // Get streak days
  let streakDays = 0
  let lastDayWithData = daysWithData.get(todayKey)
  let lastDayWithDataKey = todayKey
  while (lastDayWithData) {
    streakDays++
    const lastDay = new Date(lastDayWithDataKey)
    lastDay.setDate(lastDay.getDate() - 1)
    lastDayWithDataKey = lastDay.toISOString()
    lastDayWithData = daysWithData.get(lastDayWithDataKey)
  }

  daysWithData.forEach((dayData, key) => {
    if (new Date(key).getTime() > new Date(lastDayWithDataKey).getTime()) {
      userScore += scoreForDayV2(dayData.score)
    }
  })

  const finalScore = adjustFinalScoreWithMultiplierV2(userScore, streakDays)
  const bonusScore = daysWithData.get(todayKey)?.watchedVideos ? 10 : 0

  return {
    finalScore: Math.floor(finalScore + bonusScore),
    streakDays,
  }
}
