import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { TeamCreateWithoutUsersInput } from '../../../coaches/store/coaches.types'
import TeamForm from '../../../coaches/ui/form/team.form'
import validationSchema from '../../../coaches/ui/form/team.validation-schema'

interface CreateTeamModalProps {
  handleSubmit: (
    values: TeamCreateWithoutUsersInput,
    setSubmitting: (val: boolean) => void
  ) => Promise<void>
  btnRef: React.MutableRefObject<null>
  handleCloseModal: () => void
  isOpen: boolean
  isTeamCreating: boolean
}

const CreateTeamModal: React.FC<CreateTeamModalProps> = ({
  handleCloseModal,
  btnRef,
  isOpen,
  handleSubmit,
  isTeamCreating,
}) => {
  const initialValues = { name: '', description: '', game: '', avatar: '' }

  return (
    <Modal
      onClose={handleCloseModal}
      finalFocusRef={btnRef}
      isOpen={isOpen}
      scrollBehavior="outside"
      size={'lg'}
    >
      <ModalOverlay />
      <ModalContent
        className="team-invite"
        bgColor="#272727"
        color="white"
        border="none"
        borderRadius="4px"
      >
        <ModalHeader textAlign="center">Create Team</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting)
            }}
          >
            {(formProps: any) => (
              <TeamForm
                {...formProps}
                isSubmitting={formProps.isSubmitting}
                isTeamCreating={isTeamCreating}
                handleCloseModal={handleCloseModal}
              />
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateTeamModal
