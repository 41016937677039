import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Flex, Text, GridItem, Grid } from '@chakra-ui/react'
import moment from 'moment'

import { Table } from '../../components/shared/ui/table'
import useDebounceState from '../../hooks/useDebounceState'
import DiscordLogo from '../../assets/images/discord.svg'

import {
  setActionListRequest,
  setActionRequest,
} from '../../components/users/store/users.actions'

import './Users.scss'
import {
  RoleEnum,
  StringFilterModes,
  USER,
  UserFetchRequest,
  UserInterface,
  UserListMetadataRequest,
  UserListRequest,
  UserWhereInput,
} from '../../components/users/store/users.types'
import { SortOrder } from '../../types'

import {
  selectUserList,
  selectUserListCount,
  selectUserError,
} from '../../components/users/store/users.selectors'

import Feedback from '../../components/shared/ui/feedback/Feedback'
import useFeedback from '../../components/shared/ui/feedback/useFeedbackModal'
import UsersDetail from './UsersDetail'
import { EAppSubscriptionReasonPackage } from '../../components/users/ui/form/types'
import SearchAndFilter from './SearchAndFilter'

interface FilterTypes {
  subscription: string[]
  role: string[]
}

const Users: React.FC = () => {
  const dispatch = useDispatch()

  const [filters, setFilters] = useState<FilterTypes>({
    subscription: [],
    role: [],
  })

  const [selected, setSelected] = useState<string[]>([])
  const [page, setPage] = useState<number>(1)

  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [enabledFilter, setEnabledFilter] = useState<boolean | string>(true)
  const history = useHistory()
  const [search, setSearch] = useDebounceState('', 600)
  const [pageCount, setPageCount] = useState<number>(0)

  const [selectedRow, setSelectedRow] = useState<any | null>(null)

  const { title, setTitle, message, setMessage, isOpen, setIsOpen } =
    useFeedback()

  const usersData = useSelector(selectUserList)

  const totalUsers = useSelector(selectUserListCount)
  const error: string = useSelector(selectUserError)

  useEffect(() => {
    if (error) {
      setTitle('Oops! Something is not right.')
      setMessage(error)
      setIsOpen(true)
    } else {
      setTitle('')
      setMessage('')
      setIsOpen(false)
    }
  }, [error])

  const resetError = () => {
    setTitle('')
    setMessage('')
    setIsOpen(false)
  }

  const handleClose = () => {
    resetError()
  }

  const renderRole = (role: RoleEnum) => {
    switch (role) {
      case RoleEnum.DIRECTOR:
        return 'Director'

      case RoleEnum.COACH:
        return 'Coach'

      case RoleEnum.GAMER:
        return 'Gamer'

      case RoleEnum.SELF:
        return 'Self'

      default:
        return 'No-Role'
    }
  }

  const searchFilter = useMemo(
    () => ({
      OR: [
        {
          email: {
            contains: search,
            mode: StringFilterModes.insensitive,
          },
        },
        {
          username: {
            contains: search,
            mode: StringFilterModes.insensitive,
          },
        },
        {
          fullName: {
            contains: search,
            mode: StringFilterModes.insensitive,
          },
        },
      ],
      AND: [
        enabledFilter === ''
          ? {}
          : {
              enabled: {
                equals: enabledFilter,
              },
            },
      ],
    }),
    [search, enabledFilter]
  )

  const handleDisableItem = useCallback(
    (id: string) => {
      history.push(`/home/users/${id}/disable`)
    },
    [history]
  )
  const handleEnableItem = useCallback(
    (id: string) => {
      history.push(`/home/users/${id}/enable`)
    },
    [history]
  )

  const handleBulkRemove = useCallback(
    (selectedRows: string[]) => {
      history.push(`/home/users/${selectedRows.join(',')}/bulk-delete`)
    },
    [history]
  )

  const userSubscription = (user: UserInterface) => {
    const FITGMR = 'FITGMR'
    const FITGMR_FREE = `${FITGMR} Free`
    const FITGMR_PRO = `${FITGMR} Pro`
    const BY_TEAM = 'by Team'
    switch (user?.appSubscriptionReason) {
      case EAppSubscriptionReasonPackage.FITGMRFREE:
        return `${FITGMR_FREE}`
      case EAppSubscriptionReasonPackage.FITGMRFREE_BYTEAM:
        return `${FITGMR_FREE} ${BY_TEAM}`
      case EAppSubscriptionReasonPackage.FITGMRPRO:
        return `${FITGMR_PRO}`
      case EAppSubscriptionReasonPackage.FITGMRPRO_BYTEAM:
        return `${FITGMR_PRO} ${BY_TEAM}`
      case EAppSubscriptionReasonPackage.FITGMRPRO_ADMIN:
        return `${FITGMR_PRO} Admin`
      default:
        return ''
    }
  }

  const headers = [
    {
      id: 'id',
      title: 'ID',
    },
    {
      id: 'fullName',
      title: 'Name',
    },
    {
      id: 'username',
      title: 'Username',
    },
    {
      id: 'email',
      title: 'Email',
      render: ({ email, usedDiscord }: UserInterface) => (
        <Flex alignItems="center">
          <Text marginRight={2}>{email}</Text>
          {usedDiscord && (
            <img src={DiscordLogo} alt="discord user" width={'18px'} />
          )}
        </Flex>
      ),
    },
    {
      id: 'appSubType',
      title: 'App Subscription Type',
      render: (user: UserInterface) => <Text>{userSubscription(user)}</Text>,
    },
    {
      id: 'createdAt',
      title: 'Date joined',
      render: ({ createdAt }: UserInterface) =>
        moment(createdAt).format('MMM D, YYYY'),
    },
    {
      id: 'enabled',
      title: 'Enabled',
      type: 'boolean',
    },
    {
      id: 'role',
      title: 'Role',
      render: ({ role }: UserInterface) => renderRole(role),
    },
  ]

  const actions = useMemo(() => {
    const response = []

    if (selected.length > 0) {
      response.push(
        <Button
          key="action-1"
          onClick={() => handleBulkRemove(selected)}
          size="sm"
        >
          {`Remove ${selected.length}`}
        </Button>
      )
    }
    return response
  }, [selected])

  const userFilters: Array<UserWhereInput> = useMemo(() => {
    const newFilters: UserWhereInput[] = search.length
      ? [searchFilter]
      : [
          enabledFilter === ''
            ? {}
            : {
                enabled: {
                  equals: enabledFilter,
                },
              },
        ]

    const { subscription } = filters
    if (subscription?.length) {
      const orFilters: UserWhereInput[] = []

      if (subscription.includes('FITGMR_FREE')) {
        orFilters.push({
          subscription: {
            equals: 'FITGMR_KIDS',
          },
        })
      }
      if (subscription.includes('FITGMR_PRO')) {
        orFilters.push({
          appSubscriptionReason: {
            equals: EAppSubscriptionReasonPackage.FITGMRPRO,
          },
        })
      }
      if (subscription.includes('FITGMR_PRO_BYTEAM')) {
        orFilters.push({
          appSubscriptionReason: {
            equals: EAppSubscriptionReasonPackage.FITGMRPRO_BYTEAM,
          },
        })
      }

      newFilters.push({ OR: orFilters })
    }

    if (filters.role?.length) {
      newFilters.push({
        role: {
          in: filters.role || [],
        },
      })
    }

    return newFilters
  }, [search, filters, enabledFilter])

  useEffect(() => {
    const data: UserListMetadataRequest = {
      where: {
        ...searchFilter,
        AND: userFilters,
      },
    }

    dispatch(setActionListRequest(USER.USER_LIST_METADATA_REQUEST, data))
    setPage(1)
  }, [searchFilter, userFilters])

  useEffect(() => {
    const take = rowsPerPage
    const skip = (page - 1) * rowsPerPage
    const data: UserListRequest = {
      take,
      skip,
      orderBy: [
        {
          id: SortOrder.asc,
        },
      ],
      where: {
        AND: userFilters,
      },
    }

    dispatch(setActionListRequest(USER.USER_LIST_REQUEST, data))
  }, [searchFilter, page, rowsPerPage, userFilters, enabledFilter])

  useEffect(() => {
    // Guessing we will get back the total users from the backend, the page, rowsPerPage

    setPageCount(Math.ceil(totalUsers / rowsPerPage))
  }, [dispatch, totalUsers, rowsPerPage])

  const filterOptions = {
    subscription: ['FITGMR_FREE', 'FITGMR_PRO', 'FITGMR_PRO_BYTEAM'],
    role: ['DIRECTOR', 'COACH', 'GAMER'],
  }

  const selectedFilters: { name: string; value: string }[] = useMemo(() => {
    const items = Object.entries(filters)
    let filtersList: any[] = []
    for (let i = 0; i < items.length; i++) {
      const filter = items[i][1].map((x: string) => ({
        name: items[i][0],
        value: x,
      }))
      filtersList = [...filtersList, ...filter]
    }
    return filtersList
  }, [usersData])

  const handleClearFilters = () =>
    setFilters({
      subscription: [],
      role: [],
    })

  const handleDeleteFilters = (e: any) => {
    const currentFilters: any = { ...filters }
    const filtersList: string[] = currentFilters[e.name]
    filtersList.splice(filtersList.indexOf(e.value), 1)
    currentFilters[e.name] = filtersList
    setFilters(currentFilters)
  }

  /**
   * On Mount set the user for the saga
   */
  useEffect(() => {
    if (selectedRow?.id) {
      const data: UserFetchRequest = {
        where: {
          id: selectedRow?.id,
        },
      }
      dispatch(setActionRequest(USER.USER_FETCH_REQUEST, data))
    }
  }, [selectedRow])

  return (
    <>
      <Grid templateColumns="repeat(5, 1fr)">
        {selectedRow && (
          <GridItem
            colSpan={1}
            className="contentDetailTransition"
            bgColor="#1C1C1D"
            rowSpan={3}
            margin={3}
            borderRadius={8}
            minHeight="950px"
            minWidth="450px"
          >
            <UsersDetail
              setSelectedRow={setSelectedRow}
              selectedRow={selectedRow}
              handleDisableItem={handleDisableItem}
              handleEnableItem={handleEnableItem}
              // loading={isLoading}
            />
          </GridItem>
        )}
        <GridItem
          colSpan={selectedRow ? 4 : 6}
          bgColor="#1C1C1D"
          marginTop={3}
          marginRight={3}
          marginLeft={!selectedRow ? 3 : 0}
          padding={5}
          borderRadius={8}
          maxHeight="150px"
        >
          <SearchAndFilter
            selectedFilters={selectedFilters}
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            handleClearFilters={handleClearFilters}
            handleDeleteFilters={handleDeleteFilters}
            setSearch={setSearch}
            search={search}
            actions={actions}
            setEnabledFilter={setEnabledFilter}
            enabledFilter={enabledFilter}
          />
        </GridItem>

        <GridItem
          colSpan={selectedRow ? 4 : 6}
          bgColor="#1C1C1D"
          marginTop={3}
          marginRight={3}
          marginBottom={3}
          marginLeft={!selectedRow ? 3 : 0}
          padding={5}
          borderRadius={8}
        >
          <Table
            className="user-table"
            showId={false}
            selectable={false}
            selected={selected}
            handleSelect={setSelected}
            headers={headers}
            items={usersData}
            withPagination
            pageCount={pageCount}
            page={page}
            setPage={setPage}
            canNextPage={page < pageCount}
            canPreviousPage={page > 1}
            rowsPerPage={rowsPerPage}
            setPageSize={setRowsPerPage}
            totalRows={usersData.length}
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
          />
        </GridItem>
      </Grid>

      <Feedback
        title={title}
        message={message}
        isOpen={isOpen}
        onClose={handleClose}
      />
    </>
  )
}

export default Users
