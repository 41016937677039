import React, { PropsWithChildren, useState, useEffect } from 'react'

import {
  Box,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  IconButton,
  Text,
  Center,
  VStack,
  useDisclosure,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Modal,
} from '@chakra-ui/react'

import { FaPlus } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import {
  CONTENT,
  VideoQuestionCrudInterface,
  VideoQuestionInterface,
  VideoQuestionSubType,
  VideoQuestionType,
} from '../store/content.types'

import { setActionRequest } from '../store/content.actions'
import { selectSelectedVideo } from '../store/content.selectors'
import VideoPopupQuestionForm from './VideoPopupQuestionForm'
import { RootState } from '../../../types'

interface VideoPopupQuestionFormComponentProps extends PropsWithChildren<any> {
  questions: VideoQuestionInterface[]
  onSubmit: (data: any) => void
  videoId: string
}

const VideoQuestions: React.FC<VideoPopupQuestionFormComponentProps> = ({
  questions,
  onSubmit,
  videoId,
}) => {
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [questionList, setQuestionList] = useState<
    Array<VideoQuestionInterface>
  >(questions || [])

  const [deleteId, setDeleteId] = useState<string>('')

  const video = useSelector((state: RootState) =>
    selectSelectedVideo(state, videoId)
  )
  useEffect(() => {
    setQuestionList(questions)
  }, [questions])

  const handleDelete = (id: string) => {
    setDeleteId(id)
    onOpen()
  }

  const handleAddQuestion = () => {
    const existingList = [...questionList]
    existingList.push({
      type: VideoQuestionType.INT,
      text: 'How many repetitions did you do?',
      subType: VideoQuestionSubType.REPETITIONS,
    })
    setQuestionList(existingList)
  }

  const handleconfirmDelete = () => {
    const data: VideoQuestionCrudInterface = {
      where: {
        id: deleteId,
      },
    }

    const updateRequest = {
      where: {
        id: videoId,
      },
      data: {
        title: {
          set: video.title,
        },
      },
    }

    dispatch(setActionRequest(CONTENT.VIDEO_QUESTION_DELETE_REQUEST, data))
    dispatch(setActionRequest(CONTENT.CONTENT_UPDATE_REQUEST, updateRequest))

    onClose()
  }

  return (
    <>
      <Flex maxWidth="540px" alignItems="start" marginLeft="auto">
        <Box width="100%">
          <Box
            height="100%"
            padding="1rem 2rem 2rem 2rem"
            background="#363636"
            border="1px solid #555"
            borderRadius="2px"
            minWidth="400px"
            overflowY="scroll"
          >
            <IconButton
              color="white"
              marginBottom="5"
              colorScheme="fitgmrPurple"
              aria-label="Add tag button"
              onClick={handleAddQuestion}
              icon={<FaPlus />}
            />
            {questionList?.length ? (
              <Accordion allowToggle>
                {questionList.map((i, k) => {
                  const key = k
                  return (
                    <AccordionItem key={`question_${i.id}_${i.text}_${key}`}>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          {`Question ${key + 1}`}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        <VideoPopupQuestionForm
                          initialData={i}
                          handleDelete={handleDelete}
                          onSubmit={onSubmit}
                          videoId={videoId}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  )
                })}
              </Accordion>
            ) : (
              <Center>
                <VStack>
                  <Text fontSize="24" fontWeight="bold">
                    No Questions
                  </Text>
                  <Text>Click the add Button</Text>
                </VStack>
              </Center>
            )}
          </Box>
        </Box>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bgColor="#353535"
          color="white"
          border="1px"
          borderColor="whiteAlpha.300"
          borderRadius="1px"
        >
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <Text textAlign="center">
              You are about to delete this question. Are you sure you want to
              proceed?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="brand" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleconfirmDelete} colorScheme="red">
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default VideoQuestions
