import React, { PropsWithChildren } from 'react'
import * as Yup from 'yup'

import { Form, Formik, Field, FieldProps } from 'formik'

import {
  Box,
  Button,
  Flex,
  Spacer,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  Text,
  Select,
} from '@chakra-ui/react'

import { CategoryEnum, PillarCategory } from '../store/content.types'

interface VideoUploadComponentProps extends PropsWithChildren<any> {
  onDataChange?: (data: any) => void
  onSubmit: (data: any) => void
  isSubmitting?: boolean
}

const metadataFormSchema = Yup.object().shape({
  title: Yup.string()
    .max(40, 'Title should be less than 40 characters.')
    .required('A title is required.'),
  category: Yup.string().required('Category is required.'),
  pillarCategory: Yup.string().required('Pillar Category is required.'),
})

const VideoCreateForm: React.FC<VideoUploadComponentProps> = ({
  onSubmit,
  isSubmitting,
}) => (
  <Flex maxWidth="540px" alignItems="center">
    <Box width="100%">
      <Box
        height="100%"
        padding="10"
        background="#363636"
        border="1px solid #555"
        borderRadius="2px"
        minWidth="400px"
        overflowY="scroll"
      >
        <Flex direction="column" height="100%">
          <Text color="white" fontSize="1.15rem" marginBottom="1.15rem">
            Select a title and category to get started.
          </Text>
          <Formik
            validationSchema={metadataFormSchema}
            onSubmit={onSubmit}
            initialValues={{
              title: '',
              category: CategoryEnum.QUICK_DRILLS,
              pillarCategory: PillarCategory.LIFESTYLE,
            }}
          >
            {() => (
              <Form>
                <Flex direction="column" height="100%">
                  <Field name="title">
                    {({ field, form }: FieldProps) => (
                      <FormControl
                        marginBottom="40px"
                        isDisabled={isSubmitting}
                        isInvalid={Boolean(
                          form.errors.title && form.touched.title
                        )}
                      >
                        <FormLabel>Title</FormLabel>
                        <InputGroup>
                          <Input {...field} id="title" placeholder="Title" />
                        </InputGroup>
                        <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Spacer />
                  <Field name="pillarCategory">
                    {({ field, form }: any) => (
                      <FormControl
                        marginBottom="40px"
                        isDisabled={isSubmitting}
                        isInvalid={
                          form.errors.pillarCategory &&
                          form.touched.pillarCategory
                        }
                      >
                        <FormLabel>Pillar</FormLabel>
                        <InputGroup>
                          <Select {...field} id="pillarCategory">
                            {Object.values(PillarCategory).map((i) => (
                              <option value={i} key={i}>
                                {i.charAt(0).toUpperCase() +
                                  i.substr(1).toLowerCase().replace('_', ' ')}
                              </option>
                            ))}
                          </Select>
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.pillarCategory}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Spacer />
                  <Field name="category">
                    {({ field, form }: any) => (
                      <FormControl
                        marginBottom="40px"
                        isDisabled={isSubmitting}
                        isInvalid={
                          form.errors.category && form.touched.category
                        }
                      >
                        <FormLabel>Category</FormLabel>
                        <InputGroup>
                          <Select {...field} id="category">
                            {Object.values(CategoryEnum).map((i) => (
                              <option value={i} key={i}>
                                {(
                                  i.charAt(0).toUpperCase() +
                                  i.slice(1).toLowerCase()
                                ).replaceAll('_', ' ')}
                              </option>
                            ))}
                          </Select>
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.category}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Spacer />
                  <Button
                    loadingText="Please wait"
                    type="submit"
                    isFullWidth
                    color="white"
                    colorScheme="fitgmrPurple"
                    isLoading={isSubmitting}
                  >
                    Next
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Flex>
      </Box>
    </Box>
  </Flex>
)

export default VideoCreateForm
