import React from 'react'
import { AddIcon } from '@chakra-ui/icons'

const Payments = React.lazy(() => import('../../../pages/Payments/Payments'))

export default {
  to: '/home/payments',
  component: Payments,
  label: 'Payments',
  isAdmin: true,
  icon: <AddIcon />,
  exact: false,
  level: 1,
  children: [],
}
