import React from 'react'
import Select from 'react-select'

type ReactSelectProps = {
  selectChange: (event: any) => void
  isDisabled: boolean
  placeholder: string
  options: { value: string; label: string }[]
  className: string
}

const ChakraReactSelect: React.FC<ReactSelectProps> = ({
  selectChange,
  isDisabled,
  placeholder,
  options,
  className,
}) => {
  console.log('isDisabled', isDisabled)
  return (
    <Select
      className={className}
      name="game"
      onChange={selectChange}
      isSearchable
      isClearable
      isDisabled={isDisabled}
      placeholder={placeholder}
      options={options}
      styles={{
        container: (baseStyles) => ({
          ...baseStyles,
          borderColor: 'black',
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? 'whiteAlpha.300' : 'whiteAlpha.600',
          boxShadow: state.isFocused ? '0 0 5px blackAlpha.400' : 'none',
          backgroundColor: 'blackAlpha.300',
          color: 'whiteAlpha.800',
        }),
        menuList: (provided) => ({
          ...provided,
          color: 'white', // Change the font color to red
          boxShadow: 'blackAlpha.400',
          backgroundColor: 'transparent',
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: '#1d1d1d',
          boxShadow: 'blackAlpha.400',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'white',
        }),
        input: (provided) => ({
          ...provided,
          color: 'white',
        }),
        option: (styles, state) => ({
          ...styles,
          backgroundColor: state.isDisabled
            ? undefined
            : state.isSelected
            ? '#44337A'
            : state.isFocused
            ? '#553C9A'
            : undefined,
        }),
      }}
    />
  )
}

export default ChakraReactSelect
