import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Flex,
  Button,
} from '@chakra-ui/react'

export interface IPaymentSuccessPromptProps {
  isOpen: boolean
  onSuccess: () => void
}

const PaymentPendingPrompt: React.FC<IPaymentSuccessPromptProps> = ({
  isOpen,
  onSuccess,
}) => (
  <Modal
    onClose={() => {}}
    isOpen={isOpen}
    scrollBehavior="outside"
    size={'lg'}
    isCentered
  >
    <ModalOverlay />
    <ModalContent
      bgColor="#272727"
      color="white"
      border="none"
      borderRadius="4px"
    >
      <ModalHeader textAlign="center">Subscription payment</ModalHeader>
      <ModalBody>
        <Center
          pb={8}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          An email has been sent to your inbox to validate your bank account
          payment. <br />
          Please verify, and wait for your information to be processed so you
          can login into FITGMR.
        </Center>
        <Flex justifyContent="center" pb={4}>
          <Button color="white" colorScheme="brand" onClick={onSuccess}>
            Continue
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  </Modal>
)
export default PaymentPendingPrompt
