import React, { PropsWithChildren, useState, useEffect } from 'react'
import * as Yup from 'yup'

import { Form, Formik, Field, FieldProps } from 'formik'

import {
  Box,
  Button,
  Flex,
  Spacer,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  Text,
  Select,
  Switch,
  Icon,
} from '@chakra-ui/react'

import { AiOutlineDelete } from 'react-icons/ai'

import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import generateSlug from '../helpers/content.slug'

import {
  VideoQuestionType,
  VideoQuestionSubType,
  VideoQuestionCrudInput,
} from '../store/content.types'
import { selectIsLoading } from '../store/content.selectors'

export interface PopUpQuestionInterface {
  id?: string
  type: VideoQuestionType
  text: string
  subType?: VideoQuestionSubType
  defineWatched?: boolean
  isCreateOrDeleteQuestion?: boolean
}

const initialQuestionConfig: { [key: string]: PopUpQuestionInterface } = {
  INT: {
    type: VideoQuestionType.INT,
    text: 'How many repetitions did you do?',
    subType: VideoQuestionSubType.REPETITIONS,
  },
  BOOLEAN: {
    type: VideoQuestionType.BOOLEAN,
    text: 'Do you feel comfortable with this movement?',
    defineWatched: false,
  },
  STRING: {
    type: VideoQuestionType.STRING,
    text: 'My Notes',
  },
}

interface VideoPopupQuestionFormComponentProps extends PropsWithChildren<any> {
  initialData?: PopUpQuestionInterface
  handleDelete: (id: string) => void
  onSubmit: (data: any) => void
  videoId?: string
}

const metadataFormSchema = Yup.object().shape({
  type: Yup.string(),
  text: Yup.string(),
  subType: Yup.string(),
  defineWatched: Yup.bool(),
  isCreateOrDeleteQuestion: Yup.bool(),
})

const VideoPopupQuestionForm: React.FC<VideoPopupQuestionFormComponentProps> =
  ({ videoId, initialData, handleDelete, onSubmit }) => {
    const {
      params: { id },
    } = useRouteMatch<{ id: string }>()

    const loading = useSelector(selectIsLoading)

    const [data, setData] = useState(
      initialData ?? initialQuestionConfig[VideoQuestionType.INT]
    )

    const [isEditing, setIsEditing] = useState<boolean>(!initialData?.id)

    useEffect(() => {
      setIsEditing(!initialData?.id)
    }, [initialData])

    const handleDataChange = (val: any) => {
      const selected = val.target.value
      const newData = initialQuestionConfig[selected]
      newData.type = selected
      setData(newData)
    }

    const handleUnitChange = (val: any) => {
      const selected = val.target.value
      const newData = { ...data }
      newData.subType = selected
      newData.text =
        selected === VideoQuestionSubType.REPETITIONS
          ? 'How many repetitions did you do?'
          : selected === VideoQuestionSubType.ROUNDS
          ? 'How many rounds did you do?'
          : "What was your time in today's workout"
      setData(newData)
    }

    const handletextChange = (val: any) => {
      const selected: string = val.target.value
      const newData = { ...data }
      newData.text = selected
      setData(newData)
    }

    function gettext(text: string) {
      switch (text) {
        case VideoQuestionType.INT:
          return 'Score'
        case VideoQuestionType.BOOLEAN:
          return 'Binary'
        case VideoQuestionType.STRING:
          return 'Text'
        default:
          return ''
      }
    }

    const handleSubmit = () => {
      const payload: VideoQuestionCrudInput = {
        text: data.text,
        type: data.type,
        slug: generateSlug(`${id || videoId} ${data.type} ${data.text}`),
      }
      if (data.subType) {
        payload.subType = data.subType
      }
      if (data.defineWatched) {
        payload.defineWatched = data.defineWatched
      }
      payload.isCreateOrDeleteQuestion = true

      onSubmit({ VideoQuestion: payload })
    }

    return (
      <Flex direction="column" height="100%" textAlign="start">
        <Flex width="100%" justify="space-between">
          <Text
            color="white"
            fontWeight="bold"
            fontSize="1.15rem"
            marginBottom="1.15rem"
          >
            {initialData?.id
              ? isEditing
                ? `Update This Question`
                : ''
              : `Add A Question`}
          </Text>
          {initialData?.id && (
            <Box>
              <>
                <Icon
                  onClick={() => handleDelete(initialData.id || '')}
                  cursor="pointer"
                  boxSize="1.25rem"
                  as={AiOutlineDelete}
                />
              </>
            </Box>
          )}
        </Flex>
        <Formik
          validationSchema={metadataFormSchema}
          onSubmit={handleSubmit}
          initialValues={{
            type: '',
            text: data?.text,
            subType: data?.subType,
            defineWatched: data.defineWatched,
          }}
        >
          {() => (
            <Form>
              <Flex direction="column" height="100%">
                <Field name="type">
                  {({ field, form }: any) => (
                    <FormControl
                      marginBottom="40px"
                      isDisabled={loading || !isEditing}
                      isInvalid={form.errors.type && form.touched.type}
                    >
                      <FormLabel>Type</FormLabel>
                      <InputGroup>
                        <Select
                          {...field}
                          id="type"
                          value={data.type}
                          onChange={handleDataChange}
                        >
                          {Object.values(VideoQuestionType).map((i) => (
                            <option value={i} key={i}>
                              {gettext(i)}
                            </option>
                          ))}
                        </Select>
                      </InputGroup>
                      <FormErrorMessage>{form.errors.type}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Spacer />
                {data?.subType && (
                  <>
                    <Spacer />
                    <Field name="subType">
                      {({ field, form }: FieldProps) => (
                        <FormControl
                          marginBottom="40px"
                          isDisabled={loading || !isEditing}
                          isInvalid={Boolean(
                            form.errors.subType && form.touched.subType
                          )}
                        >
                          <FormLabel>Units</FormLabel>
                          <InputGroup>
                            <Select
                              {...field}
                              id="subType"
                              value={data.subType}
                              onChange={handleUnitChange}
                            >
                              {Object.values(VideoQuestionSubType).map((i) => (
                                <option value={i} key={i}>
                                  {i.charAt(0).toUpperCase() +
                                    i.substr(1).toLowerCase()}
                                </option>
                              ))}
                            </Select>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.subType}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </>
                )}
                <Spacer />
                <Field name="text">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      marginBottom="40px"
                      isDisabled={loading || !isEditing}
                      isInvalid={Boolean(form.errors.text && form.touched.text)}
                    >
                      <FormLabel>Display Text</FormLabel>
                      <InputGroup>
                        <Input
                          {...field}
                          id="text"
                          placeholder="Display Text"
                          value={data.text}
                          onChange={handletextChange}
                        />
                      </InputGroup>
                      <FormErrorMessage>{form.errors.text}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Spacer />
                {data?.type === VideoQuestionType.BOOLEAN && (
                  <Flex
                    width="100%"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    marginBottom={10}
                  >
                    <FormControl
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <FormLabel margin="0" htmlFor="defineWatched">
                        Mark this video as watched on Positive response?
                      </FormLabel>
                      <Switch
                        disabled={loading}
                        marginLeft={5}
                        id="defineWatched"
                        isChecked={data.defineWatched}
                        isDisabled={loading || !isEditing}
                        colorScheme="fitgmrPurple"
                        onChange={(e) => {
                          setData({
                            ...data,
                            defineWatched: e.target.checked,
                          })
                        }}
                      />
                    </FormControl>
                  </Flex>
                )}
                <Spacer />
                {isEditing && (
                  <Button
                    loadingText="Please wait"
                    type="submit"
                    isFullWidth
                    color="white"
                    colorScheme="fitgmrPurple"
                    isLoading={loading}
                  >
                    Save
                  </Button>
                )}
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    )
  }
export default VideoPopupQuestionForm
