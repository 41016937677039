export interface ConfigInterface {
  env: string
  url: string
  socketURL: string
  stripePublishableKey: string
  amplitudeApiKey?: string
  discordOauthUrl?: string
  discordRedirectUrl?: string
}

interface Envs {
  [key: string]: ConfigInterface
}

const envs: Envs = {
  local: {
    env: 'local',
    url: 'http://localhost:4000/',
    socketURL: 'ws://localhost:4000/',
    discordOauthUrl:
      'https://discord.com/api/oauth2/authorize?client_id=824614621423468544&redirect_uri=http%3A%2F%2Flocalhost%3A3000&response_type=code&scope=identify',
    discordRedirectUrl: 'http://localhost:3000',
    stripePublishableKey:
      'pk_test_51K6KZYD3u3eeH8G4DfBPvrQGflnE4T3bYtNU3A6kGuhGw6iYe67z5OzGgHdfuQla1bm0f2bad6iIxySp6TRyAA1E008PAGojDE',
  },
  dev: {
    env: 'dev',
    url: 'https://fitgmr-api-dev-sdflkje.cleverbuild.biz/',
    socketURL: 'wss://fitgmr-api-dev-sdflkje.cleverbuild.biz/',
    discordOauthUrl:
      'https://discord.com/api/oauth2/authorize?client_id=824614621423468544&redirect_uri=https%3A%2F%2Ffitgmr-dev-sdflkje.cleverbuild.biz%2Fauth%2Flogin%2F&response_type=code&scope=identify%20email',
    discordRedirectUrl:
      'https://fitgmr-dev-sdflkje.cleverbuild.biz/auth/login/',
    stripePublishableKey:
      'pk_test_51K6KZYD3u3eeH8G4DfBPvrQGflnE4T3bYtNU3A6kGuhGw6iYe67z5OzGgHdfuQla1bm0f2bad6iIxySp6TRyAA1E008PAGojDE',
  },
  qa: {
    env: 'qa',
    url: 'https://fitgmr-api-qa-sdflkje.cleverbuild.biz/',
    socketURL: 'wss://fitgmr-api-qa-sdflkje.cleverbuild.biz',
    discordOauthUrl:
      'https://discord.com/api/oauth2/authorize?client_id=834460307127533629&redirect_uri=https%3A%2F%2Ffitgmr-qa-sdflkje.cleverbuild.biz%2Fauth%2Flogin%2F&response_type=code&scope=identify%20email',
    discordRedirectUrl: 'https://fitgmr-qa-sdflkje.cleverbuild.biz/auth/login/',
    stripePublishableKey:
      'pk_test_51K6Ka5DVKecRA7eewT1S8jtJceDeQQzih1vZHCYGJp6HZSACfua16mAvsdPAZvc4El6XBGUFFbEuDqiENxIDNtGf00vw33Eq7s',
  },
  staging: {
    env: 'staging',
    url: 'https://fitgmr-api-staging-sdflkje.cleverbuild.biz/',
    socketURL: 'wss://fitgmr-api-staging-sdflkje.cleverbuild.biz/',
    amplitudeApiKey: '05ea27cf07ab4ecb6d80e92ddfa1479c',
    discordOauthUrl:
      'https://discord.com/api/oauth2/authorize?client_id=834460307127533629&redirect_uri=https%3A%2F%2Ffitgmr-staging-sdflkje.cleverbuild.biz%2Fauth%2Flogin%2F&response_type=code&scope=identify%20email',
    discordRedirectUrl:
      'https://fitgmr-staging-sdflkje.cleverbuild.biz/auth/login/',
    stripePublishableKey:
      'pk_test_51K1xBTLy0aYixfPQoOiWDmZrDzqfKzlamocQIURNYurdXCJLX0U5dZzcaOLRGbcG7wXltem72vV6NlsxXGleCjiE00AJlciQJC',
  },
  production: {
    env: 'production',
    url: 'https://api.fitgmr.gg/',
    socketURL: 'wss://api.fitgmr.gg/',
    amplitudeApiKey: 'bcefad6eec7f020305328a7cdb24e7c8',
    discordOauthUrl:
      'https://discord.com/api/oauth2/authorize?client_id=888195319773425705&redirect_uri=https%3A%2F%2Fadmin.fitgmr.gg%2Fauth%2Flogin%2F&response_type=code&scope=identify%20email',
    discordRedirectUrl: 'https://admin.fitgmr.gg/auth/login/',
    stripePublishableKey:
      'pk_live_51K1xBTLy0aYixfPQ0h8OYUJSV1JaF96qbMqnfdIPrmEUEfYpvsxn0ZVu5LjXiaWEDiJwuEOVShwElC3qfQFEiEOM00tBHgsdwK',
  },
}

export const config = envs[process.env.REACT_APP_ENV || 'dev']
console.log('Using config from', config)
