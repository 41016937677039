import React, { useCallback, useState } from 'react'
import {
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
} from '@chakra-ui/react'
import { DropEvent, useDropzone } from 'react-dropzone'
import { FaDownload, FaTimes } from 'react-icons/fa'

import './AvatarInput.scss'

const AvatarInput: React.FC<{
  name: string
  id: string
  label?: string
  disabled?: boolean
  value: string | undefined
  placeholder?: string
  onChange?: (file: File | null) => void
  error?: boolean
  helperText?: string
  className?: string
}> = ({ id, label, value, onChange, helperText, disabled, className }) => {
  const [previewImage, setPreviewImage] = useState<
    string | ArrayBuffer | null | undefined
  >()

  const onDrop = useCallback(
    (_acceptedFiles: any, _fileRejections: any, event: DropEvent) => {
      const changeEvent = (event as React.ChangeEvent<HTMLInputElement>)?.target
        ?.files
      const dragEvent = (event as React.DragEvent<HTMLElement>)?.dataTransfer
        ?.files

      const dropFileList = changeEvent || dragEvent
      if (dropFileList && dropFileList.length > 0) {
        const reader = new FileReader()
        reader.onload = (ev: ProgressEvent<FileReader>) => {
          if (ev?.target?.result) {
            setPreviewImage(ev.target.result)
            return
          }
          setPreviewImage(null)
        }
        reader.readAsDataURL(dropFileList[0])
        if (onChange) {
          onChange(dropFileList[0])
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  // Hooks
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop,
    multiple: false,
  })

  return (
    <FormControl id={id} className={`avatar-input ${className || ''}`}>
      <FormLabel color="white">{label}</FormLabel>
      {previewImage || value ? (
        <div
          className={`avatar-input__container ${disabled ? 'disabled' : ''}`}
          style={{
            backgroundImage: `url(${previewImage || value || ''})`,
          }}
        >
          <div
            role="presentation"
            className="avatar-input__container__reset"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setPreviewImage(null)
              if (onChange) {
                onChange(null)
              }
            }}
          >
            <Icon
              as={FaTimes}
              h={12}
              w={12}
              className="avatar-input__container__reset__icon"
            />
          </div>
        </div>
      ) : (
        <div
          className="avatar-input__file-drop-zone"
          {...getRootProps({ id: 'files' })}
        >
          <input
            name={'files'}
            {...getInputProps({
              id: 'files',
            })}
            capture
            disabled={disabled}
          />
          {isDragActive ? (
            <div className="avatar-input__file-drop-zone__drop-comment">
              Drop the files here...
            </div>
          ) : (
            <Flex
              className="avatar-input__file-drop-zone__drop-comment"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <IconButton
                className="avatar-input__file-drop-zone__drop-comment__icon"
                aria-label="drop-icon"
                icon={<FaDownload />}
              />
              <div>Drop your Team Avatar Here</div>
            </Flex>
          )}
        </div>
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default AvatarInput
