import React from 'react'
import { ContainerProps as ChakraContainerProps } from '@chakra-ui/react'
import ScreenContainer from './container'
import useScreen from './hooks/useScreen'

interface ContainerProps extends ChakraContainerProps {
  children: React.ReactNode
  level?: number
  onScreenExited?: Function
}

const Container: React.FC<ContainerProps> = ({ children, level, ...props }) => {
  const {
    zIndex, // UI stack index
    exit, // Exit handler function.
  } = useScreen(level)

  return (
    <ScreenContainer {...props} zIndex={zIndex}>
      {typeof children === 'function'
        ? children({ level, exit, zIndex })
        : children}
    </ScreenContainer>
  )
}

export default Container
