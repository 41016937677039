import { gql } from '@apollo/client'

const createWeeklyChallenge = gql`
  mutation WeeklyChallenge($data: WeeklyChallengeCreateInput!) {
    createWeeklyChallenge(data: $data) {
      id
      description
      week
      year
    }
  }
`

const updateWeeklyChallenge = gql`
  mutation UpdateWeeklyChallenge(
    $data: WeeklyChallengeUpdateInput!
    $where: WeeklyChallengeWhereUniqueInput!
  ) {
    updateWeeklyChallenge(data: $data, where: $where) {
      id
      description
      week
      year
    }
  }
`

const deleteWeeklyChallenge = gql`
  mutation DeleteWeeklyChallenge($where: WeeklyChallengeWhereUniqueInput!) {
    deleteWeeklyChallenge(where: $where) {
      id
      description
      week
      year
    }
  }
`

export const WEEKLY_CHALLENGE_MUTATIONS = {
  CREATE_WEEKLY_CHALLENGE: createWeeklyChallenge,
  UPDATE_WEEKLY_CHALLENGE: updateWeeklyChallenge,
  DELETE_WEEKLY_CHALLENGE: deleteWeeklyChallenge,
}
