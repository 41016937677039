import React, { useContext } from 'react'
import { Icon } from '@chakra-ui/react'
import { RiMenuLine } from 'react-icons/ri'
import './Sidebar.scss'
import {
  SidebarContext,
  ISidebarProvider,
} from '../../../../context/SidebarContext/Context'

type Props = {}

export const MobileBar: React.FC<Props> = () => {
  const { isMobileSidebarOpen, setIsMobileSidebarOpen } = useContext(
    SidebarContext
  ) as ISidebarProvider

  const handleToogleSidebar = () => {
    setIsMobileSidebarOpen(true)
  }
  return (
    <div className={`mobile-bar ${isMobileSidebarOpen && 'sidebar-open'}`}>
      <Icon className="sidebar-footer-item" onClick={handleToogleSidebar}>
        <RiMenuLine />
      </Icon>
    </div>
  )
}
