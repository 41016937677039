import React from 'react'

const CreateTeamComponent = React.lazy(() => import('./create-team.component'))

export default {
  to: '/home/my-team/new',
  component: CreateTeamComponent,
  exact: true,
  isAdmin: false,
}
