import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  FormErrorMessage,
  Textarea,
  VStack,
  Icon,
  Flex,
  Switch,
  HStack,
  Container,
  AspectRatio,
  Image,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import Dropzone from 'react-dropzone'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'

import { Form, Formik, Field } from 'formik'

import {
  CONTENT,
  InstructorSeriesCreateOrUpdateInterface,
  VideoSeriesCoverImageCreateInterace,
  VideoSeriesInterface,
} from '../../../content/store/content.types'

import {
  selectError,
  selectStagingSeries,
  selectUpdateSeries,
} from '../../../content/store/content.selectors'
import {
  setActionFailure,
  setActionRequest,
  setActionSuccess,
} from '../../../content/store/content.actions'

interface UseModalInterface {
  onSubmit: (data: any, action: 'CREATE' | 'UPDATE' | 'UPLOAD') => void
  isSubmitting?: boolean
  selectedSeries: VideoSeriesInterface
}

const sectionOneSchema = Yup.object().shape({
  name: Yup.string().required('A name is required.').nullable(),
  description: Yup.string()
    .max(1000, 'Description should be less than 300 characters.')
    .nullable(),
})

const sectionTwoSchema = Yup.object().shape({
  id: Yup.string().nullable(),
})

const sectionThreeSchema = Yup.object().shape({
  basic: Yup.bool().required('An id is required.').nullable(),
})

const useSeriesModal = ({
  onSubmit,
  isSubmitting,
  selectedSeries,
}: UseModalInterface) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const dispatch = useDispatch()

  const stagingSeries = useSelector(selectStagingSeries)
  const updatedSeries = useSelector(selectUpdateSeries)
  const error: string = useSelector(selectError)

  const [coverImage, setCoverImage] = useState<File | null>(null)

  const [videoSeries, setVideoSeries] =
    useState<VideoSeriesInterface>(selectedSeries)
  const [step, setStep] = useState<number>(1)

  const [nameError, setNameError] = useState<string>('')

  const handleSubmit = (data: any) => {
    if (step === 1 && !videoSeries.id) {
      onSubmit(data, 'CREATE')
    } else if (step === 1 && videoSeries.id) {
      const updateData: InstructorSeriesCreateOrUpdateInterface = {
        data: {
          name: { set: data.name },
          description: { set: data.description },
        },
        where: {
          id: videoSeries!.id,
        },
        stepNumber: 1,
      }

      onSubmit(updateData, 'UPDATE')
    } else if (step === 2) {
      const uploadData: VideoSeriesCoverImageCreateInterace = {
        where: {
          id: videoSeries!.id,
        },
        file: coverImage!,
      }
      dispatch(
        setActionRequest(CONTENT.SERIES_COVER_IMAGE_UPLOAD_REQUEST, uploadData)
      )
    } else if (step === 3) {
      const updateData: InstructorSeriesCreateOrUpdateInterface = {
        data: {
          basic: {
            set: data.basic,
          },
        },
        where: {
          id: videoSeries!.id,
        },
        stepNumber: 3,
      }

      onSubmit(updateData, 'UPDATE')
    }
  }

  useEffect(() => {
    if (error.includes('There is already a series with that name'))
      setNameError('There is already a series with that name')
  }, [error])

  useEffect(() => {
    setVideoSeries(selectedSeries)
  }, [selectedSeries.id, stagingSeries?.id])

  const beforeOpen = (
    startingStep: number | null = null,
    baseSeries: VideoSeriesInterface | null = null
  ) => {
    if (baseSeries) {
      setVideoSeries(baseSeries)
    }

    if (startingStep) {
      setStep(startingStep)
    }
  }

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    if (stagingSeries?.id) {
      setStep(2)
      setVideoSeries(stagingSeries)
    }
    if (stagingSeries?.coverImage) {
      setStep(3)
      setVideoSeries(stagingSeries)
    }
    if (stagingSeries?.isComplete) {
      setVideoSeries(stagingSeries)
      dispatch(
        setActionSuccess(CONTENT.SERIES_CREATE_STAGING_REQUEST, {
          stagingSeries: null,
          updatedSeries: null,
        })
      )
      dispatch(setActionFailure(CONTENT.SERIES_CREATE_FAILED, { message: '' }))
      setCoverImage(null)
      handleClose()
    }
  }, [stagingSeries?.id, stagingSeries?.coverImage, stagingSeries?.isComplete])

  useEffect(() => {
    if (updatedSeries?.id) {
      setStep(2)
      setVideoSeries(updatedSeries)
    }
    if (updatedSeries?.coverPhotoUpdated) {
      setStep(3)
      setVideoSeries(updatedSeries)
    }
    if (updatedSeries?.isComplete) {
      setVideoSeries(updatedSeries)
      dispatch(
        setActionSuccess(CONTENT.SERIES_CREATE_STAGING_REQUEST, {
          stagingSeries: null,
          updatedSeries: null,
        })
      )
      dispatch(setActionFailure(CONTENT.SERIES_CREATE_FAILED, { message: '' }))
      setCoverImage(null)
      handleClose()
    }
  }, [
    updatedSeries?.id,
    updatedSeries?.coverPhotoUpdated,
    updatedSeries?.isComplete,
  ])

  const handleDrop = (files: File[]) => {
    setCoverImage(files[0])
  }

  const AddModal: React.FC = () => (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handleClose}
      motionPreset="slideInBottom"
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        bgColor="#353535"
        color="white"
        border="1px"
        borderColor="whiteAlpha.300"
        borderRadius="1px"
      >
        {step === 1 && (
          <>
            <ModalHeader>
              {videoSeries.id ? 'Update this series' : 'Add a series'}
            </ModalHeader>
            <ModalCloseButton />
            <Formik
              validationSchema={sectionOneSchema}
              onSubmit={handleSubmit}
              initialValues={{
                name: videoSeries.name || '',
                description: videoSeries.description || '',
              }}
            >
              {() => (
                <Form>
                  <ModalBody>
                    <Field name="name">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            (form.touched.name && form.errors.name) ||
                            error.includes(
                              'There is already a series with that name'
                            )
                          }
                          marginBottom="40px"
                          isDisabled={isSubmitting}
                        >
                          <FormLabel>Name</FormLabel>
                          <InputGroup>
                            <Input
                              {...field}
                              id="name"
                              placeholder="Add a name"
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.name || nameError}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="description">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.touched.description && form.errors.description
                          }
                          marginBottom="40px"
                          isDisabled={isSubmitting}
                        >
                          <FormLabel>Description</FormLabel>
                          <InputGroup>
                            <Textarea
                              {...field}
                              id="description"
                              placeholder="Description"
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.description}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      mr={3}
                      onClick={() => handleClose()}
                      color="white"
                      colorScheme="fitgmrRed"
                    >
                      Cancel
                    </Button>
                    <Button
                      mr={3}
                      color="white"
                      colorScheme="brand"
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {videoSeries.id ? 'Update' : 'Create'}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </>
        )}

        {step === 2 && (
          <>
            <ModalHeader>
              {videoSeries.id
                ? `Update the cover image for ${videoSeries.name}`
                : 'Add a cover image'}
            </ModalHeader>
            <Formik
              validationSchema={sectionTwoSchema}
              onSubmit={handleSubmit}
              initialValues={{
                id: videoSeries.id || '',
              }}
            >
              {() => (
                <Form>
                  <ModalBody>
                    <Field name="id">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.touched.id && form.errors.id}
                          marginBottom="40px"
                          isDisabled={isSubmitting}
                        >
                          <InputGroup>
                            <Input {...field} id="id" type="hidden" />
                          </InputGroup>
                        </FormControl>
                      )}
                    </Field>

                    <Dropzone onDrop={handleDrop} accept="image/*">
                      {({ isDragActive, getInputProps, getRootProps }) => (
                        <Container
                          className={`upload-container ${
                            isDragActive ? '--active' : ''
                          } ${coverImage ? '--complete' : ''}`}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <VStack>
                            {videoSeries.coverImage ? (
                              <AspectRatio ratio={16 / 9} width="350px">
                                <Image src={videoSeries.coverImage} />
                              </AspectRatio>
                            ) : (
                              <Icon boxSize="8em" as={AiOutlineCloudUpload} />
                            )}
                            {coverImage ? (
                              <p>
                                Click next to upload:{' '}
                                <span>{coverImage.name}</span>
                              </p>
                            ) : isDragActive ? (
                              <>
                                <p>Drop your file here to upload</p>
                              </>
                            ) : (
                              <>
                                <p>
                                  Drag and drop your file, or click to select
                                  files
                                </p>
                              </>
                            )}
                          </VStack>
                        </Container>
                      )}
                    </Dropzone>
                  </ModalBody>

                  <ModalFooter>
                    {selectedSeries.id && (
                      <Button
                        mr={3}
                        onClick={() => setStep(3)}
                        color="white"
                        colorScheme="fitgmrBlue"
                      >
                        Skip
                      </Button>
                    )}
                    <Button
                      mr={3}
                      color="white"
                      colorScheme="brand"
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      Upload
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </>
        )}

        {step === 3 && (
          <>
            <ModalHeader>Set the series rules</ModalHeader>
            <Formik
              validationSchema={sectionThreeSchema}
              onSubmit={handleSubmit}
              initialValues={{
                basic: videoSeries.basic || false,
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  <ModalBody>
                    <VStack marginTop={5} minHeight="200px" width="100%">
                      <HStack width="100%">
                        <Field name="basic">
                          {() => (
                            <Flex
                              width="50%"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                            >
                              <FormControl
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                              >
                                <FormLabel margin="0" htmlFor="basic">
                                  Getting Started
                                </FormLabel>
                                <Switch
                                  disabled={isSubmitting}
                                  marginLeft={5}
                                  id="basic"
                                  isDisabled={isSubmitting}
                                  defaultChecked={videoSeries.basic}
                                  colorScheme="fitgmrPurple"
                                  onChange={(e) => {
                                    setFieldValue('basic', e.target.checked)
                                  }}
                                />
                              </FormControl>
                            </Flex>
                          )}
                        </Field>
                      </HStack>
                    </VStack>
                  </ModalBody>

                  <ModalFooter>
                    {selectedSeries.id && (
                      <Button
                        mr={3}
                        onClick={() => handleClose()}
                        color="white"
                        colorScheme="fitgmrRed"
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      mr={3}
                      color="white"
                      colorScheme="brand"
                      type="submit"
                      isLoading={Boolean(selectedSeries.id && isSubmitting)}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </>
        )}
      </ModalContent>
    </Modal>
  )

  return {
    onOpen,
    AddModal,
    onClose,
    beforeOpen,
  }
}

export default useSeriesModal
