import { createSelector } from 'reselect'

import { UserState } from './users.types'
import { RootState } from '../../../types'

export const selectUserState = (state: RootState): UserState => state.user

export const selectUserList = createSelector(
  selectUserState,
  (state: UserState) => state.userList
)

export const selectUserListCount = createSelector(
  selectUserState,
  (state: UserState) => state.totalUsers
)

export const selectUserError = createSelector(
  selectUserState,
  (state: UserState) => (state.error ? state.error.message : '')
)

export const selectIsLoading = createSelector(
  selectUserState,
  (state: UserState) => state.isLoading
)

export const selectSelectedUser = createSelector(
  selectUserState,
  (state: UserState) => state.selectedUser
)
