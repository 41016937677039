import React from 'react'

const UserStatisticsComponent = React.lazy(
  () => import('./user-statistics.component')
)

export default {
  to: '/home/my-team/statistics/:id',
  component: UserStatisticsComponent,
  exact: true,
  isAdmin: false,
}
