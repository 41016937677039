import { Reference } from '@apollo/client'

import { SortOrder } from '../../../types'
import client from '../../../gql/client'
import {
  UserCrudPayload,
  UserFetchRequest,
  UserListMetadataRequest,
  UserListRequest,
  UserRoleUpdatePayload,
} from '../store/users.types'

import { USER_MUTATIONS } from '../mutations/users.mutations'
import { USER_QUERIES } from '../queries/users.queries'

const userFetchService = (data: UserFetchRequest): Promise<any> =>
  client.query({
    query: USER_QUERIES.GET_USER,
    variables: data,
  })

const userListService = (data: UserListRequest): Promise<any> =>
  client.query({
    query: USER_QUERIES.GET_ALL_USERS,
    variables: { ...data, orderBy: [{ createdAt: SortOrder.desc }] },
  })

const userListMetadataService = (data: UserListMetadataRequest): Promise<any> =>
  client.query({
    query: USER_QUERIES.GET_ALL_AGGREGATE_USERS,
    variables: data,
  })

const userUpdateService = (data: UserCrudPayload): Promise<any> =>
  client.mutate({
    mutation: USER_MUTATIONS.UPDATE_USER,
    variables: data,
  })

const userChangeRoleService = (data: UserRoleUpdatePayload): Promise<any> =>
  client.mutate({
    mutation: USER_MUTATIONS.UPDATE_USER_ROLE,
    variables: data,
  })

const userDeleteService = (data: UserCrudPayload): Promise<any> =>
  client.mutate({
    mutation: USER_MUTATIONS.DELETE_USER,
    variables: data,
    update(cache, { data: { deleteUser } }) {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          users(ref: Reference[]) {
            const reference = cache.identify(deleteUser)
            return ref.filter((refs) => refs.__ref !== reference)
          },
        },
      })
    },
  })

const userDisableService = (data: UserCrudPayload): Promise<any> =>
  client.mutate({
    mutation: USER_MUTATIONS.DISABLE_USER,
    variables: data,
  })

const userEnableService = (data: UserCrudPayload): Promise<any> =>
  client.mutate({
    mutation: USER_MUTATIONS.ENABLE_USER,
    variables: data,
  })

const USER_SERVICE = {
  USER_FETCH_SERVICE: userFetchService,
  USER_LIST_SERVICE: userListService,
  USER_LIST_METADATA_SERVICE: userListMetadataService,
  USER_UPDATE_SERVICE: userUpdateService,
  USER_CHANGE_ROLE_SERVICE: userChangeRoleService,
  USER_DELETE_SERVICE: userDeleteService,
  USER_DISABLE_SERVICE: userDisableService,
  USER_ENABLE_SERVICE: userEnableService,
}

export default USER_SERVICE
