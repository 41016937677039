import React, { useEffect, createContext, useState } from 'react'
import TEAM_SERVICE from '../../components/coaches/services/coaches.services'
import { TeamInterface } from '../../components/coaches/store/coaches.types'

export type ITeamProvider = {
  selectedTeam: TeamInterface | null
  isTeamLoading: boolean
  setSelectedTeam: React.Dispatch<React.SetStateAction<TeamInterface | null>>
  handleSelectTeam: (team: TeamInterface | undefined) => void
}

export const TeamContext = createContext<ITeamProvider | null>(null)

export function TeamProvider({ children }: any) {
  const [selectedTeam, setSelectedTeam] = useState<TeamInterface | null>()
  const [isTeamLoading, setIsTeamLoading] = useState<boolean>(true)

  const handleGetSelectedTeam = async () => {
    try {
      const { data } = await TEAM_SERVICE.GET_SELECTED_TEAM()
      setIsTeamLoading(false)
      if (data) setSelectedTeam(data.getSelectedTeam.Team)
    } catch (err) {
      setIsTeamLoading(false)
    }
  }

  const handleSelectTeam = async (team: TeamInterface) => {
    setSelectedTeam(team)
    try {
      await TEAM_SERVICE.SET_SELECTED_TEAM({ teamId: team.id })
    } catch (err) {
      // handling error
    }
  }

  useEffect(() => {
    handleGetSelectedTeam()
  }, [])

  const value: any = {
    selectedTeam,
    isTeamLoading,
    setSelectedTeam,
    handleSelectTeam,
  }
  return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>
}
