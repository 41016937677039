import { ApolloError, useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { LazyQueryHookOptions } from '@apollo/client/react/types/types'
import {
  ACTIVITY_QUERIES,
  scoreStatsMethod,
  teamScoreStatsMethod,
} from '../queries/activity.queries'
import {
  IUserScoreStatistics,
  IUserScoresStatsResponse,
  IScoreStatsResponse,
  IScoreStatsDataResponse,
} from '../types'
import {
  getCurrentMoment,
  getCurrentDateFormat,
} from '../../../helpers/userDate'
import {
  fitgmrMainColor,
  pillarColors,
  pillarOrder,
  FitGmrPillars,
} from '../../../helpers/constants'

const pillarSlugToLabel = (
  pillarSlug: FitGmrPillars | string | undefined
): string => {
  switch (pillarSlug) {
    case 'fitgmr':
      return 'FitGMR Score'
    case 'daily':
      return 'Daily Score'
    case 'game-performance':
      return 'Game Performance Score'
    case 'game-readiness':
      return 'Game Readiness Score'
    case FitGmrPillars.sleep:
      return 'Sleep'
    case FitGmrPillars.nutrition:
      return 'Nutrition'
    case FitGmrPillars.mental:
      return 'Mental'
    case FitGmrPillars.lifestyle:
      return 'Lifestyle'
    case FitGmrPillars.physical:
      return 'Physical'
    default:
      break
  }
  return ''
}

const pillarSlugToColor = (
  pillarSlug: FitGmrPillars | string | undefined
): string => {
  switch (pillarSlug) {
    case 'fitgmr':
    case 'daily':
      return fitgmrMainColor
    default:
      break
  }
  if (pillarSlug && pillarSlug in FitGmrPillars) {
    return pillarColors[pillarSlug as FitGmrPillars]
  }
  return fitgmrMainColor
}

const sortPerPillarScoreStatistics = (
  statsResponses: IScoreStatsResponse[] | undefined
): IScoreStatsResponse[] | undefined => {
  if (statsResponses == null) {
    return statsResponses
  }

  const reordered: IScoreStatsResponse[] = []
  pillarOrder.forEach((pillarSlug) => {
    const stats = statsResponses.find((stat) => stat.pillarSlug === pillarSlug)
    if (stats != null) {
      reordered.push(stats)
    }
  })
  return reordered
}
const convertScoreStatsResponseToObj = (
  statsResponse: IScoreStatsResponse | undefined,
  statsType?: string
) => ({
  label: pillarSlugToLabel(
    statsType != null ? statsType : statsResponse?.pillarSlug
  ),
  color: pillarSlugToColor(statsResponse?.pillarSlug),
  data: statsResponse
    ? statsResponse.points.map((point) => ({
        dateStr: point.date,
        score: Math.round(point.value),
      }))
    : [],
})

const calAveragePoint =
  (scoreData: IScoreStatsDataResponse[]) =>
  (point: IScoreStatsDataResponse, pointIndex: number) => {
    const pointValueToSum = scoreData[pointIndex].value || 0
    return {
      ...point,
      value: point.value + pointValueToSum,
    }
  }

const convertArrayScoreStatsResponseToAVGObj = (
  statsArrayResponse: IUserScoresStatsResponse[]
): IUserScoresStatsResponse => {
  let totalMembers = 1
  const tempStatsResponse: IUserScoresStatsResponse = statsArrayResponse.length
    ? statsArrayResponse.reduce(
        (acum: IUserScoresStatsResponse, statsResponse) => {
          totalMembers++
          const mainScoreStatisticPoints = acum.mainScoreStatistics!.points.map(
            calAveragePoint(statsResponse.mainScoreStatistics!.points)
          )

          const { perPillarScoreStatistics } = acum
          const avgPerPillarScoreStatistics = perPillarScoreStatistics?.map(
            (perPillarStat, pillarIndex) => {
              const points = perPillarStat.points.map(
                calAveragePoint(
                  statsResponse.perPillarScoreStatistics![pillarIndex].points
                )
              )
              return { ...perPillarStat, points }
            }
          )
          return {
            ...statsResponse,
            mainScoreStatistics: {
              ...statsResponse.mainScoreStatistics!,
              points: mainScoreStatisticPoints,
            },
            perPillarScoreStatistics: avgPerPillarScoreStatistics,
          }
        }
      )
    : {
        type: '',
        count: 0,
        mainScoreStatistics: { pillarSlug: '', points: [] },
        perPillarScoreStatistics: [],
      }

  return {
    ...tempStatsResponse,
    mainScoreStatistics: {
      ...tempStatsResponse.mainScoreStatistics!,
      points: tempStatsResponse.mainScoreStatistics!.points.map((point) => ({
        ...point,
        value: point.value / totalMembers,
      })),
    },
    perPillarScoreStatistics: [
      ...tempStatsResponse.perPillarScoreStatistics!.map(
        (perPillarScoreStat) => ({
          ...perPillarScoreStat,
          points: perPillarScoreStat.points.map((point) => ({
            ...point,
            value: point.value / totalMembers,
          })),
        })
      ),
    ],
  }
}

const convertArrayScoreStatsResponseToSumObj = (
  statsArrayResponse: IUserScoresStatsResponse[]
): IUserScoresStatsResponse =>
  statsArrayResponse.reduce((acum: IUserScoresStatsResponse, statsResponse) => {
    const points = [...acum.mainScoreStatistics!.points]
    if (statsResponse.mainScoreStatistics) {
      statsResponse.mainScoreStatistics.points.forEach((point) => {
        if (acum.mainScoreStatistics && acum.mainScoreStatistics.points) {
          points.push(point)
        }
      })
    }

    return {
      ...acum,
      mainScoreStatistics: {
        ...acum.mainScoreStatistics!,
        points,
      },
      perPillarScoreStatistics: undefined,
    }
  })

const useActivityScoreStats = () => {
  const [error, setError] = useState<ApolloError>()
  const [userScoresStats, setUserScoresStats] = useState<IUserScoreStatistics>()
  const [teamScoresStats, setTeamScoresStats] = useState<IUserScoreStatistics>()

  const options: LazyQueryHookOptions = {
    fetchPolicy: 'network-only',
  }

  const [refetchScoreStatistics, { data, loading, error: queryError }] =
    useLazyQuery(ACTIVITY_QUERIES.SCORE_STATS, options)

  const [
    fetchTeamScoreStatistics,
    { data: teamData, loading: teamLoading, error: teamQueryError },
  ] = useLazyQuery(ACTIVITY_QUERIES.TEAM_SCORE_STATS, options)

  useEffect(() => {
    if (data) {
      const responseObj = data[scoreStatsMethod] as IUserScoresStatsResponse
      const userScoreSt: IUserScoreStatistics = {
        mainScoreStatistics: convertScoreStatsResponseToObj(
          responseObj.mainScoreStatistics,
          responseObj.type
        ),
        extraScoresStatistics: sortPerPillarScoreStatistics(
          responseObj.perPillarScoreStatistics
        )?.map((statsResponse) =>
          convertScoreStatsResponseToObj(statsResponse)
        ),
      }

      setUserScoresStats(userScoreSt)
    }
  }, [data])

  useEffect(() => {
    if (teamData) {
      const responseObj = teamData[
        teamScoreStatsMethod
      ] as IUserScoresStatsResponse[]

      const newResponseObj = responseObj.find(
        (r) => r.type === 'game-readiness' || r.type === 'game-performance'
      )
        ? convertArrayScoreStatsResponseToSumObj(responseObj)
        : convertArrayScoreStatsResponseToAVGObj(responseObj)

      const teamScoreSt: IUserScoreStatistics = {
        mainScoreStatistics: convertScoreStatsResponseToObj(
          newResponseObj.mainScoreStatistics,
          newResponseObj.type
        ),
        extraScoresStatistics: sortPerPillarScoreStatistics(
          newResponseObj.perPillarScoreStatistics
        )?.map((statsResponse) =>
          convertScoreStatsResponseToObj(statsResponse)
        ),
      }

      setTeamScoresStats(teamScoreSt)
    }
  }, [teamData])

  useEffect(() => {
    if (queryError) {
      setError(queryError)
    }
  }, [queryError])

  useEffect(() => {
    if (teamQueryError) {
      setError(teamQueryError)
    }
  }, [teamQueryError])

  const loadScoreStatistics = (statsType: string, userId: string) => {
    const currentMoment = getCurrentMoment()
    const substractCount = 0
    const startDateMoment = currentMoment
      .clone()
      .subtract(6 + substractCount, 'days')
    const endDateMoment = currentMoment.clone().subtract(substractCount, 'days')
    const dateFormatStr = getCurrentDateFormat()

    const startDateStr = startDateMoment.format(dateFormatStr)
    const endDateStr = endDateMoment.format(dateFormatStr)

    refetchScoreStatistics({
      variables: {
        type: statsType,
        startDate: startDateStr,
        endDate: endDateStr,
        userId,
      },
    })
  }

  const loadTeamScoreStatistics = (
    statsType: string,
    lastXDays?: number,
    teamId?: string
  ) => {
    const currentMoment = getCurrentMoment()
    const initialSubtractCount =
      typeof lastXDays === 'undefined' ? 6 : lastXDays
    const substractCount = 0
    const startDateMoment = currentMoment
      .clone()
      .subtract(initialSubtractCount + substractCount, 'days')
    const endDateMoment = currentMoment.clone().subtract(substractCount, 'days')
    const dateFormatStr = getCurrentDateFormat()

    const startDateStr = startDateMoment.format(dateFormatStr)
    const endDateStr = endDateMoment.format(dateFormatStr)
    fetchTeamScoreStatistics({
      variables: {
        type: statsType,
        startDate: startDateStr,
        endDate: endDateStr,
        teamId,
      },
    })
  }
  return {
    loadScoreStatistics,
    userScoresStats,
    loading,
    error,
    loadTeamScoreStatistics,
    teamScoresStats,
    teamLoading,
  }
}

export default useActivityScoreStats
