import React, { useMemo } from 'react'
import {
  Box,
  Table as ChakraTable,
  Flex,
  Center,
  CircularProgress,
} from '@chakra-ui/react'

import Body from './Body'
import Header from './Header'
import Pagination from './Pagination'

import './Table.scss'

const Table: React.FC<{
  className?: string
  headers: any[]
  items: { id: string; [key: string]: any }[]
  selected?: string[]
  handleSelect?: (val: string[]) => void
  handleSelectOnce?: (val: string[]) => void
  selectable: boolean
  selectableClick?: boolean
  noAnimate?: boolean
  noShufle?: boolean
  selectOnce?: boolean
  hover?: boolean
  showId: boolean
  withPagination?: boolean
  page?: number
  pageCount?: number
  canPreviousPage?: boolean
  canNextPage?: boolean
  setPage?: (val: number) => void
  rowsPerPage?: number
  setPageSize?: (val: number) => void
  totalRows?: number
  bg?: string
  color?: string
  emptyMessage?: React.ReactNode
  onRowClick?: (val: any) => void
  onColumnClick?: (val: any, val2: any) => void
  sortKeys?: string[]
  sortBy?: (sortKey: any | null, sortDirection: number) => void
  currentSortState?: any
  setSelectedRow?: (val: any | null) => void
  selectedRow?: any | null
  isLoading?: boolean
  isExact?: boolean
  setSelectedItemOnce?: (val: any) => void
}> = ({
  className = '',
  headers = [],
  items = [],
  selected = [],
  handleSelect,
  selectable = false,
  selectableClick,
  showId = false,
  withPagination = false,
  page = 1,
  pageCount = 1,
  setPage,
  rowsPerPage = 10,
  setPageSize,
  canPreviousPage,
  canNextPage,
  bg = 'primary.card',
  color = 'white.500',
  emptyMessage,
  sortBy,
  sortKeys,
  onRowClick,
  onColumnClick,
  currentSortState,
  setSelectedRow,
  selectedRow,
  selectOnce,
  setSelectedItemOnce,
  isLoading,
}) => {
  const noContent = useMemo(() => {
    if (!items.length) {
      if (emptyMessage) {
        return emptyMessage
      }
      return (
        <Flex alignItems="center" justifyContent="center">
          {isLoading ? (
            <Center>
              <CircularProgress isIndeterminate color="#953bdc" py={24} />
            </Center>
          ) : (
            'No Items Found!'
          )}
        </Flex>
      )
    }
    return null
  }, [items, emptyMessage])

  return (
    <Box
      width="100%"
      bg={bg}
      color={color}
      rounded="lg"
      padding="5px 0"
      className={className}
    >
      <>
        <ChakraTable size="sm" variant="unstyled">
          <Header
            selectable={selectable}
            headers={headers}
            selected={selected}
            items={items}
            handleSelect={handleSelect}
            sortKeys={sortKeys}
            currentSortState={currentSortState}
            showId={showId}
            sortBy={sortBy}
          />
          <Body
            items={items}
            setSelectedItemOnce={setSelectedItemOnce}
            selectOnce={selectOnce}
            selectedRow={selectedRow}
            selectableClick={selectableClick}
            onRowClick={onRowClick}
            headers={headers}
            selected={selected}
            selectable={selectable}
            onColumnClick={onColumnClick}
            handleSelect={handleSelect}
            showId={showId}
            setSelectedRow={setSelectedRow}
          />
        </ChakraTable>
        {noContent}
      </>
      {withPagination && (
        <Pagination
          setPage={setPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          page={page}
          rowsPerPage={rowsPerPage}
          setPageSize={setPageSize}
        />
      )}
    </Box>
  )
}

export default Table
