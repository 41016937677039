import React from 'react'
import { AddIcon } from '@chakra-ui/icons'

const TeamsPerformance = React.lazy(
  () => import('../../../pages/TeamsPerformance/TeamsPerformance')
)

export default {
  to: '/home/teams-performance',
  component: TeamsPerformance,
  label: 'Teams Performance',
  isAdmin: true,
  icon: <AddIcon />,
  exact: true,
  level: 1,
  children: [],
}
