import React from 'react'
import { config } from '../../config'

const Signup: React.FC = () => {
  const isAndroidInAppBrowser = window.navigator.userAgent.match(/Android/i)

  if (isAndroidInAppBrowser) {
    window.open(`${config.url}api/v1/storeLink`, '_system', 'location=yes')
  } else {
    window.location.href = `${config.url}api/v1/storeLink`
  }

  return <></>
}

export default Signup
