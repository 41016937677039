import React, { createContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../components/auth/store/auth.selectors'
import { UserInterface } from '../../components/users/store/users.types'

export type ISidebarProvider = {
  isNavigationCollapsed: boolean
  isMobileSidebarOpen: boolean
  user: UserInterface | undefined
  setIsMobileSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsNavigationCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  closeMobileSidebar: () => void
}

export const SidebarContext = createContext<ISidebarProvider | null>(null)

export function SidebarProvider({ children }: any) {
  const [isNavigationCollapsed, setIsNavigationCollapsed] =
    useState<boolean>(true)
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState<boolean>(false)
  const user = useSelector(selectUser)

  const closeMobileSidebar = () => {
    setIsMobileSidebarOpen(false)
  }

  const value: any = {
    isNavigationCollapsed,
    isMobileSidebarOpen,
    user,
    setIsMobileSidebarOpen,
    setIsNavigationCollapsed,
    closeMobileSidebar,
  }
  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  )
}
