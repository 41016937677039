import React, { useState, useMemo, PropsWithChildren } from 'react'
import { Box, Button, Flex, Input, Text } from '@chakra-ui/react'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { useSelector } from 'react-redux'
import moment from 'moment'
import * as Yup from 'yup'

import { Form, Formik, Field, FieldProps } from 'formik'

import { selectIsLoading } from '../store/content.selectors'

import './VideoCalendar.scss'

interface VideoCalendarProps extends PropsWithChildren<any> {
  selectedDay: string
  onSubmit: (data: any) => void
}

const validationSchema = Yup.object().shape({
  availableFrom: Yup.string().nullable(),
  isScheduleInput: Yup.bool(),
})

const VideoCalendar: React.FC<VideoCalendarProps> = ({
  selectedDay,
  onSubmit,
}) => {
  const loading = useSelector(selectIsLoading)

  const [day, setDay] = useState<string>(selectedDay)

  const formattedSelectedDay = useMemo(() => {
    if (day) {
      return moment(day).toDate()
    }
    return new Date()
  }, [day])

  const onDayClick = (day: Date) => {
    const date = moment(day).toISOString()
    setDay(date)
  }

  const onTodayButtonClick = (day: Date) => {
    const date = moment(day).toISOString()
    setDay(date)
  }

  const handleSubmit = (data: any) => {
    const newData = { ...data }
    newData.availableFrom = day.substring(0, 10)
    onSubmit(newData)
  }

  return (
    <>
      <Flex maxWidth="540px" alignItems="center" marginLeft="auto">
        <Box width="100%">
          <Box
            height="100%"
            padding={10}
            background="#363636"
            border="1px solid #555"
            borderRadius="2px"
            minWidth="400px"
            overflowY="scroll"
          >
            <Text
              color="white"
              fontWeight="bold"
              fontSize="1.15rem"
              marginBottom="0.5rem"
              textAlign="start"
            >
              Schedule This Video
            </Text>
            <Text color="white" marginBottom="1.15rem" textAlign="start">
              The video will be available in the library from the selected day.
            </Text>
            <DayPicker
              selectedDays={formattedSelectedDay}
              todayButton="Today"
              showOutsideDays
              onDayClick={onDayClick}
              onTodayButtonClick={onTodayButtonClick}
            />

            <Formik
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              initialValues={{
                availableFrom: day,
                isScheduleInput: true,
              }}
            >
              <Form>
                <Field name="availableFrom">
                  {({ field }: FieldProps) => (
                    <Input
                      {...field}
                      id="availableFrom"
                      type="hidden"
                      value={day}
                    />
                  )}
                </Field>

                <Button
                  loadingText="Please wait"
                  type="submit"
                  isFullWidth
                  color="white"
                  colorScheme="fitgmrPurple"
                  isLoading={loading}
                  marginTop={10}
                >
                  Save
                </Button>
              </Form>
            </Formik>
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export default VideoCalendar
