import { gql } from '@apollo/client'

const getAllUsers = gql`
  query Users(
    $where: UserWhereInput
    $orderBy: [UserOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    users(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      usedDiscord
      email
      fullName
      username
      birthGender
      enabled
      role
      timeToBed
      timeOutOfBed
      preferMetricUnits
      waterIntakePreference
      weight
      healthyMeals
      hoursAtDesk
      sleepRoutines
      createdAt
      updatedAt
      coachSubscription
      subscription
      appSubscriptionReason
      subscription
    }
  }
`

const getAllAggregateUsers = gql`
  query AggregateUser($where: UserWhereInput) {
    aggregateUser(where: $where) {
      _count {
        id
      }
    }
  }
`

export const getUserMethod = 'user'
const getUser = gql`
  query User($where: UserWhereUniqueInput!) {
    ${getUserMethod}(where: $where) {
      id
      authId
      usedDiscord
      email
      fullName
      username
      birthGender
      enabled
      role
      country
      birthDate
      avatar
      timeToBed
      timeOutOfBed
      preferMetricUnits
      appSubscriptionReason
      waterIntakePreference
      weight
      healthyMeals
      hoursAtDesk
      sleepRoutines
      createdAt
      updatedAt
      subscription
      coachSubscription
    }
  }
`

export const getUserWithStatsMethod = 'user'
const getUserWithStats = gql`
  query User($where: UserWhereUniqueInput!) {
    ${getUserWithStatsMethod}(where: $where) {
      id
      authId
      usedDiscord
      email
      fullName
      username
      birthGender
      enabled
      role
      country
      birthDate
      avatar
      timeToBed
      timeOutOfBed
      preferMetricUnits
      waterIntakePreference
      subscription
      appSubscriptionReason
      weight
      healthyMeals
      hoursAtDesk
      sleepRoutines
      createdAt
      updatedAt
      coachSubscription
      score {
        score
        prevDayScore
        todayScore
        pillarsIsolatedScore {
          score
          pillarSlug
        }
        extraPillarsTodayScores {
          pillarSlug
          score
        }
      }
    }
  }
`

export const USER_QUERIES = {
  GET_ALL_USERS: getAllUsers,
  GET_ALL_AGGREGATE_USERS: getAllAggregateUsers,
  GET_USER: getUser,
  GET_USER_WITH_STATS: getUserWithStats,
}
