import { gql, Reference } from '@apollo/client'

import client from '../../../gql/client'
import {
  WeeklyChallengeCreateRequest,
  WeeklyChallengeDeleteRequest,
  WeeklyChallengeListRequest,
  WeeklyChallengeUpdateRequest,
} from '../store/challenge.types'

import { WEEKLY_CHALLENGE_MUTATIONS } from '../mutations/challenge.mutations'
import { WEEKLY_CHALLENGE_QUERIES } from '../queries/challenge.queries'

const weeklyChallengeCreateService = (
  data: WeeklyChallengeCreateRequest
): Promise<any> =>
  client.mutate({
    mutation: WEEKLY_CHALLENGE_MUTATIONS.CREATE_WEEKLY_CHALLENGE,
    variables: {
      data,
    },
    update(cache, { data: { createWeeklyChallenge } }) {
      cache.modify({
        fields: {
          weeklyChallenges(existingWeeklyChallenges = []) {
            const newWeeklyChallengeRef = cache.writeFragment({
              data: createWeeklyChallenge,
              fragment: gql`
                fragment NewWeeklyChallenge on WeeklyChallenges {
                  id
                  description
                  week
                  year
                }
              `,
            })
            return [...existingWeeklyChallenges, newWeeklyChallengeRef]
          },
        },
      })
    },
  })

const weeklyChallengeListService = (
  data: WeeklyChallengeListRequest
): Promise<any> =>
  client.query({
    query: WEEKLY_CHALLENGE_QUERIES.GET_WEEKLY_CHALLENGES,
    variables: data,
  })

const weeklyChallengeUpdateService = (
  data: WeeklyChallengeUpdateRequest
): Promise<any> =>
  client.mutate({
    mutation: WEEKLY_CHALLENGE_MUTATIONS.UPDATE_WEEKLY_CHALLENGE,
    variables: data,
  })

const weeklyChallengeDeleteService = (
  data: WeeklyChallengeDeleteRequest
): Promise<any> =>
  client.mutate({
    mutation: WEEKLY_CHALLENGE_MUTATIONS.DELETE_WEEKLY_CHALLENGE,
    variables: data,
    update(cache, { data: { deleteWeeklyChallenge } }) {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          weeklyChallenges(existingWeeklyChallengesRefs: Reference[]) {
            const reference = cache.identify(deleteWeeklyChallenge)
            return existingWeeklyChallengesRefs.filter(
              (refs) => refs.__ref !== reference
            )
          },
        },
      })
    },
  })

const WEEKLY_CHALLENGE_SERVICE = {
  WEEKLY_CHALLENGE_CREATE_SERVICE: weeklyChallengeCreateService,
  WEEKLY_CHALLENGE_LIST_SERVICE: weeklyChallengeListService,
  WEEKLY_CHALLENGE_UPDATE_SERVICE: weeklyChallengeUpdateService,
  WEEKLY_CHALLENGE_DELETE_SERVICE: weeklyChallengeDeleteService,
}

export default WEEKLY_CHALLENGE_SERVICE
