import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Center,
  CircularProgress,
} from '@chakra-ui/react'

export interface ILoadingModalProps {
  isOpen: boolean
}

const LoadingModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
}) => (
  <Modal
    onClose={() => {}}
    isOpen={isOpen}
    scrollBehavior="outside"
    size={'lg'}
    isCentered
  >
    <ModalOverlay />
    <ModalContent
      bgColor="#272727"
      color="white"
      border="none"
      borderRadius="4px"
    >
      <ModalBody>
        <Center>
          <CircularProgress isIndeterminate color="#953bdc" py={24} />
        </Center>
      </ModalBody>
    </ModalContent>
  </Modal>
)

export default LoadingModal
