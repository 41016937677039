import React, { ChangeEvent, FormEvent, useEffect } from 'react'
import {
  Button,
  VStack,
  HStack,
  ModalFooter,
  FormLabel,
  FormControl,
} from '@chakra-ui/react'
import TextInput from '../../../shared/ui/text-input/TextInput'
import AvatarInput from '../../../shared/ui/avatar-input/AvatarInput'
import useTeam from '../../../../hooks/useTeam'

import './team.form.scss'
import ChakraReactSelect from './chakra.react.select'

export interface TeamFormValues {
  id: string
  name: string
  avatar: string
  description: string
  game: string
}

const gameOptions = [
  { value: 'Apex Legends', label: 'Apex Legends' },
  { value: 'Arena of Valor', label: 'Arena of Valor' },
  { value: 'Dota 2', label: 'Dota 2' },
  { value: 'CS:GO', label: 'CS:GO' },
  { value: 'EAFC24', label: 'EAFC24' },
  { value: 'Fortnite', label: 'Fortnite' },
  { value: 'League of Legends', label: 'League of Legends' },
  { value: 'MLBB', label: 'MLBB' },
  { value: 'Overwatch', label: 'Overwatch' },
  { value: 'PUBG Mobile', label: 'PUBG Mobile' },
  { value: 'Rainbow Six: Siege', label: 'Rainbow Six: Siege' },
  { value: 'Rocket League', label: 'Rocket League' },
  { value: 'Super Smash Bros.', label: 'Super Smash Bros.' },
  { value: 'Team Fight Tactics', label: 'Team Fight Tactics' },
  { value: 'Valorant', label: 'Valorant' },
  { value: 'Other', label: 'Other' },
]

interface TeamFormProps {
  values: TeamFormValues
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  errors: TeamFormValues
  touched: {
    name: boolean
    description: boolean
    game: boolean
    avatar: boolean
  }
  isValid: boolean
  handleChange: (
    event: FormEvent<HTMLParagraphElement> | ChangeEvent<HTMLSelectElement>
  ) => void
  handleBlur: (event: FormEvent<HTMLParagraphElement>) => void
  handleReset: () => void
  dirty: boolean
  isSubmitting: boolean
  setFieldValue: (key: string, value: string) => void
  readonly?: boolean
  validateForm: () => void
  isEditing?: boolean
  handleCloseModal?: () => void
  isTeamCreating?: boolean
}

const TeamForm: React.FC<TeamFormProps> = ({
  values = {
    name: '',
    description: '',
    game: '',
    avatar: '',
    id: '',
  },
  errors,
  touched = {},
  isValid = false,
  isSubmitting = false,
  handleSubmit,
  handleChange,
  handleBlur,
  handleReset,
  setFieldValue,
  readonly = false,
  validateForm,
  isEditing = false,
  handleCloseModal,
  isTeamCreating = false,
}) => {
  const { name, description, avatar, id, game } = values
  const { uploadAvatarImage, uploading } = useTeam()

  useEffect(() => {
    validateForm()
  }, [validateForm])

  const selectChange = (event: any) => {
    setFieldValue('game', event?.value)
  }
  return (
    <form
      onSubmit={handleSubmit}
      onReset={handleReset}
      autoComplete="off"
      className="team-form"
    >
      <VStack justifyContent="flex-start">
        {readonly ? (
          <div
            className={`team-form__container ${readonly ? 'disabled' : ''}`}
            style={{
              backgroundImage: `url(${avatar || ''})`,
            }}
          />
        ) : (
          <AvatarInput
            className="team-form__avatar_container"
            name="avatar"
            id="avatar"
            value={avatar}
            disabled={uploading || readonly}
            onChange={async (file: File | null) => {
              if (file) {
                const imageSrc = await uploadAvatarImage(file, id)
                if (imageSrc) {
                  setFieldValue('avatar', imageSrc)
                }
              } else {
                setFieldValue('avatar', '')
              }
            }}
            placeholder={avatar}
            error={Boolean(errors.avatar) && Boolean(touched.avatar)}
            helperText={
              Boolean(errors.avatar) && Boolean(touched.avatar)
                ? errors.avatar
                : ''
            }
          />
        )}

        <TextInput
          name="name"
          id="name"
          label="Team Name"
          disabled={readonly}
          value={name || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={name}
          error={Boolean(errors.name) && Boolean(touched.name)}
          helperText={
            Boolean(errors.name) && Boolean(touched.name) ? errors.name : ''
          }
        />
        <TextInput
          name="description"
          label="Description"
          id="description"
          disabled={readonly}
          value={description || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={description}
          error={Boolean(errors.description) && Boolean(touched.description)}
          helperText={
            Boolean(errors.description) && Boolean(touched.description)
              ? errors.description
              : ''
          }
        />
        <FormControl id="game">
          <FormLabel>Game</FormLabel>

          <ChakraReactSelect
            className="team-form__game-select"
            selectChange={selectChange}
            isDisabled={readonly}
            placeholder={game || 'Select a game'}
            options={gameOptions}
          />
        </FormControl>
      </VStack>
      {!readonly && (
        <ModalFooter
          maxW="300px"
          w="300px"
          margin="0 auto"
          paddingInlineStart={0}
          paddingInlineEnd={0}
          justifyContent="center"
          mt={2}
          mb={2}
        >
          <HStack w="100%" justifyContent="center">
            <Button
              lineHeight={1}
              color="white"
              type="button"
              className="outline"
              variant="outline"
              borderRadius="4px"
              onClick={handleCloseModal}
              isFullWidth
            >
              Cancel
            </Button>
            <Button
              lineHeight={1}
              type="submit"
              color="white"
              borderRadius="4px"
              variant="solid"
              isFullWidth
              bg="brand.400"
              _hover={{
                bg: 'brand.500',
              }}
              isLoading={uploading || isTeamCreating}
              disabled={isSubmitting || !isValid}
            >
              {isEditing ? 'Update Team' : 'Create Team'}
            </Button>
          </HStack>
        </ModalFooter>
      )}
    </form>
  )
}

export default TeamForm
