import React, { useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  useDisclosure,
} from '@chakra-ui/react'

interface ConfirmModalPropsInterface {
  body: React.ReactElement
  itemId?: string
  handleAction: (id?: string) => void
  handleClose: () => void
  shouldOpen: boolean
}

const ConfirmModal: React.FC<ConfirmModalPropsInterface> = ({
  body,
  handleAction,
  handleClose,
  shouldOpen,
  itemId,
}) => {
  const { onClose, onOpen, isOpen } = useDisclosure()

  useEffect(() => {
    if (shouldOpen) {
      onOpen()
    } else {
      onClose()
    }
  }, [shouldOpen])

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent
        bgColor="#353535"
        color="white"
        border="1px"
        borderColor="whiteAlpha.300"
        borderRadius="1px"
      >
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>

        <ModalFooter>
          <Button
            colorScheme="fitgmrBlue"
            mr={3}
            onClick={handleClose}
            color="white"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleAction(itemId)}
            colorScheme="fitgmrRed"
            color="white"
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmModal
