import { gql } from '@apollo/client'

const createVideo = gql`
  mutation createVideo($data: VideoCreateInput!) {
    createVideo(data: $data) {
      id
      title
      category
    }
  }
`

const uploadVideo = gql`
  mutation UploadVideo($where: VideoWhereUniqueInput!, $file: Upload!) {
    uploadVideo(where: $where, file: $file) {
      id
      title
      category
      workflowStatus
    }
  }
`

const deleteVideo = gql`
  mutation DeleteVideo($where: VideoWhereUniqueInput!) {
    deleteVideo(where: $where) {
      id
    }
  }
`

const updateVideo = gql`
  mutation updateVideo(
    $where: VideoWhereUniqueInput!
    $data: VideoUpdateInput!
  ) {
    updateVideo(where: $where, data: $data) {
      id
      updatedAt
      title
      category
      pillarCategory
      description
      difficulty
      tags
      enabled
      instructorId
      seriesId
      episode
      selectedThumbnail
      availableFrom
      autoComplete
      filename
      resolutionWidth
      duration
      resolutionHeight
      sourceUrl
      url
      thumbnailUrl
      workflowStatus
      VideoInstructor {
        id
        fullName
      }
      VideoSeries {
        name
        id
      }
      VideoQuestion {
        id
        text
        type
        subType
        defineWatched
      }
    }
  }
`

const enableVideo = gql`
  mutation EnableVideo($where: VideoWhereUniqueInput!) {
    enableVideo(where: $where) {
      id
      title
      category
      pillarCategory
      description
      difficulty
      tags
      enabled
      instructorId
      seriesId
      episode
      selectedThumbnail
      availableFrom
      autoComplete
      filename
      resolutionWidth
      duration
      resolutionHeight
      sourceUrl
      url
      thumbnailUrl
      workflowStatus
      VideoInstructor {
        id
        fullName
      }
      VideoSeries {
        name
        id
      }
      VideoQuestion {
        id
        text
        type
        subType
        defineWatched
      }
    }
  }
`

const disableVideo = gql`
  mutation DisableVideo($where: VideoWhereUniqueInput!) {
    disableVideo(where: $where) {
      id
      title
      category
      pillarCategory
      description
      difficulty
      tags
      enabled
      instructorId
      seriesId
      episode
      selectedThumbnail
      availableFrom
      autoComplete
      filename
      resolutionWidth
      duration
      resolutionHeight
      sourceUrl
      url
      thumbnailUrl
      workflowStatus
      VideoInstructor {
        id
        fullName
      }
      VideoSeries {
        name
        id
      }
      VideoQuestion {
        id
        text
        type
        subType
        defineWatched
      }
    }
  }
`

const createInstructor = gql`
  mutation CreateVideoInstructorMutation($data: VideoInstructorCreateInput!) {
    createVideoInstructor(data: $data) {
      id
      fullName
    }
  }
`

const deleteInstructor = gql`
  mutation DeleteVideoInstructorMutation(
    $where: VideoInstructorWhereUniqueInput!
  ) {
    deleteVideoInstructor(where: $where) {
      id
      fullName
    }
  }
`

const updateInstructor = gql`
  mutation UpdateVideoInstructorMutation(
    $data: VideoInstructorUpdateInput!
    $where: VideoInstructorWhereUniqueInput!
  ) {
    updateVideoInstructor(data: $data, where: $where) {
      id
      fullName
    }
  }
`

const createSeries = gql`
  mutation CreateVideoSeriesMutation($data: VideoSeriesCreateInput!) {
    createVideoSeries(data: $data) {
      id
      name
      description
      coverImage
      basic
    }
  }
`

const deleteSeries = gql`
  mutation DeleteVideoSeries($where: VideoSeriesWhereUniqueInput!) {
    deleteVideoSeries(where: $where) {
      id
      name
    }
  }
`

const updateSeries = gql`
  mutation UpdateVideoSeries(
    $data: VideoSeriesUpdateInput!
    $where: VideoSeriesWhereUniqueInput!
  ) {
    updateVideoSeries(data: $data, where: $where) {
      id
      name
      description
      coverImage
      basic
      skippable
      skipText
    }
  }
`

const uploadCoverImage = gql`
  mutation UploadVideoSeriesCoverImageMutation(
    $where: VideoSeriesWhereUniqueInput!
    $file: Upload!
  ) {
    uploadVideoSeriesCoverImage(where: $where, file: $file) {
      id
      name
      description
      coverImage
      basic
    }
  }
`

const uploadVideoThumbnail = gql`
  mutation UploadVideoThumbnail($where: VideoWhereUniqueInput!, $file: Upload) {
    uploadVideoThumbnail(where: $where, file: $file) {
      id
      title
      category
      pillarCategory
      description
      difficulty
      tags
      enabled
      instructorId
      seriesId
      episode
      selectedThumbnail
      availableFrom
      autoComplete
      filename
      resolutionWidth
      duration
      resolutionHeight
      sourceUrl
      url
      thumbnailUrl
      workflowStatus
      VideoInstructor {
        id
        fullName
      }
      VideoSeries {
        name
        id
      }
      VideoQuestion {
        id
        text
        type
        subType
        defineWatched
      }
    }
  }
`

const createVideoQuestion = gql`
  mutation CreateVideoQuestion($data: VideoQuestionCreateInput!) {
    createVideoQuestion(data: $data) {
      id
      text
      type
      subType
      defineWatched
    }
  }
`

const updateVideoQuestion = gql`
  mutation UpdateVideoQuestion(
    $data: VideoQuestionUpdateInput!
    $where: VideoQuestionWhereUniqueInput!
  ) {
    updateVideoQuestion(data: $data, where: $where) {
      id
      text
      type
      subType
      defineWatched
    }
  }
`

const deleteVideoQuestion = gql`
  mutation DeleteVideoQuestion($where: VideoQuestionWhereUniqueInput!) {
    deleteVideoQuestion(where: $where) {
      id
    }
  }
`

export const CONTENT_MUTATIONS = {
  CREATE_VIDEO: createVideo,
  UPLOAD_VIDEO: uploadVideo,
  UPDATE_VIDEO: updateVideo,
  DELETE_VIDEO: deleteVideo,
  ENABLE_VIDEO: enableVideo,
  DISABLE_VIDEO: disableVideo,
  CREATE_INSTRUCTOR: createInstructor,
  DELETE_INSTRUCTOR: deleteInstructor,
  UPDATE_INSTRUCTOR: updateInstructor,
  CREATE_SERIES: createSeries,
  DELETE_SERIES: deleteSeries,
  UPDATE_SERIES: updateSeries,
  UPLOAD_SERIES_COVER_IMAGE: uploadCoverImage,
  UPLOAD_VIDEO_THUMBNAIL: uploadVideoThumbnail,
  CREATE_VIDEO_QUESTION: createVideoQuestion,
  DELETE_VIDEO_QUESTION: deleteVideoQuestion,
  UPDATE_VIDEO_QUESTION: updateVideoQuestion,
}
