import React, { useContext } from 'react'
import { Box } from '@chakra-ui/react'

import { routing as HomeRouter } from '../../components/home/index'
import Page from '../../components/shared/ui/page/Page'
import { NewNavigation } from '../../components/shared/ui/navigation/NewNavigation'
import { SideBar } from '../../components/shared/ui/sideBar/SideBar'
import './index.scss'

import {
  ISidebarProvider,
  SidebarContext,
} from '../../context/SidebarContext/Context'
import { MobileBar } from '../../components/shared/ui/sideBar/MobileBar'

const Home: React.FC = () => {
  const { isNavigationCollapsed, isMobileSidebarOpen } = useContext(
    SidebarContext
  ) as ISidebarProvider

  return (
    <div className={`container ${isMobileSidebarOpen && 'sidebar-open'}`}>
      <MobileBar />
      <SideBar />
      <NewNavigation />
      <Page>
        <Box
          height="100vh"
          className={`contentContainer ${
            isNavigationCollapsed && 'navBarCollapsed'
          }`}
        >
          <HomeRouter />
        </Box>
      </Page>
    </div>
  )
}

export default Home
