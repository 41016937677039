import React from 'react'
import { AddIcon } from '@chakra-ui/icons'

const WeeklyChallenge = React.lazy(
  () => import('../../../pages/WeeklyChallenge/WeeklyChallenge')
)

export default {
  to: '/home/weekly-challenge',
  component: WeeklyChallenge,
  label: 'Weekly Challenge',
  icon: <AddIcon />,
  exact: false,
  isAdmin: true,
  level: 1,
  children: [],
}
