import React, { useEffect, useState } from 'react'
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons'
import { Thead, Tr, Th, Checkbox, useBoolean, Flex } from '@chakra-ui/react'
import { TableHeader } from './types'

type HeaderTypes = {
  selectable: boolean
  headers: TableHeader[]
  selected?: string[]
  items: any[]
  handleSelect?: (val: string[]) => void
  sortKeys?: string[]
  showId: boolean
  sortBy?: (sortKey: any | null, sortDirection: number) => void
  currentSortState: string
}

function Header({
  selectable,
  headers,
  selected,
  items,
  handleSelect,
  sortKeys,
  showId,
  sortBy,
  currentSortState,
}: HeaderTypes) {
  const [order, setOrder] = useBoolean()
  const [selectedSortKey, setSelectedSortKey] = useState<string | null>(
    currentSortState && Object.keys(currentSortState).length
      ? Object.keys(currentSortState)[0]
      : null
  )

  useEffect(() => {
    const order = currentSortState && Object.values(currentSortState)[0]
    if (currentSortState && Object.values(currentSortState)[0]) {
      if (order === 'desc') {
        setOrder.off()
      } else {
        setOrder.on()
      }
    }
  }, [currentSortState])
  const itemsIds: string[] = items?.map((item) => item.id)
  const rowActions = headers.find((header) => header.id === 'actions')

  const setCheckedItems = (isChecked: boolean) => {
    if (handleSelect) {
      handleSelect([])
      if (isChecked) {
        handleSelect(itemsIds)
      }
    }
  }

  const handleSort = (id: string, sortBy: any) => {
    setSelectedSortKey(id)
    if (sortBy) {
      if (selectedSortKey === id) {
        if (!order) {
          setSelectedSortKey(null)
          sortBy(null, 1)
        } else {
          setOrder.off()
          sortBy(id, -1)
        }
      } else {
        sortBy(id, 1)
        setOrder.on()
      }
    }
  }

  return (
    <Thead bgColor="#1C1C1D" borderBottom={'.5px solid rgb(110, 110, 110)'}>
      <Tr>
        {selectable && (
          <Th data-column="global-selector">
            <Checkbox
              isChecked={selected?.length === itemsIds.length}
              onChange={(e) => setCheckedItems(e.target.checked)}
            />
          </Th>
        )}
        {headers
          .filter((head) => {
            const withId = !showId ? head.id !== 'id' : true
            const withoutActions = head.id !== 'actions'
            return withId && withoutActions
          })
          .map((head) => (
            <Th
              color="#fff"
              fontSize="14px"
              key={head.id}
              data-column={head.id}
              textTransform="capitalize"
              fontWeight="bold"
              cursor="pointer"
              onClick={() => handleSort(head.id, sortBy)}
              position={head?.position ? 'sticky' : 'relative'}
              left={head?.position ? '1' : '0'}
              backgroundColor={head?.position ? '#1C1C1D' : 'transparent'}
              zIndex={head?.position ? 1 : 0}
            >
              <Flex alignItems="center" color={'#fff'}>
                {head.title}
                {sortKeys && sortBy && sortKeys.includes(head.id) && (
                  <Flex
                    h="17px"
                    alignItems="flex-start"
                    role="presentation"
                    cursor="pointer"
                    style={{ marginLeft: 5 }}
                  >
                    {selectedSortKey === head.id ? (
                      order ? (
                        <ArrowUpIcon
                          aria-label="sorted ascending"
                          color="#fff"
                        />
                      ) : (
                        <ArrowDownIcon
                          aria-label="sorted descending"
                          color="#fff"
                        />
                      )
                    ) : (
                      <ArrowUpIcon
                        aria-label="sorted last updated"
                        color="#a0a0a0"
                      />
                    )}
                  </Flex>
                )}
              </Flex>
            </Th>
          ))}
        {rowActions?.actions && <Th data-column="item-actions" />}
      </Tr>
    </Thead>
  )
}

export default React.memo(Header)
