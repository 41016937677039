import React, { useMemo, useRef, useState } from 'react'

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuOptionGroup,
  MenuDivider,
  IconButton,
  Box,
  Button,
} from '@chakra-ui/react'

import { FiFilter } from 'react-icons/fi'

import { BsTag } from 'react-icons/bs'
import useClickOutside from '../../../../hooks/useClickOutside/useClickOutside'

interface DropdownFilterProps {
  type: 'filter' | 'tags'
  filterOptions: any
  filterState: {}
  onSetFilters: (filters: any) => void
  handleLabel: (label: string) => string
}

const DropdownFilter: React.FC<DropdownFilterProps> = ({
  type = 'filter',
  filterOptions,
  filterState,
  onSetFilters,
  handleLabel,
}) => {
  const getIcon = useMemo(
    () => (type === 'filter' ? <FiFilter /> : <BsTag />),
    [type]
  )

  const menuRef = useRef(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [selectedFilters, setSelectedFilters] = useState<any>(filterState)

  const filters = type === 'filter' ? Object.keys(filterOptions) : filterOptions

  const handleSelectFilters = (items: any, name?: string) => {
    if (name) {
      const filters: any = {}
      filters[name] = items
      setSelectedFilters({
        ...selectedFilters,
        ...filters,
      })
    } else {
      setSelectedFilters(items)
    }
  }

  const applyFilters = () => {
    onSetFilters(selectedFilters)
    setIsOpen(false)
  }

  useClickOutside(menuRef, () => {
    if (isOpen) setIsOpen(false)
  })

  return (
    <>
      <Menu isOpen={isOpen} closeOnSelect={false}>
        <MenuButton
          onClick={() => setIsOpen((state) => !state)}
          borderRadius="4px"
          width="34px"
          height="34px"
          as={IconButton}
          aria-label="Options"
          icon={getIcon}
          _expanded={{ bg: 'white', color: 'black' }}
          _focus={{ outlineColor: 'transparent' }}
        />
        <MenuList
          ref={menuRef}
          borderRadius="4px"
          bgColor="#353535"
          color="white"
          width="125px"
          zIndex={100}
        >
          {type === 'filter' ? (
            filters.length &&
            filters.map((item: string, key: number) => (
              <Box key={`filterList-${item}`}>
                <MenuItem>
                  <Menu
                    matchWidth
                    closeOnSelect={false}
                    placement="right-start"
                    gutter={20}
                  >
                    <MenuButton
                      justifyItems="start"
                      width="100%"
                      as={Box}
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="20px"
                      textAlign="start"
                    >
                      {handleLabel(item)}
                    </MenuButton>
                    <MenuList
                      borderRadius="4px"
                      bgColor="#353535"
                      color="white"
                      width="125px"
                      zIndex={100}
                    >
                      <Box maxHeight="250px" overflowY="scroll">
                        <MenuOptionGroup
                          type="checkbox"
                          onChange={(e) => handleSelectFilters(e, item)}
                        >
                          {filterOptions[item].map(
                            (i: string | { id: string; name: string }) => {
                              let value: string
                              let label: string

                              if (typeof i === 'string') {
                                value = i
                                label = i
                              } else {
                                value = i.id
                                label = i.name
                              }

                              return (
                                <MenuItemOption
                                  fontWeight="500"
                                  fontSize="14px"
                                  lineHeight="20px"
                                  value={value}
                                  key={`filterOption-${value}`}
                                >
                                  {handleLabel(label)}
                                </MenuItemOption>
                              )
                            }
                          )}
                        </MenuOptionGroup>
                      </Box>
                      <Box padding="5px 10px">
                        <Button
                          onClick={applyFilters}
                          borderRadius="6px"
                          fontSize="14px"
                          bgColor="white"
                          size="sm"
                          color="black"
                          _hover={{ bg: '#dedddd' }}
                        >
                          Apply Filters
                        </Button>
                      </Box>
                    </MenuList>
                  </Menu>
                </MenuItem>
                {key === filters.length - 1 ? null : <MenuDivider />}
              </Box>
            ))
          ) : (
            <>
              <Box maxHeight="250px" overflowY="scroll">
                <MenuOptionGroup
                  type="checkbox"
                  onChange={(e) => handleSelectFilters(e)}
                >
                  {filterOptions.map((i: string) => (
                    <MenuItemOption
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="20px"
                      value={i}
                      key={`filter-${i}`}
                    >
                      {handleLabel(i)}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </Box>
              <Box padding="5px 10px">
                <Button
                  onClick={applyFilters}
                  borderRadius="6px"
                  fontSize="14px"
                  bgColor="white"
                  size="sm"
                  color="black"
                  _hover={{ bg: '#dedddd' }}
                >
                  Apply Filters
                </Button>
              </Box>
            </>
          )}
        </MenuList>
      </Menu>
    </>
  )
}

export default DropdownFilter
