import { createSelector } from 'reselect'

import { TeamState } from './coaches.types'
import { RootState } from '../../../types'
import { RoleEnum } from '../../users/store/users.types'

export const selectTeamState = (state: RootState): TeamState => state.team

export const selectTeamError = createSelector(
  selectTeamState,
  (state: TeamState) => (state.error ? state.error.message : '')
)

export const selectIsLoading = createSelector(
  selectTeamState,
  (state: TeamState) => state.isLoading
)

export const selectTeam = createSelector(
  selectTeamState,
  (state: TeamState) => state.team
)

export const selectHasTeam = createSelector(selectTeamState, (state) => {
  let hasTeam = false
  const team = state.team?.users
  if (team && team?.length && team.some((i) => i.role === RoleEnum.GAMER))
    hasTeam = true
  return hasTeam
})
