import {
  ApolloClient,
  InMemoryCache,
  from,
  DefaultOptions,
} from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { onError } from '@apollo/client/link/error'

import { config } from '../config'

if (config.env !== 'production') {
  console.info(`Application currently running in ${config.env} mode`)
}

const uploadLink = createUploadLink({ uri: config.url })

const errorLink = (expirationCallback?: () => void) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )

        if (message.includes('jwt expired')) {
          console.warn('[GraphQL] Auth Session Expired')
          if (expirationCallback) {
            setTimeout(() => {
              expirationCallback()
            }, 0)
          }
        }
      })
    }
    if (networkError) {
      console.warn(`[GraphQL Network error]: ${networkError}`)
    }
  })
const httpLink: any = uploadLink

export const defaultLink = (expirationCallback?: () => void) =>
  from([errorLink(expirationCallback), httpLink])

export const defaultSocketLink = (
  expirationCallback?: () => void,
  token?: string
) => {
  const socketLink = new WebSocketLink({
    uri: config.socketURL,
    options: {
      reconnect: true,
      minTimeout: 1000,
      inactivityTimeout: 1000,
      connectionParams: {
        Authorization: `Bearer ${token}`,
      },
    },
  })
  return from([errorLink(expirationCallback), socketLink])
}

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  link: defaultLink(),
  cache: new InMemoryCache(),
  defaultOptions,
})

export default client
