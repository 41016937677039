import React from 'react'
import { AddIcon } from '@chakra-ui/icons'

const VideoRecommendation = React.lazy(
  () => import('../../../pages/VideoRecommendation/VideoRecommendation')
)

export default {
  to: '/home/video-recommendation',
  component: VideoRecommendation,
  label: 'Recommendation',
  isAdmin: true,
  icon: <AddIcon />,
  exact: true,
  level: 1,
  children: [],
}
