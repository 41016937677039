import { gql } from '@apollo/client'

const updateUser = gql`
  mutation UpdateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(data: $data, where: $where) {
      id
      authId
      usedDiscord
      email
      fullName
      username
      birthGender
      enabled
      role
      country
      birthDate
      avatar
      createdAt
      updatedAt
    }
  }
`

const deleteUser = gql`
  mutation DeleteUser($email: String!) {
    deleteUser(email: $email) {
      count
      errors
    }
  }
`

const disableUser = gql`
  mutation DisableUser($email: String!) {
    disableUser(email: $email) {
      id
      authId
      usedDiscord
      email
      fullName
      username
      birthGender
      enabled
      country
      birthDate
      avatar
      createdAt
      updatedAt
    }
  }
`

const enableUser = gql`
  mutation EnableUser($email: String!) {
    enableUser(email: $email) {
      id
      authId
      usedDiscord
      email
      fullName
      username
      birthGender
      enabled
      country
      birthDate
      avatar
      createdAt
      updatedAt
    }
  }
`

const updateUserRole = gql`
  mutation Mutation($role: Role!, $email: String!) {
    updateUserRole(role: $role, email: $email) {
      id
      email
      updatedAt
      role
    }
  }
`

export const USER_MUTATIONS = {
  UPDATE_USER: updateUser,
  UPDATE_USER_ROLE: updateUserRole,
  DELETE_USER: deleteUser,
  DISABLE_USER: disableUser,
  ENABLE_USER: enableUser,
}
