import React from 'react'
import { Action } from 'redux'
import { AuthState } from './components/auth/store/auth.types'
import { RoleEnum, UserState } from './components/users/store/users.types'
import { WeeklyChallengeState } from './components/weekly-challenge/store/challenge.types'
import { VideoStateInterface } from './components/content/store/content.types'
import { TeamState } from './components/coaches/store/coaches.types'
import { PaymentState } from './components/payments/store/payments.types'

// Global types are defined here
export interface PayloadedAction<T = any> extends Action {
  payload?: T
}

export interface PayloadRequired<T = any> extends Action {
  payload: T
}

export interface RootState {
  auth: AuthState
  weeklyChallenge: WeeklyChallengeState
  user: UserState
  payment: PaymentState
  team: TeamState
  content: VideoStateInterface
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface PrivateRouteProps extends React.PropsWithChildren<any> {
  path: string
  isAuthenticated?: boolean
  isAdmin?: boolean
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export const RoleRule: Record<RoleEnum, RoleEnum[]> = {
  [RoleEnum.SELF]: [RoleEnum.SELF],
  [RoleEnum.GAMER]: [RoleEnum.GAMER],
  [RoleEnum.COACH]: [RoleEnum.GAMER],
  [RoleEnum.DIRECTOR]: [RoleEnum.GAMER, RoleEnum.COACH],
  [RoleEnum.ADMIN]: [RoleEnum.GAMER, RoleEnum.COACH, RoleEnum.DIRECTOR],
  [RoleEnum.SUPERADMIN]: [
    RoleEnum.GAMER,
    RoleEnum.COACH,
    RoleEnum.DIRECTOR,
    RoleEnum.ADMIN,
  ],
}
