import { Center } from '@chakra-ui/react'
import React from 'react'
import VideoStagingCreateForm from '../../../components/content/ui/VideoStagingCreateForm'

type Step1Props = {
  onSubmit: (video: any) => void
  isSubmitting: boolean
}

export default function Step1({ onSubmit, isSubmitting }: Step1Props) {
  return (
    <Center>
      <VideoStagingCreateForm onSubmit={onSubmit} isSubmitting={isSubmitting} />
    </Center>
  )
}
