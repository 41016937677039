export interface PriceDetails {
  monthly: string
  yearly: string
}

export interface PricingConfig {
  title: string
  key: string
  features?: Array<string>
  price: PriceDetails
  previousPrice?: PriceDetails
}

export const pricingConfig = [
  {
    title: 'FITGMR Performance',
    key: 'FITGMR Coach Performance',
    features: [
      'Includes 5 FITGMR gamer accounts, 1 Director account, and 1 Coach account',
      '$9.99/mo per additional FITGMR gamer account',
      'Ability to buy multiple dashboards',
      'Free Training Grounds membership for each gamer account',
      'Ideal for esports programs with 1-3 varsity teams',
    ],
    price: {
      monthly: '$39.99',
      yearly: '$399.99',
    } as PriceDetails,
  },
] as PricingConfig[]
