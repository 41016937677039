import React from 'react'
import { AddIcon } from '@chakra-ui/icons'
import EditFormRouting from '../edit/user-edit.routing'
import DeleteFormRouting from '../delete/user-delete.routing'

const Users = React.lazy(() => import('../../../pages/Users/Users'))

export default {
  to: '/home/users',
  component: Users,
  label: 'Users',
  isAdmin: true,
  icon: <AddIcon />,
  exact: false,
  level: 1,
  children: [EditFormRouting, DeleteFormRouting],
}
