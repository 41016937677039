import React, {
  useEffect,
  createContext,
  useState,
  useContext,
  useMemo,
} from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { useLazyQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  RoleEnum,
  UserInterface,
} from '../../components/users/store/users.types'
import { TEAM_QUERIES } from '../../components/coaches/queries/teams.queries'
import useTeam from '../../hooks/useTeam'
import { TeamContext } from '../TeamContext/Context'
import { selectUser } from '../../components/auth/store/auth.selectors'

export type IPaymentProvider = {
  isSubscriptionExpiredPromptOpen: boolean
  isRenewal: boolean
  isTeamsPaymentOpen: boolean
  isTeamDashboardModalOpen: boolean
  setIsSubscriptionExpiredPromptOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >
  setIsTeamsPaymentOpen: React.Dispatch<React.SetStateAction<boolean>>
  isTeamSeatAdded: boolean
  handleTeamSeatAdded: (val: boolean) => void
  teamPayment: (data: any) => void
  onCloseTeamDashboardModal: () => void
  handleCloseSubscriptionExpiredModal: () => void
  handleRenewSubscription: () => void
  handleClosePaymentdModal: () => void
  addTeamHandler: () => void
  openAddNewDashboard: (func?: () => void) => () => void
}

type Props = {
  children: React.ReactNode
}

export const PaymentContext = createContext<IPaymentProvider | null>(null)

export function PaymentProvider({ children }: Props) {
  const history = useHistory()
  const [isSubscriptionExpiredPromptOpen, setIsSubscriptionExpiredPromptOpen] =
    useState<boolean>(false)
  const [isRenewal, setIsRenewal] = useState<boolean>(false)
  const [isTeamsPaymentOpen, setIsTeamsPaymentOpen] = useState<boolean>(false)
  const [isTeamSeatAdded, setIsTeamSeatAdded] = useState<boolean>(false)
  const {
    isOpen: isTeamDashboardModalOpen,
    onOpen: onOpenTeamDashboardModal,
    onClose: onCloseTeamDashboardModal,
  } = useDisclosure()

  const { getTeam } = useTeam()
  const user: UserInterface | undefined = useSelector(selectUser)

  const { selectedTeam, setSelectedTeam } = useContext<any>(TeamContext)
  const isManagerPortal = useMemo(
    () => history.location.pathname === '/home/manager-portal',
    [history.location.pathname]
  )

  const isNoTeamPage = useMemo(
    () => history.location.pathname === '/home/no-team/',
    [history.location.pathname]
  )

  const [teamPayment] = useLazyQuery(TEAM_QUERIES.VERIFY_TEAM_SUBSCRIPTION, {
    onCompleted: (tData) => {
      if (tData?.verifyTeamSubscription?.status === 'inActive') {
        setIsSubscriptionExpiredPromptOpen(true)
      } else {
        getTeam(selectedTeam?.id)
        if (isManagerPortal || isNoTeamPage) {
          history.push(`/home/my-team/statistics/`)
        }
      }
    },
    onError: () => {},
  })

  const handleRenewSubscription = () => {
    setIsRenewal(true)
    setIsTeamsPaymentOpen(true)
  }

  const handleCloseSubscriptionExpiredModal = () => {
    setIsSubscriptionExpiredPromptOpen(false)
  }

  const handleClosePaymentdModal = () => {
    setIsTeamsPaymentOpen(false)
  }

  const addTeamHandler = () => {
    if (isManagerPortal) setSelectedTeam(null)
    onOpenTeamDashboardModal()
  }

  const openAddNewDashboard = (executeBefore?: () => void) => () => {
    if (executeBefore) executeBefore()
    setIsTeamsPaymentOpen(true)
  }

  const handleTeamSeatAdded = (val: boolean) => {
    setIsTeamSeatAdded(val)
  }

  useEffect(() => {
    // only check dashboard status if the user is a manager
    if (user?.role === RoleEnum.DIRECTOR) {
      teamPayment({
        variables: {
          teamId: selectedTeam?.id,
        },
      })
    }
  }, [selectedTeam?.id])

  const value: IPaymentProvider = {
    isSubscriptionExpiredPromptOpen,
    isRenewal,
    isTeamsPaymentOpen,
    isTeamDashboardModalOpen,
    setIsSubscriptionExpiredPromptOpen,
    setIsTeamsPaymentOpen,
    onCloseTeamDashboardModal,
    teamPayment,
    handleCloseSubscriptionExpiredModal,
    handleRenewSubscription,
    handleClosePaymentdModal,
    addTeamHandler,
    openAddNewDashboard,
    handleTeamSeatAdded,
    isTeamSeatAdded,
  }
  return (
    <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
  )
}
