import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setFetchTeamRequest,
  setUpdateTeamRequest,
} from '../components/coaches/store/coaches.actions'
import {
  TeamCreatePayload,
  TeamInterface,
  TeamUpdatePayload,
} from '../components/coaches/store/coaches.types'
import {
  selectIsLoading,
  selectTeam,
} from '../components/coaches/store/coaches.selectors'
import { TEAM_MUTATIONS } from '../components/coaches/mutations/teams.mutations'
import { TEAM_QUERIES } from '../components/coaches/queries/teams.queries'
import { selectUser } from '../components/auth/store/auth.selectors'
import { UserInterface } from '../components/users/store/users.types'

const useTeam = (
  fetchOnMount?: boolean,
  teamId?: string,
  createTeamSuccess?: () => void
) => {
  const user: UserInterface | undefined = useSelector(selectUser)

  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const [error, setError] = useState<ApolloError>()
  const [initialLoading, setInitialLoading] = useState(true)
  const loading = useSelector(selectIsLoading)
  const team: TeamInterface | null = useSelector(selectTeam)
  const getTeam = useCallback((id: string) => {
    dispatch(setFetchTeamRequest({ where: { id } }))
  }, [])

  const updateTeam = useCallback((data: TeamUpdatePayload) => {
    dispatch(setUpdateTeamRequest(data))
  }, [])

  const [refetchTeams, { data: managerTeams, loading: managerTeamsLoading }] =
    useLazyQuery(TEAM_QUERIES.GET_TEAMS_BY_OWNER, {
      fetchPolicy: 'cache-and-network',
      variables: {
        ownerId: user?.id,
      },
      onCompleted: () => {
        setInitialLoading(false)
      },
    })

  const [createTeamMutation, { loading: createTeamLoading, error: teamError }] =
    useMutation<{
      createTeam: {
        name: string
      }
    }>(TEAM_MUTATIONS.CREATE_TEAM, {
      onCompleted() {
        refetchTeams()
        if (createTeamSuccess) {
          createTeamSuccess()
        }
      },
      onError() {},
    })

  const [uploadAvatarMutation, { loading: uploading }] = useMutation<{
    uploadTeamAvatar: string
  }>(TEAM_MUTATIONS.UPLOAD_TEAM_AVATAR)

  const uploadAvatarImage = async (file: File, teamId: string) => {
    const { data } = await uploadAvatarMutation({
      variables: {
        file,
        teamId,
      },
    })
    const avatarUrl = data?.uploadTeamAvatar
    if (avatarUrl && avatarUrl.length > 0) {
      return avatarUrl
    }
    return ''
  }

  const createTeam = async (data: TeamCreatePayload) => {
    await createTeamMutation({
      variables: data,
    })
  }

  useEffect(() => {
    if (fetchOnMount && teamId) {
      getTeam(teamId)
    }
  }, [fetchOnMount, teamId, getTeam])

  return {
    getTeam,
    updateTeam,
    createTeam,
    uploadAvatarImage,
    team,
    loading,
    uploading,
    error,
    managerTeams: managerTeams?.teamsByOwner,
    managerTeamsLoading,
    refetchTeams,
    initialLoading,
    createTeamLoading,
    teamError,
  }
}

export default useTeam
