/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { SettingsIcon } from '@chakra-ui/icons'
import {
  HStack,
  Box,
  Tag,
  Stack,
  Button,
  Text,
  FormLabel,
} from '@chakra-ui/react'
import SeriesEditModal from '../../components/content/ui/SeriesEditModal'
import AddRecommendationModal from './add-recommendation.component'

const SearchAndFilter: React.FC<{
  refetch: () => void
}> = ({ refetch }) => {
  const [openSeriesEditModal, setOpenEditSeriesModal] = useState<boolean>(false)

  const handleCloseEditSeriesModal = () => {
    setOpenEditSeriesModal(false)
    refetch()
  }

  const handleManageSeriesModal = () => {
    setOpenEditSeriesModal(true)
  }

  return (
    <>
      <HStack>
        <HStack>
          <Stack>
            <Button
              mt="5"
              // leftIcon={<SettingsIcon />}
              variant="solid"
              colorScheme="fitgmrBlue"
              color="white"
              borderRadius="8px"
              p="0 5"
              fontSize="14px"
              onClick={handleManageSeriesModal}
            >
              <Text height="13" fontWeight="bold">
                Add Video Recommendation
              </Text>
            </Button>
          </Stack>
        </HStack>
      </HStack>

      <AddRecommendationModal
        open={openSeriesEditModal}
        closeModal={handleCloseEditSeriesModal}
      />
    </>
  )
}

export default SearchAndFilter
