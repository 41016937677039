import React, { useState } from 'react'
import { Center, Image, VStack, Container } from '@chakra-ui/react'

import { useMutation } from '@apollo/client'
import logo from '../../assets/images/logo.svg'

import { AUTH_MUTATIONS } from '../../components/auth/mutations/auth.mutations'
import CouponForm from '../../components/auth/ui/CouponForm'
import Page from '../../components/shared/ui/page/Page'

const Coupon: React.FC = () => {
  const [isDone, setIsDone] = useState(false)
  const [showError, setShowError] = useState(false)
  const [applyCoupon] = useMutation(AUTH_MUTATIONS.APPLY_COUPON, {
    onCompleted: () => {
      setIsDone(true)
      // TODO: Open app via Deep Linking
    },
    onError: () => {
      setShowError(true)
    },
  })
  const handleSubmit = ({
    email,
    coupon,
  }: {
    email: string
    coupon: string
  }) => {
    applyCoupon({ variables: { email, couponId: coupon } })
  }

  return (
    <Page>
      <Center h="100vh" color="GrayText" bg="#171717">
        <VStack>
          <Container centerContent pb={8}>
            <Image
              htmlWidth="300px"
              objectFit="contain"
              alt="FitGMR Logo"
              src={logo}
            />
          </Container>
          {isDone ? (
            <div>Coupon applied successfully!</div>
          ) : (
            <CouponForm onSubmit={handleSubmit} showError={showError} />
          )}
        </VStack>
      </Center>
    </Page>
  )
}

export default Coupon
