import { Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

import { TeamInterface } from '../store/coaches.types'
import CustomSelect from '../../shared/ui/select'
import TagInput from '../../shared/ui/tag-input/TagInput'

type FiltersType = {
  managedTeams?: TeamInterface[] | null
  setManagedTeams: React.Dispatch<React.SetStateAction<TeamInterface[] | null>>
  resetManagedTeams?: () => void
  allTeams: TeamInterface[]
  sortDirection?: boolean | null
  setSortDirection?: React.Dispatch<React.SetStateAction<boolean | null>>
}

export default function Filters({
  managedTeams,
  setManagedTeams,
  resetManagedTeams,
  allTeams,
  sortDirection,
  setSortDirection,
}: FiltersType) {
  const [sortedBy, setSortedBy] = useState<string>()
  const [tags, setTags] = useState<string[]>([])

  useEffect(() => {
    if (sortedBy) {
      const teams = [...allTeams]
      const sortedTeams = teams.sort((a: any, b: any) =>
        a[sortedBy] < b[sortedBy] ? 1 : -1
      )
      setManagedTeams(sortedTeams)
    }
  }, [sortedBy])

  const sortItemsByCategory = (sortedBy: string) => {
    const teams = [...allTeams]
    const sortedItems = teams.sort((a: any, b: any) =>
      sortDirection
        ? a[sortedBy] < b[sortedBy]
          ? 1
          : -1
        : a[sortedBy] > b[sortedBy]
        ? 1
        : -1
    )

    setManagedTeams(sortedItems)
  }

  const onSortChange = (val: string) => {
    if (setSortDirection) setSortDirection(!sortDirection)
    setSortedBy(val)
    sortItemsByCategory(val)
  }
  useEffect(() => {
    if (sortedBy) {
      sortItemsByCategory(sortedBy)
    }
  }, [sortDirection])

  const sortByOptions = [
    {
      label: 'Name',
      value: 'name',
    },
    {
      label: 'Av.FITGMR Score',
      value: 'fitgmrScore',
    },
    {
      label: 'Date Joined',
      value: 'dateJoined',
    },
    {
      label: 'No. of Members',
      value: 'noOfMembers',
    },
  ]

  const filterByOptions = allTeams?.reduce<any>(
    (acc, option: TeamInterface) => {
      if (!acc.includes(option.game)) {
        acc.push(option.game)
      }
      return acc
    },
    []
  )

  useEffect(() => {
    if (tags.length && managedTeams) {
      setManagedTeams(
        managedTeams?.filter((team: any) => tags.includes(team.game))
      )
    } else if (resetManagedTeams) resetManagedTeams()
  }, [tags.length])

  return (
    <Box className="coach-portal__filters-container">
      <Box style={{ width: '250px', marginRight: 20 }}>
        {tags.length > 1 ? (
          <TagInput
            onTagAdded={(values: string[]) => (values ? setTags(values) : null)}
            defaultTags={tags}
            tagOptions={filterByOptions}
            placeholder="Filter by game"
          />
        ) : (
          <TagInput
            onTagAdded={(values: string[]) => (values ? setTags(values) : null)}
            defaultTags={tags}
            tagOptions={filterByOptions}
            placeholder="Filter by game"
          />
        )}
      </Box>
      <Box style={{ width: '250px' }}>
        <CustomSelect
          options={sortByOptions}
          name="sortBy"
          onSelect={(val: string) => onSortChange(val)}
          defaultValue={sortedBy}
          placeholder="Sort by"
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      </Box>
    </Box>
  )
}
