import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Flex,
  Button,
} from '@chakra-ui/react'

export interface ISubscriptionExpiredPromptProps {
  isOpen: boolean
  onSuccess: () => void
  renew: () => void
}

const SubscriptionExpiredPrompt: React.FC<ISubscriptionExpiredPromptProps> = ({
  isOpen,
  onSuccess,
  renew,
}) => (
  <Modal
    onClose={() => {}}
    isOpen={isOpen}
    scrollBehavior="outside"
    size={'lg'}
    isCentered
  >
    <ModalOverlay />
    <ModalContent
      bgColor="#272727"
      color="white"
      border="none"
      borderRadius="4px"
    >
      <ModalHeader textAlign="center">Subscription expired</ModalHeader>
      <ModalBody>
        <Center pb={8}>
          Your subscription has expired. Please open billing to activate your
          subscription.
        </Center>
        <Flex justifyContent="center" pb={4}>
          <Button colorScheme="brand" color="white" onClick={renew}>
            Renew subscription
          </Button>
          <Button color="white" onClick={onSuccess} ml={4}>
            Close
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  </Modal>
)
export default SubscriptionExpiredPrompt
