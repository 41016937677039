import { gql } from '@apollo/client'

const createComment = gql`
  mutation CreateComment($data: IUserComment!) {
    createComment(data: $data) {
      id
      pillarSlug
      questionSlug
      date
      content
      createdAt
      updatedAt
      userId
      teamId
      user {
        fullName
        avatar
      }
    }
  }
`

const updateComment = gql`
  mutation updateComment($data: IUserComment!) {
    updateComment(data: $data) {
      id
    }
  }
`

const deleteComment = gql`
  mutation deleteComment($id: String!, $userId: String!) {
    deleteComment(id: $id, userId: $userId) {
      id
    }
  }
`
const clearTeamNotifications = gql`
  mutation clearTeamNotifications($userId: String!, $teamId: String!) {
    clearTeamNotifications(userId: $userId, teamId: $teamId)
  }
`

export const JOURNAL_MUTATIONS = {
  CREATE_COMMENT: createComment,
  UPDATE_COMMENT: updateComment,
  DELETE_COMMENT: deleteComment,
  CLEAR_TEAM_NOTIFICATIONS: clearTeamNotifications,
}
