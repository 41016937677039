import { PayloadedAction } from '../../../types'

// Constants
import { AuthState, AUTH } from './auth.types'

const initialState: AuthState = {
  isLoading: false,
  error: null,
  auth: {
    isAuthenticated: false,
    groups: [],
  },
  passwordReset: {
    requestSent: false,
    codeVerified: false,
    passwordReset: false,
    email: '',
    code: '',
  },
  redirectTo: '',
}

export default function AuthReducer(
  state: AuthState = initialState,
  { type, payload }: PayloadedAction
) {
  switch (type) {
    case AUTH.LOADING || AUTH.LOGIN_REQUEST || AUTH.DISCORD_LOGIN_REQUEST:
      return {
        ...state,
        ...payload,
      }
    case AUTH.DISCORD_LOGIN_SUCCESS:
    case AUTH.LOGIN_SUCCESS:
    case AUTH.SIGNUP_SUCCESS:
      return {
        ...state,
        auth: payload,
      }
    case AUTH.REFRESH_USER_TEAM:
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            ...state.auth.user,
            Team: payload,
          },
        },
      }
    case AUTH.REFRESH_USER:
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            ...state.auth.user,
            ...payload,
          },
        },
      }
    case AUTH.LOGOUT_SUCCESS:
      return initialState
    case AUTH.DISCORD_LOGIN_FAILED:
    case AUTH.LOGIN_FAILED:
    case AUTH.LOGOUT_FAILED:
    case AUTH.SIGNUP_FAILED:
      return {
        ...state,
        error: payload,
      }
    case AUTH.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          ...payload,
        },
      }
    case AUTH.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        error: payload,
      }
    case AUTH.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        auth: {
          ...state.auth,
          ...payload,
        },
      }
    case AUTH.REFRESH_TOKEN_FAILED:
      return {
        ...state,
        error: payload,
        auth: {
          isAuthenticated: payload.isAuthenticated,
        },
      }
    case AUTH.UPDATE_USER_GROUP:
      return {
        ...state,
        auth: {
          ...state.auth,
          groups: payload.groups,
          user: {
            ...state.auth.user,
            role: payload.role,
          },
        },
      }
    case AUTH.UPDATE_TEAM_SEATS:
      return {
        ...state,
        auth: {
          ...state.auth,
          v: state.auth?.v || 0 + 1,
          user: {
            ...state.auth.user,
            teamSeats: state.auth.user?.teamSeats + payload.teamSeats,
          },
        },
      }
    case AUTH.SET_REDIRECT_URL:
      return {
        ...state,
        redirectTo: payload.redirectTo,
      }
    default:
      return state
  }
}
