import { useState, useCallback } from 'react'

// eslint-disable-next-line no-undef
let debounce: NodeJS.Timeout
const useDebounceState = (
  value: string,
  delay: number
): [string, (val: string) => void] => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<string>(value)

  const setValue: (val: string) => void = useCallback(
    (_value: string): void => {
      if (debounce) {
        clearTimeout(debounce)
      }
      debounce = setTimeout(() => setDebouncedValue(_value), delay)
    },
    [delay]
  )

  return [debouncedValue, setValue]
}

export default useDebounceState
