import { PayloadedAction } from '../../../types'
import { WeeklyChallengeState, WEEKLY_CHALLENGE } from './challenge.types'
import { AUTH } from '../../auth/store/auth.types'

const initialState: WeeklyChallengeState = {
  isLoading: false,
  error: null,
  weeklyChallenge: [],
}

export default function WeeklyChallengeReducer(
  state: WeeklyChallengeState = initialState,
  { type, payload }: PayloadedAction
) {
  switch (type) {
    case WEEKLY_CHALLENGE.LOADING ||
      WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_REQUEST ||
      WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_LIST_REQUEST:
      return {
        ...state,
        ...payload,
      }
    case WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_SUCCESS:
      return {
        ...state,
        weeklyChallenge: [...state.weeklyChallenge, payload],
      }
    case WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_FAILED ||
      WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_LIST_FAILED ||
      WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_UPDATE_FAILED ||
      WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_DELETE_FAILED:
      return {
        ...state,
        error: payload,
      }
    case WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_UPDATE_FAILED:
      return {
        ...state,
        error: payload,
      }
    case WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_LIST_SUCCESS:
      return {
        ...state,
        weeklyChallenge: payload,
      }
    case AUTH.LOGOUT_SUCCESS:
      return initialState
    default:
      return state
  }
}
