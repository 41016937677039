import React from 'react'

const WaitingForTeamComponent = React.lazy(
  () => import('./waiting-for-team.component')
)

export default {
  to: '/home/my-team/waiting',
  component: WaitingForTeamComponent,
  exact: true,
  isAdmin: false,
}
