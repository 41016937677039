import client from '../../../gql/client'
import {
  UserCredentials,
  ForgotPasswordRequest,
  ChangePasswordRequest,
  ForgotPasswordVerifyRequest,
  RefreshTokenRequest,
  UserSignupCredentials,
} from '../store/auth.types'

import { AUTH_MUTATIONS } from '../mutations/auth.mutations'

const loginService = (credentials: UserCredentials): Promise<any> =>
  client.mutate({
    mutation: AUTH_MUTATIONS.LOGIN,
    variables: {
      email: credentials.email,
      password: credentials.password,
    },
  })

const signUpService = (credentials: UserSignupCredentials): Promise<any> =>
  client.mutate({
    mutation: AUTH_MUTATIONS.SIGNUP,
    variables: {
      ...credentials,
    },
  })

const forgotPasswordService = (request: ForgotPasswordRequest): Promise<any> =>
  client.mutate({
    mutation: AUTH_MUTATIONS.FORGOT_PASSWORD,
    variables: {
      email: request.email,
    },
  })

const forgotPasswordVerifyService = (
  request: ForgotPasswordVerifyRequest
): Promise<any> =>
  client.mutate({
    mutation: AUTH_MUTATIONS.FORGOT_PASSWORD_VERIFY,
    variables: {
      code: request.code,
      email: request.email,
    },
  })

const changePasswordService = (request: ChangePasswordRequest): Promise<any> =>
  client.mutate({
    mutation: AUTH_MUTATIONS.CHANGE_PASSWORD,
    variables: {
      password: request.password,
      email: request.email,
      code: request.code,
    },
  })

const refreshTokenService = (request: RefreshTokenRequest): Promise<any> =>
  client.mutate({
    mutation: AUTH_MUTATIONS.REFRESH_TOKEN,
    variables: {
      accessToken: request.accessToken,
      refreshToken: request.refreshToken,
      expiresIn: request.expiresIn,
    },
  })

const AUTH_SERVICE = {
  LOGIN_SERVICE: loginService,
  SIGNUP_SERVICE: signUpService,
  FORGOT_PASSWORD_SERVICE: forgotPasswordService,
  FORGOT_PASSWORD_VERIFY_SERVICE: forgotPasswordVerifyService,
  CHANGE_PASSWORD_SERVICE: changePasswordService,
  REFRESH_TOKEN_SERVICE: refreshTokenService,
}

export default AUTH_SERVICE
