import { useMutation } from '@apollo/client'
import { useDisclosure } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { TEAM_MUTATIONS } from '../mutations/teams.mutations'
import { TeamInterface } from '../store/coaches.types'

type IUseDeleteTeam = {
  title: string
  subtitle: string
  isDeleteDashboardModalOpen: boolean
  loading: boolean
  onOpenDeleteDashboardModal: () => void
  onCloseDeleteDashboardModal: () => void
  handleDeleteTeam: () => void
}

export const useDeleteTeam = ({
  team,
}: {
  team: TeamInterface
}): IUseDeleteTeam => {
  const {
    isOpen: isDeleteDashboardModalOpen,
    onOpen: onOpenDeleteDashboardModal,
    onClose: onCloseDeleteDashboardModal,
  } = useDisclosure()
  const history = useHistory()

  const [deleteTeam, { loading }] = useMutation(TEAM_MUTATIONS.DELETE_TEAM, {
    onCompleted: () => {
      onCloseDeleteDashboardModal()
      history.replace('/home/manager-portal')
    },
  })

  const handleDeleteTeam = () => {
    deleteTeam({ variables: { teamId: team.id } })
  }

  return {
    title: 'Delete Dashboard',
    subtitle: `Are you sure you want to delete ${team?.name}?`,
    isDeleteDashboardModalOpen,
    loading,
    onOpenDeleteDashboardModal,
    onCloseDeleteDashboardModal,
    handleDeleteTeam,
  }
}
