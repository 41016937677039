import { all, call, put, takeLatest } from 'redux-saga/effects'
import { PayloadedAction } from '../../../types'
import AuthService from './auth.services'
import {
  UserCredentials,
  AUTH,
  ForgotPasswordRequest,
  ForgotPasswordVerifyRequest,
  RefreshTokenRequest,
  UserSignupCredentials,
} from '../store/auth.types'

import {
  setAuthActionInProgress,
  setLoginSuccess,
  setLoginFailure,
  setPasswordResetSuccess,
  setPasswordResetFailure,
  setTokenRefreshSuccess,
  setTokenRefreshFailure,
  setSignUpSuccess,
  setSignUpFailure,
} from '../store/auth.actions'

function* userLogin(action: PayloadedAction<UserCredentials>): any {
  yield put(setAuthActionInProgress(true))
  try {
    const response = yield call<any>(AuthService.LOGIN_SERVICE, action.payload)
    const data = response.data.loginUser

    localStorage.setItem('accessToken', data.accessToken)
    localStorage.setItem('refreshToken', data.refreshToken)
    localStorage.setItem('expiresIn', data.expiresIn)
    data.isAuthenticated = true
    yield put(setLoginSuccess(data))
  } catch (err) {
    let error =
      "We're having some trouble logging you in. Please try again later."
    if (
      err.message ===
      'Error logging in user. Error verifying login. There is no user with that email.'
    ) {
      error = 'Email not found'
    } else if (
      err.message === 'Error logging in user. Wrong password. 401:Unauthorized'
    ) {
      error = 'Incorrect password'
    } else if (
      err.message.includes(
        'Error logging in user. Error verifying login. Try limit reached.'
      )
    ) {
      error = err.message.replace(
        'Error logging in user. Error verifying login. Try limit reached.',
        'Error logging in user. You have reached your try limit.'
      )
    } else if (
      err.message.includes(
        "You've signed up using Discord. Please use Discord to log back in."
      )
    ) {
      error =
        "You've signed up using Discord. Please use Discord to log back in."
    } else if (err.message.includes('Wrong login type')) {
      error =
        'Error logging in user. No discord ID found. Please sign up using Discord.'
    } else {
      error = err.message
    }

    yield put(setLoginFailure({ message: error }))
  } finally {
    yield put(setAuthActionInProgress(false))
  }
}

function* loginSaga() {
  yield takeLatest(AUTH.LOGIN_REQUEST, userLogin)
}

function* forgotPassword(action: PayloadedAction<ForgotPasswordRequest>): any {
  yield put(setAuthActionInProgress(true))
  try {
    const response = yield call<any>(
      AuthService.FORGOT_PASSWORD_SERVICE,
      action.payload
    )
    yield put(
      setPasswordResetSuccess({
        email: action.payload?.email,
        requestSent: Boolean(response.data.forgotPassword),
      })
    )
  } catch (err) {
    yield put(setPasswordResetFailure({ message: err.message }))
  } finally {
    yield put(setAuthActionInProgress(false))
  }
}

function* forgotPasswordVerify(
  action: PayloadedAction<ForgotPasswordVerifyRequest>
): any {
  yield put(setAuthActionInProgress(true))
  try {
    const response = yield call<any>(
      AuthService.FORGOT_PASSWORD_VERIFY_SERVICE,
      action.payload
    )
    yield put(
      setPasswordResetSuccess({
        code: action.payload?.code,
        codeVerified: Boolean(response.data.forgotPasswordVerify),
      })
    )
  } catch (err) {
    yield put(setPasswordResetFailure({ message: err.message }))
  } finally {
    yield put(setAuthActionInProgress(false))
  }
}

function* changePassword(
  action: PayloadedAction<ForgotPasswordVerifyRequest>
): any {
  yield put(setAuthActionInProgress(true))
  try {
    const response = yield call<any>(
      AuthService.CHANGE_PASSWORD_SERVICE,
      action.payload
    )
    yield put(
      setPasswordResetSuccess({
        passwordReset: Boolean(response.data.forgotPasswordFinal),
      })
    )
  } catch (err) {
    yield put(setPasswordResetFailure({ message: err.message }))
  } finally {
    yield put(setAuthActionInProgress(false))
  }
}

function* refreshToken(action: PayloadedAction<RefreshTokenRequest>): any {
  try {
    const response = yield call<any>(
      AuthService.REFRESH_TOKEN_SERVICE,
      action.payload
    )
    yield put(setTokenRefreshSuccess(response.data.refreshToken))
  } catch (err) {
    yield put(
      setTokenRefreshFailure({
        isAuthenticated: false,
        message: err.message,
      })
    )
  }
}

function* forgotPasswordSaga() {
  yield takeLatest(AUTH.FORGOT_PASSWORD_REQUEST, forgotPassword)
  yield takeLatest(AUTH.FORGOT_PASSWORD_VERIFY_REQUEST, forgotPasswordVerify)
  yield takeLatest(AUTH.CHANGE_PASSWORD_REQUEST, changePassword)
  yield takeLatest(AUTH.REFRESH_TOKEN_REQUEST, refreshToken)
}

function* userSignup(action: PayloadedAction<UserSignupCredentials>): any {
  yield put(setAuthActionInProgress(true))
  try {
    const response = yield call<any>(AuthService.SIGNUP_SERVICE, action.payload)
    const data = response.data.signUpUser
    localStorage.setItem('accessToken', data.accessToken)
    localStorage.setItem('refreshToken', data.refreshToken)
    localStorage.setItem('expiresIn', data.expiresIn)
    data.isAuthenticated = true
    yield put(setSignUpSuccess(data))
  } catch (err) {
    yield put(setSignUpFailure({ message: err.message }))
  } finally {
    yield put(setAuthActionInProgress(false))
  }
}

function* signUpSaga() {
  yield takeLatest(AUTH.SIGNUP_REQUEST, userSignup)
}

export default function* authSaga() {
  yield all([loginSaga(), forgotPasswordSaga(), signUpSaga()])
}
