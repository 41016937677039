import {
  CategoryEnum,
  PillarCategory,
  VideoInterface,
} from '../content/store/content.types'

export type VideoRecommendationType = {
  id: string
  title: string
  description: string
  pillarSlug: PillarCategory | string
  questionSlug: CategoryEnum
  answer: string
  thumbnailUrl: string
  selectedThumbnail: string
  Video: VideoInterface
  url: string
}

export const PillarVideoCategories: any[] = [
  {
    mental: [
      {
        activity: 'emotional-state',
        options: ['Good', 'Bad', 'Neutral'],
      },
    ],
  },
  {
    sleep: [
      {
        activity: 'sleep-quality',
        options: ['terrible', 'neutral', 'good', 'great'],
      },
      {
        activity: 'sleep-disturbance',
        options: [
          'discomfort',
          'nightmare',
          'noise',
          'pet',
          'temperature',
          'toilet',
          'worry',
          'other',
        ],
      },
    ],
  },
  {
    physical: [
      {
        activity: 'pain',
        options: [
          'back',
          'elbows',
          'eyes',
          'fingers',
          'head',
          'hips',
          'knees',
          'neck',
          'shoulders',
          'wrist',
        ],
      },
    ],
  },
]
