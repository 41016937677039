import React, { useContext } from 'react'
import { Tooltip, Icon } from '@chakra-ui/react'
import { FiLogOut } from 'react-icons/fi'
import { MdAccountBalanceWallet } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import {
  ISidebarProvider,
  SidebarContext,
} from '../../../../context/SidebarContext/Context'
import { config } from '../../../../config'
import { AuthPayload } from '../../../auth/store/auth.types'
import { TeamContext } from '../../../../context/TeamContext/Context'

import {
  selectTokens,
  selectIsTeamManager,
} from '../../../auth/store/auth.selectors'

import { setLogoutSuccess } from '../../../auth/store/auth.actions'

type Props = {}

export const SideBarFooter: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const tokens: AuthPayload = useSelector(selectTokens)
  const { setSelectedTeam } = useContext<any>(TeamContext)
  const isTeamManager: boolean = useSelector(selectIsTeamManager)
  const { url } = config

  const { setIsMobileSidebarOpen } = useContext(
    SidebarContext
  ) as ISidebarProvider

  const goToBilling = () => {
    setIsMobileSidebarOpen(false)
    window.open(
      `${url}api/v1/create-customer-portal-session/${tokens.accessToken}`,
      '_blank'
    )
  }
  const signOutHandler = () => {
    setSelectedTeam(null)
    dispatch(setLogoutSuccess())
  }

  return (
    <div className="sidebar-footer">
      {isTeamManager && (
        <Tooltip hasArrow label="Billing" placement="right">
          <Icon className="sidebar-footer-item" onClick={goToBilling}>
            <MdAccountBalanceWallet />
          </Icon>
        </Tooltip>
      )}
      <Tooltip hasArrow label="Sign Out" placement="right">
        <Icon className="sidebar-footer-item" onClick={signOutHandler}>
          <FiLogOut />
        </Icon>
      </Tooltip>
    </div>
  )
}
