import { gql } from '@apollo/client'

export const getJournalCommentsMethod = 'getJournalComments'
const getJournalComments = gql`
  query getJournalComments($teamId: String!, $date: String!, $userId: String!) {
    ${getJournalCommentsMethod}(teamId: $teamId, date: $date, userId: $userId) {
      id
      pillarSlug
      questionSlug
      date
      content
      createdAt
      updatedAt
      userId
      teamId
      user {
        fullName
        avatar
      }
    }
  }
`

export const getRepliedCommentMethod = 'getRepliedComment'
const getRepliedComment = gql`
  query getRepliedComment($answerTo: String!) {
    ${getRepliedCommentMethod}(answerTo: $answerTo) {
      id
      pillarSlug
      questionSlug
      date
      content
      createdAt
      updatedAt
      userId
      teamId
      user {
        fullName
        avatar
      }
    }
  }
`

export const getUserAnswerBySlugMethod = 'userAnswerBySlug'
const userAnswerBySlug = gql`
  query userAnswerBySlug($questionSlug: String!, $date: String!, $userId: String!) {
    ${getUserAnswerBySlugMethod}(questionSlug: $questionSlug, date: $date, userId: $userId) {
      value
    }
  }
`

export const JOURNAL_QUERIES = {
  JOURNAL_COMMENTS: getJournalComments,
  USER_ANSWER: userAnswerBySlug,
  REPLIED_COMMENT: getRepliedComment,
}
