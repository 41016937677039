import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { PAYMENT_QUERIES } from '../../components/payments/queries/payments.queries'
import CustomSelect from '../../components/shared/ui/select'

type Props = {
  onSuccess(): void
}

const frequencyOptions = [
  { value: 'yearly', label: 'Yearly' },
  { value: 'monthly', label: 'Monthly' },
]

function AddPaymentForm({ onSuccess }: Props) {
  const [email, setEmail] = useState('')
  const [paymentFrequency, setPaymentFrequency] = useState('monthly')

  const [createPayment, { error }] = useMutation(PAYMENT_QUERIES.CREATE_PAYMENT)

  const handleSubmit = async () => {
    try {
      await createPayment({
        variables: {
          data: {
            email,
            paymentFrequency,
          },
        },
      })
      onSuccess()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {error && <Text color="red.500">{error.message}</Text>}
      <HStack>
        <FormControl id="date-filter" width="300px">
          <FormLabel margin="0" fontSize="14px">
            Email
          </FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl id="date-filter" width="150px">
          <FormLabel margin="0" fontSize="14px">
            Payment Frequency
          </FormLabel>
          <CustomSelect
            name="payment-frequency"
            defaultValue={paymentFrequency}
            onSelect={setPaymentFrequency}
            options={frequencyOptions}
          />
        </FormControl>
      </HStack>
      <Button
        mt="20px"
        onClick={handleSubmit}
        disabled={!email || !paymentFrequency}
        mb="20px"
      >
        Add Payment
      </Button>
    </>
  )
}

export default AddPaymentForm
