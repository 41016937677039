import { gql } from '@apollo/client'

export const scoreStatsMethod = 'myScoreStats'
const scoreStats = gql`
  query MyScoreStats($userId: String, $type: String!, $startDate: String!, $endDate: String!) {
    ${scoreStatsMethod}(userId: $userId, query: {type: $type, startDate: $startDate, endDate: $endDate}) {
      type
      count
      mainScoreStatistics {
        pillarSlug
        points {
          date
          value
        }
      }
      perPillarScoreStatistics {
        pillarSlug
        points {
          date
          value
        }
      }
    }
  }
`
export const teamScoreStatsMethod = 'myTeamScoreStats'
const teamScoreStats = gql`
  query TeamScoreStats($type: String!, $startDate: String!, $endDate: String!, $teamId: String) {
    ${teamScoreStatsMethod}(query: {type: $type, startDate: $startDate, endDate: $endDate} , teamId: $teamId) {
      type
      count
      mainScoreStatistics {
        pillarSlug
        points {
          date
          value
        }
      }
      perPillarScoreStatistics {
        pillarSlug
        points {
          date
          value
        }
      }
    }
  }
`

export const proTeamDataStatsMethod = 'myTeamDataStats'
const proTeamDataStats = gql`
  query ProTeamDataStats($startDate: String!, $endDate: String!, $teamId: String) {
    ${proTeamDataStatsMethod}(query: {startDate: $startDate, endDate: $endDate} , teamId: $teamId) {
      userScoreStatistics {
        date
        id
        fitgmrScore
        gameReadiness
        pillarSlug
        score
        pureScore
        completenessScore
        totalAnswers
        totalQuestions
      }
      userSpecificData{
        username
        id
        birthGender
        actualGender
        country
        birthDate
        waterIntakePreference
        weight
        height
        timeToBed
        timeOutOfBed
        hoursAtDesk
        gameRoutines
        sleepRoutines
        waistCircumference
        hipCircumference
        healthyMeals
      }
    }
  }
`

export const teamDataStatsMethod = 'myTeamDataStats'
const teamDataStats = gql`
  query TeamDataStats($startDate: String!, $endDate: String!, $teamId: String) {
    ${teamDataStatsMethod}(query: {startDate: $startDate, endDate: $endDate} , teamId: $teamId) {
      userScoreStatistics {
        date
        id
        fitgmrScore
        gameReadiness
        pillarSlug
        score
        pureScore
        completenessScore
        totalAnswers
        totalQuestions
      }
    }
  }
`

export const userAnswers = 'userAnswers'
const activityPillarStatisticsAnswers = gql`
  query ${userAnswers}(
    $startDate: String!
    $endDate: String!
    $userId: String!
    $pillarSlug: String!
  ) {
    userAnswers(
      where: {
        date: { gte: $startDate, lt: $endDate }
        userId: {
          equals:  $userId
        },
        question: {
          is: {
            pillarSlug: {
              equals: $pillarSlug
            }
          }
        }
      }
    ) {
      date
      questionSlug
      question {
        pillarSlug
      }
      value
      id
    }
  }
`

export const ACTIVITY_QUERIES = {
  SCORE_STATS: scoreStats,
  TEAM_SCORE_STATS: teamScoreStats,
  TEAM_DATA_STATS: teamDataStats,
  PRO_TEAM_DATA_STATS: proTeamDataStats,
  ACTIVITY_ANSWERS: activityPillarStatisticsAnswers,
}
