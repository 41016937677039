import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  description: Yup.string()
    .min(2, 'Too Short!')
    .max(1000, 'Description should be less than 1000 characters.')
    .nullable(),
  game: Yup.string().nullable(),
  avatar: Yup.string().nullable(),
})

export default validationSchema
