import React from 'react'

const DeleteComponent = React.lazy(() => import('./user-delete.component'))

export default {
  to: ['/home/users/:id/disable', '/home/users/:id/enable'],
  component: DeleteComponent,
  exact: true,
  isAdmin: true,
}
