import { createSelector } from 'reselect'

import { VideoStateInterface } from './content.types'
import { RootState } from '../../../types'

const selectVideoState = (state: RootState): VideoStateInterface =>
  state.content

export const selectVideoList = createSelector(
  selectVideoState,
  (state) => state.videos
)

export const selectS3Data = createSelector(
  selectVideoState,
  (state) => state.s3Video
)

export const selectSelectedVideo = createSelector(
  selectVideoState,
  (_: any, id: string) => id,
  (state, id) => state.videos.filter((i) => i.id === id)[0]
)

export const selectSelectedSerie = createSelector(
  selectVideoState,
  (_: any, id: string) => id,
  (state, id) => state.series.filter((i) => i.id === id)[0]
)

export const selectIsLoading = createSelector(
  selectVideoState,
  (state) => state.isLoading
)

export const selectIsUploading = createSelector(
  selectVideoState,
  (state) => state.isUploading
)

export const selectError = createSelector(selectVideoState, (state) =>
  state.error ? state.error.message : ''
)

export const selectUploadedVideoId = createSelector(selectVideoState, (state) =>
  state.uploadedVideo?.id ? state.uploadedVideo?.id : ''
)

export const selectUploadedVideoStatus = createSelector(
  selectVideoState,
  (state) => state.uploadedVideo?.workflowStatus
)

export const selectUploadedVideo = createSelector(
  selectVideoState,
  (state) => state.uploadedVideo
)

export const selectInstructorFilterOptions = createSelector(
  selectVideoState,
  (state) => {
    const options: any[] = []
    const instructors = state.instructors || []
    for (let i = 0; i < instructors.length; i++) {
      const name: string = instructors[i].fullName
      const { id } = instructors[i]
      if (id) options.push({ id, name })
    }
    const set = new Set([...options])
    return Array.from(set)
  }
)

export const selectSeriesFilterOptions = createSelector(
  selectVideoState,
  (state) => {
    const options: any[] = []
    const series = state.series || []
    for (let i = 0; i < series.length; i++) {
      const { name } = series[i]
      const { id } = series[i]
      if (id) options.push({ id, name })
    }

    const set = new Set([...options])
    return Array.from(set)
  }
)

export const selectInstructors = createSelector(
  selectVideoState,
  (state) => state.instructors || []
)
export const selectTotalInstructors = createSelector(
  selectVideoState,
  selectInstructors,
  (state, instructors) => instructors.length
)

export const selectSeries = createSelector(
  selectVideoState,
  (state) => state.series || []
)

export const selectTotalSeries = createSelector(
  selectVideoState,
  selectSeries,
  (state, series) => series.length
)

export const selectTotalVideos = createSelector(
  selectVideoState,
  (state) => state.totalVideos
)

export const selectVideoTags = createSelector(
  selectVideoState,
  (state) => state.tags || []
)

export const selectStagingSeries = createSelector(
  selectVideoState,
  (state) => state.stagingSeries
)

export const selectUpdateSeries = createSelector(
  selectVideoState,
  (state) => state.updatedSeries
)
