import React from 'react'

import { SortOrder } from '../../../types'

export { SortOrder } from '../../../types'

export interface WeeklyChallengeState {
  isLoading: boolean
  error: WeeklyChallengeError | null
  weeklyChallenge: WeeklyChallenge[]
}

export interface WeeklyChallengeCreateRequest {
  year: number
  week: number
  description: string
}

export interface WeeklyChallengeError {
  graphQLErrors?: []
  networkError?: number
  message: string
}

export interface DateRange {
  from: Date
  to: Date
}

export enum WEEKLY_CHALLENGE {
  LOADING = 'challenge:loading',
  WEEKLY_CHALLENGE_REQUEST = 'challenge:create:request',
  WEEKLY_CHALLENGE_SUCCESS = 'challenge:create:success',
  WEEKLY_CHALLENGE_FAILED = 'challenge:create:failed',
  WEEKLY_CHALLENGE_LIST_REQUEST = 'challenge:list:request',
  WEEKLY_CHALLENGE_LIST_SUCCESS = 'challenge:list:success',
  WEEKLY_CHALLENGE_LIST_FAILED = 'challenge:list:failed',
  WEEKLY_CHALLENGE_UPDATE_REQUEST = 'challenge:update:request',
  WEEKLY_CHALLENGE_UPDATE_SUCCESS = 'challenge:update:success',
  WEEKLY_CHALLENGE_UPDATE_FAILED = 'challenge:update:failed',
  WEEKLY_CHALLENGE_DELETE_REQUEST = 'challenge:delete:request',
  WEEKLY_CHALLENGE_DELETE_SUCCESS = 'challenge:delete:success',
  WEEKLY_CHALLENGE_DELETE_FAILED = 'challenge:delete:failed',
}

export interface WeeklyChallenge {
  id: string
  year: number
  week: number
  description: string
}

export interface CalendarComponentProps extends React.PropsWithChildren<any> {
  selectedWeek: number
  selectedDays: Date[]
  onDayClick: (date: Date) => void
  onDayMouseEnter: (date: Date) => void
  onDayMouseLeave: () => void
  onWeekClick: (week: any, days: any, e: any) => void
  modifiers?: any
}

enum WeeklyChallengeScalarField {
  'id',
  'year',
  'week',
  'description',
}

type WeeklyChallengeOrderByInput = {
  id?: SortOrder
  year?: SortOrder
  week?: SortOrder
  description?: SortOrder
}

export interface WeeklyChallengeListRequest {
  orderBy?: WeeklyChallengeOrderByInput
  take?: number
  skip?: number
  distinct?: WeeklyChallengeScalarField
}

export interface ChallengeCardProps extends React.PropsWithChildren<any> {
  active: boolean
  id: string
  year: number
  week: number
  description: string
}

type WeeklyChallengeYearWeekCompound = {
  year: number
  week: number
}

interface ChallengeWhereUniqueInput {
  id?: string
  // eslint-disable-next-line camelcase
  year_week?: WeeklyChallengeYearWeekCompound
}

export interface WeeklyChallengeDeleteRequest {
  where: ChallengeWhereUniqueInput
}

type StringFieldUpdateOperationsInput = {
  set: string
}

type IntFieldUpdateOperationsInput = {
  set?: number
  increment?: number
  decrement?: number
  multiply?: number
  divide?: number
}

interface WeeklyChallengeUpdateInput {
  id?: StringFieldUpdateOperationsInput
  year?: IntFieldUpdateOperationsInput
  week?: IntFieldUpdateOperationsInput
  description?: StringFieldUpdateOperationsInput
}

export interface WeeklyChallengeUpdateRequest {
  where: ChallengeWhereUniqueInput
  data: WeeklyChallengeUpdateInput
}
