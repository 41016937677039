import React from 'react'

const UploadVideo = React.lazy(
  () => import('../../../pages/Content/UploadVideo')
)
const List = React.lazy(() => import('../../../pages/Content/ContentList'))

const ContentList = React.lazy(
  () => import('../../../pages/Content/ContentList')
)

export default [
  {
    to: '/home/videos',
    component: List,
    label: 'Upload Video',
    exact: true,
    isAdmin: true,
    level: 1,
    children: [],
  },
  {
    to: '/home/video/upload',
    component: UploadVideo,
    label: 'Upload Video',
    exact: true,
    isAdmin: true,
    level: 1,
    children: [],
  },
  {
    to: '/home/videos/:id/edit',
    component: ContentList,
    exact: true,
    isAdmin: true,
    level: 1,
    children: [],
  },
]
