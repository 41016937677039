import { PayloadedAction } from '../../../types'

import {
  WEEKLY_CHALLENGE,
  WeeklyChallengeCreateRequest,
  WeeklyChallenge,
  WeeklyChallengeError,
  WeeklyChallengeListRequest,
  WeeklyChallengeDeleteRequest,
  WeeklyChallengeUpdateRequest,
} from './challenge.types'

export const setWeeklyChallengeActionInProgress: (
  isLoading: boolean
) => PayloadedAction = (isLoading) => ({
  type: WEEKLY_CHALLENGE.LOADING,
  payload: {
    isLoading,
  },
})

export const setWeeklyChallengeRequest: (
  data: WeeklyChallengeCreateRequest
) => PayloadedAction = (data) => ({
  type: WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_REQUEST,
  payload: data,
})

export const setWeeklyChallengeSuccess: (
  payload: WeeklyChallenge
) => PayloadedAction = (payload) => ({
  type: WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_SUCCESS,
  payload: {
    ...payload,
  },
})

export const setWeeklyChallengeFailure: (
  failPayload: WeeklyChallengeError
) => PayloadedAction = (failPayload) => ({
  type: WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_FAILED,
  payload: {
    ...failPayload,
  },
})

export const setWeeklyChallengeListRetrieveRequest: (
  data: WeeklyChallengeListRequest
) => PayloadedAction = (data) => ({
  type: WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_LIST_REQUEST,
  payload: data,
})

export const setWeeklyChallengeListRetrieveSuccess: (
  payload: WeeklyChallenge
) => PayloadedAction = (payload) => ({
  type: WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_LIST_SUCCESS,
  payload,
})

export const setWeeklyChallengeListRetrieveFailure: (
  failPayload: WeeklyChallengeError
) => PayloadedAction = (failPayload) => ({
  type: WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_LIST_FAILED,
  payload: {
    ...failPayload,
  },
})

export const setWeeklyChallengeUpdateRequest: (
  data: WeeklyChallengeUpdateRequest
) => PayloadedAction = (data) => ({
  type: WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_UPDATE_REQUEST,
  payload: data,
})

export const setWeeklyChallengeUpdateFailure: (
  failPayload: WeeklyChallengeError
) => PayloadedAction = (failPayload) => ({
  type: WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_UPDATE_FAILED,
  payload: {
    ...failPayload,
  },
})

export const setWeeklyChallengeDeleteRequest: (
  data: WeeklyChallengeDeleteRequest
) => PayloadedAction = (data) => ({
  type: WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_DELETE_REQUEST,
  payload: data,
})

export const setWeeklyChallengeDeleteFailure: (
  failPayload: WeeklyChallengeError
) => PayloadedAction = (failPayload) => ({
  type: WEEKLY_CHALLENGE.WEEKLY_CHALLENGE_DELETE_FAILED,
  payload: {
    ...failPayload,
  },
})
