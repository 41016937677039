import React from 'react'
import { Appearance } from '@stripe/stripe-js'
import { ReactComponent as SleepIcon } from '../assets/images/pillar_icons/sleep.svg'
import { ReactComponent as NutritionIcon } from '../assets/images/pillar_icons/nutrition.svg'
import { ReactComponent as MentalIcon } from '../assets/images/pillar_icons/mental.svg'
import { ReactComponent as PhysicalIcon } from '../assets/images/pillar_icons/physical.svg'
import { ReactComponent as LifestyleIcon } from '../assets/images/pillar_icons/lifestyle.svg'
import { ReactComponent as GamePerformance } from '../assets/images/pillar_icons/game_readiness.svg'
import { ReactComponent as GameReadiness } from '../assets/images/pillar_icons/game_perf.svg'

/* eslint-disable camelcase */
export enum FitGmrPillars {
  physical = 'physical',
  nutrition = 'nutrition',
  mental = 'mental',
  lifestyle = 'lifestyle',
  sleep = 'sleep',
}

export enum ExtraPillars {
  game_readiness = 'game_readiness',
  game_perf = 'game_perf',
}

export const fitgmrMainColor = '#A541F4'
export const pillarColors: {
  [key in FitGmrPillars | ExtraPillars | 'empty']: string
} = {
  physical: '#FF9901',
  nutrition: '#4DF9A6',
  mental: '#F84B75',
  lifestyle: '#3EB1F2',
  sleep: '#C985FF',
  game_readiness: '#A541F4',
  game_perf: '#A541F4',

  empty: '#272727',
}

export const pillarOrder: Array<FitGmrPillars> = [
  FitGmrPillars.sleep,
  FitGmrPillars.physical,
  FitGmrPillars.nutrition,
  FitGmrPillars.mental,
  FitGmrPillars.lifestyle,
]

export type IPillar = {
  title: string
  color: string
  slug: string
}

export const AllPillarsList: IPillar[] = [
  {
    color: pillarColors[FitGmrPillars.sleep],
    title: FitGmrPillars.sleep,
    slug: FitGmrPillars.sleep,
  },
  {
    color: pillarColors[FitGmrPillars.physical],
    title: FitGmrPillars.physical,
    slug: FitGmrPillars.physical,
  },
  {
    color: pillarColors[FitGmrPillars.nutrition],
    title: FitGmrPillars.nutrition,
    slug: FitGmrPillars.nutrition,
  },
  {
    color: pillarColors[FitGmrPillars.mental],
    title: FitGmrPillars.mental,
    slug: FitGmrPillars.mental,
  },
  {
    color: pillarColors[FitGmrPillars.lifestyle],
    title: FitGmrPillars.lifestyle,
    slug: FitGmrPillars.lifestyle,
  },
  {
    color: pillarColors[ExtraPillars.game_readiness],
    title: 'Game readiness',
    slug: ExtraPillars.game_readiness,
  },
  {
    color: pillarColors[ExtraPillars.game_perf],
    title: 'Game performance',
    slug: ExtraPillars.game_perf,
  },
]

export const AllPillarIcons: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  [FitGmrPillars.sleep]: SleepIcon,
  [FitGmrPillars.physical]: PhysicalIcon,
  [FitGmrPillars.nutrition]: NutritionIcon,
  [FitGmrPillars.mental]: MentalIcon,
  [FitGmrPillars.lifestyle]: LifestyleIcon,
  [ExtraPillars.game_perf]: GamePerformance,
  [ExtraPillars.game_readiness]: GameReadiness,
}

export const gamePerformanceGraphColor = [
  '#3EB1F2',
  '#4DF9A6',
  '#FF9901',
  '#F84B75',
  '#A541F4',
  '#7c263b',
]

export const ELEMENT_OPTIONS = {
  style: {
    base: {
      backgroundColor: '#272727',
      fontSize: '16px',
      color: '#707070',
      fontStyle: 'normal',
      fontWeight: 'lighter',
      fontFamily: 'inherit',
      '::placeholder': {
        color: '#707070',
        fontSize: '18px',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

export const CARD_ELEMENT_OPTIONS = {
  placeholder: '16 digits',
  style: {
    base: {
      backgroundColor: '#272727',
      fontSize: '16px',
      color: '#707070',
      fontStyle: 'normal',
      fontWeight: 'lighter',
      fontFamily: 'inherit',
      '::placeholder': {
        color: '#707070',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

export const CVC_ELEMENT_OPTIONS = {
  placeholder: '3 digits',
  style: {
    base: {
      backgroundColor: '#272727',
      fontSize: '16px',
      color: '#707070',
      fontStyle: 'normal',
      fontWeight: 'lighter',
      fontFamily: 'inherit',
      '::placeholder': {
        color: '#707070',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

export const EXPIRY_OPTIONS = {
  placeholder: 'MM / YY',
  style: {
    base: {
      backgroundColor: '#272727',
      fontSize: '16px',
      color: '#707070',
      fontStyle: 'normal',
      fontWeight: 'lighter',
      fontFamily: 'inherit',
      '::placeholder': {
        color: '#707070',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

export const appearance: Appearance = {
  theme: 'night',
  variables: {
    colorBackground: '#404040',
  },
}
