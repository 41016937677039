import React from 'react'

import { VStack, InputGroup } from '@chakra-ui/react'

import CreatableSelect from 'react-select/creatable'
import { MultiValue } from 'react-select'

interface TagInputProps {
  onTagAdded: (values: string[]) => void
  defaultTags?: string[]
  tagOptions?: string[]
  placeholder?: string
}

const TagInput: React.FC<TagInputProps> = ({
  onTagAdded,
  defaultTags = [],
  tagOptions,
  placeholder,
}) => {
  const handleChange = (
    val: MultiValue<{ value: string; label: string }>
    // meta: ActionMeta<any>
  ) => {
    onTagAdded(val.map((i) => i.value))
  }

  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      cursor: 'pointer',
      border: '1px solid #444',
      background: 'transparent',
      height: 'var(--chakra-sizes-10)',

      '&:active': {
        color: '#6a6a6a',
      },
    }),
    container: (styles: any) => ({
      ...styles,
      width: '100%',
    }),
    input: (styles: any) => ({
      ...styles,
      color: 'white',
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: '#3a3a3a',
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: '#e2e8f0',
    }),
    menu: (styles: any) => ({
      ...styles,
      zIndex: 100,
      backgroundColor: '#2c2c2c',
    }),
    option: (styles: any, { isFocused, isSelected }: any) => ({
      ...styles,
      backgroundColor: isFocused
        ? '#3a3a3a'
        : isSelected
        ? '#3a3a3a'
        : undefined,
    }),
  }

  return (
    <VStack width="100%">
      <InputGroup>
        <CreatableSelect
          isMulti
          onChange={handleChange}
          styles={customStyles}
          options={tagOptions?.map((i) => ({ label: i, value: i }))}
          placeholder={placeholder || 'Search or create a new tag'}
          hideSelectedOptions
          defaultValue={defaultTags.map((i: string) => ({
            label: i,
            value: i,
          }))}
          value={
            defaultTags.length > 1
              ? {
                  label: `${defaultTags.length} Selected`,
                  value: 'Selected',
                }
              : defaultTags.map((i: string) => ({
                  label: i,
                  value: i,
                }))
          }
        />
      </InputGroup>
    </VStack>
  )
}

export default TagInput
