import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { PayloadedAction } from '../../../types'
import UserService from './users.services'
import {
  USER,
  UserFetchRequest,
  UserListMetadataRequest,
  UserListMetadataResponse,
  UserListRequest,
  UserUpdatePayload,
} from '../store/users.types'

import {
  setActionFailure,
  setActionInProgress,
  setActionListSuccess,
  setActionSuccess,
  setListMetadataSuccess,
} from '../store/users.actions'
import { selectSelectedUser } from '../store/users.selectors'

function* userList(action: PayloadedAction<UserListRequest>): any {
  yield put(setActionFailure(USER.USER_LIST_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      UserService.USER_LIST_SERVICE,
      action.payload
    )
    const { data } = response
    yield put(setActionListSuccess(USER.USER_LIST_SUCCESS, data))
  } catch (err) {
    const error =
      "We're having some trouble fetching the user list. Please try again later."
    yield put(setActionFailure(USER.USER_LIST_FAILED, { message: error }))
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* userListMetadata(
  action: PayloadedAction<UserListMetadataRequest>
): any {
  yield put(setActionFailure(USER.USER_LIST_METADATA_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      UserService.USER_LIST_METADATA_SERVICE,
      action.payload
    )
    const data: UserListMetadataResponse = response.data.aggregateUser
    const { _count } = data
    yield put(
      setListMetadataSuccess(USER.USER_LIST_METADATA_SUCCESS, {
        totalUsers: _count?.id || 0,
      })
    )
  } catch (err) {
    const error =
      "We're having some trouble fetching the user list metadata. Please try again later."
    yield put(
      setActionFailure(USER.USER_LIST_METADATA_FAILED, { message: error })
    )
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* userFetch(action: PayloadedAction<UserFetchRequest>): any {
  yield put(setActionFailure(USER.USER_FETCH_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      UserService.USER_FETCH_SERVICE,
      action.payload
    )
    const { data } = response
    yield put(setActionSuccess(USER.USER_FETCH_SUCCESS, data))
  } catch (err) {
    const error =
      "We're having some trouble fetching the user list. Please try again later."
    yield put(setActionFailure(USER.USER_FETCH_FAILED, { message: error }))
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* userUpdate(action: PayloadedAction<UserUpdatePayload>): any {
  yield put(setActionFailure(USER.USER_UPDATE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const user = yield select(selectSelectedUser)
    const { role, ...userUpdateData } = action.payload?.data!

    if (role?.set !== user.role) {
      yield call<any>(UserService.USER_CHANGE_ROLE_SERVICE, {
        role: role?.set,
        email: user.email,
      })
    }
    const response = yield call<any>(UserService.USER_UPDATE_SERVICE, {
      where: action.payload?.where,
      data: userUpdateData,
    })
    const data = response.data.updateUser
    yield put(setActionSuccess(USER.USER_UPDATE_SUCCESS, data))
  } catch (err) {
    const error = err.message
    yield put(setActionFailure(USER.USER_UPDATE_FAILED, { message: error }))
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* userDelete(action: PayloadedAction<UserListRequest>): any {
  yield put(setActionFailure(USER.USER_DELETE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(UserService.USER_DELETE_SERVICE, action)
    const data = response.data.deleteUser
    yield put(setActionSuccess(USER.USER_DELETE_SUCCESS, data))
  } catch (err) {
    const error =
      "We're having some trouble deleting this user. Please try again later."
    yield put(setActionFailure(USER.USER_DELETE_FAILED, { message: error }))
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* userDisable(action: PayloadedAction<UserListRequest>): any {
  yield put(setActionFailure(USER.USER_DISABLE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      UserService.USER_DISABLE_SERVICE,
      action.payload
    )
    const data = response.data.disableUser
    yield put(setActionSuccess(USER.USER_DISABLE_SUCCESS, data))
  } catch (err) {
    const error =
      "We're having some trouble disabling this user. Please try again later."
    yield put(setActionFailure(USER.USER_DISABLE_FAILED, { message: error }))
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* userEnable(action: PayloadedAction<UserListRequest>): any {
  yield put(setActionFailure(USER.USER_ENABLE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      UserService.USER_ENABLE_SERVICE,
      action.payload
    )
    const data = response.data.enableUser
    yield put(setActionSuccess(USER.USER_ENABLE_SUCCESS, data))
  } catch (err) {
    const error =
      "We're having some trouble enabling this user. Please try again later."
    yield put(setActionFailure(USER.USER_ENABLE_FAILED, { message: error }))
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* userSagas() {
  yield takeLatest(USER.USER_LIST_REQUEST, userList)
  yield takeLatest(USER.USER_LIST_METADATA_REQUEST, userListMetadata)
  yield takeLatest(USER.USER_FETCH_REQUEST, userFetch)
  yield takeLatest(USER.USER_UPDATE_REQUEST, userUpdate)
  yield takeLatest(USER.USER_DELETE_REQUEST, userDelete)
  yield takeLatest(USER.USER_DISABLE_REQUEST, userDisable)
  yield takeLatest(USER.USER_ENABLE_REQUEST, userEnable)
}

export default function* userSaga() {
  yield all([userSagas()])
}
