import { createSelector } from 'reselect'

import { WeeklyChallengeState } from './challenge.types'
import { RootState } from '../../../types'

export const selectChallengeState: (state: RootState) => WeeklyChallengeState =
  (state) => state.weeklyChallenge

export const selectIsLoading = createSelector(
  selectChallengeState,
  (state: WeeklyChallengeState) => state.isLoading
)

export const selectError = createSelector(
  selectChallengeState,
  (state: WeeklyChallengeState) => (state.error ? state.error.message : '')
)

export const selectChallengeList = createSelector(
  selectChallengeState,
  (state: WeeklyChallengeState) => state.weeklyChallenge
)
