// IMPORTS
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Input, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react'
import { SearchIcon, CloseIcon } from '@chakra-ui/icons'
import { motion, AnimatePresence } from 'framer-motion'
import './Search.scss'

// TYPES
interface SearchBoxInterface {
  icon?: boolean
  val?: string
  placeholder?: string
  className?: string
  loading?: boolean
  clearInput?: boolean
  onChange: (value: string) => void
}

// COMPONENT
export const SearchBox = ({
  icon,
  placeholder,
  onChange,
  val = '',
  loading = false,
  className,
  clearInput,
}: SearchBoxInterface) => {
  const [search, updateSearch] = useState<string>(val)
  const inputRef = useRef(null)
  const onUpdateSearch = (e: any) => {
    const { value } = e.target
    updateSearch(value)
    onChange(value)
  }
  const onClearSearch = useCallback(() => {
    updateSearch('')
    onChange('')
  }, [onChange])

  const onSearchClick = () => {
    if (inputRef) {
      // @ts-ignore
      inputRef.current.focus()
    }
  }

  useEffect(() => {
    updateSearch(val)
  }, [val])

  // to clear the search from outside the component
  // used to clear the search after closing the gamer popover
  useEffect(() => {
    if (clearInput) onClearSearch()
  }, [clearInput])

  return (
    <AnimatePresence>
      <InputGroup width="auto" className={`search-input ${className}`}>
        <Input
          as={motion.input}
          whileFocus={{ width: '290px' }}
          width="240px"
          ref={inputRef}
          type="text"
          variant="outline"
          value={search}
          placeholder={placeholder || 'Search'}
          onChange={onUpdateSearch}
          bgColor="#272727"
          color="Gray"
          borderRadius="4px"
          focusBorderColor="transparent"
          border="none"
        />
        <InputRightElement className="right-element">
          {loading ? (
            <Spinner />
          ) : search.length ? (
            <CloseIcon aria-label="close search" onClick={onClearSearch} />
          ) : (
            icon && <SearchIcon aria-label="search" onClick={onSearchClick} />
          )}
        </InputRightElement>
      </InputGroup>
    </AnimatePresence>
  )
}

export default SearchBox
