import {
  Box,
  Flex,
  HStack,
  Text,
  Icon,
  Input,
  useDisclosure,
  Modal,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
} from '@chakra-ui/react'
import React, { ForwardedRef, useEffect, useState } from 'react'

import {
  AiOutlineCheckCircle,
  AiOutlineDelete,
  AiOutlineEdit,
} from 'react-icons/ai'

import moment from 'moment'

import { useDispatch } from 'react-redux'
import {
  setWeeklyChallengeDeleteRequest,
  setWeeklyChallengeUpdateRequest,
} from '../store/challenge.actions'
import {
  ChallengeCardProps,
  WeeklyChallengeDeleteRequest,
  WeeklyChallengeUpdateRequest,
} from '../store/challenge.types'
import './ChallengeCard.scss'

const WeeklyChallengeCard: React.FC<ChallengeCardProps> = React.forwardRef(
  (
    { id, week, year, description, active = true },
    ref: ForwardedRef<HTMLDivElement | null>
  ) => {
    const dispatch = useDispatch()
    const startDate = moment().day('Sunday').year(year).week(week).toDate()
    const endDate = moment().day('Saturday').year(year).week(week).toDate()

    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [challengeDescription, setChallengeDescription] =
      useState<string>(description)

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
      setChallengeDescription(description)
    }, [setChallengeDescription, description])

    const handleUpdate = () => {
      if (description === challengeDescription) {
        setIsEditing(false)
      } else {
        const data: WeeklyChallengeUpdateRequest = {
          where: {
            id,
          },
          data: {
            description: {
              set: challengeDescription,
            },
          },
        }
        dispatch(setWeeklyChallengeUpdateRequest(data))
        setIsEditing(false)
      }
    }

    const handleDelete = () => {
      const data: WeeklyChallengeDeleteRequest = {
        where: {
          id,
        },
      }
      dispatch(setWeeklyChallengeDeleteRequest(data))
      onClose()
    }

    return (
      <div ref={ref} className="card-container">
        <Box
          key={id}
          maxW="sm"
          bgColor="#353535"
          padding={4}
          width="95%"
          minW="268px"
          className={active ? 'card active' : 'card inactive'}
          data-testid="card"
        >
          {isEditing ? (
            <Input
              color="white"
              fontWeight="bold"
              fontSize="1.15rem"
              value={challengeDescription}
              onChange={(e) => setChallengeDescription(e.target.value)}
            />
          ) : (
            <Text color="white" fontWeight="bold" fontSize="1.15rem">
              {challengeDescription}
            </Text>
          )}
          <Text>
            {moment(startDate).format('MMM D, YYYY')} -{' '}
            {moment(endDate).format('MMM D, YYYYY')}
          </Text>
          <Text fontSize="0.85rem">
            Week {week} of year {year}
          </Text>
          <Flex justifyContent="flex-end">
            <HStack color="white">
              {isEditing ? (
                <Icon
                  onClick={handleUpdate}
                  cursor="pointer"
                  boxSize="1.25rem"
                  as={AiOutlineCheckCircle}
                />
              ) : (
                <>
                  <Icon
                    onClick={onOpen}
                    cursor="pointer"
                    boxSize="1.25rem"
                    as={AiOutlineDelete}
                  />
                  <Icon
                    onClick={() => setIsEditing(true)}
                    cursor="pointer"
                    boxSize="1.25rem"
                    as={AiOutlineEdit}
                  />
                </>
              )}
            </HStack>
          </Flex>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            bgColor="#353535"
            color="white"
            border="1px"
            borderColor="whiteAlpha.300"
            borderRadius="1px"
          >
            <ModalHeader />
            <ModalCloseButton />
            <ModalBody>
              <Text textAlign="center">
                You are about to delete the challenge for week{' '}
                <Text fontSize="1.15rem" fontWeight="bold">
                  {moment(startDate).format('MMM D, YYYY')} -{' '}
                  {moment(endDate).format('MMM D, YYYY')}.
                </Text>{' '}
                Are you sure you want to proceed?
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="brand" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={handleDelete} colorScheme="red">
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    )
  }
)

export default WeeklyChallengeCard
