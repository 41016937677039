import React, { useCallback, useEffect, useState } from 'react'
import { CloseIcon } from '@chakra-ui/icons'
import {
  HStack,
  Container,
  Box,
  Flex,
  Heading,
  Spinner,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'

import {
  UserInterface,
  UserUpdatePayload,
} from '../../components/users/store/users.types'

import UserForm from '../../components/users/ui/form/user.form'
import validationSchema from '../../components/users/ui/form/user.validation-schema'

import { setUpdateRequest } from '../../components/users/store/users.actions'

type UserDetailsProps = {
  selectedRow: UserInterface | null
  setSelectedRow: (user: UserInterface | null) => void
  handleDisableItem: (id: string) => void
  handleEnableItem: (id: string) => void
}

export default function UsersDetail({
  selectedRow,
  setSelectedRow,
  handleDisableItem,
  handleEnableItem,
}: UserDetailsProps) {
  const handleDone = () => {
    setSelectedRow(null)
  }

  const dispatch = useDispatch()

  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [enabled, setEnabled] = useState<boolean | undefined>(
    selectedRow?.enabled
  )

  useEffect(() => {
    if (isUpdated) {
      setIsUpdated(false)
    }
  }, [enabled, isUpdated])

  const handleSubmit = useCallback(
    (values: UserInterface, setSubmitting: (val: boolean) => void) => {
      const data: UserUpdatePayload = {
        where: {
          id: values.id,
        },
        data: {
          fullName: { set: values.fullName },
          username: { set: values.username },
          role: { set: values.role },
        },
      }

      dispatch(setUpdateRequest(data))
      setSubmitting(false)
      setSelectedRow(null)
    },
    []
  )

  const content = !selectedRow ? (
    <Flex w="100%" h="30vh" align="center" justify="center">
      <Spinner size="xl" />
    </Flex>
  ) : (
    <Formik
      enableReinitialize
      initialValues={selectedRow || {}}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting)
      }}
    >
      {(formProps: any) => (
        <UserForm
          selectedRow={selectedRow}
          setIsUpdated={setIsUpdated}
          setEnabled={setEnabled}
          handleDisableItem={handleDisableItem}
          handleEnableItem={handleEnableItem}
          {...formProps}
        />
      )}
    </Formik>
  )

  return (
    <Container>
      <HStack h="auto" pt="5" justifyContent="space-between" mb="20px">
        <Box>
          <Flex justifyContent="flex-start">
            <Heading mr="2" size="md" color="white">
              Details
            </Heading>
          </Flex>
        </Box>

        <CloseIcon
          cursor="pointer"
          boxSize={4}
          onClick={handleDone}
          alignSelf="start"
        />
      </HStack>
      <Box position="relative">{content}</Box>
    </Container>
  )
}
