import { all, call, put, takeLatest } from 'redux-saga/effects'
import { PayloadedAction } from '../../../types'
import TeamService from './coaches.services'
import {
  TEAM,
  TeamCreatePayload,
  TeamFetchRequest,
  TeamInvitePayload,
  TeamRemoveUserPayload,
  TeamUpdatePayload,
} from '../store/coaches.types'

import {
  setFetchTeamFailure,
  setFetchTeamSuccess,
  setUpdateTeamFailure,
  setUpdateTeamSuccess,
  setActionInProgress,
  sendTeamInviteFailure,
  setCreateTeamFailure,
  setCreateTeamSuccess,
  setRemoveUserFailure,
  setRemoveUserSuccess,
  setRemoveTeamInviteSuccess,
} from '../store/coaches.actions'
import { setUpdateUserTeam } from '../../auth/store/auth.actions'

function* teamFetch(action: PayloadedAction<TeamFetchRequest>): any {
  yield put(setFetchTeamFailure(TEAM.TEAM_FETCH_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      TeamService.TEAM_FETCH_SERVICE,
      action.payload
    )
    const { data } = response
    yield put(setFetchTeamSuccess(TEAM.TEAM_FETCH_SUCCESS, data))
  } catch (err) {
    const error =
      "We're having some trouble fetching the team list. Please try again later."
    yield put(setFetchTeamFailure(TEAM.TEAM_FETCH_FAILED, { message: error }))
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* teamUpdate(action: PayloadedAction<TeamUpdatePayload>): any {
  yield put(setUpdateTeamFailure(TEAM.TEAM_UPDATE_FAILED, { message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      TeamService.TEAM_UPDATE_SERVICE,
      action.payload
    )
    const data = response.data.updateTeam
    yield put(setUpdateTeamSuccess(data))
  } catch (err) {
    const error = err.message
    yield put(setUpdateTeamFailure(TEAM.TEAM_UPDATE_FAILED, { message: error }))
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* teamCreate(action: PayloadedAction<TeamCreatePayload>): any {
  yield put(setFetchTeamFailure(TEAM.TEAM_FETCH_FAILED, { message: '' }))
  yield put(setCreateTeamFailure({ message: '' }))
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      TeamService.TEAM_CREATE_SERVICE,
      action.payload
    )
    const data = response.data.createTeam
    yield put(setCreateTeamSuccess(data))
    yield put(setUpdateUserTeam(data))
  } catch (err) {
    const error = err.message
    yield put(setCreateTeamFailure({ message: error }))
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* teamInvite(action: PayloadedAction<TeamInvitePayload>): any {
  yield put(setActionInProgress(true))
  try {
    yield call<any>(TeamService.TEAM_INVITE_SERVICE, action.payload)
  } catch (err) {
    const error = err.message
    yield put(sendTeamInviteFailure({ message: error }))
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* teamDeleteInvite(action: PayloadedAction<String>): any {
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      TeamService.TEAM_DELETE_INVITE_SERVICE,
      action.payload
    )

    const data = response.data.deleteTeamInvite
    yield put(setRemoveTeamInviteSuccess(data))
  } catch (err) {
    const error = err.message
    yield put(setRemoveUserFailure({ message: error }))
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* removeUser(action: PayloadedAction<TeamRemoveUserPayload>): any {
  yield put(setActionInProgress(true))
  try {
    const response = yield call<any>(
      TeamService.TEAM_REMOVE_USER,
      action.payload
    )
    const data = response.data.removeTeamMember
    yield put(setRemoveUserSuccess(data))
  } catch (err) {
    const error = err.message
    yield put(setRemoveUserFailure({ message: error }))
  } finally {
    yield put(setActionInProgress(false))
  }
}

function* teamSagas() {
  yield takeLatest(TEAM.TEAM_FETCH_REQUEST, teamFetch)
  yield takeLatest(TEAM.TEAM_UPDATE_REQUEST, teamUpdate)
  yield takeLatest(TEAM.TEAM_CREATE_REQUEST, teamCreate)
  yield takeLatest(TEAM.TEAM_INVITE_REQUEST, teamInvite)
  yield takeLatest(TEAM.TEAM_REMOVE_USER_REQUEST, removeUser)
  yield takeLatest(TEAM.TEAM_DELETE_INVITE_REQUEST, teamDeleteInvite)
}

export default function* teamSaga() {
  yield all([teamSagas()])
}
