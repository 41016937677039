import { PayloadedAction } from '../../../types'

import {
  USER,
  UserCrudPayload,
  UserErrorInterface,
  UserInterface,
  UserListMetadataPayload,
  UserListRequest,
  UserUpdatePayload,
} from './users.types'

export const setActionInProgress = (isLoading: boolean): PayloadedAction => ({
  type: USER.USER_ACTION_LOADING,
  payload: {
    isLoading,
  },
})

export const setActionListSuccess = (
  type: USER,
  payload: UserInterface[]
): PayloadedAction => ({
  type,
  payload,
})

export const setActionListRequest = (
  type: USER,
  payload: UserListRequest
): PayloadedAction => ({
  type,
  payload,
})

export const setActionRequest = (
  type: USER,
  payload: UserCrudPayload
): PayloadedAction => ({
  type,
  payload,
})

export const setActionSuccess = (
  type: USER,
  payload: UserInterface
): PayloadedAction => ({
  type,
  payload,
})

export const setActionFailure = (
  type: USER,
  payload: UserErrorInterface
): PayloadedAction => ({
  type,
  payload: {
    error: payload,
  },
})

export const setUpdateRequest = (
  payload: UserUpdatePayload
): PayloadedAction => ({
  type: USER.USER_UPDATE_REQUEST,
  payload,
})

export const setListMetadataSuccess = (
  type: USER,
  payload: UserListMetadataPayload
): PayloadedAction => ({
  type,
  payload,
})
