import { gql } from '@apollo/client'

export const currentUserVideosMethod = 'myVideos'
const getRecommendations = gql`
  query ($take: Float, $skip: Float) {
    getRecommendations(take: $take, skip: $skip) {
      list {
        id
        title
        description
        pillarSlug
        questionSlug
        answer
        Video {
          id
          title
          description
          selectedThumbnail
          url
        }
      }
      count
    }
  }
`

export const RECOMMENDATION_QUERIES = {
  GET_RECOMMENDATION: getRecommendations,
}
